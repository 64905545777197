import styled from 'styled-components';
import { Box } from '@mui/material';
import { cellPadding } from 'src/components/DataGrid/DataGrid.styles';

export const StyledStatusLabel = styled.span`
  display: inline-block;
  padding: 8px 10px;
  border-radius: 20px;
  margin-left: 6px;
  font-size: 11px;
  line-height: normal;
  color: ${({ theme }) => theme.palette.lightBlack};
  font-family: ${({ theme }) => theme.fonts.demiBold};
  background-color: #F7F8FA;
  text-transform: uppercase;
`;

export const LabelContainer = styled(Box)`
  display: flex;
  align-items: center;
  
  ${cellPadding}
`;
