import styled from 'styled-components';
import { Typography } from '@mui/material';
import { generalTableCellStyles } from 'src/components/TableSection/TableSection.styles';

export const StyledFullName = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};

        ${generalTableCellStyles}
    }
`;

export const StyledEmail = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.grey};

        ${generalTableCellStyles}
    }
`;

export const StyledJobInfo = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightGrey};

        ${generalTableCellStyles}
    }
`;
