import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/root-reducer';
import type { AccessCodeState } from 'src/@types/redux';

const initialState: AccessCodeState = {
    accessCode: '',
    isValid: undefined,
};

export { initialState as accessCodeInitialState };

export const accessCodeSlice = createSlice<AccessCodeState, {
    setAccessCode: CaseReducer<AccessCodeState, PayloadAction<string>>;
    setIsValid: CaseReducer<AccessCodeState, PayloadAction<boolean>>;
}>({
    name: 'accessCode',
    initialState,
    reducers: {
        setAccessCode: (state, { payload: accessCode }) => ({
            ...state,
            accessCode,
            isValid: undefined,
        }),
        setIsValid: (state, { payload: isValid }) => ({
            ...state,
            isValid,
        }),
    },
});

export const {
    setAccessCode,
    setIsValid,
} = accessCodeSlice.actions;

export const accessCodeSelector = (state: RootState): AccessCodeState => state[accessCodeSlice.name];
