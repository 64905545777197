import { SubscriptionAction } from 'src/constants';
import { cancelProfileSubscription, restoreProfileSubscription } from 'src/services/sso-api';
import {
    cancelSubscription,
    restoreSubscription,
    resumeSubscription,
    pauseSubscription,
    terminateSubscription,
} from 'src/services/subscription-service-api';

type SubscriptionHandler = (subscriptionUUID: string) => Promise<void>;

type ActionFunctionMapping = Record<SubscriptionAction, SubscriptionHandler>;

const udbActionFunctionMapping: ActionFunctionMapping = {
    [SubscriptionAction.CANCEL]: cancelSubscription,
    [SubscriptionAction.RESTORE]: restoreSubscription,
    [SubscriptionAction.PAUSE]: pauseSubscription,
    [SubscriptionAction.RESUME]: resumeSubscription,
    [SubscriptionAction.TERMINATE]: terminateSubscription,
};

const profileActionFunctionMapping: Partial<ActionFunctionMapping> = {
    [SubscriptionAction.CANCEL]: cancelProfileSubscription,
    [SubscriptionAction.RESTORE]: restoreProfileSubscription,
};

const getSubscriptionAction = (isUdb: boolean, selectedAction: SubscriptionAction) => {
    return isUdb ? udbActionFunctionMapping[selectedAction] : profileActionFunctionMapping[selectedAction];
};

export default getSubscriptionAction;
