import React, { FC } from 'react';
import { EditSocialMediaModalFormValues } from 'src/components/Modals/EditSocialMediaModal/EditSocialMediaModal';
import { StartInputAdornment } from 'src/components/Inputs/components';
import { LabeledInput } from 'src/components/Inputs';
import { ReactComponent as TwitterIcon } from 'src/assets/icons/twitter-icon.svg';

export type SocialXInputProps = {
    forceChangeValues?: (newValues: { socialX: string | undefined }) => void;
    values?: EditSocialMediaModalFormValues;
    initialValues?: EditSocialMediaModalFormValues;
    hasRevertButton?: boolean;
    isRevertBtnVisible?: boolean;
};

export const SocialXInput: FC<SocialXInputProps> = ({
    hasRevertButton,
    forceChangeValues,
    values,
    initialValues,
    isRevertBtnVisible,
}) => (
    <LabeledInput
        disallowSpace
        testId="socialX-input"
        name="socialX"
        label="X (Twitter)"
        placeholder="Link to your X (Twitter) account"
        inputProps={{
            startAdornment: (
                <StartInputAdornment>
                    <TwitterIcon />
                </StartInputAdornment>
            ),
        }}
        flex={1}
        isRevertBtnVisible={isRevertBtnVisible}
        hasRevertButton={hasRevertButton}
        onRevert={() => {
            if (forceChangeValues) {
                forceChangeValues({
                    ...values,
                    socialX: initialValues?.socialX,
                });
            }
        }}
    />
);
