import React, { ChangeEvent, FC, SyntheticEvent } from 'react';
import { Box, Grid, Stack, StackProps } from '@mui/material';
import { Field, FieldInputProps, FieldProps, FormikProps } from 'formik';
import type { GridColumnParams } from 'src/@types/newsletters-set';
import { NewsletterModel } from 'src/@types/sso-api';
import {
    StyledDateTimeContainer,
    StyledDescription,
    StyledSampleIssueContainer,
    StyledTitle,
} from 'src/components/NewslettersSet/components/NewsletterItem/NewsletterItem.styles';
import { Checkbox } from 'src/components/Checkbox';
import { ReactComponent as LinkIcon } from 'src/assets/icons/link-icon.svg';
import { dateTimeFormatter } from 'src/services/newsletter-formatters';
import { VerticalsFormValues } from 'src/components/NewslettersDelivery/NewslettersDelivery';
import { TABLE_SECTION_DEFAULT_COLUMNS, UDBNewsletterChannel } from 'src/constants';

type NewsLetterProps = {
    newsletter?: NewsletterModel & {
        selectedChannels?: UDBNewsletterChannel[];
    };
    changeHandler?: (event: SyntheticEvent, newValues?: Record<string, boolean>) => void;
    onCheckboxChange?: (checked: boolean, name: string) => void;
    isSMSDisabled?: boolean;
    isEmailDisabled?: boolean;
    SMSTooltipText?: string;
    paddingBottom?: StackProps['paddingBottom'];
    paddingTop?: StackProps['paddingTop'];
    productNameOnly?: boolean;
    subscribeAll?: boolean;
    columnParams: GridColumnParams;
};

const NewsletterItem: FC<NewsLetterProps> = ({
    newsletter = {} as NewsletterModel,
    onCheckboxChange,
    changeHandler,
    isSMSDisabled,
    SMSTooltipText,
    paddingTop,
    paddingBottom,
    isEmailDisabled,
    subscribeAll,
    productNameOnly,
    columnParams,
}) => {
    const { name, description, sendDay, sendTime, customSendTime, sampleIssue, id } = newsletter;

    const handleCheck = (
        field: FieldInputProps<VerticalsFormValues>,
        form: FormikProps<VerticalsFormValues>,
    ) => (
        event: SyntheticEvent,
    ) => {
        const { checked } = event.target as HTMLInputElement;
        const itemsKey = field.name.split('_')[1];
        const uncheckUnsubscribeAllCheckBoxProps = { target: {
            type: 'checkbox',
            name: 'unsubscribeAll',
            value: '',
            checked: false,
        } };

        const newValues = {
            ...form.values,
            [field.name]: checked,
            unsubscribeAll: false,
        };

        const allItems = Object.entries(newValues)
            .filter(([key]) => key.split('_')[1] === itemsKey && key !== `subscribeAll_${itemsKey}`)
            .map(([, value]) => value);

        const updatedValues = {
            ...newValues,
            ...(subscribeAll && { [`subscribeAll_${itemsKey}`]: allItems.every(Boolean) }),
        };

        onCheckboxChange?.(checked, field.name);
        form.setValues(updatedValues).then(() => {
            changeHandler?.(event, updatedValues);
            changeHandler?.(uncheckUnsubscribeAllCheckBoxProps as ChangeEvent<HTMLInputElement>);
        });
    };

    return (
        <Stack
            paddingTop={paddingTop}
            paddingBottom={paddingBottom ?? (productNameOnly ? { sm: 2.75, xs: 2 } : 2)}
            position="relative"
            alignItems="center"
            justifyContent="center"
        >
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <Grid item display="flex" alignItems="center" {...columnParams.newsletters}>
                    <Box paddingRight={2}>
                        <Stack direction="row" spacing={0.75}>
                            <StyledTitle>
                                {name}
                            </StyledTitle>
                        </Stack>
                        {!productNameOnly && (
                            <StyledDescription>
                                {description}
                            </StyledDescription>
                        )}
                    </Box>
                </Grid>
                <Grid item display="flex" alignItems="center" {...columnParams.date}>
                    <StyledDateTimeContainer>
                        {customSendTime || dateTimeFormatter(sendDay, sendTime)}
                    </StyledDateTimeContainer>
                </Grid>
                <Grid item display="flex" alignItems="center" {...columnParams.sampleIssue}>
                    {sampleIssue && (
                        <>
                            <Box display={{ xs: 'none', md: 'inline' }}>
                                <StyledSampleIssueContainer target="_blank" href={sampleIssue}>
                                    <LinkIcon />
                                        Read here
                                </StyledSampleIssueContainer>
                            </Box>
                            <Box display={{ xs: 'inline', md: 'none' }}>
                                <StyledSampleIssueContainer target="_blank" href={sampleIssue}>
                                        Sample issue
                                </StyledSampleIssueContainer>
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
            <Stack direction="row" spacing={{ xs: 1, md: 3.25 }} position="absolute" right={0}>
                <Stack width="45px" {...columnParams.email}>
                    <Field name={`${id}_email`} type="checkbox">
                        {({ field, form }: FieldProps) => (
                            <Checkbox
                                name={field.name}
                                value={field.value}
                                data-testid={field.name}
                                onClick={handleCheck(field, form)}
                                checked={field.checked}
                                disabled={isEmailDisabled}
                            />
                        )}
                    </Field>
                </Stack>
                <Stack width="45px" {...columnParams.sms}>
                    <Field name={`${id}_sms`} type="checkbox">
                        {({ field, form }: FieldProps) => (
                            <Checkbox
                                tooltipPlacement="bottom-start"
                                tooltipTitle={SMSTooltipText}
                                name={field.name}
                                value={field.value}
                                data-testid={field.name}
                                onClick={handleCheck(field, form)}
                                checked={field.checked}
                                disabled={isSMSDisabled}
                            />
                        )}
                    </Field>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default NewsletterItem;
