import styled, { css } from 'styled-components';

const variantSelector = ({ variant = 'horizontal' }: { variant?: 'horizontal' | 'vertical' }) => {
    if (variant === 'horizontal') {
        return css`
            width: 100%;
        `;
    }

    return css`
        height: 100%;
    `;
};

const variantPseudoElementsSelector = ({ variant = 'horizontal' }: { variant?: 'horizontal' | 'vertical' }) => {
    if (variant === 'horizontal') {
        return css`
            width: 100%;
            height: 1px;
        `;
    }

    return css`
        width: 1px;
        height: 100%;
    `;
};

const Line = styled.span<{ variant?: 'horizontal' | 'vertical' }>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.palette.lightGrey};
    line-height: 16px;
    font-size: 16px;

    ${variantSelector}
    
    &:before, &:after {
        content: '';
        background: ${({ theme }) => theme.palette.dirtyWhite};

        ${variantPseudoElementsSelector}
    }

    &:before {
        margin-right: ${({ children }) => (children ? '10px' : 0)};
    }

    &:after {
        margin-left: ${({ children }) => (children ? '10px' : 0)};
    }
`;

export default Line;
