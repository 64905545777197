import React from 'react';
import { useSelector } from 'react-redux';
import { resetPassword } from 'src/services/sso-api';
import { accessCodeSelector } from 'src/redux/slices';
import { ResetPasswordForm } from 'src/components/Forms/ResetPasswordForm';
import { SingleModalLayout } from 'src/layouts';
import { LinkExpiredContent } from 'src/components/LinkExpiredContent';
import { SpinnerPage } from 'src/pages/Spinner';
import type { AccessCodeState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

const ResetPasswordPage: React.FC = () => {
    const { accessCode, isValid } = useSelector<RootState, AccessCodeState>(accessCodeSelector);

    const handleSubmit = async (newPassword: string, repeatNewPassword: string) => {
        await resetPassword({ newPassword, repeatNewPassword, accessCode });
    };

    if (isValid === undefined) {
        return <SpinnerPage />;
    }

    return (
        <SingleModalLayout testId="reset-password-page">
            {isValid ? (
                <ResetPasswordForm onSubmit={handleSubmit} submitLabel="Reset password" />
            ) : (
                <LinkExpiredContent
                    testId="reset-password-link-invalid-message"
                    mainButtonLink="/reset-password"
                    mainButtonText="Get a new reset password link"
                />
            )}
        </SingleModalLayout>
    );
};

export default ResetPasswordPage;
