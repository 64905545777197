import React, { FC, useEffect, useState } from 'react';
import { BoxProps } from '@mui/material';
import { LabeledSelect } from 'src/components/Select/LabeledSelect';
import { OptionsType } from 'src/components/Select/Select';
import { SnackbarMessageVariants } from 'src/constants';
import { getIndustriesList } from 'src/services/unified-db-api';
import { useSnackbarMessage } from 'src/hooks';

type IndustrySelectProps = {
    onChangeHandler?: (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => void;
    onRevert?: () => void;
    hasRevertButton?: boolean;
    isRevertBtnVisible?: boolean;
} & BoxProps;

export const IndustrySelect: FC<IndustrySelectProps> = ({
    onChangeHandler,
    hasRevertButton,
    onRevert,
    isRevertBtnVisible,
    ...boxProps
}) => {
    const [industriesList, setIndustriesList] = useState<OptionsType[]>([]);

    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getIndustriesList()
            .then((data) => (
                data
                    .map(({ id, name }) => ({ value: `${id}`, label: name }))
                    .sort((a, b) => a.label.localeCompare(b.label))
            ))
            .catch(() => {
                addMessage('Failed to fetch industries list', SnackbarMessageVariants.ERROR);

                return [];
            })
            .then((data) => setIndustriesList([{ value: '', label: 'None' }, ...data]));
    }, []);

    return (
        <LabeledSelect
            testId="industry-input"
            name="industryId"
            label="Industry"
            placeholder="Select industry"
            onFormChange={onChangeHandler}
            options={industriesList}
            hasRevertButton={hasRevertButton}
            onRevert={onRevert}
            isRevertBtnVisible={isRevertBtnVisible}
            { ...boxProps}
        />
    );
};
