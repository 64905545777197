import { SchemaType } from 'src/@types/tool-bar';
import {
    EmailStatus,
    MULTI_SELECT_CONDITIONS,
    NUMBER_CONDITIONS,
    paymentTypeMapping,
    subscriptionStatusMapping,
    SubscriptionType,
    TEXT_CONDITIONS,
    TIME_CONDITIONS,
    ToolBarConditionTypes,
    ToolBarModelTypes,
} from 'src/constants';
import {
    dateValidator,
    multiSelectValidator,
    stringValidator,
} from 'src/components/ToolBar/services/tool-bar-validators';

export const defaultFilteringSchema: SchemaType = {
    modelType: ToolBarModelTypes.FILTERING,
    quickAction: {
        label: 'Quick filters',
        title: 'Select quick filter',
        options: [
            { label: 'Only active subscriptions', value: 'onlyActiveSubscriptions' },
            { label: 'Only canceled subscriptions', value: 'onlyCanceledSubscriptions' },
            { label: 'Only expired subscriptions', value: 'onlyExpiredSubscriptions' },
            { label: 'On grace period', value: 'onGracePeriodSubscriptions' },
            { label: 'Expiring next week', value: 'expiringNextWeekSubscriptions' },
            { label: 'Expiring this week', value: 'expiringThisWeekSubscriptions' },
            { label: 'Expired this week', value: 'expiredThisWeekSubscriptions' },
            { label: 'Expired last week', value: 'expiredLastWeekSubscriptions' },
        ],
    },
    fields: {
        activationDate: {
            order: 1,
            label: 'Activation date',
            value: 'activationDate',
            conditionType: ToolBarConditionTypes.DATE_OR_DATE_TIME,
            conditionOptions: TIME_CONDITIONS,
            validateConditionValue: dateValidator,
        },
        expirationDate: {
            order: 2,
            label: 'Expiration date',
            value: 'expirationDate',
            conditionType: ToolBarConditionTypes.DATE_OR_DATE_TIME,
            conditionOptions: TIME_CONDITIONS,
            validateConditionValue: dateValidator,
        },
        gracePeriodEndDate: {
            order: 3,
            label: 'Grace period end date',
            value: 'gracePeriodEndDate',
            conditionType: ToolBarConditionTypes.DATE_OR_DATE_TIME,
            conditionOptions: TIME_CONDITIONS,
            validateConditionValue: dateValidator,
        },
        paymentType: {
            order: 4,
            label: 'Payment type',
            value: 'paymentType',
            conditionType: ToolBarConditionTypes.MULTI_SELECT,
            conditionOptions: MULTI_SELECT_CONDITIONS,
            valueOptions: Object.entries(paymentTypeMapping).map(([value, label]) => ({
                label,
                value,
            })),
            validateConditionValue: multiSelectValidator(Object.keys(paymentTypeMapping)),
        },
        status: {
            order: 5,
            label: 'Subscription status',
            value: 'status',
            conditionType: ToolBarConditionTypes.MULTI_SELECT,
            conditionOptions: MULTI_SELECT_CONDITIONS,
            valueOptions: Object.entries(subscriptionStatusMapping).map(([value, label]) => ({
                label,
                value,
            })),
            validateConditionValue: multiSelectValidator(Object.keys(subscriptionStatusMapping)),
        },
        'owner.user.cmStatus': {
            order: 6,
            label: 'CM status',
            value: 'owner.user.cmStatus',
            conditionType: ToolBarConditionTypes.MULTI_SELECT,
            conditionOptions: MULTI_SELECT_CONDITIONS,
            valueOptions: Object.values(EmailStatus).map((status) => ({
                label: status,
                value: status,
            })),
            validateConditionValue: multiSelectValidator(Object.values(EmailStatus)),
        },
        'owner.userUUID': {
            order: 7,
            label: 'Owner UUID',
            value: 'owner.userUUID',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        uuid: {
            order: 8,
            label: 'Subscription UUID',
            value: 'uuid',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        stripeSubscriptionId: {
            order: 9,
            label: 'Stripe subscription',
            value: 'stripeSubscriptionId',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        stripeCustomerId: {
            order: 10,
            label: 'Stripe customer',
            value: 'stripeCustomerId',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        'owner.user.email': {
            order: 11,
            label: 'Email address',
            value: 'owner.user.email',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        'owner.user.fullName': {
            order: 12,
            label: 'Full name',
            value: 'owner.user.fullName',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        'owner.user.companyName': {
            order: 13,
            label: 'Company',
            value: 'owner.user.companyName',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
    },
};

export const getFilteringSchema = (type: SubscriptionType) => {
    if (type === SubscriptionType.ENTERPRISE) {
        return {
            ...defaultFilteringSchema,
            fields: {
                ...defaultFilteringSchema.fields,
                userCount: {
                    order: 14,
                    label: 'Users',
                    value: 'userCount',
                    conditionType: ToolBarConditionTypes.NUMBER_FIELD,
                    conditionOptions: NUMBER_CONDITIONS,
                    validateConditionValue: stringValidator,
                },
                'domains.domain': {
                    order: 15,
                    label: 'Domains',
                    value: 'domains.domain',
                    conditionType: ToolBarConditionTypes.TEXT_FIELD,
                    conditionOptions: TEXT_CONDITIONS,
                    validateConditionValue: stringValidator,
                },
            },
        };
    }
    return defaultFilteringSchema;
};
