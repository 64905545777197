import React, { FC, Fragment, useState } from 'react';
import { Box } from '@mui/material';
import { NoResultMessage, SearchResultLabel } from './AccountOwnerStep.styles';
import SearchManagerForm from 'src/components/Forms/ManagerModalForm/components/SearchManegerForm/SearchManagerForm';
import { UserInfoBox } from 'src/components/UserInfoBox';
import { PageSection } from 'src/components/PageSection';
import type { User } from 'src/@types/unified-db-api';
import { StepContainer } from 'src/pages/udb/CreateSubscription/components/StepContainer';

type AccountOwnerStepProps = {
    ownerData?: User;
    handleGoBack: (data: User | null) => void;
    handleNextButtonClick: (data: User) => void;
};

const AccountOwnerStep: FC<AccountOwnerStepProps> = ({
    ownerData,
    handleGoBack,
    handleNextButtonClick,
}) => {
    const [searchResult, setSearchResult] = useState<User | null>(ownerData || null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <StepContainer
            handleGoBack={() => handleGoBack(searchResult)}
            showGoBackButton
            title="Select account owner"
            showNextButton={!!searchResult}
            handleNextButtonClick={() => {
                handleNextButtonClick(searchResult!);
            }}
        >
            <PageSection isLoading={isLoading} isMultiSection variant="headless" header="Search owner">
                <SearchManagerForm
                    hideLabel
                    setIsLoading={setIsLoading}
                    setRequestResult={setSearchResult}
                    initialEmail={searchResult?.email.email}
                />
                {searchResult !== null && (
                    <Fragment>
                        {searchResult ? (
                            <Box paddingTop={3}>
                                <SearchResultLabel paddingBottom={{ xs: 1, sm: 1.5 }}>
                                    Search result
                                </SearchResultLabel>
                                <UserInfoBox
                                    data={searchResult}
                                    isManager={false}
                                />
                            </Box>
                        ) : (
                            <NoResultMessage paddingTop={{ xs: 3, sm: 4.5 }}>
                                No results found. Please try again.
                            </NoResultMessage>
                        )}
                    </Fragment>
                )}
            </PageSection>
        </StepContainer>
    );
};

export default AccountOwnerStep;
