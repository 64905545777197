import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { SingleModalForm } from 'src/components/Forms/components';
import { LabeledInput } from 'src/components/Inputs';
import { PasswordInput } from 'src/components/Inputs/PasswordInput';
import { Button } from 'src/components/Buttons';
import {
    companyNameValidator,
    emailValidator,
    fullNameValidator,
    jobTitleValidator,
    passwordValidator,
    strictPasswordValidator,
    validateSchemaObject,
} from 'src/services/validators';
import {
    COMPANY_NAME_IS_REQUIRED_MESSAGE,
    EMPTY_EMAIL_VALIDATION_ERROR,
    FULL_NAME_IS_REQUIRED_MESSAGE,
    JOB_TITLE_IS_REQUIRED_MESSAGE,
    MAX_FULL_NAME_LENGTH,
} from 'src/constants';
import type { RequiredDataFormValuesType } from 'src/@types/create-account';

type SignUpFormProps = {
    initialValues?: RequiredDataFormValuesType;
    isSubmitting: boolean;
    handleSubmit: (
        formValues: RequiredDataFormValuesType,
        form: FormikHelpers<RequiredDataFormValuesType>
    ) => void;
};

const SignUpRequiredDataForm: FC<SignUpFormProps> = (
    { initialValues, handleSubmit, isSubmitting },
) => {
    return (
        <SingleModalForm
            isSubmitting={isSubmitting}
            initialValues={{
                email: initialValues?.email ?? '',
                password: initialValues?.password ?? '',
                repeatPassword: initialValues?.repeatPassword ?? '',
                fullName: initialValues?.fullName ?? '',
                company: initialValues?.company ?? '',
                jobTitle: initialValues?.jobTitle ?? '',
            }}
            onSubmit={handleSubmit}
            validationSchema={validateSchemaObject({
                email: emailValidator.required(EMPTY_EMAIL_VALIDATION_ERROR),
                password: strictPasswordValidator,
                repeatPassword: passwordValidator,
                fullName: fullNameValidator.required(FULL_NAME_IS_REQUIRED_MESSAGE),
                company: companyNameValidator.required(COMPANY_NAME_IS_REQUIRED_MESSAGE),
                jobTitle: jobTitleValidator.required(JOB_TITLE_IS_REQUIRED_MESSAGE),
            })}
            testId="create-account-form-required-data"
            title="Create account"
            subtitle={(
                <>
                    Already have an account?&nbsp;
                    <NavLink to="/log-in">Log in</NavLink>
                </>
            )}
            fieldsBlock={(
                <>
                    <LabeledInput
                        disallowSpace
                        required
                        name="email"
                        label="Email address"
                        placeholder="Enter your email address"
                    />
                    <PasswordInput
                        required
                        name="password"
                        label="Enter password"
                        placeholder="Enter your password"
                        showAutoGenerate
                        hasActiveValidator
                        validator={strictPasswordValidator}
                    />
                    <PasswordInput
                        required
                        name="repeatPassword"
                        label="Confirm password"
                        placeholder="Confirm your password"
                        testId="repeat-password-input"
                    />

                    <LabeledInput
                        required
                        name="fullName"
                        label="Full name"
                        placeholder="Enter your full name"
                        inputMaxLength={MAX_FULL_NAME_LENGTH}
                    />
                    <LabeledInput
                        required
                        name="company"
                        label="Company"
                        placeholder="Enter your company name"
                    />
                    <LabeledInput
                        required
                        name="jobTitle"
                        label="Job title"
                        placeholder="Enter your job title"
                    />
                </>
            )}
            buttonsBlock={(
                <Button type="submit" testId="next-button">Next</Button>
            )}
        />
    );
};

export default SignUpRequiredDataForm;
