import React, { FC, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    StyledButton,
    StyledCaption,
    StyledDescription,
    StyledLogo,
    StyledPaper,
    StyledPrice,
    StyledTitleDescription,
} from './SubscriberSection.styles';
import Line from 'src/components/Line';
import { Button } from 'src/components/Buttons';

type SubscriberSectionProps = {
    logoComponent: React.ElementType;
    price: string;
    PriceDescription: string;
    features: ReactNode[];
    IconComponent: React.ElementType;
    caption?: string;
    buttonLabel: string;
    buttonLink: string;
    isStyledButton?: boolean;
};

const SubscriberSection: FC<SubscriberSectionProps> = ({
    logoComponent: LogoComponent,
    price,
    PriceDescription,
    caption,
    features,
    IconComponent,
    buttonLabel,
    buttonLink,
    isStyledButton = false,
}) => {
    const navigate = useNavigate();
    const ButtonComponent = isStyledButton ? StyledButton : Button;

    return (
        <StyledPaper>
            <StyledLogo>
                <LogoComponent />
            </StyledLogo>
            <Line />
            <Stack spacing={0.5} paddingTop={{ xs: 3.6, sm: 3 }} paddingBottom={3}>
                <StyledPrice>{price}</StyledPrice>
                <StyledDescription>{PriceDescription}</StyledDescription>
            </Stack>
            <Stack spacing={2.5} paddingLeft={{ xs: 2, sm: 4.5 }} paddingRight={{ xs: 2, sm: 4.5 }} >
                <>
                    <Line />
                    {features.map((description, index) => (
                        <Stack key={index} direction="row" alignItems="center" spacing={2} paddingTop={0.5}>
                            <Box>
                                <IconComponent />
                            </Box>
                            <Box>
                                <StyledTitleDescription>{description}</StyledTitleDescription>
                            </Box>
                        </Stack>
                    ))}
                </>
                {caption && (
                    <StyledCaption>{caption}</StyledCaption>
                )}
            </Stack>
            <Box
                marginTop="auto"
                paddingX={{ xs: 2, sm: 4.5 }}
                paddingBottom={{ xs: 3.5, sm: 4.5 }}
                paddingTop = {{ xs: 4, sm: 3.75 }}
            >
                <ButtonComponent
                    fullWidth
                    onClick={() => {
                        navigate(buttonLink);
                    }}
                >
                    {buttonLabel}
                </ButtonComponent>
            </Box>
        </StyledPaper>
    );
};

export default SubscriberSection;
