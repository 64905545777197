import styled from 'styled-components';
import { Typography } from '@mui/material';

export const PageDescription = styled(Typography)`
  && {
      color: ${({ theme }) => theme.palette.lightBlack};
      font-family: ${({ theme }) => theme.fonts.medium};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 4px;
      
      @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
        font-weight: 450;
    }
  }
`;
