import styled from 'styled-components';
import { Typography } from '@mui/material';

export const SignedText = styled(Typography)`
    && {
        position: absolute;
        top: 0;
        right: 12px;
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        color: ${({ theme }) => theme.palette.grey};
    }
`;
