import React, { FC } from 'react';
import { Stack, ListItem, ListItemText } from '@mui/material';
import { StyledList, StyledTitle } from './QRCodeModal.styles';
import { ReactComponent as ListCircle } from 'src/assets/icons/list-circle.svg';

export type TipsListProps = {
    tips: string[];
    titleTestId: string;
    listTestId: string;
};

const TipsList: FC<TipsListProps> = ({ tips, titleTestId, listTestId }) => (
    <StyledList>
        <StyledTitle data-testid={titleTestId}>
            {titleTestId.includes('scanning') ?
                'Here are a few tips to follow in case you are having trouble scanning the QR code:' :
                'Follow the instructions below in case your camera is not working:'}
        </StyledTitle>
        {tips.map((text, index) => (
            <ListItem key={index} data-testid={listTestId}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    {titleTestId.includes('scanning') && <ListCircle />}
                    <ListItemText primary={text} />
                </Stack>
            </ListItem>
        ))}
    </StyledList>
);

export default TipsList;
