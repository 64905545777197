import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import {
    StyledActiveSessionIpAddress,
    StyledActiveSessionLocation,
    StyledActiveSessionTitle,
} from './ActiveSessionItem.styles';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import { RoundedBadge } from 'src/components/Badge';
import { formatDate } from 'src/services/formatters';
import type { GridColumnProps } from 'src/components/ActiveSessionSection/ActiveSessionSection';
import StyledTooltip from 'src/components/Tooltip';
import { ReactComponent as RedCrossIcon } from 'src/assets/icons/red-cross-icon.svg';
import { useConfirmationModal } from 'src/hooks/useConfirmationModal';
import { revokeToken, ssoApiClient } from 'src/services/sso-api';
import { useSnackbarMessage } from 'src/hooks';
import { DateFormats, GeneralModalKey, SnackbarMessageVariants } from 'src/constants';
import { getLocationText } from 'src/services/active-session-helper';
import { ActiveSessionType } from 'src/@types/active-session';
import { getCachedGeolocationByIP } from 'src/services/ip-geolocation-cache';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';

type ActiveSessionProps = {
    gridColumnParams: GridColumnProps;
    session: ActiveSessionType;
    updateActiveSessionsList?: () => void;
    setTerminatingSessions?: Dispatch<SetStateAction<Array<string>>>;
};

const ActiveSessionItem: FC<ActiveSessionProps> = ({
    gridColumnParams,
    session,
    updateActiveSessionsList,
    setTerminatingSessions,
}) => {
    const [location, setLocation] = useState<string>(getLocationText());

    const { token, ip, browser, os, lastActivity, isCurrent } = session;

    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getCachedGeolocationByIP(ip)
            .then(getLocationText)
            .then(setLocation);
    }, [ip]);

    const terminateSession = async () => {
        if (isCurrent) {
            return ssoApiClient.revokeToken();
        }

        return revokeToken(token)
            .then(() => updateActiveSessionsList?.());
    };

    const { openModal } = useConfirmationModal({
        onConfirm: async () => {
            setTerminatingSessions?.((prev) => [...prev, token]);
            return terminateSession()
                .then(() => {
                    addMessage('Session terminated successfully', SnackbarMessageVariants.SUCCESS);
                })
                .catch(() => {
                    addMessage('Failed to terminate session', SnackbarMessageVariants.ERROR);
                }).finally(() => {
                    setTerminatingSessions?.((prev) => [...prev].filter((prevToken) => prevToken !== token));
                });
        },
    });

    const handleTerminate = () => {
        openModal({
            key: GeneralModalKey.confirmationModal,
            title: 'Terminate session?',
            extraProps: {
                header: 'Are you sure you want to terminate this session?',
                text: 'The selected session will be terminated.',
                confirmButtonText: 'Proceed',
                mobileDirection: 'row',
            },
        });
    };

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" position="relative">
            <Grid container columns={4}>
                <Grid item {...gridColumnParams.lastActivity}>
                    <StyledActiveSessionTitle>
                        {formatDate(lastActivity, DateFormats.SHORT)}
                    </StyledActiveSessionTitle>
                    {isCurrent && (
                        <Box
                            marginBottom={{ xs: 0.5, md: 0 }}
                            marginLeft={{ xs: 0.5, md: 1 }}
                        >
                            <RoundedBadge
                                label="Current"
                                variant={RoundedBadgeVariant.BLUE}
                            />
                        </Box>
                    )}
                </Grid>
                <Grid item {...gridColumnParams.location}>
                    <Stack>
                        <StyledActiveSessionLocation data-testid="session-ip-location">
                            {location}
                        </StyledActiveSessionLocation>
                        <StyledActiveSessionIpAddress>{ip}</StyledActiveSessionIpAddress>
                    </Stack>
                </Grid>
                <Grid item {...gridColumnParams.os}>
                    <StyledActiveSessionTitle>{os}</StyledActiveSessionTitle>
                    {browser && (
                        <StyledActiveSessionTitle display={{ xs: 'inline', md: 'none' }}>
                            &nbsp;| {browser}
                        </StyledActiveSessionTitle>
                    )}
                </Grid>
                <Grid item {...gridColumnParams.browser}>
                    <StyledActiveSessionTitle>{browser}</StyledActiveSessionTitle>
                </Grid>
            </Grid>
            <Stack right={0} width="32px" alignItems="flex-end" position="absolute">
                <ActionButton
                    data-attribute-to-change="stroke"
                    data-variant={ActionButtonVariant.RED}
                    data-testid="terminate-button"
                    onClick={handleTerminate}
                >
                    <StyledTooltip title="Terminate session" arrow>
                        <RedCrossIcon />
                    </StyledTooltip>
                </ActionButton>
            </Stack>
        </Stack>
    );
};

export default ActiveSessionItem;
