import React, { Dispatch, FC, SetStateAction } from 'react';
import { StyledHeaderAmount } from './DomainsSection.styles';
import { DomainItem } from './index';
import { Scope, useUserCan } from 'src/hooks/useUserCan';
import { makePlural } from 'src/services/text-modifier';
import { useGeneralModal, useReloadPage } from 'src/hooks';
import {
    DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE,
    editableSubscriptionStatuses,
    GeneralModalKey,
    TABLE_SECTION_DEFAULT_COLUMNS,
} from 'src/constants';
import { Menu } from 'src/components/Menu';
import type { GridColumnProps as BaseGridColumnProps } from 'src/components/TableSection/TableSection';
import { PaginationData } from 'src/@types/pagination';
import { TableSection } from 'src/components/TableSection';
import type { DomainModel, SubscriptionModel } from 'src/@types/subscription-service-api';
import { MenuVariant } from 'src/components/Menu/Menu';

export type GridColumnProps = BaseGridColumnProps<'domain' | 'userCount' | 'icon'>;

type DomainsSectionProps = {
    paginationModel: PaginationData;
    setPagination: Dispatch<SetStateAction<PaginationData>>;
    subscription: SubscriptionModel;
    domains: DomainModel[];
    showDomainActions?: boolean;
    showRemoveButton?: boolean;
    isLoading: boolean;
};

export const gridColumnParams: GridColumnProps = {
    domain: {
        column_title: 'Domain',
        md: 3,
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        display: 'flex',
        alignItems: 'center',
    },
    userCount: {
        column_title: 'Number of users',
        md: 1,
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        md: 1,
        xs: 2,
        right: '0',
        display: 'flex',
        position: { xs: 'absolute', md: 'inherit' },
        justifyContent: 'flex-end',
    },
};

export const getDomainActions = (
    { status }: SubscriptionModel,
): Array<{ label: string, value: GeneralModalKey, disabled: boolean, tooltipTitle: string }> => {
    const isAddDomainDisabled = !editableSubscriptionStatuses.includes(status);

    return [
        {
            label: 'Add domain',
            value: GeneralModalKey.addDomain,
            disabled: isAddDomainDisabled,
            tooltipTitle: isAddDomainDisabled ? DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE : '',
        },
    ];
};

const DomainsSection: FC<DomainsSectionProps> = ({
    domains,
    subscription,
    paginationModel,
    setPagination,
    isLoading,
    showDomainActions,
    showRemoveButton,
}) => {
    const { userCount, verticalIds, uuid: subscriptionUUID } = subscription;

    const canManage = useUserCan(Scope.SUBSCRIPTIONS_SERVICE_WRITE);
    const { openModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();

    const updateListOnItemRemoval = () => {
        if (paginationModel.currentPage > 1 && domains.length === 1) {
            return setPagination((prev) => ({
                ...prev,
                currentPage: prev.currentPage - 1,
            }));
        }

        return reloadPage();
    };

    const handleActionSelect = (value: string) => {
        openModal({
            key: value as GeneralModalKey,
            extraProps: {
                subscriptionUUID,
                verticalIds,
                domains,
            },
        });
    };

    return (
        <TableSection
            paginationModel={paginationModel}
            setPagination={setPagination}
            items={domains}
            headerHint={
                !isLoading && domains.length ? (
                    <StyledHeaderAmount>
                        &nbsp;({userCount} {makePlural('user', userCount)})
                    </StyledHeaderAmount>
                ) : undefined
            }
            headerChildren={showDomainActions && canManage && (
                <Menu
                    buttonVariant={MenuVariant.THREE_DOTS_TEXT_BUTTON}
                    menuItems={getDomainActions(subscription)}
                    onClick={handleActionSelect}
                    tooltipMaxWidth="unset"
                />
            )}
            headerPaddingBottom={{ xs: 0, md: 2 }}
            gridColumnParams={gridColumnParams}
            sectionHeader="Coverage information"
            noContentMessage="There are no domains attached to this subscription"
            isLoading={isLoading}
            renderItem={(domain: DomainModel, extraProps) => (
                <DomainItem
                    subscription={subscription}
                    gridColumnParams={extraProps.gridColumnParams}
                    domain={domain}
                    domainCount={paginationModel.totalCount}
                    refreshDomains={updateListOnItemRemoval}
                    showRemoveButton={showRemoveButton}
                />
            )}
        />
    );
};

export default DomainsSection;
