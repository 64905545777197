import React, { FC } from 'react';
import { Box, Grid, GridProps, Stack } from '@mui/material';
import { invoiceLinkRenderer } from 'src/components/InvoiceLink';
import { Scope, useUserCan } from 'src/hooks/useUserCan';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import {
    StyledPaymentAmountInfo,
    StyledPeriodInfo,
    StyledStripeInvoiceID,
} from 'src/components/PaymentSection/components/PaymentItem/PaymentItem.styles';
import { useGeneralModal } from 'src/hooks';
import { PriceHistoryModelWithRenewal, SubscriptionModel } from 'src/@types/subscription-service-api';
import { MenuVariant } from 'src/components/Menu/Menu';
import { getPaymentStatusBadgeProps, getUdbPaymentActions } from 'src/components/PaymentSection/services/getters';
import { DateFormats, GeneralModalKey, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { Menu } from 'src/components/Menu';
import { RoundedBadge } from 'src/components/Badge';
import { ReactComponent as PaymentDetailsIcon } from 'src/assets/icons/payment-details-icon.svg';
import { formatDate, formatPrice } from 'src/services/formatters';

type PaymentItemProps = {
    payment: PriceHistoryModelWithRenewal;
    rowProps: Record<string, GridProps>;
    subscription: SubscriptionModel;
    variant: 'udb' | 'profile';
};

const PaymentItem: FC<PaymentItemProps> = ({ variant, payment, rowProps, subscription }) => {
    const { openModal } = useGeneralModal();

    const canManage = useUserCan(Scope.SUBSCRIPTIONS_SERVICE_WRITE);
    const { priceInCents, stripeInvoiceID, periodStart, periodEnd } = payment;
    const paymentStatusBadgeProps = getPaymentStatusBadgeProps(payment, subscription);
    const { label: paymentStatus } = paymentStatusBadgeProps;
    const isFree = subscription.paymentType === 'free';
    const price = isFree ? 0 : priceInCents;
    const isUdb = variant === 'udb';

    const actionHandler = (action: string) => {
        openModal({
            key: action as GeneralModalKey,
            extraProps: {
                payment,
                variant,
                uuid: subscription.uuid,
                paymentType: subscription.type,
            },
        });
    };

    return (
        <Stack direction="row" alignItems="center">
            <Grid container gap={{ xs: 0.5, md: 0 }} columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <Grid item {...rowProps.amount}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <StyledPaymentAmountInfo paddingTop={{ xs: 0.25, md: 0 }}>
                            {price !== null ? formatPrice(price) : 'Not available'}
                        </StyledPaymentAmountInfo>
                        <Box display={{ xs: 'block', md: 'none' }}>
                            <RoundedBadge
                                {...paymentStatusBadgeProps}
                            />
                        </Box>
                    </Stack>
                </Grid>
                <Grid item {...rowProps.stripeInvoiceID}>
                    <StyledStripeInvoiceID>
                        {!isFree && stripeInvoiceID ? invoiceLinkRenderer(payment, variant) : 'Not available'}
                    </StyledStripeInvoiceID>
                </Grid>
                <Grid item {...rowProps.periodStart}>
                    <StyledPeriodInfo>
                        {formatDate(periodStart)}
                    </StyledPeriodInfo>
                </Grid>
                <Grid item {...rowProps.periodEnd}>
                    <StyledPeriodInfo>
                        {formatDate(periodEnd)}
                    </StyledPeriodInfo>
                </Grid>
                <Grid item {...rowProps.fullPeriod}>
                    <StyledPeriodInfo>
                        {`${formatDate(periodStart, DateFormats.DATE)} - ${formatDate(periodEnd, DateFormats.DATE)}`}
                    </StyledPeriodInfo>
                </Grid>
                <Grid item {...rowProps.status}>
                    <Box>
                        <RoundedBadge
                            {...paymentStatusBadgeProps}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box position="absolute" right={36}>
                {isUdb ? (
                    <Menu
                        menuItems={getUdbPaymentActions(payment, paymentStatus, canManage)}
                        buttonVariant={MenuVariant.THREE_DOTS_ACTION_BUTTON}
                        onClick={actionHandler}
                    />
                ) : (
                    <ActionButton
                        data-testid="details-button"
                        data-attribute-to-change="stroke"
                        data-variant={ActionButtonVariant.BLUE}
                        onClick={() => actionHandler(GeneralModalKey.paymentDetailsModal) }
                    >
                        <PaymentDetailsIcon />
                    </ActionButton>
                )}
            </Box>
        </Stack>
    );
};

export default PaymentItem;
