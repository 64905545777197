import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 14px;
    width: fit-content;
    text-transform: uppercase;
    line-height: 20px; 

    @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
      font-family: ${({ theme }) => theme.fonts.demiBold};
      font-size: 16px;
    }
  }
`;

export const StyledDescription = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.lightGrey};
    font-size: 14px;
    line-height: 20px;
    width: fit-content;
    
    @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
      font-size: 16px;
    }
  }
`;

export const StyledDateTimeContainer = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.black};
    font-size: 14px;
    line-height: 20px;
    padding-right: 12px;

    @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
      font-size: 16px;
      font-family: ${({ theme }) => theme.fonts.normal};
    }
  }
`;

export const StyledSampleIssueContainer = styled.a`
  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.palette.blue};
  line-height: normal;
  font-size: 14px;
  text-decoration: none;

  @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
    font-size: 16px;
  }
  
  svg {
    margin-right: 6px;
    margin-bottom: -4px;
  }
`;
