import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/root-reducer';
import type { UserUpgradeState } from 'src/@types/redux';

const initialState: UserUpgradeState = Object.freeze({
    userUUID: undefined,
    isCheckoutSucceeded: undefined,
    isNewUser: undefined,
});

export { initialState as userUpgradeInitialState };

export const userUpgradeSlice = createSlice<UserUpgradeState, {
    setUserUpgrade: CaseReducer<UserUpgradeState, PayloadAction<UserUpgradeState>>;
    clearUserUpgrade: CaseReducer<UserUpgradeState, PayloadAction>;
}>({
    name: 'userUpgrade',
    initialState,
    reducers: {
        setUserUpgrade: (state, { payload: userUpgrade }) => ({
            ...state,
            ...userUpgrade,
        }),
        clearUserUpgrade: () => ({
            ...initialState,
        }),
    },
});

export const {
    setUserUpgrade,
    clearUserUpgrade,
} = userUpgradeSlice.actions;

export const userUpgradeSelector = (state: RootState): UserUpgradeState => state[userUpgradeSlice.name];
