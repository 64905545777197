import React, { FC, Fragment, ReactElement } from 'react';
import { Stack } from '@mui/material';
import { StyledContent } from './ConfirmationMessage.styles';
import Line from 'src/components/Line';

export type ConfirmationMessageData = {
    content: ReactElement;
    key: string;
    testId: string;
};

export type ConfirmationMessagesProps = {
    messages: ConfirmationMessageData[];
};

const ConfirmationMessages: FC<ConfirmationMessagesProps> = ({ messages }) => {
    return (
        <Stack spacing={{ sm: 2, xs: 1.5 }}>
            {messages.map((item, index) => (
                <Fragment key={item.key}>
                    {index !== 0 && <Line />}
                    <StyledContent data-testid={item.testId}>
                        {item.content}
                    </StyledContent>
                </Fragment>
            ))}
        </Stack>
    );
};

export default ConfirmationMessages;
