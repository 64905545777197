import styled, { css } from 'styled-components';
import { Chip } from '@mui/material';

export enum RoundedBadgeVariant {
    GREEN = 'green',
    BLUE = 'blue',
    RED = 'red',
    GREY = 'grey',
    ORANGE = 'orange',
    DEFAULT = 'default',
    OUTLINED = 'outlined',
}

const variantMapping = {
    [RoundedBadgeVariant.GREEN]: css`
        color: ${({ theme }) => theme.palette.green};
        background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.green};
        
        &:hover {
            background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.greenHover};
        }
    `,
    [RoundedBadgeVariant.BLUE]: css`
        color: ${({ theme }) => theme.palette.blue};
        background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.blue};
        
        &:hover {
            background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.blueHover};
        }
    `,
    [RoundedBadgeVariant.RED]: css`
        color: ${({ theme }) => theme.palette.red};
        background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.red};
        
        &:hover {
            background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.redHover};
        }
    `,
    [RoundedBadgeVariant.GREY]: css`
        color: ${({ theme }) => theme.palette.lightBlack};
        background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.grey};
        
        &:hover {
            background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.greyHover};
        }
    `,
    [RoundedBadgeVariant.ORANGE]: css`
        color: ${({ theme }) => theme.palette.orange};
        background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.orange};
        
        &:hover {
            background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.orangeHover};
        }
    `,
    [RoundedBadgeVariant.OUTLINED]: css`
        color: ${({ theme }) => theme.palette.lightBlack};
        border: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
        background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.outlined};
        
        &:hover {
            background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.outlinedHover};
        }
    `,
    [RoundedBadgeVariant.DEFAULT]: css`
        color: ${({ theme }) => theme.palette.lightBlack};
        background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
        
        &:hover {
            background-color: ${({ theme }) => theme.palette.roundedBadgeBackground.defaultHover};
        }
    `,
};

const variantSelector = (props: { 'data-variant': RoundedBadgeVariant }) => variantMapping[props['data-variant']];

export const StyledChip = styled(Chip)`
    && {
        height: 24px;
        font-size: 11px;
        padding: 8px 10px;
        border-radius: 20px;
        cursor: default;
        text-transform: uppercase;
        font-family: ${({ theme }) => theme.fonts.demiBold};

        & .MuiChip-label {
            padding: 0;
        }

        ${variantSelector}
    }
`;
