import React from 'react';
import { TextButton } from 'src/components/Buttons/TextButton';
import { showIntercom } from 'src/services/intercom';
import { SingleModalLayout } from 'src/layouts';
import PageTitle from 'src/components/PageTitle';
import { PageDescription } from 'src/components/ReceivedRequestMessage/ReceivedRequestMessage.styles';

export const ReceivedRequestMessage = () => (
    <SingleModalLayout variant="message" testId="received-request-success-page">
        <PageTitle variant="form" title="We have received your request." textAlign="center">
            <PageDescription>
              Thanks for your interest in an Enterprise subscription.
              The Endpoints subscriptions team will be in touch with your
              enterprise quote shortly.<br />If you have any additional questions, please contact us
                <TextButton onClick={showIntercom}> here</TextButton>.
            </PageDescription>
        </PageTitle>
    </SingleModalLayout>
);
