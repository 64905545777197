import styled, { css } from 'styled-components';
import { Box, Grid, Typography } from '@mui/material';

const visibleVariant = (props: unknown & { 'data-visible'?: boolean }) => props['data-visible']
    ? css`
      visibility: hidden;
  `
    : css`
      visibility: visible;
  `;

export const StyledIcon = styled(Box)`
    && {
        display: flex;
        cursor: pointer;
        transition: opacity 0.3s ease;

        ${visibleVariant};

        svg {
            &:hover {
                path {
                    stroke: ${({ theme }) => theme.palette.blue};
                }
            }
        }
    }
`;

export const StyledText = styled(Typography).attrs({
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
})`
    &:first-letter {
        text-transform: uppercase;
    }
`;

export const StyledGridItem = styled(Grid)`
    gap: 8px;
    display: flex;
    align-items: center;
    
    &:hover ${StyledIcon} {
        visibility: visible;
    }
`;
