import styled from 'styled-components';

export const Overlay = styled.div<{ fullOverlay: boolean }>`
  position: ${({ fullOverlay }) => (fullOverlay ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  z-index: 120;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.white};
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;
`;
