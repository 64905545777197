import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { FormikHelpers } from 'formik';
import { PageSection } from 'src/components/PageSection';
import { BasicInformationForm, SocialNetworksForm } from 'src/components/Forms';
import { getProfileInfo, updateProfileInfo } from 'src/services/sso-api';
import {
    NOT_LINKEDIN_URL_MESSAGE,
    NOT_TWITTER_URL_MESSAGE,
    NOT_VALID_URL_MESSAGE,
    SnackbarMessageVariants,
} from 'src/constants';
import { ProfileInfo, ProfileInfoSocial } from 'src/@types/profile-info';
import { prepareProfileInfoRequestData } from 'src/services/request-data-formatter';
import { Spinner } from 'src/components/Spinner';
import { useReloadPage, useSnackbarMessage } from 'src/hooks';

const serverErrorMapping: Record<string, { field: string, message: string }> = {
    'social.website': {
        field: 'website',
        message: NOT_VALID_URL_MESSAGE,
    },
    'social.socialX': {
        field: 'socialX',
        message: NOT_TWITTER_URL_MESSAGE,
    },
    'social.socialLinkedIn': {
        field: 'socialLinkedIn',
        message: NOT_LINKEDIN_URL_MESSAGE,
    },
};

const ProfilePersonal: FC = () => {
    const [profileInfo, setProfileInfo] = useState<ProfileInfo>({} as ProfileInfo);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();

    const handleFormSubmit = (
        data: Partial<ProfileInfo | ProfileInfoSocial>,
        form: FormikHelpers<Partial<ProfileInfo>> | FormikHelpers<Partial<ProfileInfoSocial>>,
    ): Promise<void> => (
        updateProfileInfo(prepareProfileInfoRequestData(data, profileInfo))
            .then((response) => {
                setProfileInfo(response);
                reloadPage();
                addMessage('Changes saved successfully', SnackbarMessageVariants.SUCCESS);
            })
            .catch((error) => {
                const errors = error.responseError?.data?.data;

                if (errors && errors.length > 0) {
                    const mappedErrors = errors.reduce((acc: Record<string, string>, e: { path: string }) => {
                        const mappedError = serverErrorMapping[e.path];

                        if (mappedError) {
                            acc[mappedError.field] = mappedError.message;
                        }

                        return acc;
                    }, {});

                    if (Object.keys(mappedErrors).length > 0) {
                        form.setErrors(mappedErrors);
                        throw new Error(error);
                    }
                }

                addMessage('Failed to update profile info', SnackbarMessageVariants.ERROR);
                throw new Error(error);
            })
    );

    useEffect(() => {
        getProfileInfo()
            .then(setProfileInfo)
            .catch(() => {
                addMessage('Failed to fetch profile info', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <Box data-testid="personal-container">
            <PageSection header="Basic information">
                <BasicInformationForm
                    profileInfo={profileInfo}
                    onSubmit={handleFormSubmit}
                />
            </PageSection>
            <PageSection header="Social networks">
                <SocialNetworksForm
                    social={profileInfo.social}
                    onSubmit={handleFormSubmit}
                />
            </PageSection>
        </Box>
    );
};

export default ProfilePersonal;
