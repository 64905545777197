export const additionalWideInputWidth = '300px';
export const additionalShortInputWidth = '130px';

export enum ActiveStep {
    TYPE_AND_BILLING = 'typeAndBilling',
    PRICING_TERMS = 'pricingTerms',
    ACCOUNT_OWNER = 'accountOwner',
    CONFIRMATION = 'confirmation',
}

export enum BillingCycleLengthInMonth {
    MONTH = 1,
    YEAR = 12,
}

export const billingCycleLengthRenderMapping: Record<BillingCycleLengthInMonth, string> = {
    [BillingCycleLengthInMonth.MONTH]: 'month',
    [BillingCycleLengthInMonth.YEAR]: 'year',
};

export enum ActivationDate {
    NOW = 'now',
    SPECIFIED = 'specified',
    MANUALLY = 'manually',
}

export enum PricingModel {
    FIXED_PRICE = 'fixedPrice',
    RENEWABLE_PRICE = 'renewablePrice',
}

export const pricingModelRenderLabels: Record<PricingModel, string> = {
    [PricingModel.FIXED_PRICE]: 'Fixed price forever',
    [PricingModel.RENEWABLE_PRICE]: 'Based on the price at a renewal',
};

export enum DiscountDuration {
    FIRST_CYCLE = 'firstCycle',
    MULTIPLE_CYCLE = 'multipleCycle',
    FOREVER = 'forever',
}

export enum DiscountType {
    PERCENTAGE = 'percentage',
    AMOUNT = 'amount',
}
