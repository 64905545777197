import { Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledSectionName = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.grey};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        text-transform: uppercase;
    }
`;
