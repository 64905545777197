import { type MutableRefObject, useEffect, useRef, useState } from 'react';
import type { GridPrivateApiCommunity } from '@mui/x-data-grid/models/api/gridApiCommunity';
import type { GridColDef } from 'src/@types/data-grid';
import { STICKY_COLUMN_CLASS_NAME } from 'src/components/DataGrid/DataGrid.styles';
import { findParentByClassName } from 'src/services/dom-explorer';

type StickyColConfig = { field?: string, index?: number };
type CalculateTransformXParams = {
    containerWidth: number;
    elementOffset: number;
    elementWidth: number;
    scrollOffset: number;
    scrollWidth: number;
    isFirstColumn: boolean;
};

export const calculateTransformX = ({
    containerWidth,
    elementOffset,
    elementWidth,
    scrollOffset,
    scrollWidth,
    isFirstColumn,
}: CalculateTransformXParams): number => {
    const offsetRightEdge = elementOffset + elementWidth;
    const headerContainerWidth = scrollWidth ? containerWidth - scrollWidth - 1 : containerWidth;

    if (offsetRightEdge - headerContainerWidth >= scrollOffset) {
        // original position of the column is not fully visible, it's on the right of the current grid scroll position
        return scrollOffset - scrollWidth;
    }

    if (elementOffset < scrollOffset) {
        // original position of the column is not fully visible, it's on the left of the current grid scroll position
        return scrollOffset - (
            offsetRightEdge > containerWidth
                ? containerWidth - elementWidth - 2 // element is partially visible
                : (elementOffset + (isFirstColumn ? 0 : 1)) // element is fully NOT visible
        );
    }

    // original position of the column is fully visible within the current grid scroll position
    return offsetRightEdge > containerWidth
        // initially element was not fully visible, we need to compensate for the Grid style transformation
        ? offsetRightEdge - containerWidth + 3
        : 0; // initially element was visible, extra transformation not needed
};

const updateTransformX = (element: HTMLElement, value: number) => {
    if (element.style.transform !== `translateX(${value}px)`) {
        element.style.transform = `translateX(${value}px)`;
    }
};

const updateCSSVariable = (element: HTMLElement, name: string, value: string) => {
    if (element.style.getPropertyValue(name) !== value) {
        element.style.setProperty(name, value);
    }
};

const updateStickyColOnScroll = (
    stickyColumnElement: HTMLElement,
    containerWidth: number,
    scrollWidth: number,
    scrollOffset: number,
    isFirstColumn: boolean,
) => {
    const prevElement = stickyColumnElement.previousElementSibling as HTMLElement | null;
    const stickyElementOffset = (prevElement?.offsetLeft || 0) + (prevElement?.clientWidth || 0);

    const newTransformX = calculateTransformX({
        containerWidth,
        elementOffset: stickyElementOffset,
        elementWidth: stickyColumnElement.clientWidth,
        scrollOffset,
        scrollWidth,
        isFirstColumn,
    });
    updateTransformX(stickyColumnElement, newTransformX);
};

export const useStickyColumn = <T extends GridColDef = GridColDef>(
    apiRef: MutableRefObject<GridPrivateApiCommunity>,
    columns: T[],
): T[] => {
    const [preparedColumns, setPreparedColumns] = useState<T[]>([]);
    const [stickyColumn, setStickyColumn] = useState<StickyColConfig>({});
    const [scrollWidth, setScrollWidth] = useState<number>(0);

    const scrollMonitor = useRef<() => void>();

    const getStickyElement = (): HTMLElement | undefined => (
        stickyColumn.field && apiRef.current?.getColumnHeaderElement?.(stickyColumn.field) || undefined
    );
    const getGridMainContainer = (element: HTMLElement | undefined): HTMLElement | undefined => (
        element && findParentByClassName(element, 'MuiDataGrid-main') || undefined
    );

    useEffect(() => {
        scrollMonitor.current?.();
        if (!apiRef.current?.instanceId) {
            return;
        }

        scrollMonitor.current = apiRef.current.subscribeEvent(
            'scrollPositionChange',
            ({ left }) => {
                const stickyElement = getStickyElement();
                const gridMainContainer = getGridMainContainer(stickyElement);
                if (!gridMainContainer || !stickyElement) {
                    return;
                }

                const gridContentContainer = gridMainContainer.querySelector('.MuiDataGrid-virtualScroller');
                const containerWidth = gridMainContainer.clientWidth;
                const gridWidth = gridContentContainer?.clientWidth || containerWidth;
                const newScrollWidth = containerWidth - gridWidth;

                setScrollWidth(newScrollWidth);
                updateStickyColOnScroll(
                    stickyElement,
                    containerWidth,
                    newScrollWidth,
                    left,
                    stickyColumn.index === 0,
                );
            },
        );

        return () => {
            scrollMonitor?.current?.();
        };
    }, [apiRef.current?.instanceId, stickyColumn.field, apiRef.current?.getRowModels?.().size]);

    useEffect(() => {
        const gridMainContainer = getGridMainContainer(getStickyElement());
        if (!gridMainContainer) {
            return;
        }

        updateCSSVariable(
            gridMainContainer,
            '--gridScrollWidth',
            scrollWidth > 0 ? `${scrollWidth}px` : '0',
        );
    }, [scrollWidth, apiRef.current?.getRowModels?.().size]);

    useEffect(() => {
        const firstStickyColumn = preparedColumns.find(({ sticky }) => sticky)?.field;
        const firstStickyIndex = preparedColumns.findIndex(({ sticky }) => sticky);
        const newPreparedColumns = columns.map((column) => {
            if (column.field !== firstStickyColumn) {
                return column;
            }

            return { ...column, headerClassName: STICKY_COLUMN_CLASS_NAME, cellClassName: STICKY_COLUMN_CLASS_NAME };
        });
        setPreparedColumns(newPreparedColumns);
        setStickyColumn({ field: firstStickyColumn, index: firstStickyIndex });
    }, [columns]);

    return preparedColumns;
};
