import React, { FC, SyntheticEvent } from 'react';
import type { NewsLetterSetSchema } from 'src/@types/newsletters-set';
import { NewslettersSet } from 'src/components/NewslettersSet';
import { SingleModalForm } from 'src/components/Forms/components';
import { VerticalModel } from 'src/@types/sso-api';
import { Button } from 'src/components/Buttons';

type SignUpFormNewsLettersProps = {
    index: number;
    handleSubmit: (values: object) => void;
    onFormChange?: (event: SyntheticEvent, values?: Record<string, boolean>) => void;
    deliveryPreferences: VerticalModel;
    newsLetterSetSchema?: NewsLetterSetSchema;
    initialValues?: Record<string, boolean>;
    submitButton?: string;
};

const SignUpNewsLettersForm: FC<SignUpFormNewsLettersProps> = ({
    index,
    onFormChange,
    handleSubmit,
    initialValues,
    deliveryPreferences,
    newsLetterSetSchema,
    submitButton = 'Next',
}) => {
    const getInitialValues = () => (
        deliveryPreferences.newsletters.reduce<Record<string, boolean>>((preparedState, { id }) => {
            const name = `${id}_email`;
            preparedState[name] = initialValues?.[name] ?? false;

            return preparedState;
        }, {
            subscribeAll_email: initialValues?.[`subscribeAll_email_${index}`] ? initialValues[`subscribeAll_email_${index}`] : false,
        })
    );

    const onFormChangeHandler = (event: SyntheticEvent, values?: Record<string, boolean>) => {
        onFormChange?.(event, { ...values, [`subscribeAll_${index}`]: false });
    };

    return (
        <SingleModalForm
            onSubmit={handleSubmit}
            onFormChange={onFormChange}
            formSpacing={{ xs: 1, md: 2.5 }}
            initialValues={getInitialValues()}
            testId={`create-account-newsletters-form-${index}`}
        >
            <NewslettersSet
                paddingX={0}
                paddingTop={{ xs: 0.5, md: 0 }}
                paddingBottom={0}
                subscribeAll
                isSMSDisabled
                onFormChangeHandler={onFormChangeHandler}
                vertical={deliveryPreferences}
                newsLetterSetSchema={newsLetterSetSchema}
            />
            <Button type="submit" testId="next-button">{submitButton}</Button>
        </SingleModalForm>
    );
};

export default SignUpNewsLettersForm;
