import { Box } from '@mui/material';
import React from 'react';
import { StyledSnackbarBody } from './SnackbarContainer.styles';
import { ReactComponent as InfoIcon } from 'src/assets/icons/snackbar/snackbar-info.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/snackbar/snackbar-error.svg';
import { ReactComponent as TickIcon } from 'src/assets/icons/snackbar/snackbar-tick.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/snackbar/snackbar-warning.svg';
import { SnackbarMessageVariants } from 'src/constants';

type SnackbarBodyProps = {
    children?: React.ReactNode;
    type: SnackbarMessageVariants;
};

const iconMap = {
    [SnackbarMessageVariants.INFO]: InfoIcon,
    [SnackbarMessageVariants.SUCCESS]: TickIcon,
    [SnackbarMessageVariants.WARNING]: WarningIcon,
    [SnackbarMessageVariants.ERROR]: ErrorIcon,
};

export const SnackbarBody: React.FC<SnackbarBodyProps> = ({ children, type }) => {
    const Icon = iconMap[type as keyof typeof iconMap];

    return (
        <StyledSnackbarBody>
            { Icon && <Icon className="snackbar-icon" /> }
            <Box data-testid="snackbar-message-content">{children}</Box>
        </StyledSnackbarBody>
    );
};
