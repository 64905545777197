import { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { ManageUserActions } from 'src/constants';

export const getManageUserActionsList = (
    canEditUser: boolean,
): Partial<Record<ManageUserActions, ManageActionType>> => {
    return {
        ...(canEditUser && {
            [ManageUserActions.logInLink]: { label: 'Generate a log in link' },
            [ManageUserActions.passwordLink]: { label: 'Generate a password change link' },
        }),
    };
};
