import React, { FC } from 'react';
import { Grid, GridProps } from '@mui/material';
import { StyledInfo } from './UsageItem.styles';
import { StyledHeaderName } from 'src/components/TableSection/HeaderSection/HeaderSection.styles';

type UsageItemProps = {
    gridColumnParams: GridProps;
    usageValue: number | string | null;
    title?: string;
};

const UsageItem: FC<UsageItemProps> = ({ gridColumnParams, usageValue, title }) => (
    <Grid item {...gridColumnParams}>
        <StyledHeaderName>{title}</StyledHeaderName>
        <StyledInfo>{usageValue}</StyledInfo>
    </Grid>
);

export default UsageItem;
