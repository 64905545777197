import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Box, Grid, GridProps, Stack } from '@mui/material';
import { StyledEmail, StyledFullName, StyledJobInfo } from './UserItem.styles';
import { StyledGridItem } from 'src/components/CopyIcon/CopyIcon.styles';
import { CopyIcon } from 'src/components/CopyIcon';
import { GRID_DEFAULT_TOOLTIP_PROPS, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import StyledTooltip from 'src/components/Tooltip';
import { StyledUserIcon } from 'src/components/ManagerSection/ManagerItem.styles';
import { Link } from 'src/components/Link';
import { RoundedBadge } from 'src/components/Badge';
import { ReactComponent as RedCrossIcon } from 'src/assets/icons/red-cross-icon.svg';
import { SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';

type UserItemProps = {
    rowProps: Record<string, GridProps>;
    user: SubscriptionUserModel;
    hasUserLink?: boolean;
};

const UserItem: FC<UserItemProps> = ({
    rowProps,
    user,
    hasUserLink,
}) => {
    const [itemWidth, setItemWidth] = useState<number>(0);
    const [parentWidth, setParentWidth] = useState<number>(0);

    const itemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (itemRef.current) {
            setItemWidth(itemRef.current.offsetWidth);
            setParentWidth(itemRef.current.parentElement!.offsetWidth);
        }
    }, [itemRef]);

    return (
        <Stack ref={itemRef} position="relative" direction="row" alignItems="center" width={`calc(100% + ${parentWidth - itemWidth}px)`}>
            <Grid container alignItems="center" columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <StyledGridItem item {...rowProps.name}>
                    {hasUserLink && (
                        <Link display="flex" target="_blank" href={`/udb/users/${user.userUUID}`}>
                            <StyledTooltip arrow title="Open user page">
                                <StyledUserIcon />
                            </StyledTooltip>
                        </Link>
                    )}
                    <StyledTooltip
                        title={user.fullName}
                        {...GRID_DEFAULT_TOOLTIP_PROPS}
                    >
                        <StyledFullName>
                            {user.fullName}
                        </StyledFullName>
                    </StyledTooltip>
                    {(user.isOwner || user.isManager) && (
                        <RoundedBadge label={user.isOwner ? 'Owner' : 'Manager'} />
                    )}
                    <CopyIcon content={user.fullName} hiddenIcon />
                </StyledGridItem>
                <StyledGridItem item {...rowProps.email}>
                    <StyledTooltip
                        title={user.email}
                        {...GRID_DEFAULT_TOOLTIP_PROPS}
                    >
                        <StyledEmail>
                            {user.email}
                        </StyledEmail>
                    </StyledTooltip>
                    <CopyIcon content={user.email} hiddenIcon />
                </StyledGridItem>
                <StyledGridItem item {...rowProps.companyName}>
                    {user.companyName && (
                        <Fragment>
                            <StyledTooltip
                                title={user.companyName}
                                {...GRID_DEFAULT_TOOLTIP_PROPS}
                            >
                                <StyledJobInfo>
                                    {user.companyName}
                                </StyledJobInfo>
                            </StyledTooltip>
                            <CopyIcon content={user.companyName} hiddenIcon />
                        </Fragment>
                    )}
                </StyledGridItem>
                <StyledGridItem item {...rowProps.jobTitle}>
                    {user.jobTitle && (
                        <Fragment>
                            <StyledTooltip
                                title={user.jobTitle}
                                {...GRID_DEFAULT_TOOLTIP_PROPS}
                            >
                                <StyledJobInfo>
                                    {user.jobTitle}
                                </StyledJobInfo>
                            </StyledTooltip>
                            <CopyIcon content={user.jobTitle} hiddenIcon />
                        </Fragment>
                    )}
                </StyledGridItem>
                <StyledGridItem item {...rowProps.jobInfo}>
                    <StyledJobInfo>
                        {user.companyName}{user.companyName && user.jobTitle ? ', ' : ''}{user.jobTitle}
                    </StyledJobInfo>
                </StyledGridItem>
            </Grid>
            <Box position="absolute" right={0} display="none">
                <ActionButton
                    data-attribute-to-change="stroke"
                    data-variant={ActionButtonVariant.RED}
                    data-testid="remove-icon"
                    onClick={() => {}}
                >
                    <RedCrossIcon />
                </ActionButton>
            </Box>
        </Stack>
    );
};

export default UserItem;
