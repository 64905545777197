import React, { FC, Fragment, ReactNode } from 'react';
import { Box } from '@mui/material';
import { StyledText } from './SubscriptionTAC.styles';
import { ReactComponent as ListCircle } from 'src/assets/icons/list-circle.svg';

type Item = {
    icon?: boolean;
    number?: string;
    text: ReactNode;
    subContent?: Item[];
    minWidth?: { xs: string, sm: string } | string;
};

export type TermItemProps = {
    content: Item[];
    paddingLeft?: { xs: string, md: string } | string;
    paddingIconLeft?: { xs: string, sm: string, md: string } | string;
};

const TermItem: FC<TermItemProps> = ({
    content,
    paddingLeft = 'unset',
    paddingIconLeft = { xs: '28px', sm: '35px' },
}) => {
    return (
        <>
            {content.map((item, index) => (
                <Fragment key={index}>
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        paddingLeft={item.icon ? paddingIconLeft : paddingLeft}
                        gap={item.icon ? 1 : 0}
                    >
                        {item.icon ? (
                            <Box paddingTop={1}>
                                <ListCircle width="5px" height="5px" />
                            </Box>
                        ) : (
                            <StyledText minWidth={item.minWidth} paddingTop={2.5}>
                                {item.number}
                            </StyledText>
                        )}
                        <StyledText paddingTop={item.icon ? 1 : 2.5}>
                            {item.text}
                        </StyledText>
                    </Box>
                    {item.subContent && (
                        <TermItem
                            content={item.subContent}
                            paddingLeft={{ xs: '34px', md: '36px' }}
                            paddingIconLeft={{ xs: '75px', sm: '85px', md: '88px' }}
                        />
                    )}
                </Fragment>
            ))}
        </>
    );
};

export default TermItem;
