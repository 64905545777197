import React, { FC, Fragment, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { InfoBoxTitle, InfoBoxValue, InfoContainer } from './InfoBox.styles';
import Line from 'src/components/Line';

type InfoBoxProps = {
    prependNode?: ReactNode;
    items: Array<{
        label: ReactNode;
        value: ReactNode;
        key: string;
    }>;
};

const InfoBox: FC<InfoBoxProps> = ({ items, prependNode }) => {
    return (
        <InfoContainer paddingX={{ xs: 2, sm: 3 }} paddingY={{ xs: 2.5, sm: 3 }} spacing={{ xs: 2, sm: 2.5 }}>
            {prependNode}
            {items.map(({ label, value, key }, index) => (
                <Fragment key={key}>
                    {(index !== 0 || !!prependNode) && <Line />}
                    <Stack direction="row" justifyContent="space-between" position="relative">
                        <InfoBoxTitle variant="caption">{label}</InfoBoxTitle>
                        <InfoBoxValue variant="caption" textAlign="right">{value}</InfoBoxValue>
                    </Stack>
                </Fragment>
            ))}
        </InfoContainer>
    );
};

export default InfoBox;
