import React, { useEffect, useState } from 'react';
import { type BoxProps, type InputProps } from '@mui/material';
import { Field, type FieldProps } from 'formik';
import { TextInput } from 'src/components/Inputs/TextInput';
import {
    StyledFieldContainer,
    StyledFormFieldSubtext,
    StyledFormFieldSubtextButton,
} from 'src/components/Inputs/StyledInput.styles';
import { INPUT_MAX_LENGTH } from 'src/constants';

type ConfirmPhoneNumberInputProps = {
    name: string;
    handleButtonClick: () => void;
    placeholder?: string;
    inputProps?: Partial<InputProps>;
    testId?: string;
    disabled?: boolean;
    hasSubmittingError?: boolean;
    allowSpace?: boolean;
    inputMaxLength?: number;
    resendAfter?: number;
} & BoxProps;

const ConfirmPhoneNumberInput = ({
    name,
    placeholder,
    inputProps,
    testId,
    disabled,
    hasSubmittingError,
    handleButtonClick,
    allowSpace = false,
    inputMaxLength = INPUT_MAX_LENGTH,
    resendAfter = 30,
    ...BoxProps
}: ConfirmPhoneNumberInputProps) => {
    const preparedTestId = testId || `${name}-input`;
    const [counter, setCounter] = useState<number>(resendAfter);

    useEffect(() => {
        if (!counter) {
            return;
        }

        const timer = setTimeout(() => {
            setCounter((prevCounter) => {
                return prevCounter - 1;
            });
        }, 1000);

        return () => clearTimeout(timer);
    }, [counter]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, {
        field,
        form,
    }: FieldProps) => {
        if (hasSubmittingError) {
            form.setErrors({});
        } else {
            form.setFieldError(name, undefined);
        }
        if (!allowSpace) {
            event.target.value = event.target.value.trim();
        }
        field.onChange(event);
    };

    return (
        <StyledFieldContainer data-testid={preparedTestId} {...BoxProps}>
            <Field name={name}>
                {({ field, form }: FieldProps) => (
                    <>
                        <TextInput
                            className={`form-input${form.errors[name] && form.touched[name] ? ' invalid' : ''}`}
                            type="text"
                            name={field.name}
                            value={field.value}
                            onChange={(event) => handleChange(event, { field, form } as FieldProps)}
                            placeholder={placeholder}
                            disabled={disabled}
                            InputProps={{
                                ...inputProps,
                                inputProps: {
                                    'data-testid': `${preparedTestId}-element`,
                                    maxLength: inputMaxLength,
                                },
                            }}
                        />
                        <StyledFormFieldSubtext>
                            Didn&apos;t get a code?&nbsp;
                            <StyledFormFieldSubtextButton
                                variant="text"
                                disabled={counter > 0}
                                onClick={handleButtonClick}
                                data-testid={`${preparedTestId}-resend-button`}
                            >
                                Resend {counter > 0 && `in ${counter}s`}
                            </StyledFormFieldSubtextButton>
                        </StyledFormFieldSubtext>
                    </>
                )}
            </Field>
        </StyledFieldContainer>
    );
};

export default ConfirmPhoneNumberInput;
