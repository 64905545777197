import React, { useState } from 'react';
import { Field, FieldProps } from 'formik';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { DateInputLabel } from './LabeledDateInput.styles';
import { DatePicker } from 'src/components/DatePicker/DatePicker';

type LabeledDateInputProps = {
    testId?: string;
    value?: string;
    name: string;
    label: string;
    placeholder: string;
    minDate?: dayjs.Dayjs;
    maxDate?: dayjs.Dayjs;
    onChange: (date?: string) => void;
    dateOnly?: boolean;
    position?: 'fixed' | 'absolute';
    shouldDisableDate?: (date: dayjs.Dayjs) => boolean;
};

export const LabeledDateInput: React.FC<LabeledDateInputProps> = ({
    name,
    label,
    minDate,
    maxDate,
    placeholder,
    testId,
    onChange,
    value,
    dateOnly,
    position,
    shouldDisableDate,
}) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => (
                <Box>
                    <DateInputLabel className={isActive ? 'focused' : undefined}>{label}</DateInputLabel>
                    <DatePicker
                        position={position}
                        label={label}
                        maxDate={maxDate}
                        minDate={minDate}
                        handleDateChange={(newDate) => {
                            const formatDate = dayjs(newDate).format('YYYY-MM-DD');
                            form.setFieldValue(
                                field.name,
                                formatDate,
                            );
                            onChange(newDate);
                            setIsActive(false);
                        }}
                        testId={testId}
                        onFocus={() => setIsActive(true)}
                        onBlur={() => setIsActive(false)}
                        dateOnly={dateOnly}
                        isActive={isActive}
                        placeholder={placeholder}
                        value={dayjs(field.value)}
                        initialValue={value}
                        shouldDisableDate={shouldDisableDate}
                    />
                </Box>
            )}
        </Field>
    );
};
