import styled, { css } from 'styled-components';
import { Link, LinkProps } from '@mui/material';

const offsetSelector = ({ 'data-outline-offset': outlineOffset }: { 'data-outline-offset'?: string }) => outlineOffset
    ? css`
            outline-offset: ${outlineOffset};
    ` : null;

export const StyledLink = styled(Link)<LinkProps & { 'data-outline-offset'?: string }>`
    && {
        color: ${({ theme }) => theme.palette.blue};
        font-family: ${({ theme }) => theme.fonts.medium};
        cursor: pointer;
        
        ${offsetSelector}
    }
`;
