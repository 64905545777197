import React from 'react';
import { StyledMessage } from 'src/components/NeedHelp/styles';
import * as intercomService from 'src/services/intercom';
import { TextButton } from 'src/components/Buttons';

export const NeedHelp = () => {
    const handleClick = () => {
        intercomService.showIntercom();
    };

    return (
        <StyledMessage>
            Need help?&nbsp;
            <TextButton data-testid="help-button" onClick={handleClick}>
                Contact us
            </TextButton>
        </StyledMessage>
    );
};
