import type { GridColDef } from '@mui/x-data-grid';
import { Order, ORDER_OPTIONS, SsoConditionValues, ToolBarConditionTypes, ToolBarModelTypes } from 'src/constants';
import { SchemaType, ToolBarModelType } from 'src/@types/tool-bar';

export const getSortingSchema = (gridColumns: readonly GridColDef[]) => (
    gridColumns
        .filter(({ sortable }) => sortable !== false)
        .reduce<SchemaType>(
        (preparedFields, { field, headerName, type = 'string' }, index) => {
            preparedFields.fields[field] = {
                order: index,
                value: field,
                label: headerName as string,
                conditionType: ToolBarConditionTypes.SELECT,
                valueOptions: ORDER_OPTIONS[type as keyof typeof ORDER_OPTIONS],
                shouldHideCondition: true,
                conditionOptions: [{
                    value: SsoConditionValues.ORDER,
                    label: 'order',
                }],
                validateConditionValue: (value) => typeof value === 'string' && Object.values(Order).includes(value as Order) && value,
            };

            return preparedFields;
        },
        {
            fields: {},
            modelType: ToolBarModelTypes.SORTING,
        },
    )
);

export const getSortingApiParams = (sortingModel: ToolBarModelType) => {
    const orderBy = Object.entries(sortingModel)
        .reduce((preparedParams, [key, { order }]) => ({
            ...preparedParams,
            [key]: order,
        }), {});

    return Object.entries(orderBy).length ? { orderBy } : {};
};
