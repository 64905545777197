import { encode } from 'hi-base32';

export const tfaKeyToSecret = (key: string): string => {
    return encode(key).replace(/=/g, '');
};

export const generateTfaKey = (): string => {
    return window.crypto.randomUUID().replaceAll('-', '');
};

export const generateQRUrl = (issuer: string, account: string, key: string): string => {
    return `otpauth://totp/${encodeURIComponent(account)}?issuer=${encodeURIComponent(issuer)}&secret=${tfaKeyToSecret(key)}`;
};
