import Cookies from 'js-cookie';
import type { IntercomSettings } from 'src/@types/global';
import type { UiConfigType } from 'src/@types/user-profile-api';
import type { UserPayload } from 'src/@types/sso-api';

export const prepareIntercomSettings = (config: UiConfigType, userData?: UserPayload): IntercomSettings => {
    return {
        api_base: config.intercom.apiUrl,
        app_id: config.intercom.appId,
        ...(userData?.intercomHash && {
            email: userData?.email,
            user_id: userData?.uuid,
            name: userData?.fullName,
            created_at: userData?.createdAt,
            phone: userData?.phoneNumber,
            user_hash: userData?.intercomHash,
        }),
    };
};

const getIntercomStoredConfig = (appId: string) => {
    const localSettings = localStorage.getItem(`intercom.intercom-state-${appId}`);
    return JSON.parse(localSettings || '{}');
};

const clearIntercomCookies = ({ intercom }: UiConfigType) => {
    const { user } = getIntercomStoredConfig(intercom.appId);

    if (user?.role === 'visitor') {
        return;
    }

    Cookies.remove(`intercom-id-${intercom.appId}`);
    Cookies.remove(`intercom-session-${intercom.appId}`);
    Cookies.remove(`intercom-device-id-${intercom.appId}`);

    Cookies.remove(`intercom-id-${intercom.appId}`, { domain: intercom.cookieDomain });
    Cookies.remove(`intercom-session-${intercom.appId}`, { domain: intercom.cookieDomain });
    Cookies.remove(`intercom-device-id-${intercom.appId}`, { domain: intercom.cookieDomain });
};

export const initIntercom = (config: UiConfigType) => {
    if (!config.intercom.appId) {
        console.log('Intercom not initialized!');
        return;
    }

    clearIntercomCookies(config);
    window.intercomSettings = prepareIntercomSettings(config);
    const { Intercom: intercom, intercomSettings } = window;

    if (typeof intercom === 'function') {
        intercom('reattach_activator');
        intercom('update', intercomSettings);
    } else {
        const intercomStub = function (...args: unknown[]) {
            intercomStub.c(args);
        };
        intercomStub.q = [] as unknown[][];
        intercomStub.c = function (args: unknown[]) {
            intercomStub.q.push(args);
        };

        window.Intercom = intercomStub;
        const onLoad = function () {
            const element = document.createElement('script');
            element.type = 'text/javascript';
            element.async = true;
            element.src = `${config.intercom.widgetUrl}/widget/${config.intercom.appId}`;
            const head = document.getElementsByTagName('head')[0];
            head.insertBefore(element, head.firstChild);
        };

        if (document.readyState === 'complete') {
            onLoad();
        } else if (window.attachEvent) {
            window.attachEvent('onload', onLoad);
        } else {
            window.addEventListener('load', onLoad, false);
        }
    }
};

export const bootIntercom = (intercomSettings?: IntercomSettings) => {
    if (window.Intercom) {
        window.Intercom('boot', intercomSettings);
    }
};

export const updateIntercom = (intercomSettings: Partial<IntercomSettings>) => {
    if (window.Intercom) {
        window.Intercom('update', intercomSettings);
    }
};

export const showIntercom = () => {
    if (window.Intercom) {
        window.Intercom('show');
    }
};

export const shutdownIntercom = () => {
    if (window.Intercom) {
        window.Intercom('shutdown');
    }
};
