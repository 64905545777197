import React, { FC, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { useGeneralModal } from 'src/hooks';
import { EditSocialMediaModalFormValues } from 'src/components/Modals/EditSocialMediaModal/EditSocialMediaModal';
import { LinkedInInput, SocialXInput, WebSiteInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';

export type EditSocialMediaModalContentProps = {
    forceChangeValues: (newValues: Partial<EditSocialMediaModalFormValues>) => void;
    isSubmitDisabled: boolean;
    updatedFormValues: Partial<EditSocialMediaModalFormValues>;
};

const EditSocialMediaModalContent: FC<EditSocialMediaModalContentProps> = ({
    isSubmitDisabled,
    forceChangeValues,
    updatedFormValues,
}) => {
    const { values, initialValues, setValues } = useFormikContext<EditSocialMediaModalFormValues>();
    const { closeModal } = useGeneralModal();

    useEffect(() => {
        const newValues = {
            website: updatedFormValues.website ?? values.website ?? initialValues.website,
            socialX: updatedFormValues.socialX ?? values.socialX ?? initialValues.socialX,
            socialLinkedIn:
                    updatedFormValues.socialLinkedIn ??
                    values.socialLinkedIn ??
                    initialValues.socialLinkedIn,
        };

        forceChangeValues(newValues);
        setValues(newValues);
    }, [updatedFormValues]);

    return (
        <Stack spacing={{ xs: 2.5, sm: 4 }}>
            <WebSiteInput
                hasRevertButton
                isRevertBtnVisible={values.website !== initialValues.website}
                forceChangeValues={forceChangeValues}
                values={values}
                initialValues={initialValues}
            />
            <LinkedInInput
                hasRevertButton
                isRevertBtnVisible={values.socialLinkedIn !== initialValues.socialLinkedIn}
                forceChangeValues={forceChangeValues}
                values={values}
                initialValues={initialValues}
            />
            <SocialXInput
                hasRevertButton
                isRevertBtnVisible={values.socialX !== initialValues.socialX}
                forceChangeValues={forceChangeValues}
                values={values}
                initialValues={initialValues}
            />
            <Stack direction="row" spacing={{ xs: 2, sm: 2.5 }} paddingTop={{ xs: 0.5, sm: 2 }}>
                <Button
                    fullWidth
                    minSize="unset"
                    variant="secondary"
                    onClick={closeModal}
                >
                        Cancel
                </Button>
                <Button
                    fullWidth
                    type="submit"
                    minSize="unset"
                    testId="submit-btn"
                    disabled={isSubmitDisabled}
                >
                        Save
                </Button>
            </Stack>
        </Stack>
    );
};

export default EditSocialMediaModalContent;
