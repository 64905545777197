import React from 'react';
import { StyledBoldText } from 'src/components/Modals/ConfirmationModal/ConfirmationMessage.styles';
import { JobInfo } from 'src/@types/unified-db-api';
import { JobInformationFormValues } from 'src/components/Modals/EditJobInformationModal/EditJobInformationModal';
import { ConfirmationMessageData } from 'src/components/Modals/ConfirmationModal/ConfirmationMessages';
import { useLookupNamesHook } from 'src/hooks';
import { getIndustriesList, getJobFunctionsList } from 'src/services/unified-db-api';

export const makeJobInformationConfirmMessages = (
    data: Partial<JobInfo>,
    jobInformation: Partial<JobInfo>,
): ConfirmationMessageData[] => {
    const messages: ConfirmationMessageData[] = [];
    const industryOldName = useLookupNamesHook(getIndustriesList, jobInformation?.industryId);
    const industryNewName = useLookupNamesHook(getIndustriesList, data?.industryId);
    const jobFunctionOldName = useLookupNamesHook(getJobFunctionsList, jobInformation?.jobFunctionId);
    const jobFunctionNewName = useLookupNamesHook(getJobFunctionsList, data?.jobFunctionId);

    const textInputs: { field: keyof JobInformationFormValues, label: string }[] = [
        { field: 'companyName', label: 'Company' },
        { field: 'jobTitle', label: 'Job title' },
    ];

    const dropdownInputs: {
        field: keyof JobInformationFormValues;
        label: string;
        oldName: string;
        newName: string;
    }[] = [
        { field: 'industryId', label: 'Industry', oldName: industryOldName, newName: industryNewName },
        { field: 'jobFunctionId', label: 'Job function', oldName: jobFunctionOldName, newName: jobFunctionNewName },
    ];

    textInputs.forEach(({ field, label }) => {
        if (data[field] === '' && jobInformation[field]) {
            messages.push({
                content: (
                    <>
                        {label} <StyledBoldText>&quot;{jobInformation[field]}&quot;</StyledBoldText> removed
                    </>
                ),
                key: field,
                testId: `message-${field}-removed`,
            });
        } else if (data[field] && !jobInformation[field]) {
            messages.push({
                content: (
                    <>
                        {label} <StyledBoldText>{data[field]}</StyledBoldText> added
                    </>
                ),
                key: field,
                testId: `message-${field}-set`,
            });
        } else if (data[field] && data[field] !== jobInformation[field]) {
            messages.push({
                content: (
                    <>
                        {label} changed from
                        <StyledBoldText> {jobInformation[field]}</StyledBoldText> to
                        <StyledBoldText> {data[field]}</StyledBoldText>
                    </>
                ),
                key: field,
                testId: `message-${field}-changed`,
            });
        }
    });

    dropdownInputs.forEach(({ field, label, oldName, newName }) => {
        if (data[field] !== jobInformation[field] && data[field] !== undefined) {
            if (jobInformation[field] && !data[field]) {
                messages.push({
                    content: (
                        <>
                            {label} selection of <StyledBoldText>&quot;{oldName}&quot;</StyledBoldText> removed
                        </>
                    ),
                    key: field,
                    testId: `message-${field}-removed`,
                });
            }
            if (!jobInformation[field] && data[field]) {
                messages.push({
                    content: (
                        <>
                            {label} set to <StyledBoldText>{newName}</StyledBoldText>
                        </>
                    ),
                    key: field,
                    testId: `message-${field}-set`,
                });
            } else if (data[field] && data[field] !== jobInformation[field]) {
                messages.push({
                    content: (
                        <>
                            {label} changed from
                            <StyledBoldText> {oldName}</StyledBoldText> to
                            <StyledBoldText> {newName}</StyledBoldText>
                        </>
                    ),
                    key: field,
                    testId: `message-${field}-changed`,
                });
            }
        }
    });

    return messages;
};
