import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageHandler from './PageHandler';
import { SnackbarMessageVariants } from 'src/constants';
import { useSnackbarMessage } from 'src/hooks';
import { RootState } from 'src/redux/root-reducer';
import { CurrentUserState } from 'src/@types/redux';
import { userSelector } from 'src/redux/slices';

const LogInPageHandler = ({ page, title }: { page: ReactElement, title: string }): ReactElement => {
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);
    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        if (currentUser) {
            addMessage('You\'re already logged in', SnackbarMessageVariants.INFO);
        }
    }, []);

    return (
        <PageHandler
            page={page}
            title={title}
        />
    );
};

export const wrapLogInPageWithHandler = (page: ReactElement, title: string = ''): ReactElement => {
    return (
        <LogInPageHandler
            page={page}
            title={title}
        />
    );
};
