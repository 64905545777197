import React from 'react';
import { StyledBoldText } from 'src/components/Modals/ConfirmationModal/ConfirmationMessage.styles';
import { ConfirmationMessageData } from 'src/components/Modals/ConfirmationModal/ConfirmationMessages';
import { EditSocialMediaModalFormValues } from 'src/components/Modals/EditSocialMediaModal/EditSocialMediaModal';

export const makeSocialMediaConfirmMessages = (
    data: Partial<EditSocialMediaModalFormValues>,
    socialMediaInformation: EditSocialMediaModalFormValues,
): ConfirmationMessageData[] => {
    const messages: ConfirmationMessageData[] = [];

    const textInputs: { field: keyof EditSocialMediaModalFormValues, label: string }[] = [
        { field: 'website', label: 'Website' },
        { field: 'socialLinkedIn', label: 'LinkedIn' },
        { field: 'socialX', label: 'X(Twitter)' },
    ];

    textInputs.forEach(({ field, label }) => {
        if (data[field] === '' && socialMediaInformation[field]) {
            messages.push({
                content: (
                    <>
                        {label} <StyledBoldText>&quot;{socialMediaInformation[field]}&quot;</StyledBoldText> removed
                    </>
                ),
                key: field,
                testId: `message-${field}-removed`,
            });
        } else if (data[field] && !socialMediaInformation[field]) {
            messages.push({
                content: (
                    <>
                        {label} <StyledBoldText>{data[field]}</StyledBoldText> added
                    </>
                ),
                key: field,
                testId: `message-${field}-set`,
            });
        } else if (data[field] && data[field] !== socialMediaInformation[field]) {
            messages.push({
                content: (
                    <>
                        {label} changed from
                        <StyledBoldText> {socialMediaInformation[field]} </StyledBoldText>
                        to
                        <StyledBoldText> {data[field]} </StyledBoldText>
                    </>
                ),
                key: field,
                testId: `message-${field}-changed`,
            });
        }
    });

    return messages;
};
