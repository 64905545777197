import { Box, InputAdornment, BoxProps as MuiBoxProps } from '@mui/material';
import { Field, FieldProps } from 'formik';
import React, { Fragment, useState } from 'react';
import { OptionsType, Select } from './Select';
import { InputWithRevertButtonWrapper } from 'src/components/Inputs/components/InputRevertButton/InputRevertButton.styles';
import { InputRevertButton } from 'src/components/Inputs/components/InputRevertButton';
import { Label } from 'src/components/Label';

type LabeledSelectProps = {
    testId?: string;
    name: string;
    label: string;
    placeholder?: string;
    hasRevertButton?: boolean;
    isRevertBtnVisible?: boolean;
    onFormChange?: (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => void;
    onRevert?: () => void;
    options: OptionsType[];
} & MuiBoxProps;

export const LabeledSelect = ({
    testId,
    name,
    label,
    placeholder,
    onRevert,
    onFormChange,
    options,
    hasRevertButton,
    isRevertBtnVisible = false,
    ...BoxProps
}: LabeledSelectProps) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const InputWrapper = hasRevertButton ? InputWithRevertButtonWrapper : Fragment;
    const inputWrapperProps = hasRevertButton ? {
        'data-is-button-visible': isRevertBtnVisible,
    } : null;

    return (
        <Field name={name}>
            {({ field, form, meta }: FieldProps) => (
                <>
                    <Box {...BoxProps}>
                        <Label isFocused={isFocused} htmlFor={name}>{label}</Label>
                        <InputWrapper
                            {...inputWrapperProps}
                        >
                            <Select
                                onOpen={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                testId={testId}
                                name={field.name}
                                value={field.value}
                                handleInputChange={(event) => {
                                    field.onChange(event);
                                    onFormChange?.(event);
                                }}
                                placeholder={placeholder}
                                options={options}
                                endAdornment={hasRevertButton && meta.initialValue !== meta.value && (
                                    <InputAdornment position="end">
                                        <InputRevertButton
                                            testId={`${testId}-revert-button`}
                                            onClick={() => {
                                                onRevert?.();
                                                form.setFieldValue(field.name, meta.initialValue);
                                            }}
                                        />
                                    </InputAdornment>
                                )}
                            />
                        </InputWrapper>
                    </Box>
                </>
            )}
        </Field>
    );
};
