import React, { FC, useState } from 'react';
import PriceCalculator from './PriceCalculator';
import { formatPrice } from 'src/services/formatters';
import { AddDomainForm } from 'src/components/Forms';
import { CalculatePriceResponse, DomainModel } from 'src/@types/subscription-service-api';
import { BasicLayout, PageVariant } from 'src/layouts';
import PageTitle from 'src/components/PageTitle';

export const getFieldToRender = (price: CalculatePriceResponse | null, userCount: number) => [
    {
        key: 'pricePerUser',
        label: 'Price per user:',
        value: formatPrice(price?.pricePerUserInCents ?? 0),
    },
    {
        key: 'discount',
        label: 'Discount:',
        value: `${formatPrice(price?.discountAmountInCents ?? 0)} (${price?.discountPercentage ?? 0}%)`,
    },
    {
        key: 'suggestPrice',
        label: `Suggested price for ${userCount} users:`,
        value: formatPrice((price?.pricePerUserInCents ?? 0) * userCount),
    },
];

const EnterpriseCalculatorPage: FC = () => {
    const [domains, setDomains] = useState<DomainModel[]>([]);

    const totalUserCount = domains.reduce((total, { userCount }) => total + userCount, 0);
    const domainsStr = domains.reduce((acc, { domain }) => `${acc}${domain}`, '');

    return (
        <BasicLayout pageVariant={PageVariant.FORM} testId="enterprise-calculator-page">
            <PageTitle title="Enterprise calculator" />
            <AddDomainForm
                domains={domains}
                setDomains={setDomains}
                totalUserCount={totalUserCount}
            />
            {domains.length > 0 && (
                <PriceCalculator
                    getFieldToRender={getFieldToRender}
                    hash={domainsStr}
                    initialUserCount={totalUserCount}
                />
            )}
        </BasicLayout>
    );
};

export default EnterpriseCalculatorPage;
