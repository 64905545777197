import React from 'react';
import { RevertButtonWrapper } from './InputRevertButton.styles';
import { ActionButtonVariant, InputActionButton } from 'src/components/Buttons/ActionButton';
import { ReactComponent as RevertIcon } from 'src/assets/icons/revert.svg';
import StyledTooltip from 'src/components/Tooltip';

type InputRevertButtonProps = {
    testId?: string;
    onClick?: () => void;
};

export const InputRevertButton = ({
    testId,
    onClick,
}: InputRevertButtonProps) => {
    return (
        <RevertButtonWrapper alignItems="center" justifyContent="center" data-testid={'input-revert-button-wrapper'}>
            <StyledTooltip title="Revert" arrow>
                <InputActionButton
                    data-attribute-to-change="fill"
                    onMouseDown={onClick}
                    data-variant={ActionButtonVariant.GREY}
                    data-testid={testId || 'input-revert-button'}
                >
                    <RevertIcon />
                </InputActionButton>
            </StyledTooltip>
        </RevertButtonWrapper>
    );
};
