import { Box } from '@mui/material';
import React, { FC } from 'react';
import Line from 'src/components/Line';

const MenuItemDivider: FC<{ testId?: string }> = ({ testId = 'menu-item-divider' }) => (
    <Box marginY={0.5} paddingX={2} data-testid={testId}>
        <Line />
    </Box>
);

export default MenuItemDivider;
