import React, { FC } from 'react';
import { Link } from '@mui/material';
import {
    StyledContent,
    StyledContentContainer,
    StyledCopyIconContainer,
    StyledGridContainer,
    StyledSubGrid,
} from './StripeLink.styles';
import { ReactComponent as LinkIcon } from 'src/assets/icons/stripe-link-icon.svg';
import { EMPTY_CELL_VALUE } from 'src/services/grid-formatters';
import StyledTooltip from 'src/components/Tooltip';
import { CopyIcon } from 'src/components/CopyIcon';

type StripeLinkProps = {
    content: string;
    href: string;
};

const StripeLink: FC<StripeLinkProps> = ({ content, href }) => {
    if (content === EMPTY_CELL_VALUE) {
        return <span>{EMPTY_CELL_VALUE}</span>;
    }

    return (
        <StyledGridContainer alignItems="center" container>
            <StyledSubGrid item flex={1}>
                <StyledContentContainer direction="row" position="relative" spacing={2}>
                    <StyledTooltip placement="bottom-start" disableInteractive title={content} arrow>
                        <StyledContent target="_blank" href={href}>{content}</StyledContent>
                    </StyledTooltip>
                    <StyledTooltip disableInteractive arrow title="Open in Stripe">
                        <Link display="flex" target="_blank" href={href}>
                            <LinkIcon width="16px" />
                        </Link>
                    </StyledTooltip>
                </StyledContentContainer>
            </StyledSubGrid>
            <StyledCopyIconContainer item position="absolute" right={16}>
                <CopyIcon content={content} />
            </StyledCopyIconContainer>
        </StyledGridContainer>
    );
};

export const stripeLinkRenderer = (type: 'customers' | 'subscriptions', id: string) => {
    return <StripeLink content={id} href={`https://dashboard.stripe.com/${type}/${id}`} />;
};
