import { useDispatch } from 'react-redux';
import { OpenModalActionPayload, UpdateModalActionPayload } from 'src/@types/redux';
import { closeModal, openModal, updateModal } from 'src/redux/slices/general-modal';

export const useGeneralModal = () => {
    const dispatch = useDispatch();

    return {
        openModal: (payload: OpenModalActionPayload) => {
            dispatch(openModal(payload));
        },
        closeModal: () => {
            dispatch(closeModal());
        },
        updateModal: (payload: UpdateModalActionPayload) => {
            dispatch(updateModal(payload));
        },
    };
};
