import React, { FC, useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { buildStripeCustomerHref, buildStripeSubscriptionHref } from 'src/services/stripe';
import Line from 'src/components/Line';
import { getPaymentTypeLabel, getSubscriptionPaymentStatus } from 'src/services/subscription-service-getters';
import { SubscriptionModel, SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { BadgeProps } from 'src/components/Badge/Badge/Badge';
import { BadgeVariant } from 'src/components/Badge/Badge/Badge.styles';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import { Badge, RoundedBadge } from 'src/components/Badge';
import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import {
    LinkContainer,
    SecondaryTitle,
    StyledGrid,
    StyledStack,
    SubscriptionInfo,
} from 'src/components/SubscriptionManageHeader/ManageHeader.styles';
import { Accordion } from 'src/components/Accordion';
import { DateFormats, roundedBadgePropsMapping, SubscriptionType } from 'src/constants';
import { formatDate } from 'src/services/formatters';
import { ManageActions } from 'src/components/ManageActions';
import { ReactComponent as StripeLinkIcon } from 'src/assets/icons/stripe-link-icon.svg';

type SubscriptionManageHeaderProps = {
    subscription: SubscriptionModel;
    managers: SubscriptionUserModel[];
    actionsList: Record<string, ManageActionType>;
    isUdb?: boolean;
};

const badgePropsMapping: Record<SubscriptionType, BadgeProps> = {
    [SubscriptionType.INSIDER]: {
        label: 'Insider',
        variant: BadgeVariant.BLUE,
    },
    [SubscriptionType.ENTERPRISE]: {
        label: 'Enterprise',
        variant: BadgeVariant.GOLD,
    },
};

const SubscriptionManageHeader: FC<SubscriptionManageHeaderProps> = ({
    subscription,
    actionsList,
    managers,
    isUdb,
}) => {
    const [owner, setOwner] = useState<SubscriptionUserModel | null>(null);

    useEffect(() => {
        const ownerData = managers.find((manager) => manager.isOwner);
        if (ownerData) {
            setOwner(ownerData);
        }
    }, [managers, owner]);

    const { stripeSubscriptionId, stripeCustomerId } = subscription.customAttributes || {};
    const paymentStatus = getSubscriptionPaymentStatus(subscription);
    const isStripeManaged = subscription.paymentType === 'stripeManaged';

    return (
        <StyledGrid container alignItems={{ xs: 'flex-start', md: isUdb ? 'flex-start' : 'center' }}>
            <Grid item xs={12} sm={9}>
                <StyledStack gap={1}>
                    <SecondaryTitle>{subscription.type === SubscriptionType.INSIDER
                        ? owner?.fullName
                        : owner?.companyName
                    }
                    </SecondaryTitle>
                    <Badge {...badgePropsMapping[subscription.type]} />
                    {!isUdb && <RoundedBadge {...roundedBadgePropsMapping[subscription.status]} />}
                </StyledStack>
                <StyledStack display={{ xs: 'none', md: 'flex' }} marginTop={0.5}>
                    <SubscriptionInfo>
                        {`Subscription period:
                            ${formatDate(subscription.activationDate, DateFormats.FULL)}
                             -
                            ${formatDate(subscription.expirationDate)}`}
                    </SubscriptionInfo>
                    {isUdb && <RoundedBadge {...roundedBadgePropsMapping[subscription.status]} />}
                </StyledStack>
                {isUdb && (
                    <StyledStack display={{ xs: 'none', md: 'flex' }} marginTop={0.5}>
                        <SubscriptionInfo>{`End of grace period: ${formatDate(subscription.gracePeriodEndDate)}`}</SubscriptionInfo>
                        {paymentStatus &&
                                <RoundedBadge label={paymentStatus} variant={RoundedBadgeVariant.OUTLINED} />
                        }
                    </StyledStack>
                )}
                <StyledStack display={{ xs: 'flex', md: 'none' }} marginTop={1}>
                    <Accordion
                        id="subscription-period"
                        title="Subscription period"
                        badgeProps={isUdb ? roundedBadgePropsMapping[subscription.status] : undefined}
                    >
                        <SubscriptionInfo>
                            {`${formatDate(subscription.activationDate, DateFormats.FULL)}
                             - 
                            ${formatDate(subscription.expirationDate)}`}
                        </SubscriptionInfo>
                    </Accordion>
                </StyledStack>
                {isUdb && (
                    <StyledStack display={{ xs: 'flex', md: 'none' }} marginTop={1}>
                        <Accordion
                            id="grace-period"
                            title="End of grace period"
                            badgeProps={paymentStatus ? {
                                label: paymentStatus,
                                variant: RoundedBadgeVariant.OUTLINED,
                            } : undefined}
                        >
                            <SubscriptionInfo>
                                {formatDate(subscription.gracePeriodEndDate)}
                            </SubscriptionInfo>
                        </Accordion>
                    </StyledStack>
                )}
            </Grid>
            <Grid
                item
                container
                xs={3}
                justifyContent="flex-end"
                position={{ xs: 'absolute', sm: 'relative' }}
                top={0}
                right={0}
            >
                <ManageActions
                    uuid={subscription.uuid}
                    subscription={subscription}
                    managers={managers}
                    actionsList={actionsList}
                    isUdb={isUdb}
                />
            </Grid>
            {isUdb && (
                <Box flexGrow={1}>
                    <Stack paddingY={1.5}><Line /></Stack>
                    <SubscriptionInfo>
                        Billing type: {getPaymentTypeLabel(subscription.paymentType)}&nbsp;
                        {stripeSubscriptionId && isStripeManaged && (
                            <LinkContainer
                                target="_blank"
                                data-testid="stripe-link"
                                href={buildStripeSubscriptionHref(stripeSubscriptionId)}
                            >
                                Open in Stripe
                                <StripeLinkIcon width="16px" />
                            </LinkContainer>
                        )}
                    </SubscriptionInfo>
                    {stripeCustomerId && (
                        <LinkContainer
                            href={buildStripeCustomerHref(stripeCustomerId)}
                            target="_blank"
                        >
                            Open associated customer in Stripe
                            <StripeLinkIcon width="16px" />
                        </LinkContainer>
                    )}
                </Box>
            )}
        </StyledGrid>
    );
};

export default SubscriptionManageHeader;
