import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledSubscriptionType = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ theme }) => theme.palette.lightBlack};
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 16px;
    line-height: 28px;

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: 14px;
    }
  }
`;
