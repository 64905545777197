import React, { FC, Fragment } from 'react';
import { Stack } from '@mui/material';
import { CopyIcon } from 'src/components/CopyIcon';
import { useGeneralModal } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import StyledTitle from 'src/components/Title';
import Line from 'src/components/Line';

export type AdditionalEmailModalProps = {
    secondaryEmails: string[];
};

const AdditionalEmailModal: FC<AdditionalEmailModalProps> = ({ secondaryEmails }) => {
    const { closeModal } = useGeneralModal();

    return (
        <Stack data-testid="additional-email-modal" gap={{ xs: 1.5, sm: 2 }}>
            {secondaryEmails.map((email, index) => (
                <Fragment key={email}>
                    {index !== 0 && <Line />}
                    <Stack flexDirection="row" justifyContent="space-between" data-testid={`email-${index}`}>
                        <StyledTitle data-font="medium" data-testid={`email-value-${index}`}>
                            {email}
                        </StyledTitle>
                        <CopyIcon content={email} />
                    </Stack>
                </Fragment>
            ))}
            <Stack paddingTop={1.5} display={{ sx: 'flex', sm: 'none' }}>
                <Button onClick={() => closeModal()} variant="secondary" fullWidth data-testid="cancel-button">Close</Button>
            </Stack>
        </Stack>
    );
};

export default AdditionalEmailModal;
