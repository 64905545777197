import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { NotFoundButton, NotFoundStackContainer } from './NotFound.styles';
import { ReactComponent as NotFoundIcon } from 'src/assets/icons/not-found.svg';
import { CenteredFullScreenLayout } from 'src/layouts';
import PageTitle from 'src/components/PageTitle';
import { userSelector } from 'src/redux/slices';
import { NeedHelp } from 'src/components/NeedHelp';
import { CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

const NotFoundPage: FC = () => {
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);
    const buttonName = !currentUser ? 'Back to Login page' : 'Back to Home page';
    const buttonHref = !currentUser ? '/log-in' : '/';

    return (
        <CenteredFullScreenLayout data-testid="not-found-page">
            <NotFoundStackContainer spacing={4}>
                <NotFoundIcon />
                <PageTitle
                    title="Oops! Page not found"
                    subtitle="The page you are looking for was moved, removed, renamed or might never existed"
                    textAlign="center"
                />
                <NotFoundButton testId="not-found-button" href={buttonHref}>{buttonName}</NotFoundButton>
                <NeedHelp />
            </NotFoundStackContainer>
        </CenteredFullScreenLayout>
    );
};

export default NotFoundPage;
