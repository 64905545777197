import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Feature, useIsFeatureEnabled } from 'src/hooks/useIsFeatureEnabled';
import { PageSection } from 'src/components/PageSection';
import { DurationInput } from 'src/components/Inputs';
import {
    billingCycleRadioItems,
    gatActivationDate,
    getActivationDateRadioItems,
    getBillingRadioItems,
    getNumberOfCyclesWhenPaymentTypeChange,
} from 'src/pages/udb/CreateSubscription/services/type-and-billing-step-getters';
import {
    ActivationDate,
    additionalShortInputWidth,
    BillingCycleLengthInMonth,
    billingCycleLengthRenderMapping,
} from 'src/pages/udb/CreateSubscription/services/constants';
import { StepContainer } from 'src/pages/udb/CreateSubscription/components/StepContainer';
import { SubscriptionTypeRadioGroup } from 'src/pages/udb/CreateSubscription/components/SubscriptionTypeRadioGroup';
import { RadioGroup } from 'src/components/Radio';
import { SubscriptionPaymentType, SubscriptionType } from 'src/constants';

export type TypeAndBillingData = {
    selectedSubscriptionType?: SubscriptionType;
    selectedPaymentType?: SubscriptionPaymentType;
    selectedBillingCycleLength?: BillingCycleLengthInMonth;
    selectedActivationDateType?: ActivationDate;
    numberOfCycles?: number;
    activationDate?: string | null;
};

type TypeAndBillingStepProps = {
    initialData?: TypeAndBillingData;
    handleNextButtonClick: (data: Required<TypeAndBillingData>) => void;
};

const TypeAndBillingStep: FC<TypeAndBillingStepProps> = ({ handleNextButtonClick, initialData = {} }) => {
    const featureAccountUpgrade = useIsFeatureEnabled(Feature.accountUpgrade);
    const [typeAndBillingData, setTypeAndBillingData] =
        useState<TypeAndBillingData>(initialData);

    const { activationDate,
        selectedActivationDateType,
        selectedSubscriptionType,
        selectedPaymentType,
        selectedBillingCycleLength,
        numberOfCycles } = typeAndBillingData;

    const hasAllData = [
        selectedSubscriptionType,
        selectedPaymentType,
        selectedBillingCycleLength,
        selectedActivationDateType,
        numberOfCycles,
        activationDate,
    ].every((item) => item !== undefined);

    const navigate = useNavigate();

    useEffect(() => {
        if (!numberOfCycles && selectedSubscriptionType) {
            setTypeAndBillingData({
                ...typeAndBillingData,
                numberOfCycles: getNumberOfCyclesWhenPaymentTypeChange(selectedPaymentType, numberOfCycles),
            });
        }
    }, [selectedSubscriptionType]);

    const getNavigateToUpgradeInsiderPage = () => {
        if (!featureAccountUpgrade) {
            return;
        }

        return () => navigate('/upgrade/insider');
    };

    const setData = (key: keyof TypeAndBillingData) => (value: TypeAndBillingData[keyof TypeAndBillingData]) => {
        setTypeAndBillingData({
            ...typeAndBillingData,
            [key]: value,
        });
    };

    const handleSubscriptionTypeChange = (selectedType: SubscriptionType) => {
        setTypeAndBillingData({
            ...typeAndBillingData,
            selectedSubscriptionType: selectedType,
        });
    };

    const setSelectedPaymentType = (paymentType: SubscriptionPaymentType) => {
        setTypeAndBillingData({
            ...typeAndBillingData,
            selectedPaymentType: paymentType,
            numberOfCycles: getNumberOfCyclesWhenPaymentTypeChange(paymentType, numberOfCycles),
        });
    };

    const setSelectedActivationDateType = (activationDateType: ActivationDate) => {
        setTypeAndBillingData({
            ...typeAndBillingData,
            selectedActivationDateType: activationDateType,
            activationDate: gatActivationDate(activationDateType),
        });
    };

    return (
        <StepContainer
            title={`Select subscription type ${selectedSubscriptionType ? ' and billing' : ''}`}
            showNextButton={hasAllData}
            handleNextButtonClick={() => handleNextButtonClick(typeAndBillingData as Required<TypeAndBillingData>)}
        >
            <PageSection variant="headless" header="Subscription type">
                <SubscriptionTypeRadioGroup
                    selectedSubscriptionType={selectedSubscriptionType}
                    onChange={handleSubscriptionTypeChange}
                />
            </PageSection>
            {selectedSubscriptionType && (
                <Fragment>
                    <PageSection variant="headless" header="Billing">
                        <RadioGroup<SubscriptionPaymentType>
                            selectedValue={selectedPaymentType}
                            items={getBillingRadioItems(selectedSubscriptionType, getNavigateToUpgradeInsiderPage())}
                            onChange={setSelectedPaymentType}
                        />
                    </PageSection>
                    <PageSection variant="headless" header="Billing cycle length">
                        <RadioGroup<BillingCycleLengthInMonth>
                            selectedValue={selectedBillingCycleLength}
                            items={billingCycleRadioItems}
                            onChange={setData('selectedBillingCycleLength')}
                        />
                    </PageSection>
                    <PageSection variant="headless" header="Activation date">
                        <RadioGroup<ActivationDate>
                            selectedValue={selectedActivationDateType}
                            items={getActivationDateRadioItems(
                                setData('activationDate'),
                                activationDate,
                                selectedActivationDateType,
                                selectedBillingCycleLength,
                            )}
                            onChange={setSelectedActivationDateType}
                        />
                    </PageSection>
                    <PageSection variant="headless" header="Expires in">
                        <Box maxWidth={additionalShortInputWidth}>
                            <DurationInput
                                disabled={selectedPaymentType === SubscriptionPaymentType.INVOICED}
                                value={numberOfCycles}
                                onChange={setData('numberOfCycles')}
                                adornmentText={selectedBillingCycleLength && (
                                    billingCycleLengthRenderMapping[selectedBillingCycleLength]
                                )}
                            />
                        </Box>
                    </PageSection>
                </Fragment>
            )}
        </StepContainer>
    );
};

export default TypeAndBillingStep;
