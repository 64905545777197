import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

export const StyledOwnerBox = styled(Box)`
    && {
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.lightBlack};
        margin: 4px 0;
    }
`;

export const StyledDateInfo = styled(Box)`
    && {
        display: flex;
        font-size: 14px;
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.lightGrey};
        
        @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
            width: 130px;
            font-family: ${({ theme }) => theme.fonts.normal};
            color: ${({ theme }) => theme.palette.black};
            font-size: 16px;
        }
    }
`;

export const StyledPlan = styled(Typography)`
    && {
        text-transform: uppercase;  
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};
        font-size: 16px;
        line-height: normal;

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
           font-family: ${({ theme }) => theme.fonts.medium};
           font-size: 14px;
        }
    }
`;
