import React, { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { FormikHelpers } from 'formik';
import { Button } from 'src/components/Buttons';
import { FormWrapper } from 'src/components/Forms/components';
import { passwordValidator, strictPasswordValidator, validateSchemaObject } from 'src/services/validators';
import { PasswordInput } from 'src/components/Inputs/PasswordInput';
import { FAILED_PASSWORD_CHANGE_MESSAGE, PASSWORDS_DO_NOT_MATCH_MESSAGE, SnackbarMessageVariants } from 'src/constants';
import { useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import { changePassword } from 'src/services/sso-api';

type ChangePasswordFormValues = {
    newPassword: string;
    repeatNewPassword: string;
};

const ChangePasswordForm: FC = () => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmitFailed, setIsSubmitFailed] = useState<boolean>(false);
    const [showButton, setShowButton] = useState<boolean>(false);
    const [isDisabledUpdate, setIsDisabledUpdate] = useState<boolean>(true);

    const { addMessage } = useSnackbarMessage();

    const initialValues = {
        newPassword: '',
        repeatNewPassword: '',
    };

    const {
        isSubmitDisabled,
        onFormChangeHandler,
    } = useIsSubmitDisabled(initialValues);

    const validationSchema = validateSchemaObject({
        newPassword: strictPasswordValidator,
        repeatNewPassword: passwordValidator,
    });

    const handleSubmit = async (
        values: ChangePasswordFormValues,
        { setErrors, resetForm }: FormikHelpers<ChangePasswordFormValues>,
    ): Promise<void> => {
        const { newPassword, repeatNewPassword } = values;
        if (newPassword !== repeatNewPassword) {
            setIsSubmitFailed(true);
            setErrors({
                repeatNewPassword: PASSWORDS_DO_NOT_MATCH_MESSAGE,
            });
            return;
        }

        setIsSubmitFailed(false);
        setIsSubmitting(true);
        await changePassword({ newPassword, repeatNewPassword })
            .then(() => {
                addMessage('Password changed successfully', SnackbarMessageVariants.SUCCESS);
                resetForm({ values: initialValues });
            })
            .catch(() => {
                setIsSubmitFailed(true);
                setErrors({ newPassword: ' ', repeatNewPassword: ' ' });
                addMessage(FAILED_PASSWORD_CHANGE_MESSAGE, SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
                setShowButton(false);
            });
    };

    const handlerAutoGenerate = () => {
        setIsSubmitFailed(false);
        setShowButton(true);
    };

    return (
        <FormWrapper<ChangePasswordFormValues>
            testId="change-password-form"
            initialValues={initialValues}
            validationSchema={validationSchema}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            onFormChange={() => {
                setIsSubmitFailed(false);
            }}
        >
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={{ xs: 0, md: 2 }}
                paddingBottom={0.25}
                paddingRight={0.25}
            >
                <Box flex={1}>
                    <PasswordInput
                        name="newPassword"
                        placeholder="New password"
                        testId="change-new-password"
                        label="New password"
                        disabled={isSubmitting}
                        hasActiveValidator
                        showAutoGenerate
                        handlerAutoGenerate={handlerAutoGenerate}
                        hasSubmittingError={isSubmitFailed}
                        paddingBottom={{ xs: 2.5, sm: 4, md: 0 }}
                        validator={strictPasswordValidator}
                    />
                </Box>
                <Stack
                    spacing={{ xs: 2, sm: 1 }}
                    direction={{ xs: 'column', sm: 'row' }}
                    flex={1}
                >
                    <Box flex={1}>
                        <PasswordInput
                            name="repeatNewPassword"
                            placeholder="Re-enter your password"
                            testId="change-repeat-new-password"
                            label="Confirm password"
                            disabled={isSubmitting}
                            hasSubmittingError={isSubmitFailed}
                            setShowUpdateButton={setShowButton}
                            onChange={onFormChangeHandler}
                            setInputValue={(value) => setIsDisabledUpdate(!value)}
                            onBlur={() => setShowButton(!isSubmitDisabled)}
                        />
                    </Box>
                    {showButton && (
                        <Box paddingTop={{ xs: 0, sm: 2.75 }}>
                            <Button
                                fullWidth
                                data-testid="change-password-submit-button"
                                disabled={isSubmitFailed || isDisabledUpdate}
                                type="submit"
                                variant="primary"
                                minSize="small"
                            >
                            Update
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Stack>
        </FormWrapper>
    );
};

export default ChangePasswordForm;
