import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import './index.css';
import { extend } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { SpinnerPage } from './pages/Spinner';
import AppRouter from './pages/AppRouter';
import { GlobalStyles, muiTheme, theme } from './theme';
import boot from './boot';
import { SnackbarContainer } from './components/Snackbar';
import 'react-toastify/dist/ReactToastify.min.css';

extend(utc);
extend(timezone);

const root = createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <SpinnerPage />
        </ThemeProvider>
    </React.StrictMode>,
);

boot().then((store) => {
    root.render(
        <Provider store={store}>
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    <SnackbarContainer />
                    <BrowserRouter>
                        <AppRouter />
                    </BrowserRouter>
                </ThemeProvider>
            </MuiThemeProvider>
        </Provider>,
    );
});
