import React, { FC } from 'react';
import { Box } from '@mui/material';
import StyledTooltip from 'src/components/Tooltip';
import { GRID_DEFAULT_TOOLTIP_PROPS, SubscriptionPaymentStatus } from 'src/constants';
import {
    LabelContainer,
    StyledStatusLabel,
} from 'src/pages/udb/Subscriptions/components/SubscriptionStatus/SubscriptionStatus.styles';

type SubscriptionStatusProps = {
    value: string;
    paymentStatus: SubscriptionPaymentStatus | null;
};

const SubscriptionStatus: FC<SubscriptionStatusProps> = ({ value, paymentStatus }) => (
    <LabelContainer>
        <StyledTooltip
            title={value}
            {...GRID_DEFAULT_TOOLTIP_PROPS}
        >
            <Box>{value}</Box>
        </StyledTooltip>
        {paymentStatus && <StyledStatusLabel>{paymentStatus}</StyledStatusLabel>}
    </LabelContainer>
);

export default SubscriptionStatus;
