import { BooleanValues, SsoConditionValues, Status, userRoleRenderMapping, UserRoles } from 'src/constants';
import { OptionsType } from 'src/components/Select/Select';

export const CONTAINS_CONDITIONS = [{
    label: 'contains',
    value: SsoConditionValues.CONTAINS,
}];

export const MULTI_SELECT_CONDITIONS = [{
    label: 'one of',
    value: SsoConditionValues.ONE_OF,
}];

export const TIME_CONDITIONS = [{
    label: 'from',
    value: SsoConditionValues.FROM,
}, {
    label: 'to',
    value: SsoConditionValues.TO,
}];

export const EQUALS_CONDITIONS = [{
    label: 'equals',
    value: SsoConditionValues.EQUALS,
}];

export const HAS_WP_PASSWORD_CONDITIONS = [{
    label: 'has',
    value: SsoConditionValues.HAS,
}];

export const STATUS_OPTIONS: OptionsType[] = [
    { label: 'Active', value: Status.ACTIVE },
    { label: 'Inactive', value: Status.INACTIVE },
];

export const ROLE_OPTIONS: OptionsType[] = [
    { label: userRoleRenderMapping[UserRoles.ADMIN], value: UserRoles.ADMIN },
    { label: userRoleRenderMapping[UserRoles.APP_MANAGER], value: UserRoles.APP_MANAGER },
    { label: userRoleRenderMapping[UserRoles.USER_MANAGER], value: UserRoles.USER_MANAGER },
    { label: userRoleRenderMapping[UserRoles.USER], value: UserRoles.USER },
    { label: userRoleRenderMapping[UserRoles.ANALYST], value: UserRoles.ANALYST },
];

export const HAS_WP_PASSWORD_OPTIONS: OptionsType[] = [
    { label: 'Yes', value: BooleanValues.TRUE },
    { label: 'No', value: BooleanValues.FALSE },
];

export const TFA_ENABLED_OPTIONS: OptionsType[] = [
    { label: 'Yes', value: BooleanValues.TRUE },
    { label: 'No', value: BooleanValues.FALSE },
];

export const TFA_ENABLED_CONDITIONS = [{
    label: 'enabled',
    value: SsoConditionValues.ENABLED,
}];
