import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SubscriberType } from 'src/constants';
import { showIntercom } from 'src/services/intercom';
import { Button, TextButton } from 'src/components/Buttons';
import PageTitle from 'src/components/PageTitle';

type AlreadyAPaidSubscriberProps = {
    goBackAction: () => void;
    type: SubscriberType.enterprise | SubscriberType.insider;
    isFirstSearchedEmail: boolean;
};

const AlreadyAPaidSubscriber: FC<AlreadyAPaidSubscriberProps> = ({ goBackAction, type, isFirstSearchedEmail }) => {
    const navigate = useNavigate();

    const isEnterprise = type === SubscriberType.enterprise;

    return (
        <Stack spacing={3} data-testid="already-a-paid-subscription">
            <PageTitle
                variant="form"
                testId="form-title"
                title={isFirstSearchedEmail ? 'You are already a subscriber' : 'Already a subscriber'}
                subtitle={
                    isFirstSearchedEmail ? (
                        <>
                            Great news. You’re already a premium subscriber
                            {isEnterprise ? ' with unlimited access through your company’s license' : ''}.<br />
                            Please <TextButton onClick={showIntercom}>contact us</TextButton> if you need help
                            logging into your existing account.
                        </>
                    ) : (
                        <>
                            This account is already a premium subscriber
                            {isEnterprise ? ' with unlimited access through your company’s license' : ''}.<br />
                            Please <TextButton onClick={showIntercom}>contact us</TextButton> if you need help.
                        </>
                    )
                }
            />
            <Stack
                direction="column"
                spacing={{ xs: 2, sm: 2.5 }}
            >
                {isFirstSearchedEmail && <Button onClick={() => navigate('/log-in')}>Log into your account</Button>}
                <Button onClick={goBackAction} variant={isFirstSearchedEmail ? 'secondary' : 'primary'}>
                    Use another email
                </Button>
            </Stack>
        </Stack>
    );
};

export default AlreadyAPaidSubscriber;
