import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { PageState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

const initialState: PageState = {
    pageReloadCount: 0,
    initiallyRequestedPage: null,
};

export { initialState as pageInitialState };

export const pageSlice = createSlice<PageState, {
    incrementPageReloadCount: CaseReducer<PageState>;
    setInitiallyRequestedPage: CaseReducer<PageState, PayloadAction<PageState['initiallyRequestedPage']>>;
    clearInitiallyRequestedPage: CaseReducer<PageState>;
}>({
    name: 'page',
    initialState,
    reducers: {
        incrementPageReloadCount: (state) => ({
            ...state,
            pageReloadCount: state.pageReloadCount + 1,
        }),
        setInitiallyRequestedPage: (state, { payload }) => ({
            ...state,
            initiallyRequestedPage: payload,
        }),
        clearInitiallyRequestedPage: (state) => ({
            ...state,
            initiallyRequestedPage: initialState.initiallyRequestedPage,
        }),
    },
});

export const {
    incrementPageReloadCount,
    setInitiallyRequestedPage,
    clearInitiallyRequestedPage,
} = pageSlice.actions;

export const pageSelector = (state: RootState): PageState => state[pageSlice.name];
