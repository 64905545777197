import styled from 'styled-components';
import { Button } from '@mui/material';

export const TextButton = styled(Button)`
    &&& {
        display: inline-flex;
        align-items: center;
        font-family: inherit;
        line-height: inherit;
        font-size: inherit;
        color: ${({ theme }) => theme.palette.blue};
        background: none;
        text-transform: none;
        padding: 0;
        margin: 0;
        height: 100%;
        min-width: unset;
        font-style: normal;
        border-radius: 0;
        border: 1px solid transparent;

        & .MuiTouchRipple-root {
            display: none;
        }

        &:focus {
            border: 1px solid ${({ theme }) => theme.palette.blue};
        }
        
        &:disabled{
            opacity: 0.5;
        }
    }
`;
