import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export type PageTitleVariant = 'page' | 'form' | 'mobile-menu';

const pageTitleVariantSelector = (props: unknown & { 'data-variant'?: PageTitleVariant }) => {
    switch (props['data-variant']) {
        case 'form':
            return css`
                font-size: 22px;
                margin: 0;

                @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
                    font-size: 24px;
                }
            `;
        case 'mobile-menu':
            return css`
                font-size: 20px;
                margin: 0;
                text-wrap: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 210px;

                @media(max-width: ${({ theme }) => theme.brakePoints.sm}){
                    line-height: normal;
                    max-width: 190px;
                }
            `;
        case 'page':
        default:
            return css`
                font-size: 20px;
                margin: 0;

                @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
                    font-size: 28px;
                    line-height: 36px;
                }
            `;
    }
};

export const StyledArrowBackIcon = styled(ArrowBackIcon)`
    && {
        width: 18px;
        height: 18px;
        color: ${({ theme }) => theme.palette.lightBlack};
        cursor: pointer;
        margin-right: 12px;

        @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
            margin-right: 24px;
            height: 20px;
            width: 20px;
        }
        
        &:hover {
            color: ${({ theme }) => theme.palette.black};
        }
    }
`;

export const StyledPageTitle = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.bold};
        color: ${({ theme }) => theme.palette.black};

        a {
            text-decoration: none;
            color: ${({ theme }) => theme.palette.blue};
        }

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            line-height: 30px;
        }

        ${pageTitleVariantSelector}
    }
`;

const pageSubtitleVariantSelector = (props: unknown & { 'data-has-title': boolean, 'data-variant'?: PageTitleVariant }) => {
    if (!props['data-has-title']) {
        return css`
            color: ${({ theme }) => theme.palette.black};
            font-family: ${({ theme }) => theme.fonts.medium};
        `;
    }

    switch (props['data-variant']) {
        case 'form':
            return css`
                font-family: ${({ theme }) => theme.fonts.medium};
            `;
        case 'page':
        default:
            return css`
                font-family: ${({ theme }) => theme.fonts.normal};
            `;
    }
};

export const StyledPageSubtitle = styled(Typography)`
    && {
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
        color: ${({ theme }) => theme.palette.lightBlack};

        & b {
            font-family: ${({ theme }) => theme.fonts.bold};
        }
        
        a {
            text-decoration: none;
            color: ${({ theme }) => theme.palette.blue};
        }

        @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 16px;
        }

        ${pageSubtitleVariantSelector}
    }
`;
