import styled, { css } from 'styled-components';

const requiredSelector = (props: { ['data-required']?: boolean }) => {
    if (props['data-required']) {
        return css`
          &:after {
            content: '*';
            color: ${({ theme }) => theme.palette.red};
            margin-left: 1px;
          }
        `;
    }
};

export const StyledLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey};
  margin-bottom: 4px;
  display: flex;
  align-items: center;

  ${requiredSelector}
  
  &.focused {
    color: ${({ theme }) => theme.palette.blue}
  }

  .icon {
    margin-right: 0.325rem;
    color: ${({ theme }) => theme.palette.grey};
    height: 1rem;
  }
`;
