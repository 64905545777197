import React, { FC, HTMLAttributes, ReactElement, useEffect, useRef, useState } from 'react';
import intlTelInput from 'intl-tel-input';
import { type BoxProps, ClickAwayListener, type InputProps, Stack, Box } from '@mui/material';
import { StyledErrorMessage, StyledFieldContainer } from 'src/components/Inputs/StyledInput.styles';
import { Label } from 'src/components/Label';
import { INPUT_MAX_LENGTH, INVALID_PHONE_NUMBER_VALIDATION_ERROR } from 'src/constants';
import 'intl-tel-input/build/js/utils';
import 'intl-tel-input/build/css/intlTelInput.css';
import { TextInput } from 'src/components/Inputs/TextInput';

const utilsScriptUrl = 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.0.3/build/js/utils.js';

let iti: intlTelInput.Plugin;

type PhoneNumberInputProps = {
    name: string;
    type?: string;
    placeholder?: string;
    label?: string;
    initialValue?: string;
    inputProps?: Partial<InputProps>;
    testId?: string;
    labelIcon?: ReactElement;
    disabled?: boolean;
    hasSubmittingError?: boolean;
    inputMaxLength?: number;
    button?: JSX.Element;
    onValidation?: (value: string, isValid: boolean) => void;
    onValueChange?: (value: string) => void;
    handleClickAway?: () => void;
    showButton?: boolean;
} & BoxProps;

const getInitialValueWithoutDialCode = (dialCode: string, initialValue?: string) => {
    return initialValue ? initialValue.split(`+${dialCode}`)[1] : '';
};

const makeFormattedValue = (value: string, nationaFormattedValue: string, dialCode: string) => {
    const nationaFormattedValueFiltered = nationaFormattedValue.replace(new RegExp(/\D+/g), '');
    const reconstructedValue = `+${dialCode}${nationaFormattedValueFiltered}`;

    if (value === reconstructedValue) {
        return nationaFormattedValue;
    }

    return nationaFormattedValue.replace(/0/, '');
};

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
    name,
    label,
    initialValue,
    placeholder,
    inputProps,
    testId,
    labelIcon,
    disabled,
    hasSubmittingError,
    onValueChange,
    onValidation,
    handleClickAway,
    showButton,
    button,
    inputMaxLength = INPUT_MAX_LENGTH,
    ...BoxProps
}) => {
    const [value, setValue] = useState<string>(initialValue || '');
    const [valueChanged, setValueChanged] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);

    const preparedTestId = testId || `${name}-input`;
    const preparedLabel = label || name.replace(/[-_]/g, ' ').replace(/\b\w/g, (m) => m.toUpperCase());
    const isFormError = !isValid && !valueChanged;

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            if (iti) {
                iti.destroy();
                setValue('');
            }

            iti = intlTelInput(inputRef.current, {
                utilsScript: utilsScriptUrl,
                separateDialCode: true,
                autoInsertDialCode: true,
                nationalMode: false,
                initialCountry: 'us',
                excludeCountries: ['ru'],
            });

            if (initialValue) {
                iti.setNumber(initialValue);
                const { dialCode } = iti.getSelectedCountryData();
                const newValue = iti.getNumber();

                setValue(makeFormattedValue(
                    newValue,
                    iti.getNumber(intlTelInputUtils.numberFormat.NATIONAL),
                    dialCode,
                ));
            }
        }
    }, [initialValue]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;

        setValue(newValue);
        setValueChanged(true);
        onValueChange?.(iti.getNumber());

        if (!initialValue || initialValue.length === 0) {
            onValidation?.(newValue, iti.isValidNumber());
            return;
        }

        const { dialCode } = iti.getSelectedCountryData();
        const initialValueWithoutDialCode = getInitialValueWithoutDialCode(dialCode, initialValue);

        if (newValue !== initialValueWithoutDialCode) {
            onValidation?.(newValue, iti.isValidNumber());
        }
    };

    const handleFocus = () => {
        setIsFocused(true);

        const { dialCode } = iti.getSelectedCountryData();
        const validationResult = iti.isValidNumber();
        const newValue = iti.getNumber().split(`+${dialCode}`)[1];

        const initialValueWithoutDialCode = getInitialValueWithoutDialCode(dialCode, initialValue);
        const isNotInitialValue = newValue !== initialValueWithoutDialCode;

        if (newValue && isNotInitialValue) {
            setValue(newValue);
        }

        onValidation?.(newValue, validationResult);
    };

    const handleBlur = () => {
        setIsFocused(false);

        const newValue = iti.getNumber();
        const validationResult = iti.isValidNumber();

        const { dialCode } = iti.getSelectedCountryData();
        const nationalValue = iti.getNumber(intlTelInputUtils.numberFormat.NATIONAL);

        if (newValue.length) {
            if (valueChanged) {
                setIsValid(validationResult);
                onValidation?.(newValue, validationResult);
            }

            setValue(validationResult ? makeFormattedValue(newValue, nationalValue, dialCode) : nationalValue);
            onValueChange?.(newValue);
            iti.setNumber(newValue);

            setValueChanged(false);
        }
    };

    return (
        <StyledFieldContainer data-testid={preparedTestId} {...BoxProps} width="100%">
            <Label isFocused={isFocused} icon={labelIcon} htmlFor={name}>{preparedLabel}</Label>
            <ClickAwayListener onClickAway={() => handleClickAway?.()}>
                <Stack
                    gap={{ xs: showButton ? 2 : 0, sm: 1 }}
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                >
                    <TextInput
                        className={`form-input${isFormError ? ' invalid' : ''}`}
                        type="tel"
                        name={name}
                        value={value}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={placeholder}
                        disabled={disabled}
                        InputProps={{
                            ...inputProps,
                            slotProps: {
                                root: {
                                    'data-testid': `${preparedTestId}-root`,
                                } as HTMLAttributes<HTMLDivElement>,
                            },
                            inputProps: {
                                'data-testid': `${preparedTestId}-element`,
                                maxLength: inputMaxLength,
                                ref: inputRef,
                            },
                        }}
                    />
                    {showButton && (
                        <Box width={{ xs: '100%', sm: 'unset' }}>
                            {button}
                        </Box>
                    )}
                </Stack>
            </ClickAwayListener>
            {isFormError && (
                <StyledErrorMessage data-testid={`${preparedTestId}-error`}>
                    {INVALID_PHONE_NUMBER_VALIDATION_ERROR}
                </StyledErrorMessage>
            )}
        </StyledFieldContainer>
    );
};

export default PhoneNumberInput;
