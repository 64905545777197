import styled from 'styled-components';

export const CenteredFullScreenLayout = styled.section`
    min-width: 100%;
    min-height: 100vh;
    background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-rows: 1fr;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
`;
