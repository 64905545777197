import React, { ReactNode } from 'react';
import { FormControl, RadioGroup as MuiRadioGroup, Stack, StackProps } from '@mui/material';
import { ContainerFormControlLabel, StyledFormControlLabel } from './Radio.styles';
import { Radio } from './index';

export type RadioGroupItem<T = string> = {
    label: ReactNode;
    value: T;
    additionalNode?: ReactNode;
    additionalNodeProps?: StackProps;
    disabled?: boolean;
};

type RadioGroupProps<T> = {
    items: Array<RadioGroupItem<T>>;
    onChange: (value: T) => void;
    selectedValue?: T;
    containerProps?: StackProps;
};

const RadioGroup = <T = string>({ items, onChange, selectedValue, containerProps }: RadioGroupProps<T>) => {
    return (
        <FormControl fullWidth>
            <MuiRadioGroup
                onChange={(event, value) => onChange(value as T)}
                value={selectedValue ?? ''}
            >
                <Stack spacing={2} {...containerProps} paddingTop={0.5}>
                    {items.map(({ value, label, additionalNode, additionalNodeProps, disabled }) => (
                        <ContainerFormControlLabel key={`${value}`} direction="row" {...additionalNodeProps}>
                            <StyledFormControlLabel disabled={disabled} data-testid={`${value}-radio`} value={value} control={<Radio />} label={label} />
                            {additionalNode}
                        </ContainerFormControlLabel>
                    ))}
                </Stack>
            </MuiRadioGroup>
        </FormControl>
    );
};

export default RadioGroup;
