import type { ReactElement } from 'react';
import React from 'react';
import { SettingsModal } from './SettingsModal';
import type { SettingsModalProps } from './SettingsModal/SettingsModal';
import QRCodeModal, { type QRCodeModalProps } from './QRCodeModal/QRCodeModal';
import PaymentDetailsModal, { type PaymentDetailsModalProps } from './PaymentDetailsModal/PaymentDetailsModal';
import { AddManagerModal } from './AddManagerModal';
import type { AddManagerModalProps } from './AddManagerModal/AddManagerModal';
import { ChangeOwnerModal } from './ChangeOwnerModal';
import type { ChangeOwnerModalProps } from './ChangeOwnerModal/ChangeOwnerModal';
import { UpcomingRenewalModal } from './UpcomingRenewalModal';
import type { UpcomingRenewalModalProps } from './UpcomingRenewalModal/UpcomingRenewalModal';
import { UpcomingRenewalModalTitle } from './UpcomingRenewalModal/components';
import { AdditionalEmailModal } from './AdditionalEmailModal';
import type { AdditionalEmailModalProps } from './AdditionalEmailModal/AdditionalEmailModal';
import { GenerateLinkModal } from './GenerateLinkModal';
import { type GenerateLinkModalProps, GenerateLinkTitleList } from './GenerateLinkModal/GenerateLinkModal';
import { UpdateGracePeriodModal } from './UpdateGracePeriodModal';
import type { UpgradeGracePeriodModalProps } from './UpdateGracePeriodModal/UpdateGracePeriodModal';
import { EditJobInformationModal } from './EditJobInformationModal';
import type { JobInformationModalProps } from './EditJobInformationModal/EditJobInformationModal';
import { EditSocialMediaModal } from './EditSocialMediaModal';
import type { EditSocialMediaModalProps } from './EditSocialMediaModal/EditSocialMediaModal';
import { EditContactInformationModal } from './EditContactInformationModal';
import { EditContactInformationModalProps } from './EditContactInformationModal/EditContactInformationModal';
import { EditPaymentRenewalNotification } from './EditPaymentRenewalNotification';
import type {
    EditPaymentRenewalNotificationProps,
} from './EditPaymentRenewalNotification/EditPaymentRenewalNotification';
import type { EditUserNameModalProps } from './EditUserNameModal/EditUserNameModal';
import { EditUserNameModal } from './EditUserNameModal';
import type { BaseModalProps } from './BaseModal/BaseModal';
import { EditNewsLetterDeliveryModal } from './EditNewsLetterDeliveryModal';
import type { EditNewsLetterDeliveryModalProps } from './EditNewsLetterDeliveryModal/EditNewsLetterDeliveryModal';
import { AddDomainModal } from './AddDomainModal';
import type { AddDomainModalProps } from './AddDomainModal/AddDomainModal';
import SubscriptionActionModal, { SubscriptionActionModalProps } from './SubscriptionActionModal/SubscriptionActionModal';
import { ContractDetailsModal } from './ContractDetailsModal';
import { ContractDetailsModalProps } from './ContractDetailsModal/ContractDetailsModal';
import { GeneralModalKey, ManageUserActions } from 'src/constants';
import {
    UsageDataModal,
    type UsageDataModalProps,
} from 'src/components/Modals/SubscriptionUsageDataModal/UsageDataModal';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import type { ConfirmationModalProps } from 'src/components/Modals/ConfirmationModal/ConfirmationModal';

type MappedModalProps = {
    content?: string | ReactElement;
    modalProps?: Pick<BaseModalProps, 'paddingX' | 'paddingBottom' | 'contentPaddingTop' | 'title'>;
};

export const getMappedModalProps = (key: GeneralModalKey, contentProps: Record<string, unknown>): MappedModalProps => {
    const map: Partial<Record<GeneralModalKey, MappedModalProps>> = {
        [GeneralModalKey.text]: { content: contentProps.text as string },
        [GeneralModalKey.confirmationModal]: {
            content: <ConfirmationModal {...contentProps as ConfirmationModalProps} />,
        },
        [GeneralModalKey.usageDataModal]: {
            content: <UsageDataModal {...contentProps as UsageDataModalProps} />,
            modalProps: {
                title: 'Get usage data',
                paddingX: 0,
            },
        },
        [GeneralModalKey.settingsModal]: {
            content: <SettingsModal {...contentProps as SettingsModalProps} />,
            modalProps: {
                title: 'Settings',
            },
        },
        [GeneralModalKey.QRCodeModal]: {
            content: <QRCodeModal {...contentProps as QRCodeModalProps} />,
            modalProps: {
                title: 'I can’t scan the QR code',
            },
        },
        [GeneralModalKey.paymentDetailsModal]: {
            content: <PaymentDetailsModal {...contentProps as PaymentDetailsModalProps} />,
            modalProps: {
                title: 'Payment details',
                paddingX: 0,
            },
        },
        [GeneralModalKey.addManager]: {
            content: <AddManagerModal {...contentProps as AddManagerModalProps} />,
            modalProps: {
                title: 'Add manager',
                paddingX: 0,
                paddingBottom: 0,
                contentPaddingTop: 0,
            },
        },
        [GeneralModalKey.changeOwner]: {
            content: <ChangeOwnerModal {...contentProps as ChangeOwnerModalProps} />,
            modalProps: {
                title: 'Change owner',
                paddingX: 0,
                paddingBottom: 0,
            },
        },
        [GeneralModalKey.upcomingRenewal]: {
            content: <UpcomingRenewalModal {...contentProps as UpcomingRenewalModalProps} />,
            modalProps: {
                title: <UpcomingRenewalModalTitle />,
            },
        },
        [GeneralModalKey.additionalEmailModal]: {
            content: <AdditionalEmailModal {...contentProps as AdditionalEmailModalProps} />,
            modalProps: {
                title: 'Additional emails',
            },
        },
        [GeneralModalKey.generateLink]: {
            content: <GenerateLinkModal {...contentProps as GenerateLinkModalProps} />,
            modalProps: {
                title: `Generate a ${GenerateLinkTitleList[
                    contentProps.generateLinkType as keyof typeof ManageUserActions
                ]} link`,
                paddingX: 0,
                paddingBottom: 0,
            },
        },
        [GeneralModalKey.updateGracePeriod]: {
            content: <UpdateGracePeriodModal {...contentProps as UpgradeGracePeriodModalProps} />,
            modalProps: {
                title: 'Update grace period',
                paddingX: 0,
            },
        },
        [GeneralModalKey.editJobInformation]: {
            content: <EditJobInformationModal {...contentProps as JobInformationModalProps} />,
            modalProps: {
                title: 'Edit job information',
                paddingX: 0,
            },
        },
        [GeneralModalKey.editSocialMedia]: {
            content: <EditSocialMediaModal {...contentProps as EditSocialMediaModalProps} />,
            modalProps: {
                title: 'Edit social media',
            },
        },
        [GeneralModalKey.editContactInformation]: {
            content: <EditContactInformationModal {...contentProps as EditContactInformationModalProps} />,
            modalProps: {
                title: 'Edit contact information',
            },
        },
        [GeneralModalKey.editPaymentRenewalNotification]: {
            content: <EditPaymentRenewalNotification {...contentProps as EditPaymentRenewalNotificationProps} />,
        },
        [GeneralModalKey.editUserName]: {
            content: <EditUserNameModal {...contentProps as EditUserNameModalProps} />,
            modalProps: {
                title: 'Edit user’s name',
            },
        },
        [GeneralModalKey.editNewsletterDelivery]: {
            content: <EditNewsLetterDeliveryModal {...contentProps as EditNewsLetterDeliveryModalProps} />,
            modalProps: {
                paddingX: 0,
                paddingBottom: 0,
                contentPaddingTop: 0,
            },
        },
        [GeneralModalKey.addDomain]: {
            content: <AddDomainModal {...contentProps as AddDomainModalProps} />,
            modalProps: {
                title: 'Add domains',
                paddingX: 0,
            },
        },
        [GeneralModalKey.subscriptionActionModal]: {
            content: <SubscriptionActionModal {...contentProps as SubscriptionActionModalProps} />,
        },
        [GeneralModalKey.contractDetailsModal]: {
            content: <ContractDetailsModal {...contentProps as ContractDetailsModalProps} />,
            modalProps: {
                title:
                !(contentProps as ContractDetailsModalProps).payment?.contractLink &&
                !(contentProps as ContractDetailsModalProps).payment?.contractSigned
                    ? 'Add contract details'
                    : 'Update contract details',
            },
        },
        // [GeneralModalKey.example]: { content: <ExampleComponent {...contentProps as ExampleComponentProps} /> },
    };

    return map[key] || {};
};
