import styled from 'styled-components';
import { Box } from '@mui/material';

export const StyledMessage = styled(Box)`
  && {
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.lightBlack};
  }
`;
