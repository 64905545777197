import React, { FC, ReactNode, useEffect, useState } from 'react';
import { updateEmailDelivery } from 'src/services/sso-api';
import { SnackbarMessageVariants, UDBNewsletterChannel } from 'src/constants';
import { ThanksForSubscribingInsiderFlowContent } from 'src/components/ThanksforSubscribingInsiderFlowContent';
import { SignUpNewslettersFlow } from 'src/components/SignUpNewslettersFlow';
import { SingleModalLayout } from 'src/layouts';
import { useSnackbarMessage } from 'src/hooks';

enum ScreenId {
    SUCCESS_MESSAGE = 'success-message',
    NEWSLETTERS = 'newsletters',
    THANK_YOU_MESSAGE = 'thank-you-message',
}

type UserCreatedFlowProps = {
    createdAccountUUID?: string;
    children: (handleShowNewsletters: () => void) => ReactNode;
};

const newslettersColumnParams = {
    newsletters: {
        md: 2.25,
        display_title: 'block',
    },
    date: {
        md: 1.15,
    },
};

const NonBasicUserCreatedFlow: FC<UserCreatedFlowProps> = ({ createdAccountUUID, children }) => {
    const [activeScreen, setActiveScreen] = useState<string>(ScreenId.SUCCESS_MESSAGE);
    const [deliveryNewslettersLength, setDeliveryNewslettersLength] = useState<number>();

    const { addMessage } = useSnackbarMessage();
    const activeNewsletterIndex = Number(activeScreen.split('_')[1]);

    useEffect(() => {
        setActiveScreen(ScreenId.SUCCESS_MESSAGE);
    }, []);

    const handleShowNewsletters = () => {
        setActiveScreen(deliveryNewslettersLength ? `${ScreenId.NEWSLETTERS}_0` : ScreenId.THANK_YOU_MESSAGE);
    };

    const goBackActionHandler = () => {
        if (activeNewsletterIndex === 0) {
            setActiveScreen(ScreenId.SUCCESS_MESSAGE);
        } else {
            setActiveScreen(`${ScreenId.NEWSLETTERS}_${activeNewsletterIndex - 1}`);
        }
    };

    const handleNewslettersSubmit = (selectedNewsletter: Record<string, boolean>) => {
        if (!createdAccountUUID) {
            addMessage('Some error is occurred', SnackbarMessageVariants.ERROR);
            return;
        }

        setActiveScreen(ScreenId.THANK_YOU_MESSAGE);

        updateEmailDelivery(createdAccountUUID, {
            newsletters: Object.keys(selectedNewsletter)
                .filter((key) => key.split('_')[1] === UDBNewsletterChannel.EMAIL)
                .map((id) => ({
                    id: Number(id.split('_')[0]),
                    isSelected: selectedNewsletter[id],
                }))
                .filter(({ isSelected, id }) => isSelected && !Number.isNaN(id)),
        }).catch(() => {
            addMessage('Technical error is occurred', SnackbarMessageVariants.ERROR);
        });
    };

    return (
        <SingleModalLayout
            goBackAction={activeScreen.includes(ScreenId.NEWSLETTERS) ? goBackActionHandler : undefined}
            variant={activeScreen.includes(ScreenId.NEWSLETTERS) ? 'newsLettersForm' : 'form'}
            testId="user-created-flow"
        >
            <>
                {activeScreen === ScreenId.SUCCESS_MESSAGE && (
                    children(handleShowNewsletters)
                )}
                <SignUpNewslettersFlow
                    pageColumnParams={newslettersColumnParams}
                    setDeliveryNewslettersLength={setDeliveryNewslettersLength}
                    onCompleted={handleNewslettersSubmit}
                    currentScreenId={ScreenId.NEWSLETTERS}
                    setActiveScreen={setActiveScreen}
                    activeScreen={activeScreen}
                    submitButton="Subscribe to selected newsletters"
                />
                {activeScreen === ScreenId.THANK_YOU_MESSAGE && (
                    <ThanksForSubscribingInsiderFlowContent />
                )}
            </>
        </SingleModalLayout>
    );
};

export default NonBasicUserCreatedFlow;
