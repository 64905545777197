import styled, { css } from 'styled-components';
import { Box, Button, TextField } from '@mui/material';

type StyledPositionErrorMessageProps = {
    'position'?: 'relative' | 'absolute';
};

export const inputStyles = css`
    padding: 0;

    fieldset {
        border: solid 1px ${({ theme }) => theme.palette.dirtyWhite};
    }

    input::placeholder {
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.lightGrey};
        opacity: 1;
    }

    input {
        padding: 0 16px;
        height: ${({ theme }) => theme.sizes.inputHeight.default};
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type=number] {
            -moz-appearance: textfield;
        }

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            height: ${({ theme }) => theme.sizes.inputHeight.xs};
        }
    }

    &:hover {
        background-color: ${({ theme }) => theme.palette.hoveredInputBackground};

        input {
            border-radius: 4px;
        }

        fieldset {
            border: solid 1px ${({ theme }) => theme.palette.dirtyWhite};
        }
    }

    &.Mui-disabled {
        background-color: ${({ theme }) => theme.palette.disabledInput};

        fieldset {
            border: solid 1px ${({ theme }) => theme.palette.dirtyWhite};
        }

        input {
            color: ${({ theme }) => theme.palette.grey};
        }
    }

    &.Mui-focused {
        fieldset {
            border: solid 1px ${({ theme }) => theme.palette.blue};
        }

        .MuiInputAdornment-root {
            .show-password {
                svg {
                    color: ${({ theme }) => theme.palette.blue};
                }
            }
        }
    }
}

&.invalid {
    .MuiInputBase-root {
        fieldset {
            border: solid 1px ${({ theme }) => theme.palette.red};
        }

        &:hover {
            fieldset {
                border: solid 1px ${({ theme }) => theme.palette.red};
            }
        }

        &.Mui-focused {
            border: none;
        }
    }
`;

export const StyledTextField = styled(TextField)`
    width: 100%;

    .MuiInputBase-root {
        ${inputStyles}
    }
`;

export const StyledFieldContainer = styled(Box)`
    position: relative;

    .form-input {
        margin: 0;
        background-color: ${({ theme }) => theme.palette.white};
        width: 100%;
    }

    .iti {
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};
        width: 100%;

        .MuiInputBase-input {
            width: calc(100% - 130px);
            padding-left: 130px !important;

            @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
                padding-left: 100px !important;
            }
        }

        .iti__selected-flag {
            background: none;
            padding: 0px 16px;
            border-right: 1px solid ${({ theme }) => theme.palette.dirtyWhite};

            .iti__arrow {
                margin: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }

            .iti__selected-dial-code {
                margin: 0px 10px;
                width: 37px;
                text-align: center;

                @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
                    margin-left: 0px;
                }
            }
        }

        .iti__flag {
            border-radius: 2px;

            @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
                display: none;
            }
        }
    }
`;

export const StyledErrorMessage = styled.p<StyledPositionErrorMessageProps>`
    position: ${({ position }) => (position === 'relative' ? 'relative' : 'absolute')};
    margin: 4px 0 0;
    color: ${({ theme }) => theme.palette.red};
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 12px;

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        position: relative;
    }
`;

export const StyledFormFieldSubtext = styled.p`
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.lightGrey};
    font-size: 12px;
    margin: 5px 0 0;
    position: absolute;
`;

export const StyledFormFieldSubtextButton = styled(Button)`
    && {
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.blue};
        text-transform: none;
        font-size: 12px;
        padding: 0;
        justify-content: flex-start;
        min-width: 0;

        &:hover {
            filter: saturate(85%);
            background: none;
        }

        &:active {
            filter: saturate(70%);
        }

        &:disabled {
            font-family: ${({ theme }) => theme.fonts.medium};
            color: ${({ theme }) => theme.palette.blue};
        }
    }
`;
