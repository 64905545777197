import React, { Dispatch, FC, ForwardedRef, forwardRef, SetStateAction, useEffect, useState } from 'react';
import { DataGridProps as MuiDataGridProps } from '@mui/x-data-grid/models/props/DataGridProps';
import { GridColumnMenuProps, GridColumnVisibilityModel, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid';
import { CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { GridRowIdGetter } from '@mui/x-data-grid/models/gridRows';
import type { GridPrivateApiCommunity } from '@mui/x-data-grid/models/api/gridApiCommunity';
import {
    ROWS_HEIGHT,
    StyledDataGrid,
    StyledGridColumnMenu,
    StyledGridColumnsPanel,
    StyledNoRowsOverlay,
} from './DataGrid.styles';
import { CopyFooter } from './components/CopyFooter';
import { useStickyColumn } from './hooks/useStickyColumn';
import { copyDataWithColumnNames } from './services/copy-service';
import type { GridColDef } from 'src/@types/data-grid';
import { Pagination } from 'src/components/Pagination';
import StyledTooltip from 'src/components/Tooltip';
import { Checkbox } from 'src/components/Checkbox';
import { ReactComponent as SettingsIcon } from 'src/assets/icons/setting-icon.svg';
import { ReactComponent as HideIcons } from 'src/assets/icons/hide-icon.svg';
import { ReactComponent as ColumnMenuIcon } from 'src/assets/icons/column-menu-icon.svg';
import { GRID_DEFAULT_PAGE_SIZE_OPTIONS } from 'src/constants';
import { ColumnsPanel } from 'src/components/DataGrid/components/ColumnsPanel';
import { getColumnsVisibilityModel } from 'src/services/data-grid-helper';

type DataGridProps = Omit<MuiDataGridProps, 'columns'> & {
    noRowsOverlayMessage: string;
    smallHeight?: boolean;
    minDataRows?: number;
    pageSize?: number;
    setPageSizeState?: Dispatch<SetStateAction<number>>;
    pageState?: number;
    setPageState?: Dispatch<SetStateAction<number>>;
    getRowId?: GridRowIdGetter;
    columns: GridColDef[];
};

const DataGrid: FC<DataGridProps> = ({
    noRowsOverlayMessage,
    columns: columnsConfig,
    smallHeight = false,
    minDataRows = 5,
    pageSize,
    pageState,
    setPageState,
    setPageSizeState,
    getRowId,
    ...props
}) => {
    const apiRef = useGridApiRef<GridPrivateApiCommunity>();
    const columns = useStickyColumn<GridColDef>(apiRef, columnsConfig);

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>(
        getColumnsVisibilityModel(columns),
    );

    useEffect(() => {
        try {
            apiRef?.current?.scrollToIndexes({ rowIndex: 0, colIndex: 0 });
        } catch (e) {
            // Ignore errors; Ignore tests virtualScroller issue
        }
    }, [getRowId && getRowId(props.rows[0] || {})]);

    useEffect(() => {
        setVisibilityModel(getColumnsVisibilityModel(columns));
    }, [columns]);

    const footer = () => {
        if (!pageSize || !pageState || !setPageSizeState || !setPageState) {
            return null;
        }

        return (
            <Pagination
                pageSize={pageSize}
                totalCount={props.rowCount}
                pageSizeOptions={GRID_DEFAULT_PAGE_SIZE_OPTIONS}
                setPageSizeState={setPageSizeState}
                pageState={pageState}
                setPageState={setPageState}
                variant="dataGrid"
            />
        );
    };

    const columnMenu = (columnMenuProps: GridColumnMenuProps) => (
        <StyledGridColumnMenu
            {...columnMenuProps}
            data-testid="column-menu"
            slots={{
                columnMenuFilterItem: () => null,
            }}
        />
    );

    const columnsPanel = () => (
        <ColumnsPanel
            visibilityModel={visibilityModel}
            setVisibilityModel={setVisibilityModel}
            columns={columns}
        />
    );

    const baseCheckbox = forwardRef(
        function baseCheckbox(checkboxProps: MuiCheckboxProps, ref: ForwardedRef<HTMLInputElement>) {
            return (
                <Checkbox {...checkboxProps} ref={ref} />
            );
        },
    );

    const noRowsOverlay = () => (
        <StyledNoRowsOverlay>{noRowsOverlayMessage}</StyledNoRowsOverlay>
    );

    return (
        <>
            <StyledDataGrid
                paginationMode="server"
                rowHeight={ROWS_HEIGHT}
                disableRowSelectionOnClick
                onClipboardCopy={copyDataWithColumnNames(columns)}
                onRowSelectionModelChange={setRowSelectionModel}
                columnVisibilityModel={visibilityModel}
                onColumnVisibilityModelChange={setVisibilityModel}
                getRowId={getRowId}
                apiRef={apiRef}
                slotProps={{
                    baseTooltip: {
                        arrow: true,
                        disableInteractive: true,
                    },
                }}
                slots={{
                    columnMenuIcon: ColumnMenuIcon,
                    columnMenuHideIcon: HideIcons,
                    columnMenuManageColumnsIcon: SettingsIcon,
                    preferencesPanel: StyledGridColumnsPanel,
                    baseTooltip: StyledTooltip,
                    noRowsOverlay,
                    columnsPanel,
                    baseCheckbox,
                    columnMenu,
                    footer,
                }}
                columnBuffer={columns.length}
                minDataRows={minDataRows}
                columns={columns}
                smallHeight={smallHeight}
                {...props}
            />
            {!!rowSelectionModel.length && (
                <CopyFooter
                    checkboxSelection={props.checkboxSelection}
                    rowSelectionModel={rowSelectionModel}
                    getRowId={getRowId}
                    columns={columns}
                    rows={props.rows}
                />
            )}
        </>
    );
};

export default DataGrid;
