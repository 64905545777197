import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledInfo = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.palette.black};
    display: flex;
    align-items: center;  
    height: 40px;  
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-weight: 450;  
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: 14px;
      height: 24px;
    }
  }
`;
