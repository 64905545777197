import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledActiveSessionTitle = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.black};
        font-size: 14px;
        line-height: normal;
        text-transform: capitalize;

        @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
            font-size: 16px;
        }
    }
`;

export const StyledActiveSessionIpAddress = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.lightGrey};
        font-size: 14px;
        line-height: normal;
        text-transform: capitalize;

        @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
            font-size: 16px;
        }
    }
`;

export const StyledActiveSessionLocation = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.demiBold};
        color: ${({ theme }) => theme.palette.black};
        font-size: 14px;
        line-height: normal;
        text-transform: uppercase;

        @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
            font-size: 16px;
        }
    }
`;

export const CurrentSessionFlag = styled(Typography)`
    && {
        height: 8px;
        width: 55px;
        font-size: 11px;
        border-radius: 20px;
        line-height: 1;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.blue};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        background-color: #F3F8FF;
    }
`;
