import { UAParser } from 'ua-parser-js';
import type { ActiveSessionType } from 'src/@types/active-session';
import type { IpGeolocationData } from 'src/@types/ip-geolocation';
import type { TokenCollection } from 'src/@types/sso-api';
import { REFRESH_TOKEN_KEY } from 'src/constants';
import { ssoApiClient } from 'src/services/sso-api';

export const isCurrentSsoSession = (token: string = ''): boolean => {
    const currentToken = ssoApiClient.tokenProvider.getToken(REFRESH_TOKEN_KEY);
    const tokenSignature = currentToken?.split('.')[2];

    return tokenSignature === token;
};

export const getLocationText = ({ country, city }: Partial<IpGeolocationData> = {}): string => (
    [country, city].filter(Boolean).join(', ') || '-'
);

export const getActiveSessionsList = (
    tokenCollection: TokenCollection,
): Promise<ActiveSessionType[]> => {
    return Promise.all(
        tokenCollection
            .sort((a, b) => b.lastActivityDateMS - a.lastActivityDateMS)
            .sort((a, b) => Number(isCurrentSsoSession(b.token)) - Number(isCurrentSsoSession(a.token)))
            .map(async ({ token, ip, userAgent, lastActivityDateMS }): Promise<ActiveSessionType> => {
                const { browser, os } = new UAParser(userAgent).getResult();

                return {
                    lastActivity: lastActivityDateMS,
                    browser: browser.name,
                    os: os.name,
                    ip: ip,
                    token,
                    isCurrent: isCurrentSsoSession(token),
                };
            }),
    );
};
