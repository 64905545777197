import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { Box } from '@mui/material';
import { GRID_DEFAULT_TOOLTIP_PROPS } from 'src/constants';
import StyledTooltip from 'src/components/Tooltip';

const DefaultCell: FC<{ value: string }> = ({ value }) => {
    return (
        <StyledTooltip
            title={value}
            {...GRID_DEFAULT_TOOLTIP_PROPS}
        >
            <Box data-testid="default-render" className="MuiDataGrid-cellContent">
                {value}
            </Box>
        </StyledTooltip>
    );
};

export const defaultCellRenderer = (props: GridRenderCellParams) => {
    return <DefaultCell value={props.formattedValue || props.value} />;
};
