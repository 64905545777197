import React, { FC } from 'react';
import { GridRowId } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { StyledBox } from './UserLinkedCell.styles';
import { CopyIcon } from 'src/components/CopyIcon';
import { LinkedCell } from 'src/components/DataGrid/components/LinkedCell';
import { ReactComponent as UserIcon } from 'src/assets/icons/user-icon.svg';

type NameLinkProps = {
    name: string;
    id: GridRowId;
};

export const UserLinkedCell: FC<NameLinkProps> = ({ name, id }) => {
    return (
        <StyledBox>
            <LinkedCell
                href={`/udb/users/${id}`}
                icon={<UserIcon />}
                text={name}
                tooltipText="Open user page"
                isIconFirst
            />
            <Box paddingRight={2}>
                <CopyIcon hiddenIcon content={name} />
            </Box>
        </StyledBox>
    );
};
