import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { RedirectionCaption } from './UserCreatedContent.styles';
import PageTitle from 'src/components/PageTitle';
import { Button } from 'src/components/Buttons';
import { SingleModalLayout } from 'src/layouts';

type UserCreatedContentProps = {
    onProceed: () => void;
};

export const secondsToRedirect = 5;

const BasicUserCreatedContent: FC<UserCreatedContentProps> = ({ onProceed }) => {
    const [count, setCount] = useState<number>(secondsToRedirect);

    useEffect(() => {
        if (!count) {
            onProceed();
            return;
        }

        const timer = setTimeout(() => {
            setCount(count - 1);
        }, 1000);

        return () => clearTimeout(timer);
    }, [count]);

    return (
        <SingleModalLayout
            testId="basic-user-created-content"
        >
            <Stack spacing={{ xs: 2.5, sm: 3 }}>
                <PageTitle
                    variant="form"
                    textAlign="center"
                    title="We created your account"
                    subtitle="We've sent you an email with a link to set up your password. Please note, the link is valid for 24 hours. Now, you will be asked to enter your payment details."
                />
                <RedirectionCaption>
                    {count
                        ? `You will be redirected to checkout in ${count} seconds`
                        : 'You\'re being redirected'
                    }
                </RedirectionCaption>
                <Button onClick={onProceed} fullWidth>
                    Continue to checkout
                </Button>
            </Stack>
        </SingleModalLayout>
    );
};

export default BasicUserCreatedContent;
