import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

export const StyledPageSection = styled(Box)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const StyledPageSectionHeader = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 16px;
    line-height: 23px;
      
    @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
      font-size: 18px;
    }
  }
`;

export const SectionTitle = styled(Typography)`
    &&&&.MuiTypography-root {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 14px;
        font-style: normal;
        line-height: 18px;

        @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 16px;
        }
    }
`;
