import React, { FC, useEffect, useState } from 'react';
import { BoxProps } from '@mui/material';
import { LabeledSelect } from 'src/components/Select/LabeledSelect';
import { OptionsType } from 'src/components/Select/Select';
import { useSnackbarMessage } from 'src/hooks';
import { getJobFunctionsList } from 'src/services/unified-db-api';
import { SnackbarMessageVariants } from 'src/constants';

type JobFunctionIdSelectProps = {
    onChangeHandler?: (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => void;
    onRevert?: () => void;
    hasRevertButton?: boolean;
    isRevertBtnVisible?: boolean;
} & BoxProps;

export const JobFunctionSelect: FC<JobFunctionIdSelectProps> = ({
    onChangeHandler,
    hasRevertButton,
    onRevert,
    isRevertBtnVisible,
    ...boxProps
}) => {
    const [jobFunctionsList, setJobFunctionsList] = useState<OptionsType[]>([]);

    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getJobFunctionsList()
            .then((data) => (
                data
                    .map(({ id, name }) => ({ value: `${id}`, label: name }))
                    .sort((a, b) => a.label.localeCompare(b.label))
            ))
            .catch(() => {
                addMessage('Failed to fetch job functions list', SnackbarMessageVariants.ERROR);

                return [];
            })
            .then((data) => setJobFunctionsList([{ value: '', label: 'None' }, ...data]));
    }, []);

    return (
        <LabeledSelect
            testId="job-function"
            name="jobFunctionId"
            label="Job function"
            placeholder="Select job function"
            onFormChange={onChangeHandler}
            options={jobFunctionsList}
            hasRevertButton={hasRevertButton}
            onRevert={onRevert}
            isRevertBtnVisible={isRevertBtnVisible}
            {...boxProps}
        />
    );
};
