import styled from 'styled-components';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
    StyledHeaderMenuItem,
} from 'src/layouts/BasicLayout/components/Header/components/HeaderMenuItem/HeaderMenuItem.styles';
import { NavButton } from 'src/components/NavButton';

export const StyledMenuIcon = styled(MenuIcon)`
    &.MuiSvgIcon-root {
        fill: ${({ theme }) => theme.palette.lightBlack};
    }
`;

export const ModalWrapper = styled(Box)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 278px;
    outline: none;
    background-color: ${({ theme }) => theme.palette.white};
`;

export const ModalHeader = styled(Box)`
    border-bottom: 1px solid ${({ theme }) => theme.palette.borderGrey};
`;

export const StyledCloseIcon = styled(CloseIcon)`
    &.MuiSvgIcon-root {
        fill: ${({ theme }) => theme.palette.lightGrey};
    }
`;

export const ModalContentWrapper = styled(Box)`
    height: calc(100% - 175px);
    overflow-y: auto;

    & > *:last-child {
        margin-bottom: 40px;
    }
    
    ${NavButton},
    ${StyledHeaderMenuItem} {
        position: relative;
        width: 100%;
        padding: 20px 24px;
        line-height: normal;
        font-family: ${({ theme }) => theme.fonts.medium};
        box-sizing: border-box;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            width: calc(100% - 48px);
            height: 1px;
            background-color: ${({ theme }) => theme.palette.borderGrey};
            transform: translateX(-50%);
        }
    }
    
    ${StyledHeaderMenuItem}:after {
        width: 100%;
    }
`;

export const ModalFooter = styled(Box)`
    border-top: 1px solid ${({ theme }) => theme.palette.borderGrey};
    box-sizing: border-box;
    padding: 20px 8px;

    ${NavButton} {
        font-family: ${({ theme }) => theme.fonts.medium};
    }
`;
