import React, { FC, ReactNode } from 'react';
import { Grid, GridProps, Stack, StackProps, TypographyProps } from '@mui/material';
import { StyledHeaderName } from './HeaderSection.styles';
import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';

export type HeaderSectionColumnParam = GridProps & {
    column_title?: string;
    display_title?: TypographyProps['display'];
};

type HeaderSectionProps = {
    gridColumnParams: Record<string, HeaderSectionColumnParam>;
    paddingBottom?: StackProps['paddingBottom'];
    paddingTop?: StackProps['paddingTop'];
    columns?: GridProps['columns'];
    justifyContent?: GridProps['justifyContent'];
    additionalTitleNode?: ReactNode;
};

const HeaderSection: FC<HeaderSectionProps> = ({
    gridColumnParams,
    paddingBottom,
    paddingTop,
    columns = TABLE_SECTION_DEFAULT_COLUMNS,
    justifyContent,
    additionalTitleNode,
}) => {
    return (
        <Stack
            direction="row"
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            alignItems="center"
            justifyContent="center"
            position="relative"
        >
            <Grid container columns={columns} justifyContent={justifyContent}>
                {Object.keys(gridColumnParams).map((key) => (
                    <Grid item key={key} {...gridColumnParams[key]}>
                        <StyledHeaderName display={gridColumnParams[key].display_title || { xs: 'none', md: 'block' }}>
                            {gridColumnParams[key].column_title}
                        </StyledHeaderName>
                    </Grid>
                ))}
                {additionalTitleNode}
            </Grid>
        </Stack>
    );
};

export default HeaderSection;
