import React from 'react';
import { Grid, GridProps } from '@mui/material';
import { StyledRequestError } from './RequestError.styles';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning-icon.svg';

type RequestErrorProps = {
    testId?: string;
} & GridProps;

const RequestError = ({ children, testId = 'request-error-block', ...gridProps }: RequestErrorProps) => (
    <StyledRequestError container data-testid={testId} paddingX={1} {...gridProps}>
        <Grid display="flex" alignItems="center" justifyContent="center" item xs={1}>
            <WarningIcon />
        </Grid>
        <Grid item xs={11} paddingX={1}>
            <p>{children}</p>
        </Grid>
    </StyledRequestError>
);

export default RequestError;
