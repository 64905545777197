import React, { FC, Fragment } from 'react';
import { Box, Stack } from '@mui/material';
import { getPaymentDetailsData, getRenewalData } from './services/getters';
import { StyledSectionName } from './PaymentDetails.styles';
import { DEFAULT_MODAL_PADDING_X } from 'src/components/Modals/BaseModal';
import { PriceHistoryModelWithRenewal } from 'src/@types/subscription-service-api';
import { useGeneralModal } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import StyledTitle, { StyledTitleWithIcon } from 'src/components/Title';
import Line from 'src/components/Line';
import StyledTooltip, { StyledMultilineTooltipBody } from 'src/components/Tooltip';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

export type PaymentDetailsModalProps = {
    payment: PriceHistoryModelWithRenewal;
    paymentType?: string;
    variant: 'profile' | 'udb';
};

const PaymentDetailsModal: FC<PaymentDetailsModalProps> = ({ payment, paymentType, variant }) => {
    const { closeModal } = useGeneralModal();
    const isUdb = variant === 'udb';
    const renewalRows = getRenewalData(payment);
    const rows = getPaymentDetailsData(payment, variant, paymentType);

    return (
        <>
            <Stack position="relative" data-testid="payment-modal-detail" gap={{ xs: 1.5, sm: 2 }} paddingX={DEFAULT_MODAL_PADDING_X}>
                {rows.map((row, index) => (
                    <Fragment key={row.title}>
                        {index !== 0 && <Line />}
                        <Stack flexDirection="row" justifyContent="space-between" data-testid={`payment-detail-${index}`}>
                            <StyledTitle whiteSpace="nowrap" data-font="medium">{row.title}</StyledTitle>
                            {row.tooltip ? (
                                <StyledTitleWithIcon variant="caption" data-font="medium" data-testid={`payment-detail-value-${index}-with-tooltip`}>
                                    {row.value}
                                    <StyledTooltip
                                        arrow
                                        title={
                                            <StyledMultilineTooltipBody>
                                                {row.tooltip}
                                            </StyledMultilineTooltipBody>
                                        }
                                    >
                                        <InfoIcon data-testid="info-icon" />
                                    </StyledTooltip>
                                </StyledTitleWithIcon>

                            ) : (
                                <StyledTitle variant="caption" data-font="medium" data-testid={`payment-detail-value-${index}`}>
                                    {row.value}
                                </StyledTitle>
                            )}
                        </Stack>
                    </Fragment>
                ))}
            </Stack>
            {isUdb && renewalRows && (
                <>
                    <Box paddingTop={{ sm: 3, xs: 2.5 }}>
                        <Line />
                    </Box>
                    <Stack gap={{ xs: 1.5, sm: 2 }} paddingX={DEFAULT_MODAL_PADDING_X}>
                        <StyledSectionName
                            data-font="medium"
                            paddingTop={{ xs: 2.5, sm: 3 }}
                            paddingBottom={ 0.5 }
                        >
                            Renewal data
                        </StyledSectionName>
                        {renewalRows.map((row, index) => (
                            <Fragment key={row.title}>
                                {index !== 0 && <Line />}
                                <Stack flexDirection="row" justifyContent="space-between">
                                    <StyledTitle whiteSpace="nowrap" data-font="medium">{row.title}</StyledTitle>
                                    <StyledTitle variant="caption" display="flex" gap={0.5} data-font="medium">{row.value}</StyledTitle>
                                </Stack>
                            </Fragment>
                        ))}
                    </Stack>
                </>
            )}
            <Stack paddingTop={{ xs: 3 }} display={{ sx: 'flex', sm: 'none' }} paddingX={DEFAULT_MODAL_PADDING_X}>
                <Button onClick={() => closeModal()} variant="secondary" fullWidth data-testid="cancel-button">Close</Button>
            </Stack>
        </>
    );
};

export default PaymentDetailsModal;
