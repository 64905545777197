import { Grid } from '@mui/material';
import React, { FC } from 'react';
import type {
    DataGridProps,
    GridRowSelectionModel,
    GridValueFormatterParams,
    GridValueGetterParams,
} from '@mui/x-data-grid';
import { GridRowIdGetter } from '@mui/x-data-grid/models/gridRows';
import { FooterContainer, IconContainer, StyledFooterTitle } from './CopyFooter.styles';
import { copyDataWithColumnNames } from 'src/components/DataGrid/services/copy-service';
import { Button } from 'src/components/Buttons';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { ReactComponent as CopyIcon } from 'src/assets/icons/copy-icon.svg';

type CopyFooterProps = {
    columns: DataGridProps['columns'];
    rowSelectionModel: GridRowSelectionModel;
    getRowId?: GridRowIdGetter;
    rows: DataGridProps['rows'];
    checkboxSelection?: boolean;
};

const CopyFooter: FC<CopyFooterProps> = ({
    columns,
    rowSelectionModel,
    getRowId,
    rows,
    checkboxSelection,
}) => {
    const handleCopyButtonClick = () => {
        if (typeof getRowId !== 'function' || !checkboxSelection) {
            return;
        }

        const copiedData = rowSelectionModel.map((selectedRowId) => {
            const rowData = rows.find((row) => getRowId(row) === selectedRowId);
            const rowValues = columns.map(({ valueGetter, valueFormatter, field }) => {
                const value = valueGetter?.({
                    row: rowData,
                    value: rowData[field],
                } as GridValueGetterParams) ?? rowData[field];

                const formattedValue = valueFormatter?.({ value } as GridValueFormatterParams) ?? value;

                return formattedValue === '\t' ? '' : formattedValue;
            });

            return rowValues.join('\t');
        }).join('\n');

        return copyDataWithColumnNames(columns)(copiedData);
    };

    return (
        <FooterContainer
            data-testid="copy-footer"
            container
            paddingX={{ sm: 3.5, xs: 2 }}
            paddingY={2}
        >
            <Grid
                item
                lg={9.75}
                md={9}
                sm={8.25}
                display={{ xs: 'none', sm: 'flex' }}
                alignItems="center"
            >
                <StyledFooterTitle>
                    <InfoIcon />
                    Copy the information from the selected rows by using a keyboard shortcut or the Copy button
                </StyledFooterTitle>
            </Grid>
            <Grid item lg={2.25} md={3} sm={3.75} xs={12}>
                <Button fullWidth variant="secondary" onClick={handleCopyButtonClick}>
                    <IconContainer>
                        <CopyIcon />
                    </IconContainer>
                    Copy selected rows
                </Button>
            </Grid>
        </FooterContainer>
    );
};

export default CopyFooter;
