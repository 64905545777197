import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { GridColumnProps } from 'src/components/TableSection/TableSection';

export const gridColumnParams: GridColumnProps = {
    plan: {
        md: 1,
        display: 'flex',
        alignItems: { xs: 'flex-start', lg: 'center' },
        justifyContent: { xs: 'center', lg: 'flex-start' },
        paddingRight: 1,
        column_title: 'Plan',
        gap: { xs: 0.5, md: 0, lg: 1 },
        flexDirection: { xs: 'column', lg: 'row' },
    },
    ActivatedOn: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Activated on',
        order: { xs: 1, md: 0 },
    },
    ExpiresOn: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Expires on',
        order: { xs: 2, md: 0 },
    },
    GracePeriod: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Grace period',
        order: { xs: 3, md: 0 },
    },
    status: {
        md: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        column_title: 'Status',
        gap: { xs: 1, md: 0.5 },
        display: { xs: 'none', md: 'flex' },
    },
};
