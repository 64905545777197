import { ssoApiRequest } from './sso-api';
import { PaginationParams } from 'src/@types/pagination';
import {
    AccessibleSubscription,
    CalculateDatesParams,
    CalculateDatesResponse,
    CalculatedRenewalItem,
    CalculatePriceRequest,
    CalculatePriceResponse,
    DomainInfoModel,
    DomainResponse,
    DomainsRequest,
    GetDomainInfoParams,
    GetManagersResponse,
    GetSubscriptionUsersResponse,
    ManagersRequest,
    PriceHistoryModel,
    PriceHistoryModelWithRenewal,
    PriceHistoryRequest,
    PriceHistoryWithRenewalResponse,
    RenewalItem,
    SetOwnerRequest,
    SubscriberTypeByVertical,
    SubscriptionModel,
    SubscriptionRequest,
    SubscriptionSearchExpand,
    SubscriptionSearchParams,
    SubscriptionSearchProps,
    SubscriptionSearchResult,
    UpdateRenewalData,
    UsersTypeByVerticalModel,
    UsersTypeByVerticalParams,
} from 'src/@types/subscription-service-api';
import type { APIClientRequestConfig } from 'src/@types/api-client';

export const ssApiRequest = async <
    RequestDataType extends object | void,
    ResponseType extends object | string | void,
>(
    url: string,
    config?: APIClientRequestConfig<RequestDataType>,
): Promise<ResponseType> => {
    return ssoApiRequest<RequestDataType, ResponseType>(`/ss/${url.replace(/^\//, '')}`, config);
};

export const addManager = async (subscriptionUUID: string, data: ManagersRequest): Promise<void> => {
    return ssApiRequest<ManagersRequest, void>(`/subscriptions/${subscriptionUUID}/managers`, {
        method: 'POST',
        data,
    });
};

export const getManagers = async (
    subscriptionUUID: string,
    params?: PaginationParams,
): Promise<GetManagersResponse> => {
    return ssApiRequest<PaginationParams, GetManagersResponse>(`/subscriptions/${subscriptionUUID}/managers`, {
        method: 'GET',
        params: {
            pageSize: params?.pageSize ?? 15,
            page: params?.page ?? 1,
        },
    });
};

export const removeManagers = async (subscriptionUUID: string, data: ManagersRequest): Promise<void> => {
    return ssApiRequest<ManagersRequest, void>(`/subscriptions/${subscriptionUUID}/managers`, {
        method: 'DELETE',
        data,
    });
};

export const setOwner = async (subscriptionUUID: string, data: SetOwnerRequest): Promise<void> => {
    return ssApiRequest<SetOwnerRequest, void>(`/subscriptions/${subscriptionUUID}/managers/set-owner`, {
        method: 'POST',
        data,
    });
};

export const calculateRenewalIndex = async (uuid: string) => {
    return ssApiRequest<void, CalculatedRenewalItem>(`/subscriptions/${uuid}/renewal-index/calculate`);
};

export const createUpcomingPriceHistory = (uuid: string) => {
    return ssApiRequest<void, Required<PriceHistoryModelWithRenewal>>(`/subscriptions/${uuid}/price-history/upcoming`, { method: 'POST' });
};

export const updatePriceHistory = async (uuid: string, priceHistoryId: number, data: Partial<PriceHistoryModel>) => {
    return ssApiRequest<Partial<PriceHistoryModel>, PriceHistoryModel>(`/subscriptions/${uuid}/price-history/${priceHistoryId}`, {
        method: 'PATCH',
        data,
    });
};

export const updateRenewal = async (
    { uuid, priceHistoryId, renewalId }: { uuid: string, priceHistoryId: number, renewalId: number },
    data: Partial<UpdateRenewalData>,
) => {
    return ssApiRequest<Partial<UpdateRenewalData>, RenewalItem>(`/subscriptions/${uuid}/price-history/${priceHistoryId}/renewal/${renewalId}`, {
        method: 'PATCH',
        data,
    });
};

export const getPriceHistory = async (uuid: string, params?: PaginationParams) => {
    return ssApiRequest<PriceHistoryRequest, PriceHistoryWithRenewalResponse>(`/subscriptions/${uuid}/price-history?expand[]=renewal`, {
        params: {
            pageSize: params?.pageSize,
            page: params?.page,
        },
    });
};

export const updateSubscription = async (
    uuid: string,
    data: Partial<SubscriptionModel>,
) => {
    return ssApiRequest<Partial<SubscriptionModel>, SubscriptionModel>(`/subscriptions/${uuid}`, {
        method: 'PATCH',
        data,
    });
};

export const subscriptionsSearch = async (data: SubscriptionSearchParams, expand: SubscriptionSearchExpand[] = []) => {
    return ssApiRequest<SubscriptionSearchProps, SubscriptionSearchResult>('/subscriptions/search', {
        method: 'POST',
        data: {
            ...data,
            expand,
        },
    });
};

export const getSubscription = async (uuid: string) => {
    return ssApiRequest<SubscriptionRequest, SubscriptionModel>(`/subscriptions/${uuid}`, {
        method: 'GET',
        params: { expand: ['customAttributes'] },
    });
};

export const getAccessibleSubscriptions = async (
    userUUID: string,
    includeInactive = true,
): Promise<AccessibleSubscription[]> => {
    return ssApiRequest<void, AccessibleSubscription[]>(`/users/${userUUID}/accessible-subscriptions`, {
        params: {
            includeInactive: includeInactive,
        },
    });
};

export const getUserTypeByVertical = async (
    userUUID: string,
) => {
    return ssApiRequest<void, SubscriberTypeByVertical[]>(`/users/${userUUID}/type-by-vertical`);
};

export const getUsersTypeByVertical = async (
    userUUIDs: string[],
) => {
    return ssApiRequest<UsersTypeByVerticalParams, UsersTypeByVerticalModel[]>('/users/type-by-vertical', {
        method: 'POST',
        data: {
            userUUIDs,
        },
    });
};

export const calculatePrice = async (data: CalculatePriceRequest): Promise<CalculatePriceResponse> => {
    return ssApiRequest<CalculatePriceRequest, CalculatePriceResponse>('/subscriptions/enterprise/calculate-price', {
        method: 'POST',
        data,
    });
};

export const getSubscriptionDomains = async (uuid: string, params?: PaginationParams) => {
    return ssApiRequest<PaginationParams, DomainResponse>(`/subscriptions/${uuid}/domains`, {
        params: {
            pageSize: params?.pageSize,
            page: params?.page,
        },
    });
};

export const getDomainInfo = async (data: GetDomainInfoParams) => {
    return ssApiRequest<GetDomainInfoParams, DomainInfoModel>('/domains/info', {
        method: 'POST',
        data,
    });
};

export const attachDomains = async (uuid: string, data: DomainsRequest): Promise<void> => {
    return ssApiRequest<DomainsRequest, void>(`/subscriptions/${uuid}/domains`, {
        method: 'POST',
        data,
    });

};

export const removeDomains = async (uuid: string, data: DomainsRequest): Promise<void> => {
    return ssApiRequest<DomainsRequest, void>(`/subscriptions/${uuid}/domains`, {
        method: 'DELETE',
        data,
    });
};

export const getSubscriptionUsers = async (uuid: string, params?: PaginationParams) => {
    return ssApiRequest<PaginationParams, GetSubscriptionUsersResponse>(`/subscriptions/${uuid}/users`, {
        params: {
            pageSize: params?.pageSize,
            page: params?.page,
        },
    });
};

export const cancelSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssApiRequest<void, void>(`/subscriptions/${subscriptionUUID}/cancel`, {
        method: 'POST',
    });
};

export const restoreSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssApiRequest<void, void>(`/subscriptions/${subscriptionUUID}/restore`, {
        method: 'POST',
    });
};

export const pauseSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssApiRequest<void, void>(`/subscriptions/${subscriptionUUID}/pause`, {
        method: 'POST',
    });
};

export const resumeSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssApiRequest<void, void>(`/subscriptions/${subscriptionUUID}/resume`, {
        method: 'POST',
    });
};

export const terminateSubscription = async (subscriptionUUID: string): Promise<void> => {
    return ssApiRequest<void, void>(`/subscriptions/${subscriptionUUID}/terminate`, {
        method: 'POST',
    });
};

export const createSubscription = async (): Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, 500);
    });
};

export const calculateDates = async (data: CalculateDatesParams) => {
    return ssApiRequest<CalculateDatesParams, CalculateDatesResponse>('/subscriptions/calculate-dates', {
        method: 'POST',
        data,
    });
};
