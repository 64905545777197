import { SsoConditionValues, Status, ToolBarConditionTypes, ToolBarModelTypes } from 'src/constants';
import { OptionsType } from 'src/components/Select/Select';
import { SchemaType } from 'src/@types/tool-bar';
import {
    dateValidator,
    multiSelectValidator,
    stringValidator,
} from 'src/components/ToolBar/services/tool-bar-validators';

export const CONTAINS_CONDITIONS = [{
    label: 'contains',
    value: SsoConditionValues.CONTAINS,
}];

export const STATUS_CONDITIONS = [{
    label: 'one of',
    value: SsoConditionValues.ONE_OF,
}];

export const TIME_CONDITIONS = [{
    label: 'from',
    value: SsoConditionValues.FROM,
}, {
    label: 'to',
    value: SsoConditionValues.TO,
}];

export const STATUS_OPTIONS: OptionsType[] = [
    { label: 'Active', value: Status.ACTIVE },
    { label: 'Inactive', value: Status.INACTIVE },
];

export const filteringSchema: SchemaType = {
    modelType: ToolBarModelTypes.FILTERING,
    fields: {
        any: {
            order: 1,
            label: 'Any',
            value: 'any',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: CONTAINS_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        id: {
            order: 2,
            label: 'ID',
            value: 'id',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: CONTAINS_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        name: {
            order: 3,
            label: 'Name',
            value: 'name',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: CONTAINS_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        status: {
            order: 4,
            label: 'Status',
            value: 'status',
            conditionType: ToolBarConditionTypes.MULTI_SELECT,
            conditionOptions: STATUS_CONDITIONS,
            valueOptions: STATUS_OPTIONS,
            validateConditionValue: multiSelectValidator(Object.values(Status), false),
        },
        url: {
            order: 5,
            label: 'URL',
            value: 'url',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: CONTAINS_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        proxyUrl: {
            order: 6,
            label: 'Proxy URL',
            value: 'proxyUrl',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: CONTAINS_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        proxyName: {
            order: 7,
            label: 'Proxy name',
            value: 'proxyName',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: CONTAINS_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        createdAt: {
            order: 8,
            label: 'Created at',
            value: 'createdAt',
            conditionType: ToolBarConditionTypes.DATE,
            conditionOptions: TIME_CONDITIONS,
            validateConditionValue: dateValidator,
        },
        updatedAt: {
            order: 9,
            label: 'Updated at',
            value: 'updatedAt',
            conditionType: ToolBarConditionTypes.DATE,
            conditionOptions: TIME_CONDITIONS,
            validateConditionValue: dateValidator,
        },
    },
} ;
