import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { CreateAccountResponse } from 'src/@types/sso-api';
import { createAccount } from 'src/services/sso-api';
import { useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import PageTitle from 'src/components/PageTitle';
import { LabeledInput } from 'src/components/Inputs';
import { SingleModalForm } from 'src/components/Forms/components';
import { Button } from 'src/components/Buttons';
import {
    companyNameValidator,
    emailValidator,
    fullNameValidator,
    jobTitleValidator,
    validateSchemaObject,
} from 'src/services/validators';
import {
    COMPANY_NAME_IS_REQUIRED_MESSAGE,
    EMPTY_EMAIL_VALIDATION_ERROR,
    FULL_NAME_IS_REQUIRED_MESSAGE,
    JOB_TITLE_IS_REQUIRED_MESSAGE,
    SnackbarMessageVariants,
} from 'src/constants';

export type CreateInsiderAccountFormValues = {
    email: string;
    fullName: string;
    company: string;
    jobTitle: string;
};

type CreateInsiderAccountFormProps = {
    initialEmail: string;
    onAccountCreated: (values: CreateInsiderAccountFormValues) => (data: CreateAccountResponse) => void;
};

const CreateInsiderAccountForm: FC<CreateInsiderAccountFormProps> = ({ onAccountCreated, initialEmail }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { addMessage } = useSnackbarMessage();

    const initialValues: CreateInsiderAccountFormValues = {
        email: initialEmail,
        fullName: '',
        company: '',
        jobTitle: '',
    };

    const { isSubmitDisabled, onFormChangeHandler } = useIsSubmitDisabled<CreateInsiderAccountFormValues>(
        initialValues,
        Object.keys(initialValues) as Array<keyof CreateInsiderAccountFormValues>,
    );

    const validationSchema = validateSchemaObject({
        email: emailValidator.required(EMPTY_EMAIL_VALIDATION_ERROR),
        fullName: fullNameValidator.required(FULL_NAME_IS_REQUIRED_MESSAGE),
        company: companyNameValidator.required(COMPANY_NAME_IS_REQUIRED_MESSAGE),
        jobTitle: jobTitleValidator.required(JOB_TITLE_IS_REQUIRED_MESSAGE),
    });

    const handleSubmit = async (values: CreateInsiderAccountFormValues) => {
        setIsSubmitting(true);
        createAccount(values)
            .then(onAccountCreated(values))
            .catch(() => {
                addMessage('Technical error is occurred', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <SingleModalForm
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            onFormChange={onFormChangeHandler}
            validationSchema={validationSchema}
            testId="create-insider-account-form"
        >
            <PageTitle
                variant="form"
                testId="form-title"
                title="Create account"
                subtitle="We need some additional details to create your account."
            />
            <Stack spacing={{ xs: 2.5, sm: 4 }} paddingBottom={{ xs: 0.5, sm: 3 }}>
                <LabeledInput
                    disallowSpace
                    name="email"
                    label="Email address"
                    placeholder="Enter your email address"
                    required
                />
                <LabeledInput
                    name="fullName"
                    label="Full name"
                    placeholder="Enter your full name"
                    required
                />
                <LabeledInput
                    name="company"
                    label="Company"
                    placeholder="Enter your company name"
                    required
                />
                <LabeledInput
                    name="jobTitle"
                    label="Job title"
                    placeholder="Enter your job title"
                    required
                />
            </Stack>
            <Button
                type="submit"
                testId="submit-button"
                disabled={isSubmitDisabled}
            >
                Create account
            </Button>
        </SingleModalForm>
    );
};

export default CreateInsiderAccountForm;
