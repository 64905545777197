import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, type GridProps, Stack } from '@mui/material';
import { StyledHeader, StyledHeaderContainer, StyledLogo } from './Header.styles';
import { HeaderLinks } from './components';
import { PageVariant } from 'src/layouts/BasicLayout/BasicLayout';

type HeaderProps = Pick<GridProps, 'paddingX'> & {
    pageVariant?: PageVariant;
    logoLinkTo?: string;
};

const Header: FC<HeaderProps> = ({ paddingX, pageVariant, logoLinkTo = '/' }) => {
    return (
        <StyledHeaderContainer position="relative" textAlign="center" paddingX={paddingX}>
            <StyledHeader
                display="inline-flex"
                alignItems="center"
                container
                data-page-variant={pageVariant}
            >
                <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={7}
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                >
                    <NavLink to={logoLinkTo} data-testid="main-logo">
                        <StyledLogo />
                    </NavLink>
                </Grid>
                <Grid
                    item
                    lg={9}
                    md={8}
                    sm={6}
                    xs={5}
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                >
                    <Stack direction="row" spacing={1}>
                        <HeaderLinks />
                    </Stack>
                </Grid>
            </StyledHeader>
        </StyledHeaderContainer>
    );
};

export default Header;
