import React, { FC, useState } from 'react';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { CurrencyAdornment } from './styles';
import { formatPrice, preparePriceInCents, stringifyPrice } from 'src/services/formatters';
import { TextInput } from 'src/components/Inputs/TextInput';
import { numberDividerRegExp } from 'src/services/validators';

type PriceInputProps = {
    initialPrice: number | null;
    placeholder?: string;
    currency?: string;
    maxPrice?: number;
    handleChange: (price: number) => void;
    inputProps?: InputBaseComponentProps;
};

const PriceInput: FC<PriceInputProps> = ({
    maxPrice,
    initialPrice,
    placeholder,
    handleChange,
    currency,
    inputProps,
}) => {
    const [price, setPrice] = useState<string>(formatPrice(initialPrice || 0, false));

    const handlePriceInputBlur = () => {
        let newPrice: number = preparePriceInCents(price || '0');

        if (maxPrice && newPrice > maxPrice) {
            newPrice = maxPrice;
        }

        setPrice(formatPrice(newPrice, false));
        handleChange(newPrice);
    };

    const handleFocus = () => {
        setPrice(stringifyPrice(preparePriceInCents(price)));
    };

    const handlePriceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { data: key } = event.nativeEvent as Event & { data: string };

        const hasDot = price.includes('.');
        const hasComma = price.includes(',');
        const hasDotOrComma = hasDot || hasComma;
        const isDotOrComma = ['.', ','].includes(key);
        const isNumber = !Number.isNaN(Number(key));

        if ((hasDotOrComma && isDotOrComma) || (!isNumber && !isDotOrComma) && key) {
            return;
        }

        const newValue = event.target.value.replace(numberDividerRegExp, '');
        setPrice(newValue.replace(',', '.'));
    };

    return (
        <TextInput
            placeholder={placeholder}
            value={price}
            autoSelect
            onChange={handlePriceInputChange}
            onBlur={handlePriceInputBlur}
            onFocus={handleFocus}
            InputProps={{
                startAdornment: currency && <CurrencyAdornment position="start">{currency}</CurrencyAdornment>,
                inputProps: {
                    'data-testid': 'price-input',
                    autoComplete: 'off',
                    ...inputProps,
                },
            }}
        />
    );
};

export default PriceInput;
