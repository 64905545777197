import type { GridColDef } from 'src/@types/data-grid';
import { defaultHeaderRenderer } from 'src/components/DataGrid/components/defaultHeaderRenderer';
import { dateValueFormatter, defaultValueFormatter } from 'src/services/grid-formatters';
import CopyCellRenderer from 'src/components/DataGrid/components/CopyCell/Renderer';
import { defaultCellRenderer } from 'src/components/DataGrid/components/defaultCellRenderer';

const COLUMN_HEADERS = {
    id: 'ID',
    name: 'Name',
    status: 'Status',
    url: 'URL',
    proxyUrl: 'Proxy URL',
    proxyName: 'Proxy name',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
};

export const applicationDataGridColumns: readonly GridColDef[] = [
    {
        field: 'id',
        headerName: COLUMN_HEADERS.id,
        minWidth: 300,
        flex: 2,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.id),
    },
    {
        field: 'name',
        headerName: COLUMN_HEADERS.name,
        minWidth: 200,
        flex: 2,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.name),
        sticky: true,
    },
    {
        field: 'status',
        headerName: COLUMN_HEADERS.status,
        minWidth: 125,
        flex: 1,
        valueFormatter: defaultValueFormatter,
        renderCell: defaultCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.status),
    },
    {
        field: 'url',
        headerName: COLUMN_HEADERS.url,
        minWidth: 250,
        flex: 2,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.url),
    },
    {
        field: 'proxyUrl',
        headerName: COLUMN_HEADERS.proxyUrl,
        minWidth: 250,
        flex: 2,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.proxyUrl),
    },
    {
        field: 'proxyName',
        headerName: COLUMN_HEADERS.proxyName,
        minWidth: 200,
        flex: 2,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.proxyName),
    },
    {
        field: 'createdAt',
        headerName: COLUMN_HEADERS.createdAt,
        minWidth: 250,
        flex: 1,
        valueFormatter: dateValueFormatter,
        renderCell: CopyCellRenderer,
        type: 'date',
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.createdAt),
    },
    {
        field: 'updatedAt',
        headerName: COLUMN_HEADERS.updatedAt,
        minWidth: 250,
        flex: 1,
        valueFormatter: dateValueFormatter,
        renderCell: CopyCellRenderer,
        type: 'date',
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.updatedAt),
    },
];
