import React from 'react';
import { StyledLink } from './SubscriptionTAC.styles';

const emailLink = <StyledLink underline="none" href="mailto:help@endpointsnews.com">help@endpointsnews.com</StyledLink>;

const minWidthMap = {
    level1: { xs: '26px', sm: '32px' },
    level2: { xs: '32px', sm: '36px' },
    level3: { xs: '40px', sm: '48px' },
};

const endpointsTerms = [
    {
        title: 'ABOUT THESE TERMS',
        content: [
            {
                number: '1.1',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                        We are Endpoints News Media Company, LLC whose principal place of business is 2029 Becker Drive,
                        Lawrence, KS 66047, USA. If you need additional information such as tax ID numbers please
                        contact {emailLink}. Except where we state otherwise, you will be contracting
                        with Endpoints News Media Company, LLC and references to “Endpoints” below refer to this
                        entity. References to “you” in these Terms means the person taking out an individual
                        subscription, or the relevant organization (and its authorized representative) in the
                        context of a corporate subscription.
                    </>
                ),
            },
            {
                number: '1.2',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                        These Terms apply to all individual and corporate paid subscriptions for access to Endpts.com
                        or any equivalent online digital apps (together “<b>Endpoints News</b>”). You should read these
                        Terms carefully before using Endpoints News and/or taking out a subscription. By subscribing,
                        you agree to be bound by these Terms (as applicable).
                    </>
                ),
            },
            {
                number: '1.3',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      If you take out an individual subscription for access to Endpoints News in the course of your
                        business or work, then you are also agreeing to these Terms on behalf of that business. If
                        you place a subscription order on behalf of an organization then you are agreeing to these
                        Terms on behalf of that organization and confirm that you are authorized to do so.
                    </>
                ),
            },
            {
                number: '1.4',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      If you have any questions about Endpoints News or if you have any difficulties with access,
                      please contact us at {emailLink}.
                    </>
                ),
            },
        ],
    },
    {
        title: 'YOUR SUBSCRIPTION TO ENDPOINTS NEWS',
        content: [
            {
                number: '2.1',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                    We offer individual subscriptions for a single subscriber to Endpoints News and corporate
                    subscriptions which provide access for all the staff of the subscribing entity
                    (together “<b>Endpoints Subscriptions</b>”).
                    </>
                ),
            },
            {
                number: '2.2',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      Individual subscriptions may be purchased by submitting an online order and providing
                      the sign-up details through Endpoints News. We will only accept an order when we have
                      successfully verified the email address given and processed the payment using the details
                      provided. For corporate subscriptions please see the contact details provided on
                      Endpoints News.
                    </>
                ),
            },
            {
                number: '2.3',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      We will try to process orders promptly but cannot guarantee activation by any specified
                      time. We may reject any order at our discretion. You confirm that the sign-up and payment
                      details provided in the order are complete and accurate, and that you are entitled to
                      purchase a subscription using those sign-up and payment details. It is your responsibility
                      to update and maintain changes to the sign-up details provided.
                    </>
                ),
            },
        ],
    },
    {
        title: 'FEES AND PAYMENT ',
        content: [
            {
                number: '3.1',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      Subscription fees must be paid at the rates and in the currency displayed or referenced
                      during the order process. Prices may vary from time to time and by country. Endpoints
                      Subscriptions are for an annual period. Any discount offers are as stated at the time
                      of order and cannot be changed during the term of the subscription. Unless otherwise
                      indicated, prices stated are exclusive of any applicable value added tax (VAT) or other
                      sales taxes. The breakdown of any VAT or sales tax will be indicated on the
                      relevant invoice.
                    </>
                ),
            },
            {
                number: '3.2',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      If we do not receive payment authorization or any authorization is subsequently cancelled,
                      we may immediately terminate or suspend the subscription order.
                    </>
                ),
            },
            {
                number: '3.3',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      We will give you at least 14 days’ notice of any increase in the price of your
                      subscription, which will take effect at your next renewal date.
                    </>
                ),
            },
            {
                number: '3.4',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      If we incorrectly state a price online or otherwise, we are not obliged to provide a
                      subscription at that price, even if we have mistakenly accepted your offer to buy a
                      subscription. If we notify you of a pricing error, you may cancel the subscription and
                      we will refund you any money paid, or you may pay the correct price. If you do neither,
                      we may cancel the subscription and refund any money paid. We will always act in good
                      faith in determining whether a genuine pricing error has occurred.
                    </>
                ),
            },
            {
                number: '3.5',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      If you are entitled to a refund we will credit that refund to the card or other payment
                      method you used to submit payment unless it has expired in which case we will contact
                      you to arrange.
                    </>
                ),
            },
            {
                number: '3.6',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      In addition to the subscription fees, you are responsible for any: (a) broadband,
                      mobile, data, messaging or other internet connection and telecommunications charges
                      that you may incur by accessing Endpoints News; (b) charges that may be levied by
                      your bank or credit card issuer (for example foreign transaction fees on transactions
                      which take place abroad or in a foreign currency).
                    </>
                ),
            },
        ],
    },
    {
        title: 'YOUR CANCELLATION RIGHTS',
        content: [
            {
                number: '4.1',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      You may terminate your Endpoints Subscription immediately by notice to Endpoints,
                      if Endpoints commits any material breach of these Terms and, where the breach is
                      capable of remedy, fails to remedy such breach within 14 days of being notified of it.
                    </>
                ),
            },
            {
                number: '4.2',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                        <b>Individual Subscriptions</b> - By placing the order for a subscription subject to these
                      Terms, you agree that we may start the subscription immediately upon our acceptance
                      of the order and that you waive any statutory consumer right to cancel the subscription
                      within 14 days of either: your order confirmation, or the point at which we provide you
                      with access to your subscription, whichever happens first. This means that you lose any
                      statutory consumer right to cancel this contract once we have successfully verified your
                      email address and processed the payment details of your subscription (see section 2.2
                      above)
                      and that any notice of cancellation that you provide (whether before or after the 14-day
                      period) will only take effect at the end of your current subscription period. You will
                      not
                      be entitled to a refund except in the event that there is a fault in our provision of
                      Endpoints News. To inform us of your wish to cancel, you must inform us of your decision
                      to cancel the subscription by contacting us at {emailLink}. Please provide:
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                      the individual subscriber’s full name;
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                      the account email address or account number; and
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                      contact telephone number.
                    </>
                ),
            },
            {
                number: '4.3',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                        <b>Corporate Subscription</b> - the subscription will commence on the agreed start date and,
                      subject to section above, there will be no right to cancel the subscription prior to
                      its expiration date.
                    </>
                ),
            },
        ],
    },
    {
        title: 'OUR CANCELLATION RIGHTS',
        content: [
            {
                number: '5.1',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      We may suspend or terminate your subscription if we are prevented from providing services
                      to you, or accepting payment from you, by applicable law or circumstances beyond our
                      control, or if we cease publishing or providing Endpoints News. If we terminate for any
                      of these reasons, we will provide you with a pro rata refund to the extent permitted by
                      applicable law.
                    </>
                ),
            },
            {
                number: '5.2',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      Endpoints may suspend or terminate your Endpoints Subscription immediately by notice to
                      you, if you commit any material breach of these Terms and, where the breach is capable of
                      remedy, fails to remedy the breach within 14 days after being notified of it.
                    </>
                ),
            },
            {
                number: '5.3',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      Endpoints may also suspend or terminate a subscription, with or without notice and without
                      refund or further obligation in the event of any verbal or written abuse of any kind
                      (including abusive, offensive or aggressive language) directed towards any Endpoints
                      personnel, or any member of the public.
                    </>
                ),
            },
        ],
    },
    {
        title: 'EFFECTS OF CANCELLATION',
        content: [
            {
                number: '6.1',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      If you are entitled to a refund, we will make the reimbursement without undue delay, and
                      not later than 14 days after the day on which we are informed about your decision to
                      cancel your subscription.
                    </>
                ),
            },
            {
                number: '6.2',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      We will make the reimbursement using your registered payment details. Additional
                      charges may apply if we are required to reimburse to any other credit card or bank.
                    </>
                ),
            },
            {
                number: '6.3',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      If you have been paying for your subscription by direct debit, we recommend you
                      also cancel your direct debit with your bank.
                    </>
                ),
            },
        ],
    },
    {
        title: 'RENEWALS',
        content: [
            {
                number: '7.1',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                        <b>Individual Subscription</b> - We will send you a reminder notice at least 14 days
                      before the renewal date stating the rate that will apply for the next renewal year.
                      Unless you notify us at least 7 days before the end of your current subscription period
                      that you wish to cancel, your subscription will continue to renew for further periods
                      of one year and will be subject to these Terms. We will charge the relevant subscription
                      using the payment method we hold on record at the renewal date.
                    </>
                ),
            },
            {
                number: '7.2',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                        <b>Corporate Subscription</b> - There is no automatic renewal of corporate subscriptions which
                      automatically end on the final day of the subscription period. We will usually send you
                      a proposal for a corporate subscription renewal well in advance of the expiration of
                      your existing subscription.
                    </>
                ),
            },
        ],
    },
    {
        title: 'OWNERSHIP OF ENDPOINTS NEWS AND ITS CONTENT',
        content: [
            {
                number: '8.1',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      Endpoints News, all content and all intellectual property rights in each are owned by us,
                      our licensors or both (as applicable). Intellectual property rights mean rights such as:
                      copyright, trade marks, domain names, design rights, database rights, patents and all
                      other intellectual property rights of any kind whether or not they are registered or
                      unregistered (anywhere in the world). We reserve all of our rights in any intellectual
                      property in connection with these Terms. This means, for example, that we remain owners
                      of them and free to use them as we see fit.
                    </>
                ),
            },
            {
                number: '8.2',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      Nothing in these Terms grants you any legal rights in Endpoints News or its content,
                      other than as necessary to enable you to use it. You agree not to adjust, to try to
                      circumvent or delete any notices contained on Endpoints News (including any intellectual
                      property notices) and in particular in any digital rights or other security technology
                      embedded or contained within it.
                    </>
                ),
            },
            {
                number: '8.3',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      You may not use any Endpoints logos or any of its trademarks, trade names or other
                      branding (whether registered or not) without written permission from Endpoints.
                    </>
                ),
            },
        ],
    },
    {
        title: 'ACCESS AND USE OF ENDPOINTS NEWS',
        content: [
            {
                number: '9.1',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      On registration, you (or your corporate users) will choose a username and password
                      (“ID”). It is your responsibility to keep your ID confidential. Sharing of passwords
                      is not permitted either simultaneously or on a rotational basis. We may cancel or
                      suspend your Endpoints Subscription if you share your access rights with any third
                      parties or attempt to allow third parties to avoid our control of access to Endpoints
                      News. The email address used for registration must not correspond to a generic or shared
                      email account, and you must be entitled to use that email address. Users accessing under
                      a corporate subscription must register using an email domain belonging to the organization
                      who holds the corporate subscription. All information received by us from your use of
                      Endpoints News will be used by Endpoints in accordance with our Privacy Policy.
                    </>
                ),
            },
            {
                number: '9.2',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      You are responsible for your (or your corporate users’) use of our content.
                      Users are permitted to do the following:
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                      View our content for personal use on any device that is compatible with Endpoints
                      News (this might be a PC, laptop, smartphone, tablet or other mobile device)
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                      Download a pdf of any article to store on your device to view offline for personal
                      use, provided that this is on an ad hoc basis and not part of any systematic copying
                      to create any archive of our content in whole or in part. The creation of any database
                      in electronic or structured manual form by systematically copying and/or downloading
                      is prohibited
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                      Print single copies of articles on paper for personal use
                    </>
                ),
            },
            {
                number: '9.3',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                        <b>Corporate subscribers</b> only may download one pdf per year of the text of an article of
                      editorial content to host on its intranet accessible to its staff only, plus one pdf per
                      year of the text of an article of editorial content for external marketing purposes,
                      provided:
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                      Only the full text of articles is displayed and not any photographs, diagrams,
                      graphs or other illustrations or graphics which may have originally appeared
                      alongside those articles;
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                      The articles are reproduced and displayed verbatim as they appear on Endpoints News;
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                      You must not in any way imply that Endpoints is endorsing you or your products or services;
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                        The following attribution wording is used immediately below all such displays of
                        any articles of Content: “
                        <i>
                            The content is used under license from the Endpoints News.
                            ©Endpoints News [Year]. The content may not be copied, reproduced or redistributed
                            without permission from Endpoints News.
                        </i>
                        ”; and
                    </>
                ),
            },
            {
                icon: true,
                text: (
                    <>
                      Endpoints reserves the right to request the immediate removal or correction of
                      any article so displayed
                    </>
                ),
            },
            {
                number: '9.4',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      Except as set out above, you may not copy content from Endpoints News or any third party
                      source of Endpoints News content such as news aggregators, and you may not republish or
                      redistribute full text articles, for example by pasting them into emails or republishing
                      them in any media, including websites, newsletters or intranets. Our content should not
                      be translated unless you have an appropriate license with us authorizing this.
                    </>
                ),
            },
            {
                number: '9.5',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      To the fullest extent permitted by law, we expressly prohibit any use of our content or
                      data (including any associated metadata) in any manner for any machine learning and/or
                      artificial intelligence purposes, including without limitation for the purposes of
                      training or development of artificial intelligence technologies or tools or machine
                      learning language models, or otherwise for the purposes of using or in connection with
                      the use of such technologies, tools or models to generate any data or content and/or to
                      synthesize or combine with any other data or content. We reserve all rights to license
                      any use of our content and data for any such purposes.
                    </>
                ),
            },
            {
                number: '9.6',
                minWidth: minWidthMap.level1,
                text: (
                    <>
                      If you believe there has been any breach of security such as the disclosure, theft
                      or unauthorized use of your ID or any payment information, you must notify Endpoints
                      immediately by emailing us at {emailLink}. We recommend that you do not use any
                      “Remember me” (or equivalent) login feature on any device that may be used by anyone other
                      than you in order to prevent unauthorized access to Endpoints News and your subscription
                      details.
                    </>
                ),
            },
        ],
    },
    {
        title: 'ACCURACY OF INFORMATION AND AVAILABILITY OF ENDPOINTS NEWS',
        content: [
            {
                number: '10.1',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      While we try to make sure that Endpoints News and all its content is accurate,
                      up-to-date and free from bugs, we cannot promise that it will be. Furthermore,
                      we cannot promise that it will be fit or suitable for any purpose. Any reliance
                      that you may place on the information on Endpoints News is at your own risk.
                    </>
                ),
            },
            {
                number: '10.2',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      Content is provided for your general information purposes only and to inform you
                      about us and our products and news, features, services and other websites that may
                      be of interest. It does not constitute technical, financial or legal advice or any
                      other type of advice and should not be relied on for any purposes. It is not intended
                      to be and should not be relied upon by users in making (or refraining from making)
                      any specific investment, purchase, sale or other decisions. Appropriate independent
                      advice should be obtained before making any such decisions. Any agreements,
                      transactions or other arrangements made between you and any third party named
                      on (or linked to from) Endpoints News are at your own responsibility and entered
                      into at your own risk. Any information that you receive via Endpoints News, whether
                      or not it is classified as “real time”, may have stopped being current by the time
                      it reaches you. Endpoints reserves complete editorial freedom in the form and content
                      of Endpoints News and may add to, remove or edit content or features at any time on a
                      permanent or temporary basis and with or without notice.
                    </>
                ),
            },
            {
                number: '10.3',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      While we try to make sure that Endpoints News is available for your use, we do not
                      promise that it will be available at all times nor do we promise the uninterrupted
                      use by you of Endpoints News.
                    </>
                ),
            },
        ],
    },
    {
        title: 'THIRD PARTY SITES AND PRODUCTS',
        content: [
            {
                number: '11.1',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      Endpoints News may contain hyperlinks or references to third party websites and
                      third party products. Any such hyperlinks or references are provided for your
                      convenience only. We have no control over third party websites and accept no legal
                      responsibility for any products, content, material or information contained in them.
                      The display of any hyperlink and reference to any third party website or third party
                      product (including any advertiser or sponsor) does not mean that we endorse that third
                      party’s website, products or services. Your use of a third party site may be governed
                      by the terms of that third party site.
                    </>
                ),
            },
            {
                number: '11.2',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      Any agreements, transactions or other arrangements made between you and any third
                      party named on (or linked to/from) Endpoints News are at your own responsibility and
                      entered into at your own risk.
                    </>
                ),
            },
        ],
    },
    {
        title: 'PRIVACY AND PERSONAL INFORMATION',
        content: [
            {
                number: '12.1',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      Respecting the privacy and security of personal information are important to us.
                      Any personal information provided will be dealt with in line with our Privacy Policy,
                      which explains what personal information we collect, how and why we collect, store, use
                      and share such information, individual rights in relation to personal information and
                      how to contact us and supervisory authorities in the event you have a query or complaint
                      about the use of your personal information.
                    </>
                ),
            },
        ],
    },
    {
        title: 'LIMITATION ON OUR LIABILITY',
        content: [
            {
                number: '13.1',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      Endpoints makes no warranty, express or implied, regarding your Endpoints Subscription,
                      Endpoints News or its content, which are provided ‘as is’. We expressly disclaim all
                      warranties to the extent permitted by law, including but not limited to warranties of
                      fitness for a particular purpose.
                    </>
                ),
            },
            {
                number: '13.2',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      Except for any legal responsibility that we cannot limit or exclude in law
                      (such as for death or personal injury), in the event that you suffer any losses
                      arising in connection with an Endpoints Subscription, or your use of Endpoints
                      News or its content:
                    </>
                ),
                subContent: [
                    {
                        number: '13.2.1',
                        minWidth: minWidthMap.level3,
                        text: (
                            <>
                              our total liability to you shall not exceed the total sums paid by you for your
                              current Endpoints Subscription; and
                            </>
                        ),
                    },
                    {
                        number: '13.2.2',
                        minWidth: minWidthMap.level3,
                        text: (
                            <>
                              we shall not be liable or legally responsible in any way (whether in contract,
                              tort (including negligence) or otherwise) for:
                            </>
                        ),
                        subContent: [
                            {
                                text: <>the individual subscriber’s full name;</>,
                                icon: true,
                            },
                            {
                                text: <>the account email address or account number; and</>,
                                icon: true,
                            },
                            {
                                text: <>contact telephone number.</>,
                                icon: true,
                            },
                        ],
                    },
                ],
            },
            {
                number: '13.3',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      The limitations of liability in this section 6 apply for the benefit of Endpoints,
                      its affiliates and all of respective officers, directors, employees, agents or any
                      company who we transfer our rights and obligations to in accordance with these Terms.
                    </>
                ),
            },
        ],
    },
    {
        title: 'LAW AND JURISDICTION',
        content: [
            {
                number: '14.1',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      These Terms will be governed by and interpreted in accordance with the laws of the
                      state of New York (without regard to its conflict of laws provisions) and the parties
                      submit to the exclusive jurisdiction of the federal and state courts located in New York
                      City. Each party irrevocably and unconditionally waives, to the fullest extent permitted
                      by applicable law, any right it may have to a trial by jury in any legal action, proceeding,
                      cause of action or counterclaim arising out of or relating to these Terms.
                    </>
                ),
            },
        ],
    },
    {
        title: 'GENERAL',
        content: [
            {
                number: '15.1',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      We shall have no liability to you for any breach of these Terms caused by any event or
                      circumstance beyond our reasonable control including, but not limited to, strikes,
                      lock-outs or other industrial disputes; epidemic or pandemic; breakdown of systems or
                      network access; or flood, fire, explosion or accident.
                    </>
                ),
            },
            {
                number: '15.2',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      You may not license or transfer any of your rights under these Terms. We may transfer
                      any of our rights or obligations under these Terms to any company within Endpoints group
                      of companies, but if we do so we will ensure that any company to whom we transfer our rights
                      or obligations will continue to honor your rights under them.
                    </>
                ),
            },
            {
                number: '15.3',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      If any provision of these Terms is found to be invalid by any court having competent
                      jurisdiction, the invalidity of that provision will not affect the validity of the
                      remaining provisions of these Terms, which will remain in full force and effect.
                    </>
                ),
            },
            {
                number: '15.4',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      Failure by either party to exercise any right or remedy under these Terms does not
                      constitute a waiver of that right or remedy. Headings in these Terms are for convenience
                      only and will have no legal meaning or effect.
                    </>
                ),
            },
            {
                number: '15.5',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      These Terms constitute the entire agreement between you and Endpoints relating to
                      your Endpoints Subscription. They supersede all previous communications, representations
                      and arrangements, either written or oral.
                    </>
                ),
            },
        ],
    },
    {
        title: 'CHANGES TO THESE TERMS',
        content: [
            {
                number: '16.1',
                minWidth: minWidthMap.level2,
                text: (
                    <>
                      These Terms were published on 24 November 2023. We may at any time and at our
                      sole discretion change, update or amend these Terms by posting a new version of
                      these Terms. However, any changes will not apply to you until the date of your next
                      renewal following the change.
                    </>
                ),
            },
        ],
    },
];

export default endpointsTerms;
