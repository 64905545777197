import React, { ReactNode } from 'react';
import { StyledLabel } from './Label.styles';

export const Label = ({
    isFocused,
    children,
    icon,
    htmlFor,
    required,
    testId = 'label-test-id',
}: {
    children: ReactNode;
    icon?: ReactNode;
    htmlFor: string;
    isFocused?: boolean;
    required?: boolean;
    testId?: string;
}) => {
    return (
        <StyledLabel
            className={isFocused ? 'focused' : undefined}
            htmlFor={htmlFor}
            data-required={required}
            data-testid={testId}
        >
            {!!icon && icon}
            {children}
        </StyledLabel>
    );
};
