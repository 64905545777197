import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Email, JobDescription, StyledStack, Title } from './UserInfoBox.styles';
import { RoundedBadge } from 'src/components/Badge';
import { User } from 'src/@types/unified-db-api';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';

type SearchItemProps = {
    data: User;
    isManager: boolean;
};

const UserInfoBox: FC<SearchItemProps> = ({ data, isManager }) => {
    const jobInfoSeparator = data.jobInfo?.jobTitle && data.jobInfo?.companyName && ' | ';

    return (
        <StyledStack
            data-testid="search-item-wrapper"
            direction={{ xs: isManager ? 'column' : 'row', sm: 'row' }}
            alignItems={{ xs: isManager ? 'flex-start' : 'center', sm: 'center' }}
            justifyContent="space-between"
        >
            <Box>
                <Title marginBottom={0.5}>{data.fullName}</Title>
                <JobDescription marginBottom={0.5}>
                    {data.jobInfo?.jobTitle}{jobInfoSeparator}{data.jobInfo?.companyName}
                </JobDescription>
                <Email marginBottom={{ xs: isManager ? 1 : 0, sx: 0 }}>{data.email.email}</Email>
            </Box>
            <Stack justifyContent={{ xs: isManager ? 'flex-start' : 'center', sm: 'center' }}>
                {isManager && (
                    <RoundedBadge
                        variant={RoundedBadgeVariant.BLUE}
                        label="Already a manager"
                    />
                )}
            </Stack>
        </StyledStack>
    );
};

export default UserInfoBox;
