import { createListenerMiddleware } from '@reduxjs/toolkit';
import { validateAccessCode } from 'src/services/sso-api';
import { setAccessCode, setIsValid } from 'src/redux/slices';

export const accessCodeListener = createListenerMiddleware();
accessCodeListener.startListening({
    actionCreator: setAccessCode,
    effect: async ({ payload: accessCode }, listenerApi) => {
        await validateAccessCode(accessCode)
            .then(({ isValid }) => listenerApi.dispatch(setIsValid(isValid)))
            .catch(() => {
                listenerApi.dispatch(setIsValid(false));
            });
    },
});
