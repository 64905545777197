import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { FormikHelpers } from 'formik';
import { LabeledInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import { ProfileInfo } from 'src/@types/profile-info';
import {
    companyNameValidator,
    countryValidator,
    fullNameValidator,
    industryIdValidator,
    jobTitleValidator,
    validateSchemaObject,
} from 'src/services/validators';
import { FormWrapper } from 'src/components/Forms/components';
import { useIsSubmitDisabled } from 'src/hooks';
import {
    FORM_COLUMN_SPACING,
    FORM_ROW_SPACING,
    FORM_SEPARATOR_MARGIN_TOP,
    FULL_NAME_IS_REQUIRED_MESSAGE,
    MAX_FULL_NAME_LENGTH,
} from 'src/constants';
import { CountrySelect, IndustrySelect, JobFunctionSelect } from 'src/components/Select';

type BasicInformationFormProps = {
    profileInfo: ProfileInfo;
    onSubmit: (data: Partial<ProfileInfo>, form: FormikHelpers<Partial<ProfileInfo>>) => Promise<void>;
};

type BasicInformationFormValues = Partial<ProfileInfo> & {
    industryId: string;
    jobFunctionId: string;
};

export const BasicInformationForm: FC<BasicInformationFormProps> = ({
    profileInfo,
    onSubmit,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { isSubmitDisabled, onFormChangeHandler, setIsSubmitDisabled } =
        useIsSubmitDisabled<ProfileInfo>(profileInfo);

    return (
        <FormWrapper<Partial<ProfileInfo>>
            onFormChange={onFormChangeHandler}
            isSubmitting={isSubmitting}
            validationSchema={validateSchemaObject({
                fullName: fullNameValidator.required(FULL_NAME_IS_REQUIRED_MESSAGE),
                company: companyNameValidator.optional(),
                jobTitle: jobTitleValidator.optional(),
                industryId: industryIdValidator.optional(),
                country: countryValidator.optional(),
            })}
            initialValues={{
                fullName: profileInfo.fullName || '',
                company: profileInfo.company ?? '',
                jobTitle: profileInfo.jobTitle ?? '',
                industryId: profileInfo.industryId?.toString() ?? '',
                jobFunctionId: profileInfo.jobFunctionId?.toString() ?? '',
                country: profileInfo.country ?? '',
            } as BasicInformationFormValues}
            onSubmit={(
                values: Partial<ProfileInfo>,
                formikHelpers: FormikHelpers<Partial<ProfileInfo>>,
            ) => {
                setIsSubmitDisabled(true);
                setIsSubmitting(true);
                onSubmit(values, formikHelpers)
                    .catch(() => setIsSubmitDisabled(false))
                    .finally(() => setIsSubmitting(false));
            }}
        >
            <Grid container columnSpacing={FORM_COLUMN_SPACING} rowSpacing={FORM_ROW_SPACING}>
                <Grid item md={4} sm={6} xs={12}>
                    <LabeledInput
                        testId="full-name-input"
                        name="fullName"
                        label="Full name"
                        placeholder="Enter your full name"
                        inputMaxLength={MAX_FULL_NAME_LENGTH}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <LabeledInput
                        testId="company-input"
                        name="company"
                        label="Company"
                        placeholder="Enter your company name"
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <LabeledInput
                        testId="job-title-input"
                        name="jobTitle"
                        label="Job title"
                        placeholder="Enter your job title"
                    />
                </Grid>
                <Grid item xs={12} marginTop={{ xs: -2.5, sm: -4, md: 0 }} /> {/* separator */}
                <Grid item md={4} sm={6} xs={12}>
                    <IndustrySelect onChangeHandler={onFormChangeHandler} />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <JobFunctionSelect onChangeHandler={onFormChangeHandler} />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <CountrySelect onChangeHandler={onFormChangeHandler} />
                </Grid>
                <Grid item xs={12} marginTop={FORM_SEPARATOR_MARGIN_TOP} /> {/* separator */}
                <Grid item xs={12} sm={1.95}>
                    <Button
                        data-testid="basic-information-form-submit-button"
                        fullWidth
                        disabled={isSubmitDisabled}
                        type="submit"
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </FormWrapper>
    );
};
