import { Tabs } from '@mui/material';
import styled, { css } from 'styled-components';
import { TabBarVariants } from './TabBar';
import { theme as mainTheme } from 'src/theme';

const positionSelector = (props: {
    'data-floating-container'?: boolean;
    theme: typeof mainTheme;
}) => (
    props['data-floating-container'] ? `
        margin-left: -40px;
        @media(max-width: ${props.theme.brakePoints.sm}){
            margin-left: -20px;
        }
    ` : null
);

const switcher = css`
    width: 290px;
    min-height: 40px;
    padding: 5px;
    border-radius: 4px;
    background-color: #F5F7F9;
    box-sizing: border-box;

    @media(max-width: ${({ theme }) => theme.brakePoints.sm}){
        &.MuiTabs-root {
            width: 100%;
            min-height: 42px;
        }
    }

    .MuiTab-root {
        width: 50%;
        min-height: 40px;
        padding: 0;
        font-size: 16px;
    
        &[aria-selected="true"] {
            font-family: ${({ theme }) => theme.fonts.demiBold};
        }
    
        @media(max-width: ${({ theme }) => theme.brakePoints.sm}){
            min-height: 32px;
            font-size: 14px;
        }
    }

    .Mui-selected {
        box-shadow: 0 1px 3px 0 rgba(189, 199, 211, 0.30);
    }`;

const tabs = css`
    .MuiTab-root {
        font-size: 18px;

        &[aria-selected="true"] {
            font-family: ${({ theme }) => theme.fonts.demiBold};
        }

        @media(max-width: ${({ theme }) => theme.brakePoints.sm}){
            padding-left: 20px;
            padding-right: 20px;
            font-size: 16px;
        }
    }
`;

const variantSelector = (props: { 'data-variant': TabBarVariants }) => {
    switch (props['data-variant']) {
        case TabBarVariants.SWITCHER:
            return switcher;
        default:
            return tabs;
    }
};

export const TabDivider = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;

     &:before {
        content: '';
        height: 50%;
        width: 2px;
        background: ${({ theme }) => theme.palette.dirtyWhite};
     }
    
    @media(max-width: ${({ theme }) => theme.brakePoints.sm}){
        &:before {
            width: 1px;
        }
    }
`;

export const StyledTabs = styled(Tabs)<{ 'data-variant': TabBarVariants }>`
    &&& {
        position: relative;
        font-family: ${({ theme }) => theme.fonts.demiBold};
        transition: 0.1s all ease;
        ${positionSelector};
    
        .MuiTabs-indicator {
            height: 100%;
            z-index: 110;
            background-color: ${({ theme }) => theme.palette.white};
            border-radius: 4px;
            color: ${({ theme }) => theme.palette.lightBlack};
        }
        
        .MuiTab-root {
            z-index: 115;
            padding: 0 40px;
            text-transform: none;
            line-height: 22px;
            color: ${({ theme }) => theme.palette.lightBlack};
            font-family: ${({ theme }) => theme.fonts.medium};
            
            @media(max-width: ${({ theme }) => theme.brakePoints.sm}){
                line-height: 18px;
            }
        }
        
        .Mui-selected {
            color: ${({ theme }) => theme.palette.black};
            z-index: 115;
        }
    
        ${variantSelector};
    }
`;
