import React, { FC, useEffect, useState } from 'react';
import type {
    EnterprisePricingTermsData,
} from 'src/pages/udb/CreateSubscription/components/EnterprisePricingTerms/EnterprisePricingTerms';
import { EnterprisePricingTerms } from 'src/pages/udb/CreateSubscription/components/EnterprisePricingTerms';
import type {
    InsiderPricingTermsData,
} from 'src/pages/udb/CreateSubscription/components/InsiderPricingTerms/InsiderPricingTerms';
import { SubscriptionPaymentType, SubscriptionType } from 'src/constants';
import { InsiderPricingTerms } from 'src/pages/udb/CreateSubscription/components/InsiderPricingTerms';
import { BillingCycleLengthInMonth } from 'src/pages/udb/CreateSubscription/services/constants';
import { StepContainer } from 'src/pages/udb/CreateSubscription/components/StepContainer';

export type PricingTermsData = {
    [SubscriptionType.INSIDER]?: InsiderPricingTermsData;
    [SubscriptionType.ENTERPRISE]?: EnterprisePricingTermsData;
};

export type PricingTermsStepProps = {
    handleGoBackClick: (data: PricingTermsData) => void;
    handleNextButtonClick: (data: PricingTermsData) => void;
    billingCycleLength: BillingCycleLengthInMonth;
    initialData: PricingTermsData | undefined;
    numberOfCycles: number;
    selectedSubscriptionType: SubscriptionType;
    selectedPaymentType: SubscriptionPaymentType;
};

const PricingTermsStep: FC<PricingTermsStepProps> = ({
    handleGoBackClick,
    handleNextButtonClick,
    billingCycleLength,
    initialData,
    numberOfCycles,
    selectedSubscriptionType,
    selectedPaymentType,
}) => {
    const [insiderData, setInsiderData] =
        useState<InsiderPricingTermsData | undefined>(initialData?.[SubscriptionType.INSIDER]);
    const [enterpriseData, setEnterpriseData] =
        useState<EnterprisePricingTermsData | undefined>(initialData?.[SubscriptionType.ENTERPRISE]);

    useEffect(() => {
        setInsiderData(initialData?.[SubscriptionType.INSIDER]);
        setEnterpriseData(initialData?.[SubscriptionType.ENTERPRISE]);
    }, [initialData]);

    return (
        <StepContainer
            handleGoBack={() => {
                handleGoBackClick({
                    [SubscriptionType.INSIDER]: insiderData,
                    [SubscriptionType.ENTERPRISE]: enterpriseData,
                });
            }}
            showGoBackButton
            showNextButton={!!insiderData?.selectedPricingModel || !!enterpriseData?.domains.length}
            title="Pricing terms"
            handleNextButtonClick={() => handleNextButtonClick({
                [SubscriptionType.INSIDER]: insiderData,
                [SubscriptionType.ENTERPRISE]: enterpriseData,
            })}
        >
            {selectedSubscriptionType === SubscriptionType.INSIDER ? (
                <InsiderPricingTerms
                    numberOfCycles={numberOfCycles}
                    initialData={insiderData}
                    billingCycleLength={billingCycleLength}
                    setInsiderData={setInsiderData}
                />
            ) : (
                <EnterprisePricingTerms
                    selectedPaymentType={selectedPaymentType}
                    initialData={enterpriseData}
                    setEnterpriseData={setEnterpriseData}
                />
            )}
        </StepContainer>
    );
};

export default PricingTermsStep;
