import styled from 'styled-components';
import { Stack } from '@mui/material';

export const StyledSpinner = styled(Stack)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;

    .MuiCircularProgress-root {
        width: 44px !important;
        height: 44px !important;

        .MuiCircularProgress-circle {
            stroke: url(#linearColors);
        }
    }
`;

export const GradientSvg = styled.svg`
    position: absolute;
    width: 44px;
    height: 44px;
`;
