import styled, { css } from 'styled-components';
import { DataGrid } from 'src/components/DataGrid';

const Table = styled(DataGrid)<{ margin?: string, marginTop?: string }>`
    &&& {
        ${({ margin }) => margin ? css`margin: ${margin};` : ''}
        ${({ marginTop }) => marginTop ? css`margin-top: ${marginTop};` : ''}
        border: 1px solid ${({ theme }) => theme.palette.dirtyWhite};
        background-color: ${({ theme }) => theme.palette.white};

        .MuiDataGrid-virtualScroller {
            min-height: unset;
        }
        
        .MuiDataGrid-columnHeaders {
            background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
            
            .MuiDataGrid-columnHeaderTitleContainer {
                justify-content: start;
            }
            .MuiDataGrid-columnHeader {
                padding: 0 16px;
                cursor: unset;
                
                .MuiDataGrid-columnHeaderTitle{
                    color: ${({ theme }) => theme.palette.lightBlack};
                    font-family: ${({ theme }) => theme.fonts.medium};
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                
                .MuiDataGrid-iconButtonContainer {
                    display: none;
                }
            }
        }

        .MuiDataGrid-cell:focus,
        .MuiDataGrid-cell:focus-within,
        .MuiDataGrid-columnHeader:focus,
        .MuiDataGrid-columnHeader:focus-within {
            outline: none;
        }

        .MuiDataGrid-row.Mui-selected{
            background-color: unset;
        }

        .MuiDataGrid-cell {
            .MuiDataGrid-cellContent {
                color: ${({ theme }) => theme.palette.black};
                font-family: ${({ theme }) => theme.fonts.medium};
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 0 16px;
            }
        }
        
        .MuiDataGrid-columnHeader--sortable {
            cursor: unset;
        }

        .MuiDataGrid-row {
            &:hover {
                background-color: unset;
            }
        }
    }
`;

export default Table;
