import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RequestLogInLinkForm } from 'src/components/Forms';
import { SingleModalLayout } from 'src/layouts';

const RequestLogInLinkPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <SingleModalLayout testId="request-log-in-link-page" goBackAction={() => navigate('/log-in')}>
            <RequestLogInLinkForm />
        </SingleModalLayout>
    );
};

export default RequestLogInLinkPage;
