import React, { FC } from 'react';
import { SubscriptionModel, SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { MenuVariant } from 'src/components/Menu/Menu';
import { Menu } from 'src/components/Menu';
import { useGeneralModal } from 'src/hooks/useGeneralModal';
import { actionModalKeyMap, GeneralModalKey, subscriptionActionMap } from 'src/constants';

export type ManageActionType = {
    label: string;
    disabled?: boolean;
    tooltipTitle?: string;
};

type ManageActionsProps = {
    uuid: string;
    subscription?: SubscriptionModel;
    managers?: SubscriptionUserModel[];
    actionsList: Record<string, ManageActionType>;
    isUdb?: boolean;
};

const ManageActions: FC<ManageActionsProps> = ({
    uuid,
    actionsList,
    subscription,
    managers,
    isUdb = false,
}) => {
    const { openModal } = useGeneralModal();

    const handleSelectChange = (selectedAction: string) => {
        const modalKey = actionModalKeyMap[selectedAction as keyof typeof actionModalKeyMap];
        const isGenerateLinkModal = modalKey === GeneralModalKey.generateLink;

        openModal({
            key: modalKey,
            extraProps: {
                isUdb,
                subscriptionUUID: uuid,
                selectedAction: subscriptionActionMap[selectedAction],
                ...(managers && { subscriptionManagers: managers }),
                ...(subscription && { gracePeriodEndDate: subscription.gracePeriodEndDate }),
                ...(subscription && { expirationDate: subscription.expirationDate }),
                ...(isGenerateLinkModal && {
                    uuid,
                    generateLinkType: selectedAction,
                }),
            },
        });
    };

    return (
        <Menu
            buttonText="Actions"
            buttonVariant={MenuVariant.TEXT}
            menuItems={Object.entries(actionsList)
                .map(([value, { label, disabled, tooltipTitle }]) => (
                    { label, value, disabled, tooltipTitle }
                ))
            }
            onClick={handleSelectChange}
        />
    );
};

export default ManageActions;
