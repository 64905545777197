import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Spinner } from 'src/components/Spinner';
import { Overlay } from 'src/components/Overlay';

type LoaderOverlayProps = {
    fullOverlay?: boolean;
};

export const LoaderOverlay: FC<LoaderOverlayProps> = ({ fullOverlay = false }) => (
    <Box data-testid="loader-overlay-spinner">
        <Overlay fullOverlay={fullOverlay} />
        <Spinner />
    </Box>
);
