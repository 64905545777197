import styled, { css } from 'styled-components';
import { Box, InputAdornment, Paper, Typography } from '@mui/material';

type StyledDatePickerLayoutProps = {
    'data-tab-bar': boolean;
    'position': 'fixed' | 'absolute';
};

const tabBarVariable = (props: StyledDatePickerLayoutProps) => (
    props['data-tab-bar'] ? (
        css`
           margin-top: 28px;
            
          @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
             margin-top: 20px;
          }
        `
    ) : (
        css`
          margin-top: 20px;

          @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
              margin-top: 16px;
          }
        `
    )
);

export const DatePickerWrapper = styled(Box)`
  position: relative;
    
  .MuiPaper-root {
    @media (max-height: ${({ theme }) => theme.brakePoints.md}) {
      top: 50%;
      transform: translate(0%, -50%);
    }
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      position: fixed;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const TabsContainer = styled(Box)`
  &&& {
    margin-top: 20px;
    border-radius: 4px;
    width: 310px;
    padding: 5px;
    background-color: ${({ theme }) => theme.palette.mainBackgroundColor};

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      margin-top: 16px;
      width: unset;
    }
     
    & .MuiTabs-root {
      min-height: 40px;
         
      @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
         min-height: 34px;
      }
         
      & .MuiTabs-scroller {
        & .MuiTabs-flexContainer {
          justify-content: space-between;

          & .MuiTab-root {
            min-height: 40px;
            min-width: 155px;
            font-size: 16px;
            padding-left: 10px;
            padding-right: 10px;

            @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
              min-height: 34px;
              min-width: 134px;
              font-size: 14px;  
           }
         }
       }
     }
   }
  }
`;

export const StyledDatePickerLayout = styled(Paper)<StyledDatePickerLayoutProps>`
  position: ${({ position }) => position};
  right: ${({ position }) => position === 'absolute' ? 0 : 'unset'};
  padding-bottom: 20px;
  width: 360px;
  z-index: 1000;

  @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
    padding-bottom: 16px;
    width: 311px;
  }
    
  &.MuiPaper-root{
      box-shadow: 0 10px 30px 0 rgba(136, 149, 172, 0.10);
  }
    
  .MuiBox-root{
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      padding: 0 16px;
    }
  }  
    
  .MuiDateCalendar-root{
    width: 320px;
    height: unset;
    padding-bottom: 28px;
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      width: 279px;
      padding-bottom: 20px;
    }
      
      .MuiPickersCalendarHeader-root{
        padding-left: 2px;
        padding-right: 0;

        ${tabBarVariable};
      }

      .MuiDayCalendar-header{
          justify-content: space-between;
      }

      .MuiPickersSlideTransition-root{
        min-height: 195px;
          
        .MuiDayCalendar-weekContainer{
          justify-content: space-between;
        }
      }
  }
  
  .MuiYearCalendar-root{
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        width: 279px;
    } 
  }
    
  .MuiTypography-root{
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.lightGrey};
  }
    
  .MuiPickersCalendarHeader-label{
    font-family: ${({ theme }) => theme.fonts.demiBold};
    color: ${({ theme }) => theme.palette.black};
  }
   
  .MuiButtonBase-root{
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.black};
  }
    
  .MuiSvgIcon-root{
    color: ${({ theme }) => theme.palette.lightBlack};
  }
    
  .MuiClock-clock{
    background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
  }
    
  .MuiTimeClock-root{
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      width: 279px;
  }
      
    .MuiClock-root{
      margin: 28px 16px;

      @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        margin: 20px 16px;
        width: 244px;
      }
    }
  }
    
  .MuiPickersArrowSwitcher-root{
    right: 0;
  }
`;

export const ClickAwayListenerWrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const StyledInputAdornment = styled(InputAdornment)`
    &&&& {
        .MuiButtonBase-root {
            margin-right: 8px;

            svg {
                width: 20px;
                height: 20px;
                @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
`;

export const StyledLabel = styled(Typography)`
    &&{
        color: ${({ theme }) => theme.palette.lightBlack};
        text-align: center;
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }
`;

export const SelectedDate = styled.span`
    &&{
        color: inherit;
        font-size: inherit;
        font-style: inherit;
        font-family: inherit;
        line-height: inherit;
        font-weight: 700;
        display: inline;
    }
`;
