import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    StyledUpcomingRenewalModalMainTitle,
    StyledUpcomingRenewalModalSubTitle,
} from './UpcomingRenewalModalTitle.styles';
import type { RootState } from 'src/redux/root-reducer';
import { subscriptionSelector, SubscriptionState } from 'src/redux/slices';
import { formatDate } from 'src/services/formatters';

const UpcomingRenewalModalTitle: FC = () => {
    const {
        upcomingPriceHistory,
    } = useSelector<RootState, SubscriptionState>(subscriptionSelector);

    return (
        <Box>
            <StyledUpcomingRenewalModalMainTitle>
                Upcoming renewal
            </StyledUpcomingRenewalModalMainTitle>
            {upcomingPriceHistory?.renewal && (
                <StyledUpcomingRenewalModalSubTitle>
                    Calculated on {formatDate(upcomingPriceHistory.renewal.calculatedOn)}
                </StyledUpcomingRenewalModalSubTitle>
            )}
        </Box>
    );
};

export default UpcomingRenewalModalTitle;
