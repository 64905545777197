import { formatDate } from 'src/services/formatters';

export const EMPTY_CELL_VALUE = '\t';

export const dateValueFormatter = ({ value }: { value: string }): string => {
    if (!value) {
        return EMPTY_CELL_VALUE;
    }

    return formatDate(value);
};

export const defaultValueFormatter = ({ value }: { value: string }) => {
    if (!value) {
        return EMPTY_CELL_VALUE;
    }

    return value;
};

export const booleanValueFormatter = ({ value }: { value: boolean }) => {
    return value ? 'Yes' : 'No';
};
