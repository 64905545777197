import React, { Dispatch } from 'react';
import { Box, Stack } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import {
    StyledPanelHeader,
    StyledPanelItem,
} from 'src/components/DataGrid/components/ColumnsPanel/ColumnsPanel.styles';
import { Checkbox } from 'src/components/Checkbox';
import { ReactComponent as PanelIcon } from 'src/assets/icons/data-grid-panel-icon.svg';
import Line from 'src/components/Line';

type ColumnsPanelProps = {
    visibilityModel: GridColumnVisibilityModel;
    setVisibilityModel: Dispatch<React.SetStateAction<GridColumnVisibilityModel>>;
    columns: readonly GridColDef[];
};

export const ColumnsPanel = ({ columns, setVisibilityModel, visibilityModel }: ColumnsPanelProps) => {
    const isAllSelected = Object.values(visibilityModel).every((value) => Boolean(value));

    const handleSelect = (field: string) => setVisibilityModel((previousModel) => ({
        ...previousModel,
        [field]: !previousModel[field],
    }));

    const handleSelectAll = () => {
        setVisibilityModel(columns.reduce<GridColumnVisibilityModel>((
            preparedModel,
            { field },
        ) => ({
            ...preparedModel,
            [field]: !isAllSelected,
        }), {}));
    };

    return (
        <Box width="100%" data-testid="column-panel" paddingLeft={3} paddingRight={2.5}>
            <Box paddingTop={2.5} paddingBottom={2.5}>
                <StyledPanelHeader paddingBottom={0.5}>
                    Show Columns
                </StyledPanelHeader>
                {columns.map(({ field, headerName }) => (
                    <Stack direction="row" alignItems="center" justifyContent="space-between" key={field}>
                        <Stack direction="row" alignItems="center" paddingTop={2.5} spacing={1.5}>
                            <Checkbox
                                data-testid={`column-${field}`}
                                checked={visibilityModel?.[field]}
                                onClick={() => handleSelect(field)}
                            />
                            <StyledPanelItem>
                                {headerName}
                            </StyledPanelItem>
                        </Stack>
                        <PanelIcon />
                    </Stack>
                ))}
            </Box>
            <Line />
            <Stack direction="row" alignItems="center" paddingY={2.5} spacing={1.5}>
                <Checkbox
                    data-testid={'all-column'}
                    checked={isAllSelected}
                    onClick={handleSelectAll}
                />
                <StyledPanelItem>
                    Show all columns
                </StyledPanelItem>
            </Stack>
        </Box>
    );
};
