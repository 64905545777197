import React, { FC } from 'react';
import { SingleModalForm } from 'src/components/Forms/components';
import {
    countryValidator,
    industryIdValidator,
    jobFunctionIdValidator,
    socialLinkedInValidator,
    socialXValidator,
    validateSchemaObject,
    websiteValidator,
} from 'src/services/validators';
import PageTitle from 'src/components/PageTitle';
import { LinkedInInput, SocialXInput, WebSiteInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import { CountrySelect, IndustrySelect, JobFunctionSelect } from 'src/components/Select';
import type { OptionalDataFormValuesType } from 'src/@types/create-account';

type SignUpFormProps = {
    onFormChange?: (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => void;
    handleSubmit: (formValues: object) => void;
    isSubmitting: boolean;
    initialValues?: OptionalDataFormValuesType;
};

const SignUpAdditionalDataForm: FC<SignUpFormProps> = ({
    handleSubmit,
    isSubmitting,
    onFormChange,
    initialValues = {},
}) => {
    return (
        <SingleModalForm
            onFormChange={onFormChange}
            isSubmitting={isSubmitting}
            initialValues={{
                industryId: initialValues.industryId ?? '',
                jobFunctionId: initialValues.jobFunctionId ?? '',
                country: initialValues.country ?? '',
                socialX: initialValues.socialX ?? '',
                socialLinkedIn: initialValues.socialLinkedIn ?? '',
                website: initialValues.website ?? '',
            }}
            onSubmit={handleSubmit}
            validationSchema={validateSchemaObject({
                industryId: industryIdValidator.optional(),
                jobFunctionId: jobFunctionIdValidator.optional(),
                country: countryValidator.optional(),
                socialX: socialXValidator.optional(),
                socialLinkedIn: socialLinkedInValidator.optional(),
                website: websiteValidator.optional(),
            })}
            testId="create-account-form-additional-data"
            customTitle={(
                <PageTitle
                    title="Complete your profile"
                    variant="form"
                    testId="complete-profile-title"
                >
                    Help us serve you better
                </PageTitle>
            )}
            fieldsBlock={(
                <>
                    <IndustrySelect onChangeHandler={onFormChange} />
                    <JobFunctionSelect onChangeHandler={onFormChange} />
                    <CountrySelect onChangeHandler={onFormChange} />
                    <WebSiteInput />
                    <LinkedInInput />
                    <SocialXInput />
                </>
            )}
            buttonsBlock={(
                <Button type="submit" testId="submit-button">Create account</Button>
            )}
        />
    );
};

export default SignUpAdditionalDataForm;
