import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RequestResetPasswordForm } from 'src/components/Forms';
import { SingleModalLayout } from 'src/layouts';

const RequestResetPasswordPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <SingleModalLayout testId="request-reset-password-page" goBackAction={() => navigate('/log-in')}>
            <RequestResetPasswordForm />
        </SingleModalLayout>
    );
};

export default RequestResetPasswordPage;
