import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    BasicUserCreatedContent,
    NonBasicUserCreatedContent,
    NonBasicUserCreatedFlow,
} from './components/UserCreatedContent';
import { AlreadyAPaidSubscriber, PaymentSuccessContent } from './components';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { useSnackbarMessage } from 'src/hooks';
import { getSubscriberType } from 'src/services/subscription-service-getters';
import { CheckoutFlow } from 'src/components/CheckoutFlow';
import type {
    AccountToUpgrade,
} from 'src/components/Forms/UpgradeToInsiderForms/UpgradeAccountForm/UpgradeAccountForm';
import type {
    CreateInsiderAccountFormValues,
} from 'src/components/Forms/UpgradeToInsiderForms/CreateInsiderAccountForm/CreateInsiderAccountForm';
import { SnackbarMessageVariants, SubscriberStatus, SubscriberType } from 'src/constants';
import type { CreateAccountResponse, SubscriberSummary } from 'src/@types/sso-api';
import { SingleModalLayout } from 'src/layouts';
import { CheckAccountToUpgradeForm, CreateInsiderAccountForm, UpgradeAccountForm } from 'src/components/Forms';
import type { RootState } from 'src/redux/root-reducer';
import type { CurrentUserState, SubscriberSelectorModel, UserUpgradeState } from 'src/@types/redux';
import { subscriberSelector, userSelector, userUpgradeSelector } from 'src/redux/slices';

let firstSearchedEmail: string;

const UpgradeToInsiderPage: FC = () => {
    const { currentUser, isUserDataLoaded } = useSelector<RootState, CurrentUserState>(userSelector);
    const { type: userSubscriberType } = useSelector<RootState, SubscriberSelectorModel>(subscriberSelector);
    const { isCheckoutSucceeded, expirationDate } = useSelector<RootState, UserUpgradeState>(userUpgradeSelector);

    const [flowHistory, setFlowHistory] = useState<ReactElement[]>([]);
    const [createdAccount, setCreatedAccount] = useState<Required<AccountToUpgrade>>();
    const [checkoutKey, setCheckoutKey] = useState<string>();

    const { addMessage } = useSnackbarMessage();

    const addNextStep = (nextStep: ReactElement) => {
        setFlowHistory((prevHistory) => (
            [
                ...prevHistory,
                nextStep,
            ]
        ));
    };

    const handleProceedToPayment = (accountToUpgrade: AccountToUpgrade) => {
        const createCheckoutKey = crypto.randomUUID();

        addNextStep(
            <CheckoutFlow
                key={createCheckoutKey}
                accountToUpgrade={accountToUpgrade}
                isNewUser={!!createdAccount}
            />,
        );

        setCheckoutKey(createCheckoutKey);
        setCreatedAccount(undefined);
    };

    const handleCreateInsiderAccountFormSubmitted = (
        accountData: CreateInsiderAccountFormValues,
    ) => async (
        responseData: CreateAccountResponse,
    ) => {
        if (!responseData.subscriberTypes) {
            addMessage('Some error occurred after the user had been created', SnackbarMessageVariants.ERROR);
            return;
        }

        const subscriberType = getSubscriberType(responseData.subscriberTypes);

        const newAccount = {
            uuid: responseData.uuid,
            email: accountData.email,
            fullName: accountData.fullName,
            subscriberType,
        };

        setCreatedAccount(newAccount);
        setFlowHistory((prevHistory) => [
            ...prevHistory.slice(0, -1),
            <UpgradeAccountForm
                key={crypto.randomUUID()}
                accountToUpgrade={newAccount}
                handleSubmit={handleProceedToPayment}
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                handleUpgradeAnotherAccount={upgradeAnotherAccount}
            />,
        ]);
    };

    const handleAccountToUpgradeChecked = (email: string, subscriber: SubscriberSummary) => {
        const subscriberType = subscriber.subscriberTypes && getSubscriberType(subscriber.subscriberTypes);
        const errorCase = subscriber.status === SubscriberStatus.INACTIVE;

        if (errorCase) {
            return;
        }

        if (subscriber.status === SubscriberStatus.DOES_NOT_EXIST) {
            addNextStep(
                <CreateInsiderAccountForm
                    key={crypto.randomUUID()}
                    onAccountCreated={handleCreateInsiderAccountFormSubmitted}
                    initialEmail={email}
                />,
            );
            return;
        }

        if (subscriberType && subscriberType !== SubscriberType.basic) {
            addNextStep(
                <AlreadyAPaidSubscriber
                    type={subscriberType}
                    isFirstSearchedEmail={firstSearchedEmail === email}
                    goBackAction={() => setFlowHistory((prevHistory) => prevHistory.slice(0, -1))}
                />,
            );
            return;
        }

        const newAccount = {
            email,
            uuid: subscriber.uuid,
            fullName: subscriber.fullName,
            subscriberType: getSubscriberType(subscriber.subscriberTypes),
        };

        addNextStep(
            <UpgradeAccountForm
                key={crypto.randomUUID()}
                accountToUpgrade={newAccount}
                handleSubmit={handleProceedToPayment}
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                handleUpgradeAnotherAccount={upgradeAnotherAccount}
            />,
        );
    };

    const upgradeAnotherAccount = () => {
        addNextStep(
            <CheckAccountToUpgradeForm
                key={crypto.randomUUID()}
                onSubmitted={handleAccountToUpgradeChecked}
            />,
        );
    };

    const handleUpgradeAccountSubmit = (accountToUpgrade: AccountToUpgrade) => {
        return accountToUpgrade?.subscriberType === SubscriberType.basic
            ? handleProceedToPayment(accountToUpgrade)
            : upgradeAnotherAccount();
    };

    const getGoBackActionHandler = () => {
        if (flowHistory.length <= 1) {
            return;
        }

        return () => setFlowHistory((prevHistory) => prevHistory.slice(0, -1));
    };

    useEffect(() => {
        if (isUserDataLoaded || !currentUser) {
            addNextStep(currentUser ? (
                <UpgradeAccountForm
                    key={crypto.randomUUID()}
                    accountToUpgrade={{
                        ...currentUser,
                        subscriberType: userSubscriberType,
                    }}
                    handleSubmit={handleUpgradeAccountSubmit}
                    handleUpgradeAnotherAccount={upgradeAnotherAccount}
                />
            ) : (
                <CheckAccountToUpgradeForm
                    key={crypto.randomUUID()}
                    onSubmitted={(email: string, subscriber: SubscriberSummary) => {
                        if (!firstSearchedEmail) {
                            firstSearchedEmail = email;
                        }
                        handleAccountToUpgradeChecked(email, subscriber);
                    }}
                />
            ));
        }
    }, [isUserDataLoaded, currentUser]);

    if (!isUserDataLoaded && currentUser) {
        return <LoaderOverlay />;
    }

    if (isCheckoutSucceeded) {
        return <PaymentSuccessContent renewalDate={`${expirationDate}`} />;
    }

    if (createdAccount) {
        return createdAccount.subscriberType === SubscriberType.basic ? (
            <BasicUserCreatedContent onProceed={() => handleProceedToPayment(createdAccount)} />
        ) : (
            <NonBasicUserCreatedFlow createdAccountUUID={createdAccount.uuid}>
                {(handleShowNewsletters) => (
                    <NonBasicUserCreatedContent handleShowNewsletters={handleShowNewsletters} />
                )}
            </NonBasicUserCreatedFlow>
        );
    }

    return (
        <SingleModalLayout
            variant={flowHistory[flowHistory.length - 1]?.key === checkoutKey ? 'checkout' : 'form'}
            goBackAction={getGoBackActionHandler()}
        >
            {flowHistory[flowHistory.length - 1]}
        </SingleModalLayout>
    );
};

export default UpgradeToInsiderPage;
