import dayjs from 'dayjs';
import { convertDateTimeZone } from './date-time-zone-converter';
import { DateFormats } from 'src/constants';

export const formatDate = (date: number | string, dateFormat = DateFormats.FULL): string => {
    if (!date) {
        return '';
    }

    const convertedDate = convertDateTimeZone(date);

    const preparedFormat = dateFormat === DateFormats.SHORT && convertedDate.isSame(dayjs(), 'day')
        ? dateFormat.replace('MMM DD', '[Today]')
        : dateFormat;

    return convertedDate.format(preparedFormat);
};

export const formatPrice = (priceInCent: number | unknown, showCurrency = true): string => {
    if (typeof priceInCent !== 'number' || Number.isNaN(priceInCent)) {
        return 'N/A';
    }

    const options: Intl.NumberFormatOptions = showCurrency
        ? { style: 'currency', currency: 'USD' }
        : { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true };

    return (priceInCent / 100).toLocaleString('en-US', options);
};

export const preparePriceInCents = (price: string): number => {
    return parseInt((parseFloat(price.replaceAll(',', '')) * 100).toFixed());
};

export const stringifyPrice = (priceInCents: number | null): string => (
    priceInCents !== null ? (priceInCents / 100).toFixed(2) : ''
);

export const stringifyNumberWithTwoDecimal = (percentage: number): string => (
    percentage.toFixed(2).replace(/(\.00)|0$/, '')
);
