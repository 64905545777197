import { useDispatch, useSelector } from 'react-redux';
import { incrementPageReloadCount, pageSelector } from 'src/redux/slices';
import type { RootState } from 'src/redux/root-reducer';
import type { PageState } from 'src/@types/redux';

export const useReloadPage = () => {
    const { pageReloadCount } = useSelector<RootState, PageState>(pageSelector);
    const dispatch = useDispatch();

    return {
        pageReloadCount,
        reloadPage: () => {
            dispatch(incrementPageReloadCount());
        },
    };
};
