import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { PriceHistoryModel, PriceHistoryModelWithRenewal } from 'src/@types/subscription-service-api';
import type { RootState } from 'src/redux/root-reducer';

export type SubscriptionState = {
    upcomingPriceHistory?: PriceHistoryModelWithRenewal;
    currentPriceHistory?: PriceHistoryModel;
};

const initialState: SubscriptionState = {
    upcomingPriceHistory: undefined,
    currentPriceHistory: undefined,
};

export { initialState as subscriptionInitialState };

export const subscriptionSlice = createSlice<SubscriptionState, {
    setUpcomingPriceHistory: CaseReducer<SubscriptionState, PayloadAction<PriceHistoryModelWithRenewal | undefined>>;
    setCurrentPriceHistory: CaseReducer<SubscriptionState, PayloadAction<PriceHistoryModel | undefined>>;
    clearUpcomingPriceHistory: CaseReducer<SubscriptionState>;
    clearCurrentPriceHistory: CaseReducer<SubscriptionState>;
}>({
    name: 'subscription',
    initialState,
    reducers: {
        setUpcomingPriceHistory: (state, { payload }) => ({
            ...state,
            upcomingPriceHistory: payload,
        }),
        setCurrentPriceHistory: (state, { payload }) => ({
            ...state,
            currentPriceHistory: payload,
        }),
        clearUpcomingPriceHistory: (state) => ({
            ...state,
            upcomingPriceHistory: initialState.upcomingPriceHistory,
        }),
        clearCurrentPriceHistory: (state) => ({
            ...state,
            currentPriceHistory: initialState.currentPriceHistory,
        }),
    },
});

export const {
    setUpcomingPriceHistory,
    setCurrentPriceHistory,
    clearUpcomingPriceHistory,
    clearCurrentPriceHistory,
} = subscriptionSlice.actions;

export const subscriptionSelector = (state: RootState) => state[subscriptionSlice.name];
