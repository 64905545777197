import React, { FC, Fragment } from 'react';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import PageTitle from 'src/components/PageTitle';
import { Button } from 'src/components/Buttons';
import type { RootState } from 'src/redux/root-reducer';
import type { ConfigState } from 'src/@types/redux';
import { configSelector } from 'src/redux/slices';

type NonBasicUserCreatedContentProps = {
    handleShowNewsletters: () => void;
};

const NonBasicUserCreatedContent: FC<NonBasicUserCreatedContentProps> = ({ handleShowNewsletters }) => {
    const { mainSiteUrl } = useSelector<RootState, ConfigState>(configSelector);

    return (
        <Fragment>
            <PageTitle
                variant="form"
                textAlign="center"
                testId="form-title"
                title="You've joined your company's license"
                subtitle="Great news - you get unlimited access through your organization. We've sent you a link to set up your password. Please note, the link is valid for 24 hours."
            />
            <Stack
                direction="column"
                spacing={{ xs: 2, sm: 2.5 }}
                marginTop={{ xs: 3, sm: 4.5 }}
            >
                <Button onClick={handleShowNewsletters} fullWidth>
                    Choose your newsletters
                </Button>
                <Button variant="secondary" href={mainSiteUrl} fullWidth>
                    Read the latest news
                </Button>
            </Stack>
        </Fragment>
    );
};

export default NonBasicUserCreatedContent;
