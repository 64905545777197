import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

export const StyledDomainAdd = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.grey};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-weight: 500;
        font-size: 14px;
    }
`;

export const StyledRecalculate = styled(Box)`
    && {
        display: flex;
        transform: translate(0%, -35%);
        position: absolute;
        right: 0;
        
        .MuiButtonBase-root {
            min-width: 126px;
            height: 40px;
        }
        
        @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
            position: static;
            transform: none;
            
            .MuiFormControl-root{
                max-width: 88px;
                position: absolute;
                transform: translate(0%, -30%);
                right: 0;
                top: 0;
            }
        }
    }
`;

export const StyledUsersTotal = styled(Typography)`
    && {
        margin-top: 24px;
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 14px;

        @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
            margin-top: 12px;
        }
    }
`;
