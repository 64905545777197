import React, { FC, ReactNode } from 'react';
import { AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { StyledAccordion } from './Accordion.styles';
import { ReactComponent as DropDownIcon } from 'src/assets/icons/triangle-down-icon.svg';
import RoundedBadge, { RoundedBadgeProps } from 'src/components/Badge/RoundedBadge/RoundedBadge';

type AccordionProps = {
    id: string;
    title: string;
    badgeProps?: RoundedBadgeProps;
    children: ReactNode;
};

const Accordion: FC<AccordionProps> = ({ id, title, badgeProps, children }) => {
    return (
        <StyledAccordion>
            <Stack direction="row" alignItems="center">
                <AccordionSummary
                    id={id}
                    aria-controls={id}
                    expandIcon={<DropDownIcon width={12} />}
                >
                    <Typography>{title}</Typography>
                </AccordionSummary>
                {badgeProps?.label && <RoundedBadge {...badgeProps} />}
            </Stack>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </StyledAccordion>
    );
};

export default Accordion;
