import React, { FC, ReactNode } from 'react';
import { Box, StackProps } from '@mui/material';
import { Link } from 'src/components/Link';
import { GRID_DEFAULT_TOOLTIP_PROPS } from 'src/constants';
import { StyledLink, StyledLinkedCell } from 'src/components/DataGrid/components/LinkedCell/LinkedCell.styles';
import { ReactComponent as LinkIcon } from 'src/assets/icons/data-grid-cell-link-icon.svg';
import StyledTooltip from 'src/components/Tooltip';

type LinkedCellProps = {
    href: string;
    onClick?: () => void;
    icon?: ReactNode;
    iconWidth?: string;
    isIconFirst?: boolean;
    text?: string;
    tooltipText?: string;
    showOnlyTooltipText?: boolean;
    tooltipPlacement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
    fullWidth?: boolean;
    paddingRight?: number;
    paddingLeft?: number;
    justifyContent?: StackProps['justifyContent'];
};

export const LinkedCell: FC<LinkedCellProps> = ({
    tooltipText,
    tooltipPlacement = 'bottom',
    href,
    text,
    icon = <LinkIcon />,
    iconWidth = '16px',
    isIconFirst = false,
    justifyContent = 'left',
    showOnlyTooltipText = false,
    paddingRight = 2,
    paddingLeft = 3,
}) => (
    <StyledLinkedCell
        data-testid="linked-cell"
        justifyContent={justifyContent}
        spacing={1}
        paddingRight={paddingRight}
        paddingLeft={paddingLeft}
        direction={isIconFirst ? 'row-reverse' : 'row'}
    >
        {text && (
            <StyledTooltip
                title={showOnlyTooltipText ? tooltipText : text}
                placement={tooltipPlacement}
                {...GRID_DEFAULT_TOOLTIP_PROPS}
            >
                <Link target="_blank" href={href}>
                    <StyledLink>{text}</StyledLink>
                </Link>
            </StyledTooltip>
        )}
        <Box minWidth={iconWidth}>
            <StyledTooltip title={tooltipText} arrow>
                <Link target="_blank" href={href}>
                    {icon}
                </Link>
            </StyledTooltip>
        </Box>
    </StyledLinkedCell>
);
