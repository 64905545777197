import { useEffect, useState } from 'react';

const formaterTime = (time: number): string => {
    return time < 10 ? `0${time}` : `${time}`;
};

const getReturnValues = (countDown: number): Record<'hours' | 'minutes' | 'seconds', string> => {
    if (countDown <= 0) {
        return { hours: '00', minutes: '00', seconds: '00' };
    }

    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    return { hours: formaterTime(hours), minutes: formaterTime(minutes), seconds: formaterTime(seconds) };
};

export const useCountDown = (endData: string): Record<'hours' | 'minutes' | 'seconds', string> => {
    const countDownDate = Date.parse(endData);

    const [countDown, setCountDown] = useState<number>(countDownDate - Date.now());

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCountDown(countDownDate - Date.now());
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [countDownDate, countDown]);

    return getReturnValues(countDown);
};
