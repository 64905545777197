import React, { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { FormikHelpers } from 'formik';
import { DomainInfoBox } from 'src/components/DomainInfoBox';
import { useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import { PageSection } from 'src/components/PageSection';
import { FormWrapper } from 'src/components/Forms/components';
import { domainValidator, validateSchemaObject } from 'src/services/validators';
import { LabeledInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import { StyledDomainAdd, StyledUsersTotal } from 'src/pages/udb/EnterpriseCalculator/EnterpriseCalculator.styles';
import { makePlural } from 'src/services/text-modifier';
import { normalizeDomain } from 'src/services/domain-processor';
import { DUPLICATE_DOMAIN_ERROR_MESSAGE, SnackbarMessageVariants } from 'src/constants';
import { getDomainInfo } from 'src/services/subscription-service-api';
import { DomainModel } from 'src/@types/subscription-service-api';

type AddDomainProps = {
    totalUserCount: number;
    domains: DomainModel[];
    setDomains: (domains: DomainModel[]) => void;
    testId?: string;
};

type AddDomainFormValues = {
    domain: string;
};

const initialValues: AddDomainFormValues = {
    domain: '',
};

const AddDomainForm: FC<AddDomainProps> = ({ domains, setDomains, totalUserCount, testId = 'domains-section' }) => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();
    const { onFormChangeHandler, isSubmitDisabled, setIsSubmitDisabled } = useIsSubmitDisabled(initialValues);

    const handleAddDomain = (values: AddDomainFormValues, helpers: FormikHelpers<AddDomainFormValues>) => {
        const normalizedDomain = normalizeDomain(values.domain);
        const existingDomain = domains.some((d) => normalizeDomain(d.domain) === normalizedDomain);

        if (existingDomain) {
            helpers.setErrors({
                domain: DUPLICATE_DOMAIN_ERROR_MESSAGE,
            });
            return;
        }

        setIsSubmitDisabled(true);
        setLoading(true);

        getDomainInfo({ domain: normalizedDomain })
            .then((data) => {
                if (data.subscriptions.length > 0) {
                    helpers.setErrors({ domain: 'This domain is already attached to another subscription' });
                    return;
                }

                setDomains([...domains, data]);
                helpers.resetForm();
            })
            .catch(() => {
                setIsSubmitDisabled(false);
                addMessage('An error occurred while checking the domain', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleRemoveDomain = (domainToRemove: string) => {
        const newDomains = domains.filter(({ domain }) => domain !== domainToRemove);
        setDomains(newDomains);
    };

    return (
        <PageSection header="Add domain" isMultiSection isLoading={isLoading} testId={testId} variant="headless">
            <FormWrapper
                testId="add-domain-form"
                onFormChange={onFormChangeHandler}
                initialValues={initialValues}
                onSubmit={handleAddDomain}
                validationSchema={validateSchemaObject({ domain: domainValidator.required() })}
            >
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 3, md: 2 }}>
                    <LabeledInput
                        disallowSpace
                        flexGrow={1}
                        placeholder="Enter domain name"
                        name="domain"
                        label=""
                        testId="domain-input"
                        errorPosition="relative"
                    />
                    <Box>
                        <Button
                            fullWidth
                            testId="add-button"
                            type="submit"
                            minSize="small"
                            disabled={isSubmitDisabled}
                        >
                                Add
                        </Button>
                    </Box>
                </Box>
            </FormWrapper>
            {domains.length > 0 && (
                <>
                    <StyledDomainAdd paddingTop={{ xs: 3 }}>
                        {makePlural('Domain', domains.length)} to be added
                    </StyledDomainAdd>
                    <Stack spacing={1.5}>
                        {domains.map((domain) => (
                            <DomainInfoBox
                                key={domain.domain}
                                domain={domain}
                                removeDomain={handleRemoveDomain}
                            />
                        ))}
                    </Stack>
                    <StyledUsersTotal>
                        {totalUserCount} {makePlural('user', totalUserCount)} in total
                    </StyledUsersTotal>
                </>
            )}
        </PageSection>
    );
};

export default AddDomainForm;
