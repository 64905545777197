import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { CenteredFullScreenLayout } from 'src/layouts';
import PageTitle from 'src/components/PageTitle';
import { Button } from 'src/components/Buttons';
import { userSelector } from 'src/redux/slices';
import { ReactComponent as ServerErrorIcon } from 'src/assets/icons/server-error-icon.svg';
import { NeedHelp } from 'src/components/NeedHelp';
import { BoxContainer, ServerErrorStackContainer } from 'src/pages/ServerError/ServerError.styles';
import { CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

const ServerErrorPage: FC = () => {
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);
    const buttonName = !currentUser ? 'Back to Login page' : 'Back to Home page';
    const buttonHref = !currentUser ? '/log-in' : '/';

    return (
        <CenteredFullScreenLayout data-testid="server-error-page">
            <ServerErrorStackContainer spacing={3} alignItems="center" justifyContent="center">
                <ServerErrorIcon />
                <PageTitle
                    title="Oops! Server error"
                    textAlign="center"
                >
                    A server error has occurred. <br />
                    Please try again later or contact us if the error persists
                </PageTitle>
                <BoxContainer>
                    <Button fullWidth testId="back-button" href={buttonHref}>{buttonName}</Button>
                </BoxContainer>
                <NeedHelp />
            </ServerErrorStackContainer>
        </CenteredFullScreenLayout>
    );
};

export default ServerErrorPage;
