import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';

export const InfoContainer = styled(Stack)`
    && {
        border-radius: 4px;
        border: 1px ${({ theme }) => theme.palette.borderGrey} solid;

        & .MuiInputBase-root {
            justify-content: space-between;

            input {
                font-size: 16px;
                text-align: end;
                padding: 0 12px;
                height: 40px;
                max-width: 64px;
            }
        }
    }
`;

export const InfoBoxTitle = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;

        @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 16px;
        }
    }
`;

export const InfoBoxValue = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 14px;
        font-style: normal;
        line-height: normal;

        svg {
            max-width: 150px;
        }

        @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 16px;
            
            svg{
                max-width: 170px;
            }
        }
    }
`;
