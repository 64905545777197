import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Grid, SelectChangeEvent, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Select } from 'src/components/Select';
import { ValueInput } from 'src/components/ToolBar/components/ValueInput';
import { ToolBarConditionIcon } from 'src/components/ToolBar/components/ToolBarCondition/ToolBarCondition.styles';
import { ConditionsParamsType, HandleToolBarConditionChangeParams, ToolbarItemContext } from 'src/@types/tool-bar';
import { getDisabledConditions, getParamOptions } from 'src/components/ToolBar/services/tool-bar-condition-helpers';
import { UdbConditions } from 'src/constants';
import { ToolBarItemContext } from 'src/components/ToolBar/components/ToolBarContext';
import { Overlay } from 'src/components/ToolBar/ToolBar';
import { OptionsType } from 'src/components/Select/Select';

type ToolBarConditionProps = {
    index?: number;
    initialConditionValue?: keyof ConditionsParamsType;
    initialParamValue?: string;
    initialInputValue?: string | string[];
    handleChange: (params: HandleToolBarConditionChangeParams) => void;
};

enum InputsToFocus {
    PARAM = 'PARAM',
    CONDITION = 'CONDITION',
    VALUE = 'VALUE',
    CLOSE_BUTTON = 'CLOSE_BUTTON',
}

let nextInputToFocus = InputsToFocus.PARAM;

export const ToolBarCondition = ({
    index,
    handleChange,
    initialInputValue,
    initialParamValue = '',
    initialConditionValue = '' as keyof ConditionsParamsType,
}: ToolBarConditionProps) => {
    const {
        conditionsModel,
        selectPlaceholder,
        schema,
        handleDelete,
        isMouseEvent,
        setIsMouseEvent,
    } = useContext<ToolbarItemContext>(ToolBarItemContext);
    const initialConditionOptions = schema.fields[initialParamValue]?.conditionOptions;

    const selectConditionRef = useRef<HTMLInputElement>(null);
    const selectParamRef = useRef<HTMLInputElement>(null);
    const valueInputRef = useRef<HTMLInputElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    const [shouldFocusNextInput, setShouldFocusNextInput] = useState<boolean>(true);
    const [paramValue, setParamValue] = useState<string>(initialParamValue);
    const [conditionValue, setConditionValue] = useState<keyof ConditionsParamsType>(
        initialConditionValue ?? (
            schema.fields[initialParamValue]?.conditionOptions[0].value
        ),
    );

    const [conditionOptions, setConditionOptions] = useState<OptionsType[]>(initialConditionOptions);
    const [inputValue, setInputValue] = useState<string | string[] | undefined>(initialInputValue);

    const shouldRenderDeleteButton = typeof handleDelete === 'function' && typeof index === 'number';
    const shouldHideCondition = schema.fields[paramValue]?.shouldHideCondition;
    const shouldShowConditionSelect = conditionOptions && !shouldHideCondition;
    const selectedCondition = getDisabledConditions(paramValue, conditionsModel);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    const handleOpenOverlay = () => setShowOverlay(true);

    const handleCloseOverlay = () => setShowOverlay(false);

    const focusMapping = {
        [InputsToFocus.PARAM]: selectParamRef,
        [InputsToFocus.CONDITION]: selectConditionRef,
        [InputsToFocus.VALUE]: valueInputRef,
        [InputsToFocus.CLOSE_BUTTON]: closeButtonRef,
    };

    useEffect(() => {
        setInputValue(initialInputValue);
    }, [initialInputValue]);

    useEffect(() => {
        setConditionOptions(initialConditionOptions);
    }, [JSON.stringify(initialConditionOptions)]);

    useEffect(() => {
        if (paramValue && conditionValue) {
            handleChange({ paramValue, conditionValue, inputValue, index: index as number });
        }
    }, [conditionValue, paramValue]);

    useEffect(() => {
        if (!isMouseEvent && shouldFocusNextInput) {
            focusMapping[nextInputToFocus].current?.focus();
            setShouldFocusNextInput(false);
        }
    }, [isMouseEvent, shouldFocusNextInput]);

    const handleParamChange = (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target;
        const disabledCondition = getDisabledConditions(value as string, conditionsModel);

        setParamValue(value as string);
        setInputValue(undefined);
        setConditionOptions(schema.fields[value as string]?.conditionOptions);
        setConditionValue(
            schema.fields[value as string]?.conditionOptions
                .filter((option) => !disabledCondition.includes(option.value))[0]
                .value as keyof ConditionsParamsType,
        );

        setIsMouseEvent(!(event instanceof KeyboardEvent));
        nextInputToFocus = InputsToFocus.CONDITION;
        setShouldFocusNextInput(true);
    };

    const handleConditionChange = (event: SelectChangeEvent<unknown>) => {
        setConditionValue(event.target.value as keyof ConditionsParamsType);
        setIsMouseEvent(!(event instanceof KeyboardEvent));
        nextInputToFocus = InputsToFocus.VALUE;
        setShouldFocusNextInput(true);

        if (event.target.value === UdbConditions.EQUAL || event.target.value === UdbConditions.NOT_EQUAL) {
            if (Array.isArray(initialInputValue)) {
                setInputValue(initialInputValue.length > 1 ? undefined : initialInputValue[0]);
            }
        }

        if (event.target.value === UdbConditions.ANY_OF || event.target.value === UdbConditions.NOT_ANY_OF) {
            if (typeof initialInputValue === 'string') {
                setInputValue([initialInputValue]);
            }
        }

        if (event.target.value === UdbConditions.IS_EMPTY || event.target.value === UdbConditions.IS_NOT_EMPTY) {
            setInputValue('');
        }
    };

    const handleValueChange = (value: string | string[] | undefined) => {
        handleChange({ paramValue, conditionValue, inputValue: value, index: index as number });
        nextInputToFocus = InputsToFocus.CLOSE_BUTTON;
        setShouldFocusNextInput(true);
    };

    const getGridColumns = (increase = 0) => {
        if (paramValue && shouldShowConditionSelect) {
            return 4 + increase;
        }
        if (paramValue) {
            return 6;
        }
        return 12;
    };

    return (
        <Stack direction="row" alignItems="center" paddingX={3} gap={1} marginBottom={{ xs: 3, sm: paramValue ? 3 : 0 }}>
            { showOverlay && (<Overlay display={{ xs: 'inherit', sm: 'none' }} color-variant="lighter" />)}
            <Grid container spacing={1.25}>
                <Grid item xs={getGridColumns(2)} sm={getGridColumns(0)} paddingLeft={0}>
                    <Select
                        title={selectPlaceholder}
                        hasHeader
                        testId="param"
                        name={'param'}
                        onChange={handleParamChange}
                        onOpen={handleOpenOverlay}
                        onClose={handleCloseOverlay}
                        placeholder={selectPlaceholder}
                        options={getParamOptions(schema, conditionsModel, paramValue)}
                        value={paramValue}
                        autoFocus={!isMouseEvent}
                        inputRef={selectParamRef}
                    />
                </Grid>
                {shouldShowConditionSelect && (
                    <Grid item xs={getGridColumns(2)} sm={3}>
                        <Select
                            title={selectPlaceholder}
                            hasHeader
                            testId="condition"
                            name={'condition'}
                            onChange={handleConditionChange}
                            onOpen={handleOpenOverlay}
                            onClose={handleCloseOverlay}
                            options={conditionOptions?.map((option) => ({
                                ...option,
                                disabled: option.value !== initialConditionValue && (
                                    selectedCondition.includes(option.value)),
                            }))}
                            value={conditionValue}
                            inputRef={selectConditionRef}
                        />
                    </Grid>
                )}
                {paramValue && (
                    <Grid item sm={getGridColumns(1)} xs={getGridColumns(8)} paddingRight={0}>
                        <ValueInput
                            title={selectPlaceholder}
                            onOpen={handleOpenOverlay}
                            onClose={handleCloseOverlay}
                            currentConditionValue={initialConditionValue}
                            currentParamValue={initialParamValue}
                            initialInputValue={inputValue}
                            inputType={schema.fields[paramValue]?.conditionType}
                            options={schema.fields[paramValue]?.valueOptions}
                            onChange={handleValueChange}
                            ref={valueInputRef}
                        />
                    </Grid>
                )}
            </Grid>
            {shouldRenderDeleteButton && conditionsModel.length > 1 && (
                <Box display={{ xs: 'none', sm: 'inherit' }}>
                    <ToolBarConditionIcon ref={closeButtonRef} data-testid="delete-button" onClick={() => handleDelete(index)}>
                        <CloseIcon />
                    </ToolBarConditionIcon>
                </Box>
            )}
        </Stack>
    );
};
