import React, { useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { StyledSelectRemove, ToolBarItemTitle } from './ToolBarItem.styles';
import { ToolbarItemContext } from 'src/@types/tool-bar';
import { ToolBarItemContext } from 'src/components/ToolBar/components/ToolBarContext';
import { ToolBarActions } from 'src/components/ToolBar/components/ToolBarItem/ToolBarItem';

enum ToolBarActionsLabel {
    QUICK_ACTIONS_LABEL = 'Quick filter',
    QUICK_ACTIONS_LABEL_SELECT = 'Select quick filter',
    ADD_CONDITIONS_COUNTER = 'Condition',
}

type ToolBarItemsTitleProps = {
    handleDelete?: (index?: number) => void;
    index?: number;
};

export const ToolBarItemsTitle = ({ handleDelete, index }: ToolBarItemsTitleProps) => {
    const { actionType, conditionsModel } = useContext<ToolbarItemContext>(ToolBarItemContext);
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        switch (actionType) {
            case ToolBarActions.QUICK_ACTIONS:
                const filteredQuickActions = conditionsModel
                    .filter(([param]) => param === 'quickAction');
                setTitle(
                    filteredQuickActions.length === 0
                        ? ToolBarActionsLabel.QUICK_ACTIONS_LABEL_SELECT
                        : ToolBarActionsLabel.QUICK_ACTIONS_LABEL,
                );
                break;
            case ToolBarActions.ADD_CONDITIONS:
                const filteredConditions = conditionsModel
                    .filter(([param]) => param !== 'quickAction');
                setTitle(
                    filteredConditions.length === 0
                        ? ''
                        : `${ToolBarActionsLabel.ADD_CONDITIONS_COUNTER} ${index !== undefined ? index + 1 : ''}`,
                );
                break;
            default:
                setTitle('');
        }
    }, [actionType, conditionsModel, index]);

    return (
        <Stack
            paddingLeft={3}
            paddingRight={3}
            direction="row"
            display={{ xs: 'flex', sm: 'none' }}
            justifyContent="space-between"
        >
            <ToolBarItemTitle data-testid="tool-bar-item-title">{title}</ToolBarItemTitle>
            {conditionsModel.length > 1 && handleDelete && (
                <StyledSelectRemove data-testid="remove-item-button" onClick={() => handleDelete(index)}>
                  Remove
                </StyledSelectRemove>
            )}
        </Stack>
    );
};
