import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { NavButton } from 'src/components/NavButton';
import { ReactComponent as TriangleDownIcon } from 'src/assets/icons/expand-icon.svg';

export const DropdownWrapper = styled(Box)`
    position: relative;
    padding-top: 6px;

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: calc(100% - 48px);
        height: 1px;
        background-color: ${({ theme }) => theme.palette.borderGrey};
        transform: translateX(-50%);
    }

    &:last-child:after {
        display: none;
    }
`;

export const DropdownButton = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-top: 14px;
    padding-bottom: 14px;
`;

export const DropdownText = styled(Typography)`
    &.MuiTypography-root {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 16px;
    }
`;

export const DropdownIcon = styled(TriangleDownIcon)`
    transform: rotate(${({ rotate }) => rotate});
    transition: transform 0.3s;

    & > path {
        fill: ${({ rotate, theme }) => rotate === '90deg' ? theme.palette.grey : theme.palette.black};
    }
`;

export const DropdownContent = styled(Box)`
    ${NavButton} {
        padding: 12px 24px;
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 16px;
        
        &:after {
            display: none;
        }
    }

    & ${DropdownWrapper} {
        &:after {
            display: none;
        }

        padding-bottom: 0;
        padding-top: 0;

        ${DropdownText} {
            &.MuiTypography-root {
                color: ${({ theme }) => theme.palette.lightBlack};
            }
        }

        ${NavButton} {
            padding-left: 32px;
            padding-top: 14px;
            padding-bottom: 14px;
            color: ${({ theme }) => theme.palette.lightBlack};
        }
    }
`;
