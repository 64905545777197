import React, { FC, ReactNode } from 'react';
import { StyledMenuItem } from 'src/components/Select/LazyLoadSelect/LazyLoadSelect.styles';

type LazyLoadSelectMenuItemProps = {
    visible?: boolean;
    disabled?: boolean;
    testId?: string;
    value: string;
    onClick: () => void;
    children: ReactNode;
};

const LazyLoadSelectMenuItem: FC<LazyLoadSelectMenuItemProps> = ({
    disabled,
    testId,
    value,
    visible,
    onClick,
    children,
}) => {
    return (
        <StyledMenuItem
            disabled={disabled}
            data-testid={testId}
            value={value}
            tabIndex={1}
            style={{ display: visible ? 'flex' : 'none' }}
            onClick={onClick}
        >
            {children}
        </StyledMenuItem>
    );
};

export default LazyLoadSelectMenuItem;
