import { NewsletterDifference } from 'src/services/udb-delivery-preferences';
import { ProfileEmailDeliveryVertical } from 'src/@types/sso-api';
import { UDBNewsletterChannel } from 'src/constants';

const isAllNewslettersHasSelectedChanel = (chanel: UDBNewsletterChannel, newsletters: ProfileEmailDeliveryVertical['newsletters']) =>
    newsletters.every(({ selectedChannels }) =>
        selectedChannels.includes(chanel));

export const getInitialValues = ({ newsletters }: ProfileEmailDeliveryVertical): Record<string, boolean> => (
    newsletters.reduce<Record<string, boolean>>(
        (preparedSate: Record<string, boolean>, { selectedChannels, id }) => {
            const hasEmail = selectedChannels.includes(UDBNewsletterChannel.EMAIL);
            const hasSMS = selectedChannels.includes(UDBNewsletterChannel.SMS);

            preparedSate[`${id}_${UDBNewsletterChannel.EMAIL}`] = hasEmail;
            preparedSate[`${id}_${UDBNewsletterChannel.SMS}`] = hasSMS;

            return preparedSate;
        },
        {
            [`subscribeAll_${UDBNewsletterChannel.EMAIL}`]: isAllNewslettersHasSelectedChanel(UDBNewsletterChannel.EMAIL, newsletters),
            [`subscribeAll_${UDBNewsletterChannel.SMS}`]: isAllNewslettersHasSelectedChanel(UDBNewsletterChannel.SMS, newsletters),
            unsubscribeAll: false,
        },
    )
);

export const getNewslettersDifference = (
    newValues: Record<string, boolean>,
    initialValues: Record<string, boolean>,
    { newsletters }: ProfileEmailDeliveryVertical,
) => (
    Object.entries(newValues)
        .reduce<NewsletterDifference[]>(
        (preparedNewsletters, [key, value]) => {
            const [newsletterId, chanel] = key.split('_');

            if (!Number.isNaN(Number(newsletterId)) && initialValues[key] !== value) {
                const name = newsletters
                    .find(({ id }) => id === Number(newsletterId))
                    ?.name;

                if (name) {
                    preparedNewsletters.push({
                        chanel: chanel as UDBNewsletterChannel,
                        id: Number(newsletterId),
                        value,
                        name,
                    });
                }
            }

            return preparedNewsletters;
        },
        [],
    )
);
