import React, { ChangeEvent, type ReactElement, ReactNode } from 'react';
import { Formik, type FormikConfig, type FormikValues } from 'formik';
import { Box, Stack, StackProps } from '@mui/material';
import { StyledFormContainer } from './Form.styles';
import { RequestError } from 'src/components/Forms/components/RequestError';
import PageTitle from 'src/components/PageTitle';
import { LoaderOverlay } from 'src/components/LoaderOverlay';

type FormProps<Values extends FormikValues> = {
    testId?: string;
    onFormChange?: (event: ChangeEvent<HTMLFormElement>) => void;
    isSubmitting?: boolean;
    formSpacing?: StackProps['spacing'];
    title?: string | ReactElement;
    subtitle?: string | ReactElement;
    fromError?: false | string;
    fieldsBlock?: ReactNode;
    buttonsBlock?: ReactNode;
    customTitle?: ReactNode;
} & FormikConfig<Values>;

const SingleModalForm = <Values extends FormikValues = FormikValues>({
    isSubmitting = false,
    initialValues,
    onSubmit,
    validationSchema,
    children,
    testId,
    title,
    subtitle,
    onFormChange,
    fieldsBlock,
    buttonsBlock,
    fromError,
    customTitle,
    formSpacing = { sm: 3, xs: 2.5 },
}: FormProps<Values>) => {
    return (
        <>
            <Formik<Values>
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
            >
                <StyledFormContainer onChange={onFormChange} data-testid={testId}>
                    <Stack spacing={formSpacing}>
                        { children ? (
                            <>{children}</>
                        ) : (
                            <>
                                {customTitle ? customTitle : (
                                    <PageTitle title={title} subtitle={subtitle} variant="form" testId="form-title" />
                                )}
                                <Stack spacing={{ xs: 2.5, sm: 4 }}>
                                    {fieldsBlock}
                                </Stack>
                                <Stack spacing={{ xs: fromError ? 3 : 0, sm: 3 }} paddingTop={{ xs: 0.5, sm: 0 }}>
                                    <Box>
                                        {fromError && <RequestError testId="form-error">{fromError}</RequestError>}
                                    </Box>
                                    {buttonsBlock}
                                </Stack>
                            </>
                        )}
                    </Stack>
                </StyledFormContainer>
            </Formik>
            {isSubmitting && <LoaderOverlay />}
        </>
    );
};

export default SingleModalForm;
