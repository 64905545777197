type TokenConfiguration = {
    key: string;
    storage: Storage;
};

export default class TokenProvider {
    private tokenConfigurations: Record<string, TokenConfiguration> = {};

    constructor(tokenConfigurations: TokenConfiguration[]) {
        tokenConfigurations.forEach((tokenProviderItem) => {
            this.setConfiguration(tokenProviderItem);
        });
    }

    setConfiguration(tokenProviderItem: TokenConfiguration): void {
        this.tokenConfigurations[tokenProviderItem.key] = tokenProviderItem;
    }

    getConfiguration(key: string): TokenConfiguration {
        if (!this.tokenConfigurations[key]) {
            throw new Error(`Token with key "${key}" not configured`);
        }

        return this.tokenConfigurations[key];
    }

    setToken(key: string, token: string): void {
        this.getConfiguration(key).storage.setItem(key, token);
    }

    getToken(key: string): string | null {
        return this.getConfiguration(key).storage.getItem(key);
    }

    clearToken(key: string): void {
        this.getConfiguration(key).storage.removeItem(key);
    }

    store(tokens: Record<string, string>): void {
        Object.entries(tokens).forEach(([key, token]) => this.setToken(key, token));
    }

    clear(): void {
        Object.keys(this.tokenConfigurations).forEach((key) => this.clearToken(key));
    }
}
