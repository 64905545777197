import { SchemaType } from 'src/@types/tool-bar';
import {
    TEXT_CONDITIONS,
    TIME_CONDITIONS,
    ToolBarConditionTypes,
    ToolBarModelTypes,
} from 'src/constants';
import {
    dateValidator,
    stringValidator,
} from 'src/components/ToolBar/services/tool-bar-validators';

export const filteringSchema: SchemaType = {
    modelType: ToolBarModelTypes.FILTERING,
    fields: {
        uuid: {
            order: 1,
            label: 'UUID',
            value: 'uuid',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        fullName: {
            order: 2,
            label: 'Full name',
            value: 'fullName',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        'email.email': {
            order: 3,
            label: 'Email address',
            value: 'email.email',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        'secondaryEmails.email': {
            order: 4,
            label: 'Secondary email address',
            value: 'secondaryEmails.email',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        'phoneNumber.phoneNumber': {
            order: 5,
            label: 'Phone number',
            value: 'phoneNumber.phoneNumber',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        'jobInfo.companyName': {
            order: 6,
            label: 'Company name',
            value: 'jobInfo.companyName',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        'jobInfo.jobTitle': {
            order: 7,
            label: 'Job title',
            value: 'jobInfo.jobTitle',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: TEXT_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        createdAt: {
            order: 8,
            label: 'Account created on',
            value: 'createdAt',
            conditionType: ToolBarConditionTypes.DATE_OR_DATE_TIME,
            conditionOptions: TIME_CONDITIONS,
            validateConditionValue: dateValidator,
        },
    },
};
