import styled from 'styled-components';
import { Paper } from '@mui/material';
import { theme as mainTheme } from 'src/theme';

export type StyledPaperVariant = 'form' | 'message' | 'newsLettersForm' | 'checkout';

const widthByVariantSelector = (props: { 'data-variant'?: StyledPaperVariant, theme: typeof mainTheme }) => {
    switch (props['data-variant']) {
        case 'message':
            return '600px';
        case 'newsLettersForm':
            return '1024px';
        case 'checkout':
            return '1128px';
        case 'form':
        default:
            return '500px';
    }
};

export const StyledPaper = styled(Paper)`
    && {
        margin: 0 auto;
        max-width: ${widthByVariantSelector};
        width: 90vw;
        position: relative;
        box-shadow: 0 15px 40px 0 ${({ theme }) => theme.palette.shadow};
    }
`;
