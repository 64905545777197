import React, { FC } from 'react';
import { StyledHeaderMenuItem } from './HeaderMenuItem.styles';
import { NavButton } from 'src/components/NavButton';
import { LinkItemType } from 'src/services/header-menu-helper';

type HeaderMenuItemProps = {
    item: LinkItemType;
    onClick?: () => void;
};

const HeaderMenuItem: FC<HeaderMenuItemProps> = ({ item, onClick }) => {
    if (!item.link) {
        return (
            <StyledHeaderMenuItem
                onClick={() => {
                    onClick?.();
                    item.onClick?.();
                }}
            >
                {item.label}
            </StyledHeaderMenuItem>
        );
    }

    return (
        <NavButton
            to={item.link}
            onClick={() => {
                onClick?.();
            }}
        >
            {item.label}
        </NavButton>
    );
};

export default HeaderMenuItem;
