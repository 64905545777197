import React, { FC, SyntheticEvent, useState } from 'react';
import { FormikHelpers } from 'formik';
import { useIsSubmitDisabled } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import { PasswordInput } from 'src/components/Inputs/PasswordInput';
import { passwordValidator, strictPasswordValidator, validateSchemaObject } from 'src/services/validators';
import { ResetPasswordFormValuesType } from 'src/@types/forms';
import {
    FAILED_PASSWORD_CHANGE_MESSAGE,
    MIN_PASSWORD_LENGTH,
    PASSWORDS_DO_NOT_MATCH_MESSAGE,
    ResetPasswordFormFields,
} from 'src/constants';
import { SingleModalForm } from 'src/components/Forms/components';
import RedirectionMessage from 'src/components/RedirectionMessage';

type ResetPasswordFormProps = {
    onSubmit: (newPassword: string, repeatNewPassword: string) => Promise<void>;
    forceChangePassword?: boolean;
    submitLabel: string;
};

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
    onSubmit,
    forceChangePassword,
    submitLabel,
}) => {
    const [isSubmitFailed, setIsSubmitFailed] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSucceeded, setSucceeded] = useState<boolean>(false);

    const initialValues = {
        newPassword: '',
        repeatNewPassword: '',
    };

    const { isSubmitDisabled, onFormChangeHandler, forceChangeValues } = useIsSubmitDisabled(initialValues, ['newPassword', 'repeatNewPassword']);

    const validationSchema = validateSchemaObject({
        newPassword: strictPasswordValidator,
        repeatNewPassword: passwordValidator,
    });

    const handleChange = (event: SyntheticEvent) => {
        onFormChangeHandler(event);
        setIsSubmitFailed(false);
    };

    const handleSubmit = async (
        values: ResetPasswordFormValuesType,
        { setErrors }: FormikHelpers<ResetPasswordFormValuesType>,
    ): Promise<void> => {
        const { newPassword, repeatNewPassword } = values;
        if (newPassword !== repeatNewPassword) {
            setErrors({
                repeatNewPassword: PASSWORDS_DO_NOT_MATCH_MESSAGE,
            });
            return;
        }

        setIsSubmitFailed(false);
        setIsSubmitting(true);
        onSubmit(newPassword, repeatNewPassword)
            .then(() => {
                setSucceeded(true);
                setIsSubmitting(false);
            })
            .catch(() => {
                setIsSubmitFailed(true);
                setIsSubmitting(false);
                setErrors({ newPassword: ' ', repeatNewPassword: ' ' });
            });
    };

    if (isSucceeded && !forceChangePassword) {
        return (
            <RedirectionMessage
                title="Your new password has been set."
                linkNameTo="the Login Page"
                link="/log-in"
                testId="redirect-after-reset-pass-message"
            />
        );
    }

    return (
        <SingleModalForm
            onSubmit={handleSubmit}
            onFormChange={handleChange}
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            validationSchema={validationSchema}
            fromError={isSubmitFailed && FAILED_PASSWORD_CHANGE_MESSAGE}
            testId="reset-password-form"
            title="Set your new password"
            subtitle={(
                <>
                    {forceChangePassword && (<>For security reasons, a password change is required.<br /></>)}
                    Your new password must be&nbsp;
                    <strong>at least {MIN_PASSWORD_LENGTH} characters</strong>&nbsp;long and contain&nbsp;
                    <strong>a number, a lower case, an upper case</strong>, and a <strong>symbol</strong>.
                </>
            )}
            fieldsBlock={(
                <>
                    <PasswordInput
                        name={ResetPasswordFormFields.NEW_PASSWORD}
                        placeholder="Enter your new password"
                        testId="new-password"
                        label="New password"
                        disabled={isSubmitting}
                        showAutoGenerate
                        handlerAutoGenerate={(newPasswordValues: Record<string, string>) => {
                            forceChangeValues(newPasswordValues);
                        }}
                        hasActiveValidator
                        hasSubmittingError={isSubmitFailed}
                        validator={strictPasswordValidator}
                    />
                    <PasswordInput
                        name={ResetPasswordFormFields.REPEAT_NEW_PASSWORD}
                        placeholder="Re-enter your new password"
                        testId="repeat-new-password"
                        label="Confirm password"
                        disabled={isSubmitting}
                        hasSubmittingError={isSubmitFailed}
                    />
                </>
            )}
            buttonsBlock={(
                <Button
                    type="submit"
                    testId="submit-reset-password"
                    disabled={isSubmitting || isSubmitDisabled}
                >
                    {submitLabel}
                </Button>
            )}
        />
    );
};

export default ResetPasswordForm;
