import React, { FC, useEffect } from 'react';
import { Box, Grid, InputAdornment, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import AdditionalEmailRow from './AdditionalEmailRow';
import { ActionButtonVariant, InputActionButton } from 'src/components/Buttons/ActionButton';
import {
    EditContactInformationFormValues,
} from 'src/components/Modals/EditContactInformationModal/EditContactInformationModal';
import {
    AdditionalEmailsWrapper,
    EmailWarning,
    PhoneNumberInputWrapper,
} from 'src/components/Modals/EditContactInformationModal/EditContactInformationModal.styles';
import { StyledCloseIcon } from 'src/components/Modals/BaseModal/BaseModal.styles';
import { DEFAULT_MODAL_MAX_WIDTH } from 'src/components/Modals/BaseModal';
import { LabeledInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import { useGeneralModal } from 'src/hooks';
import { CountrySelect } from 'src/components/Select';
import PhoneNumberInput from 'src/components/Inputs/PhoneNumberInput';
import { Label } from 'src/components/Label';
import {
    InputWithRevertButtonWrapper,
} from 'src/components/Inputs/components/InputRevertButton/InputRevertButton.styles';
import { InputRevertButton } from 'src/components/Inputs/components/InputRevertButton';
import StyledTooltip from 'src/components/Tooltip';
import { ChangeEmailCheckData } from 'src/@types/sso-api';
import { ReactComponent as WarningIcon } from 'src/assets/icons/snackbar/snackbar-warning.svg';
import { FormikValuesType } from 'src/@types/forms';
import { Email } from 'src/@types/unified-db-api';

export type EditContactInformationModalContentProps = {
    changeEmailStatus: ChangeEmailCheckData;
    secondaryEmails: Email[] | undefined;
    phoneNumber: string | undefined;
    phoneNumberRemoved: boolean;
    setPhoneNumberRemoved: (value: boolean) => void;
    removedEmails: number[];
    setRemovedEmails: (value: number[]) => void;
    isSubmitDisabled: boolean;
    onCountryChange: (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => void;
    forceChangeValues: (newValues: Partial<EditContactInformationFormValues>) => void;
    updatedFormValues: Partial<EditContactInformationFormValues>;
};

const EditContactInformationModalContent: FC<EditContactInformationModalContentProps> = ({
    changeEmailStatus,
    secondaryEmails,
    phoneNumber,
    phoneNumberRemoved,
    setPhoneNumberRemoved,
    removedEmails,
    setRemovedEmails,
    isSubmitDisabled,
    onCountryChange,
    forceChangeValues,
    updatedFormValues,
}) => {
    const { values, initialValues, setValues } = useFormikContext<FormikValuesType>();
    const { closeModal } = useGeneralModal();

    const isEmailInitial = values.email !== initialValues.email;
    const isCountryInitial = values.country !== initialValues.country;

    useEffect(() => {
        if (updatedFormValues) {
            const newValues = {
                country: updatedFormValues.country || values.country || initialValues.country,
                email: updatedFormValues.email || values.email || initialValues.email,
            };

            forceChangeValues(newValues);
            setValues(newValues);
        }
    }, [updatedFormValues]);

    return (
        <Stack maxWidth={DEFAULT_MODAL_MAX_WIDTH}>
            <Grid container spacing={{ xs: 2.5, sm: 4 }}>
                {changeEmailStatus.newEmail?.length && (
                    <Grid item xs={12}>
                        <EmailWarning>
                            <WarningIcon />
                            <Box>
                                <p>Email change confirmation has been sent to</p>
                                <strong>{changeEmailStatus.newEmail || 'email'}</strong>
                            </Box>
                        </EmailWarning>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <LabeledInput
                        testId="email-input"
                        disallowSpace
                        name="email"
                        label="Primary email address"
                        placeholder="Enter your email"
                        isRevertBtnVisible={isEmailInitial}
                        inputProps={{ required: true }}
                        flex={1}
                        hasRevertButton
                        onRevert={() => forceChangeValues({ ...values, email: initialValues.email })}
                    />
                </Grid>
                {secondaryEmails && secondaryEmails.length > 0 && (
                    <Grid item xs={12}>
                        <Label htmlFor="additional-emails">Additional email addresses</Label>
                        <AdditionalEmailsWrapper gap={{ xs: 1.5, sm: 2 }}>
                            {secondaryEmails?.map((email, index) => (
                                <AdditionalEmailRow
                                    id={email.id}
                                    email={email.email}
                                    index={index}
                                    isRemoved={removedEmails.includes(email.id)}
                                    onRevert={() => setRemovedEmails(removedEmails.filter(
                                        (removedEmail) => removedEmail !== email.id,
                                    ))}
                                    onRemove={() => setRemovedEmails([...removedEmails, email.id])}
                                    key={email.id}
                                />
                            ))}
                        </AdditionalEmailsWrapper>
                    </Grid>
                )}
                {phoneNumber && (
                    <PhoneNumberInputWrapper item xs={12} data-phone-number-removed={phoneNumberRemoved}>
                        <InputWithRevertButtonWrapper
                            direction="row"
                            alignItems="flex-end"
                            data-is-button-visible={phoneNumberRemoved}
                        >
                            <PhoneNumberInput
                                testId="phone-number-input"
                                name="phoneNumber"
                                label="Phone number"
                                placeholder="Phone number"
                                disabled
                                inputProps={{ endAdornment: phoneNumberRemoved ? (
                                    <InputAdornment position="end">
                                        <InputRevertButton testId="phone-number-revert-button" onClick={() => setPhoneNumberRemoved(false)} />
                                    </InputAdornment>
                                ) : (
                                    <InputAdornment position="end">
                                        <StyledTooltip title="Remove phone number" arrow>
                                            <Box marginRight={2}>
                                                <InputActionButton
                                                    data-attribute-to-change="fill"
                                                    data-variant={ActionButtonVariant.GREY}
                                                    onMouseDown={() => setPhoneNumberRemoved(true)}
                                                    data-testid="phone-number-remove-button"
                                                >
                                                    <StyledCloseIcon />
                                                </InputActionButton>
                                            </Box>
                                        </StyledTooltip>
                                    </InputAdornment>
                                ) }}
                                initialValue={phoneNumber}
                            />
                        </InputWithRevertButtonWrapper>
                    </PhoneNumberInputWrapper>
                )}
                <Grid item xs={12}>
                    <CountrySelect
                        isRevertBtnVisible={isCountryInitial}
                        onChangeHandler={onCountryChange}
                        flex={1}
                        hasRevertButton
                        onRevert={() => forceChangeValues({ ...values, country: initialValues.country })}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" spacing={{ xs: 2, sm: 2.5 }} marginTop={{ xs: 3, sm: 6 }}>
                <Button
                    minSize="small"
                    data-testid="contact-information-cancel-button"
                    onClick={closeModal}
                    fullWidth
                    variant="secondary"
                >
                    Cancel
                </Button>
                <Button
                    minSize="small"
                    fullWidth
                    data-testid="contact-information-submit-button"
                    disabled={
                        isSubmitDisabled &&
                        removedEmails.length === 0 &&
                        !phoneNumberRemoved
                    }
                    type="submit"
                >
                    Save
                </Button>
            </Stack>
        </Stack>
    );
};

export default EditContactInformationModalContent;
