import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { GridColumnProps } from 'src/components/TableSection/TableSection';

export const gridColumnParams: GridColumnProps = {
    amount: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Amount',
        gap: { xs: 0, md: 1 },
    },
    stripeInvoiceID: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: { xs: 'none', md: 'flex' },
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Invoice',
        gap: { xs: 0, md: 1 },
    },
    periodStart: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: { xs: 'none', md: 'flex' },
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Period Start',
        gap: { xs: 0, md: 1 },
    },
    periodEnd: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: { xs: 'none', md: 'flex' },
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Period End',
        gap: { xs: 0, md: 1 },
    },
    fullPeriod: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 0,
        display: { xs: 'flex', md: 'none' },
        flexDirection: 'column',
        justifyContent: 'center',
        gap: { xs: 0, md: 1 },
    },
    status: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Payment Status',
        display: { xs: 'none', md: 'flex' },
        gap: { xs: 0, md: 1 },
    },
};
