import styled from 'styled-components';
import { Typography } from '@mui/material';

export const NoResultMessage = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.medium};
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;

export const SearchResultLabel = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.grey};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;
