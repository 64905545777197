import { APIClientResponseError } from 'src/@types/api-client';

export default class APIClientError extends Error {
    responseError: APIClientResponseError | null = null;

    constructor(message = '', responseError: APIClientResponseError | null = null) {
        super(message);
        this.name = 'APIClientError';
        this.responseError = responseError;
    }
}
