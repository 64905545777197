import React, { FC, useState } from 'react';
import {
    DropdownButton,
    DropdownContent,
    DropdownIcon,
    DropdownText,
    DropdownWrapper,
} from './MenuDropdown.styles';
import { HeaderMenuItem } from 'src/layouts/BasicLayout/components/Header/components/HeaderMenuItem';
import { DropdownItemType, LinkItemType, isDropdownItem } from 'src/services/header-menu-helper';

type MenuDropdownProps = {
    dropdownItem: DropdownItemType;
    index: number;
    onItemClick: () => void;
};

const MenuDropdown: FC<MenuDropdownProps> = ({ dropdownItem, index, onItemClick }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const childrenItems = dropdownItem.children?.map((subItem, subIndex) => {
        if (isDropdownItem(subItem)) {
            return (<MenuDropdown
                key={`menu-item-${index}-${subIndex}`}
                dropdownItem={subItem as DropdownItemType}
                index={index}
                onItemClick={onItemClick}
            />);
        }

        return (
            <HeaderMenuItem key={`menu-item-${index}-${subIndex}`} item={subItem as LinkItemType} onClick={onItemClick} />
        );
    });

    return (
        <DropdownWrapper width="100%" paddingBottom={0.5}>
            <DropdownButton
                data-testid="dropdown-button"
                paddingX={3}
                onClick={() => setIsOpen((prev) => !prev)}
            >
                <DropdownText>
                    {dropdownItem.label}
                </DropdownText>
                <DropdownIcon
                    width={12}
                    height={12}
                    rotate={isOpen ? '-90deg' : '90deg'}
                />
            </DropdownButton>
            {isOpen && (
                <DropdownContent data-testid="dropdown-container" display="flex" flexDirection="column" alignItems="flex-start">
                    {childrenItems}
                </DropdownContent>
            )}
        </DropdownWrapper>
    );
};

export default MenuDropdown;
