import styled from 'styled-components';
import { Box } from '@mui/material';

export const StyledGracePeriod = styled(Box)`
    && {
        align-items: center;
        padding: 14px 16px;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.palette.dirtyWhite};
        color: ${({ theme }) => theme.palette.grey};
        background: #F4F4F4;  
        height: 21px;
        font-family: ${({ theme }) => theme.fonts.normal};
      
        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
          height: 14px;
        }
    } 
`;

export const StyledBox = styled(Box)`
    && {
        font-size: 16px;
        max-width: 478px;
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.black};

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
        }
    } 
`;

export const StyledModalLoading = styled(Box)`
    && {
        position: relative;
        height: 127px;
        
        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            height: 109px;
        }
    } 
`;

export const InfoBox = styled(Box)`
    && {
        align-items: center;
        display: flex;
        padding: 16px;
        background-color: ${({ theme }) => theme.palette.snackbar.infoBackground};
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 15px;
        border-radius: 4px;
    }
`;
