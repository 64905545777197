import styled from 'styled-components';
import { Box } from '@mui/material';

export const CustomDatePicker = styled(Box)`
   && {
      @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
         max-width: 750px;
      }
  }    
`;
