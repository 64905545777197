import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

export const ModalWrapper = styled(Box)`
    max-width: 478px;
`;

export const ModalTitle = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 16px;
        
        @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
        }
    }
`;
