import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getManagerSectionActionsList, getUdbManageSubscriptionActionsList } from './services/action-schema';
import { Scope, useUserCan } from 'src/hooks/useUserCan';
import { SubscriptionManageHeader } from 'src/components/SubscriptionManageHeader';
import { UserSection } from 'src/components/UserSection';
import { UsageSection } from 'src/components/SubscriptionUsageSection';
import { DomainsSection } from 'src/components/DomainsSection';
import { PaymentSection } from 'src/components/PaymentSection';
import { PaginationData } from 'src/@types/pagination';
import type { RootState } from 'src/redux/root-reducer';
import {
    clearCurrentPriceHistory,
    clearUpcomingPriceHistory,
    setCurrentPriceHistory,
    setUpcomingPriceHistory,
    subscriptionSelector,
    SubscriptionState,
} from 'src/redux/slices/subscription';
import {
    calculateRenewalIndex,
    getManagers,
    getPriceHistory,
    getSubscription,
    getSubscriptionDomains,
    getSubscriptionUsers,
} from 'src/services/subscription-service-api';
import { ManagerSection } from 'src/components/ManagerSection';
import { useSnackbarMessage } from 'src/hooks';
import {
    DEFAULT_PAGINATION_DATA,
    PaidSubscriptionStatus,
    SnackbarMessageVariants,
    SubscriptionType,
} from 'src/constants';
import { useReloadPage } from 'src/hooks/useReloadPage';
import PageTitle from 'src/components/PageTitle';
import { BasicLayout, CenteredFullScreenLayout } from 'src/layouts';
import { searchUsers } from 'src/services/unified-db-api';
import { APIClientResponseHTTPError } from 'src/@types/api-client';
import { NotFoundPage } from 'src/pages/NotFound';
import { ServerErrorPage } from 'src/pages/ServerError';
import { Spinner } from 'src/components/Spinner';
import {
    CalculatedRenewalItem,
    DomainModel,
    PriceHistoryModelWithRenewal,
    SubscriptionModel,
    SubscriptionUserModel,
} from 'src/@types/subscription-service-api';
import { prepareSubscriptionUserData } from 'src/services/subscription-formatters';

const UdbSubscriptionManage: FC = () => {
    const [subscription, setSubscription] = useState<SubscriptionModel>();
    const [paymentItems, setPaymentItems] = useState<PriceHistoryModelWithRenewal[]>([]);
    const [usage, setUsage] = useState<CalculatedRenewalItem>();
    const [error, setError] = useState<APIClientResponseHTTPError>();
    const [managersData, setManagersData] = useState<SubscriptionUserModel[]>([]);
    const [usersData, setUsersData] = useState<SubscriptionUserModel[]>([]);
    const [isManagersLoading, setIsManagersLoading] = useState<boolean>(false);
    const [isDomainsLoading, setIsDomainsLoading] = useState<boolean>(false);
    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState<boolean>(false);
    const [isPaymentItemsLoading, setIsPaymentItemsLoading] = useState<boolean>(false);
    const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
    const [domains, setDomains] = useState<DomainModel[]>([]);

    const [paymentsPagination, setPaymentsPagination] = useState<PaginationData>(DEFAULT_PAGINATION_DATA);
    const [managersPagination, setManagersPagination] = useState<PaginationData>(DEFAULT_PAGINATION_DATA);
    const [domainsPagination, setDomainsPagination] = useState<PaginationData>(DEFAULT_PAGINATION_DATA);
    const [usersPagination, setUsersPagination] =
        useState<PaginationData>({ ...DEFAULT_PAGINATION_DATA, pageSize: 15 });

    const { upcomingPriceHistory } = useSelector<RootState, SubscriptionState>(subscriptionSelector);
    const { addMessage } = useSnackbarMessage();
    const { uuid = '' } = useParams<{ uuid: string }>();
    const dispatch = useDispatch();
    const { pageReloadCount } = useReloadPage();
    const canManage = useUserCan(Scope.SUBSCRIPTIONS_SERVICE_WRITE);
    const showUsageSection = (subscription?.status === PaidSubscriptionStatus.ACTIVE
        || subscription?.status === PaidSubscriptionStatus.PAUSED) && usage;

    const updateManagersList = async () => {
        setIsManagersLoading(true);
        return getManagers(uuid, {
            page: managersPagination.currentPage,
            pageSize: managersPagination.pageSize,
        })
            .then(({
                data,
                ...paginationData
            }) => {
                const managerUUIDs = data.map((item) => item.userUUID);
                setManagersPagination(paginationData);
                searchUsers({
                    filterBy: {
                        filters: [{
                            name: 'uuid',
                            value: managerUUIDs,
                            comparison: 'anyOf',
                        }],
                    },
                    pageSize: managersPagination.pageSize,
                }, ['email', 'jobInfo'])
                    .then(({ data: searchUsersData }) => {
                        setManagersData(prepareSubscriptionUserData(searchUsersData, data));
                    });
            })
            .catch(() => addMessage('Failed to load managers data', SnackbarMessageVariants.WARNING))
            .finally(() => {
                setIsManagersLoading(false);
            });
    };

    const getPayments = async () => {
        setIsPaymentItemsLoading(true);
        return getPriceHistory(uuid, {
            page: paymentsPagination.currentPage,
            pageSize: paymentsPagination.pageSize,
        })
            .then(({
                data,
                ...paginationData
            }) => {
                setPaymentItems(data);
                setPaymentsPagination(paginationData);

                if (paginationData.currentPage === 1) {
                    const fetchedCurrentPriceHistory = data.find((item) => item.isCurrent);
                    const fetchedUpcomingPriceHistory = data.find((item) => item.isUpcoming);

                    dispatch(setCurrentPriceHistory(fetchedCurrentPriceHistory));
                    dispatch(setUpcomingPriceHistory(fetchedUpcomingPriceHistory));
                }
            })
            .catch(() => {
                addMessage('Failed to load price history data', SnackbarMessageVariants.WARNING);

                if (paymentsPagination.currentPage === 1) {
                    dispatch(clearCurrentPriceHistory());
                    dispatch(clearUpcomingPriceHistory());
                }
            })
            .finally(() => {
                setIsPaymentItemsLoading(false);
            });
    };

    const getDomains = async () => {
        setIsDomainsLoading(true);
        return getSubscriptionDomains(uuid, {
            page: domainsPagination.currentPage,
            pageSize: domainsPagination.pageSize,
        })
            .then(({
                data,
                ...paginationData
            }) => {
                setDomainsPagination(paginationData);
                setDomains(data);
            })
            .catch(() => addMessage('Failed to load domains data', SnackbarMessageVariants.WARNING))
            .finally(() => setIsDomainsLoading(false));
    };

    const getUsers = async () => {
        setIsUsersLoading(true);
        return getSubscriptionUsers(uuid, {
            page: usersPagination.currentPage,
            pageSize: usersPagination.pageSize,
        })
            .then(({ data, ...paginationData }) => {
                const usersUUIDs = data.map((item) => item.userUUID);
                setUsersPagination(paginationData);
                searchUsers({
                    filterBy: {
                        filters: [{
                            name: 'uuid',
                            value: usersUUIDs,
                            comparison: 'anyOf',
                        }],
                    },
                    pageSize: usersPagination.pageSize,
                }, ['email', 'jobInfo'])
                    .then(({ data: searchUsersData }) => {
                        setUsersData(prepareSubscriptionUserData(searchUsersData, data));
                    });
            })
            .catch(() => addMessage('Failed to load users data', SnackbarMessageVariants.WARNING))
            .finally(() => {
                setIsUsersLoading(false);
            });
    };

    useEffect(() => {
        if (!subscription) {
            return;
        }

        getDomains();
    }, [domainsPagination.pageSize, domainsPagination.currentPage]);

    useEffect(() => {
        if (!subscription) {
            return;
        }

        getPayments();
    }, [paymentsPagination.pageSize, paymentsPagination.currentPage]);

    useEffect(() => {
        if (!subscription) {
            return;
        }

        updateManagersList();
    }, [managersPagination.pageSize, managersPagination.currentPage]);

    useEffect(() => {
        if (!subscription) {
            return;
        }

        getUsers();
    }, [usersPagination.pageSize, usersPagination.currentPage]);

    useEffect(() => {
        setIsSubscriptionLoading(true);

        getSubscription(uuid)
            .then(async (fetchedSubscription) => {
                setSubscription(fetchedSubscription);

                let dataPromises: Array<Promise<void | number | string>> = [];
                if (fetchedSubscription.type === SubscriptionType.ENTERPRISE) {
                    dataPromises = [
                        getDomains(),
                        getUsers(),
                        calculateRenewalIndex(uuid)
                            .then(setUsage)
                            .catch(() => addMessage('Failed to load usage data', SnackbarMessageVariants.WARNING)),
                    ];
                }

                await Promise.all([
                    updateManagersList(),
                    getPayments(),
                    ...dataPromises,
                ]);
            })
            .catch(({ responseError }) => {
                setError(responseError);
                dispatch(clearCurrentPriceHistory());
                dispatch(clearUpcomingPriceHistory());
            })
            .finally(() => {
                setIsSubscriptionLoading(false);
            });
    }, [uuid, pageReloadCount]);

    if (error) {
        return [404, 403].includes(error.data?.status as number) ? <NotFoundPage /> : <ServerErrorPage />;
    }

    if (!subscription) {
        return (
            <CenteredFullScreenLayout>
                <Spinner />
            </CenteredFullScreenLayout>
        );
    }

    return (
        <BasicLayout testId="subscription-manage-uuid-page">
            <Box position="relative">
                <PageTitle
                    title="Manage subscription"
                    marginBottom={{
                        xs: 2.5,
                        sm: 3,
                    }}
                />
                <SubscriptionManageHeader
                    isUdb
                    subscription={subscription}
                    managers={managersData}
                    actionsList={getUdbManageSubscriptionActionsList(
                        subscription,
                        upcomingPriceHistory,
                        canManage,
                    )}
                />
                {showUsageSection && (
                    <UsageSection usage={usage} variant="udb" />
                )}
                <PaymentSection
                    isLoading={isSubscriptionLoading && isPaymentItemsLoading}
                    paginationModel={paymentsPagination}
                    setPagination={setPaymentsPagination}
                    priceHistory={paymentItems}
                    subscription={subscription}
                    variant="udb"
                />
                <ManagerSection
                    hasUserLink
                    withRemoveButton
                    isLoading={isSubscriptionLoading && isManagersLoading}
                    managers={managersData}
                    actionsList={getManagerSectionActionsList(managersData, subscription)}
                    subscription={subscription}
                    updateManagersList={updateManagersList}
                    paginationModel={managersPagination}
                    setPagination={setManagersPagination}
                />
                {subscription.type === SubscriptionType.ENTERPRISE && (
                    <DomainsSection
                        showRemoveButton
                        showDomainActions
                        domains={domains}
                        subscription={subscription}
                        paginationModel={domainsPagination}
                        setPagination={setDomainsPagination}
                        isLoading={isSubscriptionLoading && isDomainsLoading}
                    />
                )}
                {subscription.type === SubscriptionType.ENTERPRISE && (
                    <UserSection
                        hasUserLink
                        users={usersData}
                        isLoading={isSubscriptionLoading && isUsersLoading}
                        paginationModel={usersPagination}
                        setPagination={setUsersPagination}
                    />
                )}
            </Box>
        </BasicLayout>
    );
};

export default UdbSubscriptionManage;
