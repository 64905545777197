import styled from 'styled-components';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const PageDescription = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.lightBlack};
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    max-width: 890px;
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
       font-family: ${({ theme }) => theme.fonts.normal};
       margin-top: -4px;
       font-size: 14px;
       font-weight: 450;
       line-height: normal;
    }
  }
`;

export const StyledFooterText = styled(Typography)`
  && {
    margin-top: 24px;
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.grey};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    max-width: 800px;
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        font-family: ${({ theme }) => theme.fonts.normal};
        margin-top: 16px;
        text-align: left;
        font-weight: 450;
        line-height: normal;
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
    color: ${({ theme }) => theme.palette.blue};
    font-family: ${({ theme }) => theme.fonts.medium};
    text-decoration: none;
    font-size: 14px;
`;
