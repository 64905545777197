import { RequiredKeys } from 'src/@types/common';
import { User } from 'src/@types/unified-db-api';

export const formattedContactInfo = (data: RequiredKeys<User, 'email'>) => {
    return {
        phoneNumber: data.phoneNumber || null,
        email: data.email.email,
        status: data.email.status,
        country: data.customAttributes?.country,
        secondaryEmails: data.secondaryEmails,
    };
};
