import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { DomainItem, DomainName, RemoveIcon, UserCount } from './DomainInfoBox.styles';
import { makePlural } from 'src/services/text-modifier';
import { TextButton } from 'src/components/Buttons';
import type { DomainInfoModel, DomainModel } from 'src/@types/subscription-service-api';

type DomainInfoBoxProps = {
    domain: DomainInfoModel | DomainModel;
    removeDomain?: (domainName: string) => void;
};

const DomainInfoBox: FC<DomainInfoBoxProps> = ({ domain, removeDomain }) => {
    return (
        <DomainItem padding={2} key={domain.domain} container alignItems="center">
            <Grid item xs={removeDomain ? 11 : 12}>
                <DomainName paddingBottom={0.75}>{domain.domain}</DomainName>
                <UserCount>{domain.userCount} {makePlural('user', domain.userCount)}</UserCount>
            </Grid>
            {removeDomain && (
                <Grid item xs={1} display="flex" justifyContent="right">
                    <TextButton data-testid="remove-domain-button" onClick={() => removeDomain(domain.domain)}>
                        <RemoveIcon />
                    </TextButton>
                </Grid>
            )}
        </DomainItem>
    );
};

export default DomainInfoBox;
