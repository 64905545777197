import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { StyledActiveValidatorsItem } from './ActiveValidators.styles';
import { ACTIVE_VALIDATORS } from 'src/services/validators';
import { ReactComponent as TickIcon } from 'src/assets/icons/tick.svg';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross.svg';

const ActiveValidators: FC<{ value: string }> = ({ value }) => {
    return (
        <Grid container data-testid="active-validators" position={{ xs: 'relative', sm: 'absolute' }} marginTop={0.5}>
            {ACTIVE_VALIDATORS.map(({
                label,
                regExp,
                key,
            }) => (
                <StyledActiveValidatorsItem
                    item
                    key={key}
                    display="flex"
                    alignItems="center"
                    data-testid={`${key}-validator`}
                    marginRight={1}
                >
                    {regExp.test(value) ? (
                        <TickIcon data-testid="valid-icon" />
                    ) : (
                        <CrossIcon data-testid="invalid-icon" />
                    )}
                    <p>{label}</p>
                </StyledActiveValidatorsItem>
            ))}
        </Grid>
    );
};

export default ActiveValidators;
