import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    paymentTypeRenderMapping,
    subscriberTypeRenderMapping,
    SubscriptionPaymentType,
    SubscriptionType,
} from 'src/constants';
import PageTitle from 'src/components/PageTitle';
import { SingleModalLayout } from 'src/layouts';
import { Button } from 'src/components/Buttons';

type SuccessMessageProps = {
    paymentType: SubscriptionPaymentType;
    subscriptionType: SubscriptionType;
    createdSubscriptionUUID: string;
    ownerEmail: string;
    reloadPage: () => void;
};

const SuccessMessage: FC<SuccessMessageProps> = ({
    ownerEmail,
    subscriptionType,
    paymentType,
    reloadPage,
    createdSubscriptionUUID,
}) => {
    const navigate = useNavigate();

    return (
        <SingleModalLayout>
            <Stack spacing={{ xs: 2, sm: 2.5 }}>
                <PageTitle
                    paddingBottom={{ xs: 1, sm: 0.5 }}
                    variant="form"
                    title="Subscription created successfully"
                    subtitle={(
                        <>
                            A {paymentTypeRenderMapping[paymentType].toLowerCase()}&nbsp;
                            {subscriberTypeRenderMapping[subscriptionType]} subscription owned by&nbsp;
                            <b>{ownerEmail}</b> has been created successfully.
                        </>
                    )}
                />
                <Button onClick={() => navigate(`/udb/subscriptions/${createdSubscriptionUUID}`)}>Open subscription</Button>
                <Button variant="secondary" onClick={reloadPage}>Create another one</Button>
            </Stack>
        </SingleModalLayout>
    );
};

export default SuccessMessage;
