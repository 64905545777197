import styled from 'styled-components';
import { Box, Grid } from '@mui/material';
import { maxWidthByPageVariant } from 'src/layouts/BasicLayout/BasicLayout.styles';

export const StyledBox = styled(Box)`
    min-height: ${({ theme }) => theme.sizes.contentHeight.md};

    @media(max-width: ${({ theme }) => theme.brakePoints.md}) {
        min-height: ${({ theme }) => theme.sizes.contentHeight.xs};
    }
`;

export const StyledContentGrid = styled(Grid)`
    text-align: left;
    ${maxWidthByPageVariant(false)};
`;
