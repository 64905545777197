import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { User } from 'src/@types/unified-db-api';
import { useSubscriptionManagersData } from 'src/hooks/useSubscriptionManagersData';
import ChangeOwnerForm from 'src/components/Forms/OwnerModalForm/components/ChangeOwnerForm/ChangeOwnerForm';
import { LoaderOverlay } from 'src/components/LoaderOverlay';

export type ChangeOwnerModalProps = {
    subscriptionUUID: string;
    preSelectedUser?: User;
};

const ChangeOwnerModal: FC<ChangeOwnerModalProps> = ({ subscriptionUUID, preSelectedUser }) => {
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const { managers, owner, isLoading, loadNextPage, refresh } = useSubscriptionManagersData(subscriptionUUID);

    useEffect(() => {
        if (!isLoading) {
            setFirstLoad(false);
        }
    }, [isLoading]);

    if (isLoading && firstLoad) {
        return (
            <Box
                data-testid="change-owner-modal-loading"
                position="relative"
                height={44}
                marginBottom={3}
                overflow="hidden"
            >
                <LoaderOverlay />
            </Box>
        );
    }

    return (
        <ChangeOwnerForm
            subscriptionUUID={subscriptionUUID}
            preSelectedUser={preSelectedUser}
            managers={managers}
            refreshData={refresh}
            owner={owner}
            loadNextPage={loadNextPage}
            isLoadingNextPage={isLoading}
        />
    );
};

export default ChangeOwnerModal;
