import styled from 'styled-components';
import { TextButton } from 'src/components/Buttons';

export const StyledTerminateButton = styled(TextButton)`
    &&& {
        color: ${({ theme }) => theme.palette.red};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        
        @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
            font-size: 16px;
        }
    }
`;
