import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { StyledLabeledInput } from './RequestAQuoteForm.styles';
import { LabeledInput } from 'src/components/Inputs';
import PhoneNumberInput from 'src/components/Inputs/PhoneNumberInput';
import { FormWrapper } from 'src/components/Forms/components';
import {
    companyNameValidator,
    domainValidator,
    emailValidator,
    fullNameValidator,
    jobTitleValidator,
    numberOfEmployeesValidator,
    phoneNumberValidator,
    validateSchemaObject,
} from 'src/services/validators';
import {
    COMPANY_NAME_IS_REQUIRED_MESSAGE,
    EMPTY_EMAIL_VALIDATION_ERROR,
    FULL_NAME_IS_REQUIRED_MESSAGE,
    INVALID_NUMBER_OF_EMPLOYEES_MESSAGE,
    INVALID_PRIMARY_EMAIL_DOMAIN_MESSAGE,
    JOB_TITLE_IS_REQUIRED_MESSAGE,
    MAX_FULL_NAME_LENGTH,
} from 'src/constants';
import { RequestAQuoteInfo } from 'src/@types/reques-a-quote';
import { Button } from 'src/components/Buttons';
import { updateRequestAQuoteInfo } from 'src/services/sso-api';
import { useIsSubmitDisabled } from 'src/hooks';
import { prepareRequestAQuoteInfoRequestData } from 'src/services/request-data-formatter';

type RequestAQuoteFormProps = {
    isSubmitting: boolean;
    setIsSubmitting: (value: boolean) => void;
    onSuccess?: () => void;
};

type RequestAQuoteFormValues = Partial<RequestAQuoteInfo> & {
    numberOfEmployees: string;
};

const RequestAQuoteForm: FC<RequestAQuoteFormProps> = ({ isSubmitting, setIsSubmitting, onSuccess }) => {
    const requiredFields: (keyof RequestAQuoteInfo)[] = ['fullName', 'email', 'company', 'jobTitle', 'numberOfEmployees', 'primaryDomain'];
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [phoneNumberIsInvalid, setPhoneNumberIsInvalid] = useState<boolean>(false);
    const [RequestAQuote, setRequestAQuote] = useState<RequestAQuoteInfo>({} as RequestAQuoteInfo);
    const { isSubmitDisabled, onFormChangeHandler, setIsSubmitDisabled } =
      useIsSubmitDisabled<RequestAQuoteInfo>(RequestAQuote, requiredFields);

    const handleFormSubmit = async (data: Partial<RequestAQuoteInfo>): Promise<void> => {
        const requestData = { ...data, phoneNumber: phoneNumber };

        await updateRequestAQuoteInfo(prepareRequestAQuoteInfoRequestData(requestData, RequestAQuote))
            .then((response) => {
                setRequestAQuote(response);
                if (onSuccess) {
                    onSuccess();
                }
            });
    };

    const handleValidation = (newValue: string, valid: boolean) => {
        setPhoneNumberIsInvalid(newValue ? !valid : !phoneNumber);
    };

    return (
        <FormWrapper<Partial<RequestAQuoteInfo>>
            testId="request-a-quote-form"
            onFormChange={onFormChangeHandler}
            isSubmitting={isSubmitting}
            validationSchema={validateSchemaObject({
                fullName: fullNameValidator.required(FULL_NAME_IS_REQUIRED_MESSAGE),
                email: emailValidator.required(EMPTY_EMAIL_VALIDATION_ERROR),
                phoneNumber: phoneNumberValidator.optional(),
                company: companyNameValidator.required(COMPANY_NAME_IS_REQUIRED_MESSAGE),
                jobTitle: jobTitleValidator.required(JOB_TITLE_IS_REQUIRED_MESSAGE),
                numberOfEmployees: numberOfEmployeesValidator.required(INVALID_NUMBER_OF_EMPLOYEES_MESSAGE),
                primaryDomain: domainValidator.required(INVALID_PRIMARY_EMAIL_DOMAIN_MESSAGE),
            })}
            initialValues={{
                fullName: RequestAQuote.fullName || '',
                email: RequestAQuote.email || '',
                phoneNumber: RequestAQuote.phoneNumber || '',
                company: RequestAQuote.company ?? '',
                jobTitle: RequestAQuote.jobTitle ?? '',
                numberOfEmployees: RequestAQuote.numberOfEmployees?.toString() ?? '',
                primaryDomain: RequestAQuote.primaryDomain ?? '',
                message: RequestAQuote.message || '',
            } as RequestAQuoteFormValues}
            onSubmit={(values: Partial<RequestAQuoteInfo>) => {
                setIsSubmitDisabled(true);
                setIsSubmitting(true);
                handleFormSubmit(values)
                    .catch(() => setIsSubmitDisabled(false))
                    .finally(() => setIsSubmitting(false));
            }}
        >
            <Grid container spacing={2.5}>
                <Grid item sm={12} xs={12}>
                    <LabeledInput
                        required
                        testId="full-name-input"
                        name="fullName"
                        label="Full name"
                        placeholder="Enter your full name"
                        inputMaxLength={MAX_FULL_NAME_LENGTH}
                    />
                </Grid>
                <Grid item xs={12} marginTop={{ xs: -2.5, sm: -1 }} /> {/* separator */}
                <Grid item sm={6} xs={12}>
                    <LabeledInput
                        required
                        testId="email-input"
                        disallowSpace
                        name="email"
                        label="Email address"
                        placeholder="Enter your email address"
                    />
                </Grid>
                <Grid paddingLeft={0} item sm={6} xs={12}>
                    <PhoneNumberInput
                        testId="phone-number-input"
                        name="phoneNumber"
                        label="Phone number"
                        placeholder="Phone number"
                        onFocus={() => setPhoneNumberIsInvalid(phoneNumberIsInvalid)}
                        onValidation={handleValidation}
                        onValueChange={(value) => setPhoneNumber(value)}
                    />
                </Grid>
                <Grid item xs={12} marginTop={{ xs: -2.5, sm: -1 }} /> {/* separator */}
                <Grid item sm={6} xs={12}>
                    <LabeledInput
                        required
                        testId="company-input"
                        name="company"
                        label="Company"
                        placeholder="Enter your company name"
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <LabeledInput
                        required
                        testId="job-title-input"
                        name="jobTitle"
                        label="Job title"
                        placeholder="Enter your job title"
                    />
                </Grid>
                <Grid item xs={12} marginTop={{ xs: -2.5, sm: -1 }} /> {/* separator */}
                <Grid item sm={6} xs={12}>
                    <LabeledInput
                        required
                        type="number"
                        testId="number-of-employees-input"
                        name="numberOfEmployees"
                        label="Number of employees"
                        placeholder="Enter number of employees"
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <LabeledInput
                        required
                        testId="primary-email-domain-input"
                        disallowSpace
                        name="primaryDomain"
                        label="Primary email domain"
                        placeholder="e.g. domain.com"
                    />
                </Grid>
                <Grid item xs={12} marginTop={{ xs: -2.5, sm: -1 }} /> {/* separator */}
                <Grid item sm={12} xs={12}>
                    <StyledLabeledInput
                        rows={3}
                        multiline
                        testId="message-input"
                        name="message"
                        label="Message"
                    />
                </Grid>
                <Grid item xs={12} marginTop={{ xs: -1, sm: -0.5 }} /> {/* separator */}
                <Grid item sm={12} xs={12}>
                    <Button
                        data-testid="request-a-quote-submit-button"
                        fullWidth
                        disabled={isSubmitDisabled || phoneNumberIsInvalid}
                        type="submit"
                    >
                      Submit
                    </Button>
                </Grid>
                <Grid item xs={12} marginTop={{ xs: -2.5, sm: -2 }} /> {/* separator */}
            </Grid>
        </FormWrapper>
    );
};

export default RequestAQuoteForm;
