import {
    CONTAINS_CONDITIONS,
    EQUALS_CONDITIONS,
    HAS_WP_PASSWORD_CONDITIONS,
    HAS_WP_PASSWORD_OPTIONS,
    MULTI_SELECT_CONDITIONS,
    ROLE_OPTIONS,
    STATUS_OPTIONS,
    TFA_ENABLED_CONDITIONS,
    TFA_ENABLED_OPTIONS,
    TIME_CONDITIONS,
} from 'src/pages/Credentials/constants';
import { SchemaType } from 'src/@types/tool-bar';
import { BooleanValues, Status, ToolBarConditionTypes, ToolBarModelTypes, UserRoles } from 'src/constants';
import {
    dateValidator,
    multiSelectValidator,
    stringValidator,
} from 'src/components/ToolBar/services/tool-bar-validators';

export const filteringSchema: SchemaType = {
    modelType: ToolBarModelTypes.FILTERING,
    fields: {
        id: {
            order: 1,
            label: 'ID',
            value: 'id',
            conditionType: ToolBarConditionTypes.NUMBER_FIELD,
            conditionOptions: EQUALS_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        uuid: {
            order: 2,
            label: 'UUID',
            value: 'uuid',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: CONTAINS_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        email: {
            order: 3,
            label: 'Email address',
            value: 'email',
            conditionType: ToolBarConditionTypes.TEXT_FIELD,
            conditionOptions: CONTAINS_CONDITIONS,
            validateConditionValue: stringValidator,
        },
        status: {
            order: 4,
            label: 'Status',
            value: 'status',
            conditionType: ToolBarConditionTypes.MULTI_SELECT,
            conditionOptions: MULTI_SELECT_CONDITIONS,
            valueOptions: STATUS_OPTIONS,
            validateConditionValue: multiSelectValidator(Object.values(Status), false),
        },
        role: {
            order: 5,
            label: 'Role',
            value: 'role',
            conditionType: ToolBarConditionTypes.MULTI_SELECT,
            conditionOptions: MULTI_SELECT_CONDITIONS,
            valueOptions: ROLE_OPTIONS,
            validateConditionValue: multiSelectValidator(Object.values(UserRoles), false),
        },
        hasWPPassword: {
            order: 6,
            label: 'WP password',
            value: 'hasWPPassword',
            conditionType: ToolBarConditionTypes.SELECT,
            conditionOptions: HAS_WP_PASSWORD_CONDITIONS,
            valueOptions: HAS_WP_PASSWORD_OPTIONS,
            validateConditionValue: multiSelectValidator(Object.values(BooleanValues)),
        },
        tfaEnabled: {
            order: 7,
            label: '2FA',
            value: 'tfaEnabled',
            conditionType: ToolBarConditionTypes.SELECT,
            conditionOptions: TFA_ENABLED_CONDITIONS,
            valueOptions: TFA_ENABLED_OPTIONS,
            validateConditionValue: multiSelectValidator(Object.values(BooleanValues)),
        },
        createdAt: {
            order: 8,
            label: 'Created at',
            value: 'createdAt',
            conditionType: ToolBarConditionTypes.DATE,
            conditionOptions: TIME_CONDITIONS,
            validateConditionValue: dateValidator,
        },
        updatedAt: {
            order: 9,
            label: 'Updated at',
            value: 'updatedAt',
            conditionType: ToolBarConditionTypes.DATE,
            conditionOptions: TIME_CONDITIONS,
            validateConditionValue: dateValidator,
        },
    },
};
