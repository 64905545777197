import styled from 'styled-components';
import { Typography } from '@mui/material';
import { generalTableCellStyles } from 'src/components/TableSection/TableSection.styles';

export const StyledHeaderAmount = styled(Typography)`
    &&{
        display: inline;
        font-size: 16px;
        font-weight: 450;
        font-style: normal;
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.normal};

        @media(min-width: ${({ theme }) => theme.brakePoints.md}) {
            font-size: 18px;
        }
    }
`;

export const StyledDomainName = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};

        ${generalTableCellStyles}
    }
`;

export const StyledUserCount = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightGrey};

        ${generalTableCellStyles}
    }
`;
