import React, { FocusEventHandler, useRef } from 'react';
import { type TextFieldProps } from '@mui/material';
import { StyledTextField } from 'src/components/Inputs/StyledInput.styles';

type TextInputPropsType = {
    name?: string;
    placeholder?: string;
    testId?: string;
    labelIcon?: JSX.Element;
    disabled?: boolean;
    hasActiveValidator?: boolean;
    className?: string;
    autoSelect?: boolean;
} & TextFieldProps;

export const TextInput = ({
    testId = 'text-input',
    placeholder = 'Enter text...',
    autoSelect,
    onFocus,
    name = 'text-input',
    ...restInputProps
}: TextInputPropsType) => {
    const inputRef = useRef<HTMLInputElement>();

    const handleFocus: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        if (autoSelect) {
            inputRef.current?.select();
        }

        onFocus?.(event);
    };

    return (
        <StyledTextField
            inputRef={inputRef}
            onFocus={handleFocus}
            name={name}
            data-testid={testId}
            placeholder={placeholder}
            {...restInputProps as TextFieldProps}
        />
    );
};
