import React, { FC } from 'react';
import { Box, Grid, GridProps } from '@mui/material';
import { parseDomain, parseTwitterHandle } from 'src/services/parser';
import { useGeneralModal } from 'src/hooks';
import { TextButton } from 'src/components/Buttons';
import { CustomAttributes } from 'src/@types/unified-db-api';
import { StyledHeaderName } from 'src/components/TableSection/HeaderSection/HeaderSection.styles';
import { StyledDomain, StyledSocialContainer } from 'src/pages/udb/ManageUser/ManageUser.styles';
import Line from 'src/components/Line';
import { PageSection } from 'src/components/PageSection';
import { ReactComponent as StripeLinkIcon } from 'src/assets/icons/stripe-link-icon.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit-icon.svg';
import { GeneralModalKey, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';

export type GridColumnProps = Record<'website' | 'linkedIn' | 'twitter', GridProps>;

const gridColumnParams: GridColumnProps = {
    website: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap-reverse',
        gap: { xs: 0, md: 1 },
        minHeight: { xs: '40px', md: '66px' },
    },
    linkedIn: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
        minHeight: { xs: '40px', md: '66px' },
    },
    twitter: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
        minHeight: { xs: '40px', md: '66px' },
    },
};

const gridColumnTitles = {
    website: 'Website',
    linkedIn: 'LinkedIn',
    twitter: 'X (Twitter)',
};

const SocialMediaSection: FC<{ uuid: string, socialMedia?: CustomAttributes, isEditable: boolean }> = ({
    socialMedia,
    isEditable,
    uuid,
}) => {
    const { openModal } = useGeneralModal();

    const handleEditClick = () => {
        openModal({
            key: GeneralModalKey.editSocialMedia,
            extraProps: {
                website: socialMedia?.website,
                socialX: socialMedia?.socialX,
                socialLinkedIn: socialMedia?.socialLinkedIn,
                uuid,
            },
        });
    };

    return (
        <PageSection
            sectionPaddingBottom={3}
            header="Social media"
            headerChildren={
                isEditable ? (
                    <TextButton data-testid="edit-btn" onClick={handleEditClick}>
                        <EditIcon />
                    </TextButton>
                ) : null
            }
        >
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <Grid item {...gridColumnParams.website}>
                    <StyledHeaderName>{gridColumnTitles.website}</StyledHeaderName>
                    {socialMedia?.website && (
                        <StyledSocialContainer href={socialMedia?.website} target="_blank">
                            <StyledDomain>{parseDomain(socialMedia.website)}</StyledDomain>
                            <Box paddingRight={{ xs: 0, md: 2 }} display="flex">
                                <StripeLinkIcon />
                            </Box>
                        </StyledSocialContainer>)}
                </Grid>
                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                    <Line />
                </Grid>
                <Grid item {...gridColumnParams.linkedIn}>
                    <StyledHeaderName>{gridColumnTitles.linkedIn}</StyledHeaderName>
                    {socialMedia?.socialLinkedIn && (
                        <StyledSocialContainer href={socialMedia?.socialLinkedIn} target="_blank">
                            {gridColumnTitles.linkedIn}
                            <StripeLinkIcon />
                        </StyledSocialContainer>
                    )}
                </Grid>
                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                    <Line />
                </Grid>
                <Grid item {...gridColumnParams.twitter}>
                    <StyledHeaderName>{gridColumnTitles.twitter}</StyledHeaderName>
                    {socialMedia?.socialX && (
                        <StyledSocialContainer href={socialMedia?.socialX} target="_blank">
                            {parseTwitterHandle(socialMedia.socialX)}
                            <StripeLinkIcon />
                        </StyledSocialContainer>
                    )}
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default SocialMediaSection;
