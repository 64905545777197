import { Field, FieldProps, FormikProps } from 'formik';
import React, { FC, SyntheticEvent, useState } from 'react';
import { TooltipProps } from '@mui/material/Tooltip';
import Checkbox from './Checkbox';
import { ChangeAllCheckboxLabel } from 'src/components/Label';

type ChangeAllArguments = SyntheticEvent & { target: { checked?: boolean } };

type ChangeAllLabeledCheckboxProps = {
    name: string;
    label: string;
    testId?: string;
    unCheckAll?: boolean;
    shouldCacheValues?: boolean;
    disabled?: boolean;
    itemsKey?: string;
    onFormChangeHandler?: (event: SyntheticEvent, values?: Record<string, boolean>) => void;
    tooltipPlacement?: TooltipProps['placement'];
    tooltipTitle?: TooltipProps['title'];
};

const ChangeAllLabeledCheckbox: FC<ChangeAllLabeledCheckboxProps> = ({
    name,
    label,
    unCheckAll,
    disabled,
    onFormChangeHandler,
    shouldCacheValues,
    tooltipTitle,
    tooltipPlacement,
    testId = 'change-all-checkbox',
    itemsKey,
}) => {
    const [cachedValues, setCachedValues] = useState<Record<string, boolean>>({});

    const handleChangeAll = (form: FormikProps<Record<string, boolean>>) => (event: ChangeAllArguments) => {
        const { checked } = event.target;

        const values = Object.keys(form.values).reduce(
            (preparedValues, key): Record<string, boolean> => {
                if (key.split('_')[1] === itemsKey || unCheckAll) {
                    const value = shouldCacheValues ? cachedValues[key] : checked;

                    return {
                        ...preparedValues,
                        [key]: checked ? !unCheckAll : value,
                    };
                }

                return {
                    ...preparedValues,
                    [key]: form.values[key],
                };
            },
            {},
        );

        if (checked && shouldCacheValues) {
            setCachedValues(form.values);
        }

        form.setValues(checked || !shouldCacheValues ? { ...values, [name]: checked } : cachedValues)
            .then(() => onFormChangeHandler?.(event, { ...values, [name]: checked }));
    };

    const shouldBeIndeterminate = (formValues: Record<string, boolean>): boolean => {
        const keyValues = Object.entries(formValues)
            .filter(([key]) => key.split('_')[1] === itemsKey && key !== name)
            .map(([, value]) => value);

        return !(keyValues.every((value) => value) || keyValues.every((value) => !value));
    };

    return (
        <Field name={name} type="checkbox">
            {({ field, form }: FieldProps) => (
                <>
                    <Checkbox
                        name={field.name}
                        value={field.value}
                        data-testid={testId}
                        disabled={disabled}
                        onClick={handleChangeAll(form)}
                        checked={field.checked}
                        tooltipPlacement={tooltipPlacement}
                        tooltipTitle={tooltipTitle}
                        indeterminate={shouldBeIndeterminate(form.values)}
                    />
                    <ChangeAllCheckboxLabel
                        data-testid={`${testId}-label`}
                        onClick={() => handleChangeAll(form)({
                            target: {
                                checked: !field.value,
                            },
                        } as ChangeAllArguments)}
                    >
                        {label}
                    </ChangeAllCheckboxLabel>
                </>
            )}
        </Field>
    );
};

export default ChangeAllLabeledCheckbox;
