import React, { FC } from 'react';
import { Box } from '@mui/material';
import { GRID_DEFAULT_TOOLTIP_PROPS } from 'src/constants';
import StyledTooltip from 'src/components/Tooltip';

interface HeaderWithTooltipProps {
    headerName: string;
}

const DefaultHeaderRenderer: FC<HeaderWithTooltipProps> = ({ headerName }) => {
    return (
        <StyledTooltip
            title={headerName}
            {...GRID_DEFAULT_TOOLTIP_PROPS}
        >
            <Box className="MuiDataGrid-columnHeaderTitle">
                {headerName}
            </Box>
        </StyledTooltip>
    );
};

export const defaultHeaderRenderer = (headerName: string) => function rendererWrapper() {
    return <DefaultHeaderRenderer headerName={headerName} />;
};
