import { CaseReducer, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GENERAL_VERTICAL_ID, SubscriberType } from 'src/constants';
import { CurrentUserState, SubscriberSelectorModel } from 'src/@types/redux';
import type { SignUpResponse, UserActiveSubscriptions, UserPayload } from 'src/@types/sso-api';
import type { RootState } from 'src/redux/root-reducer';

const initialState: CurrentUserState = {
    currentUser: null,
    tfaAccessCode: undefined,
    activeSubscriptions: undefined,
    isUserDataLoaded: false,
    signUpData: undefined,
};

export { initialState as userInitialState };

export const userSlice = createSlice<CurrentUserState, {
    setCurrentUser: CaseReducer<CurrentUserState, PayloadAction<UserPayload>>;
    clearCurrentUser: CaseReducer<CurrentUserState>;
    setTFAAccessCode: CaseReducer<CurrentUserState, PayloadAction<string>>;
    clearTFAAccessCode: CaseReducer<CurrentUserState>;
    setActiveSubscriptions: CaseReducer<CurrentUserState, PayloadAction<Array<UserActiveSubscriptions>>>;
    clearActiveSubscriptions: CaseReducer<CurrentUserState>;
    setIsUserDataLoaded: CaseReducer<CurrentUserState, PayloadAction<boolean>>;
    setSignUpData: CaseReducer<CurrentUserState, PayloadAction<Pick<SignUpResponse, 'subscriberTypes'>>>;
}>({
    name: 'currentUser',
    initialState,
    reducers: {
        setCurrentUser: ({ currentUser, ...restState }, { payload }) => ({
            ...(currentUser ? restState : initialState),
            currentUser: payload,
        }),
        clearCurrentUser: () => initialState,
        setTFAAccessCode: (state, { payload }) => ({
            ...state,
            tfaAccessCode: payload,
        }),
        clearTFAAccessCode: (state) => ({
            ...state,
            tfaAccessCode: undefined,
        }),
        setActiveSubscriptions: (state, { payload }) => ({
            ...state,
            activeSubscriptions: payload,
        }),
        clearActiveSubscriptions: (state) => ({
            ...state,
            activeSubscriptions: undefined,
        }),
        setIsUserDataLoaded: (state, { payload }) => ({
            ...state,
            isUserDataLoaded: payload,
        }),
        setSignUpData: (state, { payload }) => ({
            ...state,
            signUpData: payload,
        }),
    },
});

export const {
    setCurrentUser,
    clearCurrentUser,
    setTFAAccessCode,
    clearTFAAccessCode,
    setActiveSubscriptions,
    clearActiveSubscriptions,
    setIsUserDataLoaded,
    setSignUpData,
} = userSlice.actions;

export const userSelector = (state: RootState): CurrentUserState => state[userSlice.name];

export const subscriberSelector = createSelector(
    userSelector,
    (userState: CurrentUserState): SubscriberSelectorModel => userState?.activeSubscriptions
        ?.find(({ verticalId }) => verticalId === GENERAL_VERTICAL_ID) ?? { type: SubscriberType.basic },
);
