import React, { FC } from 'react';
import { Box, Grid } from '@mui/material';
import { StyledDomainName, StyledUserCount } from './DomainsSection.styles';
import { GridColumnProps } from './DomainsSection';
import { Scope, useUserCan } from 'src/hooks/useUserCan';
import { StyledGridItem } from 'src/components/CopyIcon/CopyIcon.styles';
import { CopyIcon } from 'src/components/CopyIcon';
import { makePlural } from 'src/services/text-modifier';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import { removeDomains } from 'src/services/subscription-service-api';
import { useSnackbarMessage } from 'src/hooks';
import { useConfirmationModal } from 'src/hooks/useConfirmationModal';
import {
    DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE,
    editableSubscriptionStatuses,
    GeneralModalKey,
    PaidSubscriptionStatus,
    SnackbarMessageVariants,
    TABLE_SECTION_DEFAULT_COLUMNS,
} from 'src/constants';
import StyledTooltip from 'src/components/Tooltip';
import type { DomainModel, SubscriptionModel } from 'src/@types/subscription-service-api';
import { ReactComponent as RedCrossIcon } from 'src/assets/icons/red-cross-icon.svg';

type DomainItemProps = {
    domain: DomainModel;
    gridColumnParams: GridColumnProps;
    domainCount: number;
    subscription: SubscriptionModel;
    refreshDomains: () => void;
    showRemoveButton?: boolean;
};

export const getRemoveButtonTooltipText = (status: PaidSubscriptionStatus, isRemoveDisabled: boolean) => {
    if (!editableSubscriptionStatuses.includes(status)) {
        return DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE;
    }

    if (isRemoveDisabled) {
        return 'Removal of the last domain is not allowed';
    }

    return 'Remove domain';
};

const DomainItem: FC<DomainItemProps> = ({
    domain,
    gridColumnParams,
    domainCount,
    subscription,
    refreshDomains,
    showRemoveButton,
}) => {
    const { addMessage } = useSnackbarMessage();
    const canManage = useUserCan(Scope.SUBSCRIPTIONS_SERVICE_WRITE);

    const { openModal } = useConfirmationModal({
        onConfirm: () => {
            return removeDomains(subscription.uuid, { domains: [domain.domain] })
                .then(() => {
                    addMessage(
                        `${domain.domain} has been successfully removed from this subscription`,
                        SnackbarMessageVariants.SUCCESS,
                    );
                    return refreshDomains();
                })
                .catch(() => {
                    addMessage('Failed to remove domain', SnackbarMessageVariants.ERROR);
                });
        },
    });

    const handleRemoveClick = () => {
        openModal({
            key: GeneralModalKey.confirmationModal,
            title: 'Remove domain',
            extraProps: {
                text: `Are you sure want to remove <b>${domain.domain}</b> from this subscription?`,
                confirmButtonText: 'Remove',
                mobileDirection: 'row',
            },
        });
    };

    const isLastDomainWithNoDraftStatus = domainCount <= 1 && subscription.status !== PaidSubscriptionStatus.DRAFT;
    const isRemoveDisabled = isLastDomainWithNoDraftStatus ||
        !editableSubscriptionStatuses.includes(subscription.status);

    return (
        <Grid position="relative" container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
            <StyledGridItem item {...gridColumnParams.domain} gap={1}>
                <StyledDomainName>{domain.domain}</StyledDomainName>
                <CopyIcon content={domain.domain} hiddenIcon />
            </StyledGridItem>
            <Grid item {...gridColumnParams.userCount}>
                <StyledUserCount display="inline">{domain.userCount}</StyledUserCount>
                <StyledUserCount display={{ xs: 'inline', md: 'none' }}>
                    &nbsp;{makePlural('user', domain.userCount)}
                </StyledUserCount>
            </Grid>
            <Grid marginTop={{ xs: 0.5, md: 0 }} item {...gridColumnParams.icon}>
                {showRemoveButton && canManage && (
                    <StyledTooltip
                        title={getRemoveButtonTooltipText(subscription.status, isRemoveDisabled)}
                        data-max-width="unset"
                        arrow
                    >
                        <Box>
                            <ActionButton
                                data-attribute-to-change="stroke"
                                data-variant={ActionButtonVariant.RED}
                                data-testid="remove-icon"
                                onClick={handleRemoveClick}
                                disabled={isRemoveDisabled}
                            >
                                <RedCrossIcon />
                            </ActionButton>
                        </Box>
                    </StyledTooltip>
                )}
            </Grid>
        </Grid>
    );
};

export default DomainItem;
