import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import {
    ActiveSubscriptionHeaderWrapper,
    DropdownButton,
    DropdownContainer,
    DropdownContent,
    DropdownNavButton,
    DropdownWrapper,
} from './DesktopMenu.styles';
import { MenuItemDivider } from 'src/layouts/BasicLayout/components/Header/components/MenuItemDivider';
import { DesktopSubMenu } from 'src/layouts/BasicLayout/components/Header/components/DesktopSubMenu';
import { HeaderMenuItem } from 'src/layouts/BasicLayout/components/Header/components/HeaderMenuItem';
import { userSelector } from 'src/redux/slices';
import {
    DropdownItemType,
    getMenuLinks,
    isDivider,
    isDropdownItem,
    isNotDivider,
    LinkItemType,
} from 'src/services/header-menu-helper';
import { CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';
import { ReactComponent as DropDownIcon } from 'src/assets/icons/expand-icon.svg';
import { ReactComponent as ProfileIcon } from 'src/assets/icons/profile-icon.svg';
import { ActiveSubscriptionHeader } from 'src/components/ActiveSubscriptionHeader';

const DesktopMenu: FC = () => {
    const currentUserState = useSelector<RootState, CurrentUserState>(userSelector);

    const allowedMenuLinks = getMenuLinks(currentUserState);

    const headerLinks: ReactElement[] = allowedMenuLinks
        .filter(isNotDivider)
        .map((menuItem, index) => {
            if (!isDropdownItem(menuItem)) {
                return (
                    <DropdownNavButton data-testid={`menu-link-${index}`} key={`menu-item-${index}`} to={menuItem.link}>
                        {menuItem.label}
                    </DropdownNavButton>
                );
            }

            const childrenItems = menuItem.children
                .map((subItem, subIndex) => {
                    if (isDropdownItem(subItem)) {
                        return (
                            <DesktopSubMenu
                                key={`menu-item-${index}-${subIndex}`}
                                menuItem={subItem as DropdownItemType}
                                index={`${index}-${subIndex}`}
                            />
                        );
                    }

                    if (isDivider(subItem)) {
                        return <MenuItemDivider key={`menu-item-${index}-${subIndex}`} testId={`menu-item-${index}-${subIndex}`} />;
                    }

                    return <HeaderMenuItem key={`menu-item-${index}-${subIndex}`} item={subItem as LinkItemType} />;
                });

            return (
                <DropdownContainer key={`menu-item-${index}`} data-testid={`menu-item-${index}`}>
                    <Box display="flex" alignItems="center" height="100%" paddingX={2.5}>
                        {menuItem.label ? (
                            <DropdownButton>
                                {menuItem.label}
                                <DropDownIcon width={12} height={12} />
                            </DropdownButton>
                        ) : (<ProfileIcon width={40} height={40} />)}
                    </Box>
                    <DropdownWrapper data-testid={`menu-item-${index}-dropdown`} pb={menuItem.label ? 0 : 0.5}>
                        {!menuItem.label && (
                            <ActiveSubscriptionHeaderWrapper>
                                <ActiveSubscriptionHeader />
                            </ActiveSubscriptionHeaderWrapper>
                        )}
                        <DropdownContent minWidth={menuItem.label ? 180 : 250} pb={0.5} pt={0.5}>
                            {childrenItems}
                        </DropdownContent>
                    </DropdownWrapper>
                </DropdownContainer>
            );
        });

    return (
        <>
            {headerLinks}
        </>
    );
};

export default DesktopMenu;
