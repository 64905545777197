import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import {
    DropdownContent,
    DropdownWrapper,
    NestedDropdownContainer,
    NestedDropdownNavButton,
} from 'src/layouts/BasicLayout/components/Header/components/DesktopMenu/DesktopMenu.styles';
import { HeaderMenuItem } from 'src/layouts/BasicLayout/components/Header/components/HeaderMenuItem';
import { DropdownItemType, LinkItemType } from 'src/services/header-menu-helper';
import { ReactComponent as DropDownIcon } from 'src/assets/icons/expand-icon.svg';
import { PageVariant } from 'src/layouts/BasicLayout/BasicLayout';

type DesktopSubMenuProps = {
    menuItem: DropdownItemType;
    index: string;
    pageVariant?: PageVariant;
};

const DesktopSubMenu: FC<DesktopSubMenuProps> = ({ menuItem, index }) => {
    const [position, setPosition] = useState<'left' | 'right'>('left');

    const containerRef = useRef<HTMLDivElement>(null);

    const childrenItems = menuItem.children
        .map((subItem, subIndex) => {
            return (
                <HeaderMenuItem key={`menu-sub-item-${index}-${subIndex}`} item={subItem as LinkItemType} />
            );
        });

    const makeDropdownPosition = () => {
        if (containerRef.current) {
            const { right, left } = containerRef.current.getBoundingClientRect();

            setPosition(window.innerWidth - right < (right - left) + 40 ? 'left' : 'right');
        }
    };

    useLayoutEffect(() => {
        window.addEventListener('resize', makeDropdownPosition);
        makeDropdownPosition();
        return () => window.removeEventListener('resize', makeDropdownPosition);
    }, []);

    return (
        <NestedDropdownContainer
            key={`menu-sub-item-${index}`}
            data-testid={`menu-sub-item-${index}`}
            data-position={position}
            ref={containerRef}
            onMouseEnter={() => {
                makeDropdownPosition();
            }}
        >
            <Box display="flex" alignItems="center" height="100%" paddingX={2.5}>
                <NestedDropdownNavButton as="div">
                    {menuItem.label}
                    <DropDownIcon height={12} width={12} />
                </NestedDropdownNavButton>
            </Box>
            <DropdownWrapper data-testid={`menu-sub-item-${index}-dropdown`}>
                <DropdownContent minWidth={menuItem.label ? 200 : 250} pb={0.5} pt={0.5}>
                    {childrenItems}
                </DropdownContent>
            </DropdownWrapper>
        </NestedDropdownContainer>
    );
};

export default DesktopSubMenu;
