import {
    DateCalendar,
    DatePickerProps as MuiDatePickerProps,
    DateTimePickerProps,
    LocalizationProvider,
    TimeClock,
} from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, IconButton, type InputProps } from '@mui/material';
import {
    ClickAwayListenerWrapper,
    DatePickerWrapper,
    SelectedDate,
    StyledDatePickerLayout,
    StyledInputAdornment,
    StyledLabel,
    TabsContainer,
} from './DatePicker.styles';
import { Overlay } from 'src/components/ToolBar/ToolBar';
import { ReactComponent as DatePickerIcon } from 'src/assets/icons/date-picker-icon.svg';
import { TabBar } from 'src/components/TabBar';
import { TextInput } from 'src/components/Inputs/TextInput';
import { Button } from 'src/components/Buttons';
import Line from 'src/components/Line';
import {
    datePickerDateFormat,
    datePickerDateTimeFormat,
    datePickerDateTimeWithSecondsFormat,
    DatePickerTypes,
    getDatePickerType,
    getPreDefinedType,
} from 'src/services/date-picker-helpers';

export type DatePickerProps = (MuiDatePickerProps<Dayjs> | DateTimePickerProps<Dayjs>) & {
    initialValue?: string | null;
    handleDateChange: (date: string | undefined) => void;
    dateOnly?: boolean;
    dateTimeOnly?: boolean;
    placeholder?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    isActive?: boolean;
    testId?: string;
    inputProps?: Partial<InputProps>;
    position?: 'fixed' | 'absolute';
    shouldDisableDate?: (date: dayjs.Dayjs) => boolean;
    withSeconds?: boolean;
};

export const DatePicker = ({
    dateOnly,
    dateTimeOnly,
    handleDateChange,
    initialValue,
    maxDate,
    minDate,
    placeholder,
    onFocus,
    onBlur,
    isActive,
    testId,
    inputProps,
    position = 'absolute',
    shouldDisableDate,
    withSeconds,
}: DatePickerProps) => {
    const calendarPaddingX = 2.5;
    const [value, setValue] = useState<Dayjs | null>(initialValue ? dayjs(initialValue) : null);
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const preDefinedType = getPreDefinedType(dateOnly, dateTimeOnly);
    const initialType = getDatePickerType(initialValue);

    const [selectedType, setSelectedType] = useState<DatePickerTypes>(initialType);

    const hasDateOnly = (preDefinedType ?? selectedType) === DatePickerTypes.DATE;
    const dateTimeFormat = withSeconds ? datePickerDateTimeWithSecondsFormat : datePickerDateTimeFormat;
    const valueFormat = hasDateOnly ? datePickerDateFormat : dateTimeFormat;
    const preparedTestId = testId ?? 'date-picker';

    const isValueEqualInitial = () => {
        const stringValue = value?.format(valueFormat);
        const initialStringValue = dayjs(initialValue).format(valueFormat);

        return initialStringValue === stringValue;
    };

    const handleTypeChange = (type: string | number) => {
        setSelectedType(type as DatePickerTypes);
    };

    const handleClickAway = () => {
        setIsOpened(false);
        setValue(initialValue ? dayjs(initialValue) : null);
    };

    const handleOpenPicker = () => {
        setIsOpened(true);
    };

    const handleConfirm = () => {
        const preparedValue = value
            ? dayjs(value ?? undefined).format(valueFormat)
            : '';

        setIsOpened(false);
        handleDateChange(preparedValue);
    };

    const openPickerIcon = () => (
        <StyledInputAdornment
            onFocus={onFocus}
            onBlur={onBlur}
            position="end"
        >
            <IconButton data-testid={`${preparedTestId}-open-button`}>
                <DatePickerIcon />
            </IconButton>
        </StyledInputAdornment>
    );

    return (
        <>
            {isOpened && <ClickAwayListenerWrapper data-testid="click-away-listener" onClick={handleClickAway} />}
            <DatePickerWrapper data-testid={`${preparedTestId}-container`}>
                <TextInput
                    className={isActive ? 'focused' : undefined}
                    onClick={handleOpenPicker}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={initialValue ? dayjs(initialValue).format(valueFormat) : ''}
                    placeholder={placeholder ?? (hasDateOnly ? 'Select date...' : 'Select date and time...')}
                    InputProps={{
                        ...inputProps,
                        readOnly: true,
                        endAdornment: openPickerIcon(),
                        inputProps: {
                            'data-testid': `${preparedTestId}-input`,
                        },
                    }}
                />
                {isOpened && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Overlay color-variant="darker" onClick={handleClickAway} />
                        <StyledDatePickerLayout data-tab-bar={!preDefinedType} position={position}>
                            <Box
                                onFocus={onFocus}
                                onBlur={onBlur}
                                paddingX={calendarPaddingX}
                            >
                                {!preDefinedType && (
                                    <TabsContainer padding={0.5}>
                                        <TabBar
                                            onChange={handleTypeChange}
                                            initialValue={selectedType}
                                            items={[
                                                {
                                                    label: 'Date',
                                                    value: DatePickerTypes.DATE,
                                                },
                                                {
                                                    label: 'Date and Time',
                                                    value: DatePickerTypes.DATE_AND_TIME,
                                                },
                                            ]}
                                        />
                                    </TabsContainer>
                                )}
                                <DateCalendar
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    value={value}
                                    onChange={setValue}
                                    data-testid={`${preparedTestId}-calendar`}
                                    slotProps={{
                                        switchViewButton: {
                                            'data-testid': `${preparedTestId}-switch-view-button`,
                                        },
                                        leftArrowIcon: {
                                            'data-testid': `${preparedTestId}-previous-arrow-icon`,
                                        },
                                        rightArrowIcon: {
                                            'data-testid': `${preparedTestId}-next-arrow-icon`,
                                        },
                                    } as DatePickerProps['slotProps']}
                                    shouldDisableDate={shouldDisableDate}
                                />
                            </Box>
                            {!hasDateOnly && (
                                <>
                                    <Line />
                                    <Box paddingX={calendarPaddingX}>
                                        <TimeClock
                                            ampm={false}
                                            onChange={setValue}
                                            value={value}
                                            showViewSwitcher={typeof value?.hour() === 'number'}
                                            views={withSeconds ? ['hours', 'minutes', 'seconds'] : ['hours', 'minutes']}
                                            data-testid="date-picker-time-clock"
                                        />
                                    </Box>
                                </>
                            )}
                            {value && (
                                <Box>
                                    <Line />
                                    <StyledLabel paddingY={1.5}>
                                        Selected {hasDateOnly ? 'date' : 'date & time'}&nbsp;
                                        <SelectedDate>
                                            {value?.format(valueFormat)}
                                        </SelectedDate>
                                    </StyledLabel>
                                </Box>
                            )}
                            <Box paddingX={calendarPaddingX}>
                                <Button
                                    fullWidth
                                    data-testid={`${preparedTestId}-confirm-button`}
                                    onClick={handleConfirm}
                                    disabled={!value || isValueEqualInitial() }
                                >
                                    Confirm
                                </Button>
                            </Box>
                        </StyledDatePickerLayout>
                    </LocalizationProvider>
                )}
            </DatePickerWrapper>
        </>
    );
};
