import { byNumericId } from './sorter';
import { SubscriberType, UDBNewsletterCampaignSegment, UDBNewsletterChannel } from 'src/constants';
import {
    ProfileEmailDeliveryPreferences,
    ProfileEmailDeliveryVertical,
    SubscriberTypeModel,
} from 'src/@types/sso-api';
import { UDBNewsletterExpanded, User, UserVertical } from 'src/@types/unified-db-api';

export type NewsletterDifference = {
    name: string;
    id: number;
    chanel: UDBNewsletterChannel;
    value: boolean;
};

const segmentBySubscriberTypeMap:
Record<SubscriberType, UDBNewsletterCampaignSegment> = {
    [SubscriberType.basic]: UDBNewsletterCampaignSegment.BASIC,
    [SubscriberType.enterprise]: UDBNewsletterCampaignSegment.ENTERPRISE,
    [SubscriberType.insider]: UDBNewsletterCampaignSegment.INSIDER,
};

export const makeDeliveryPreferences = (
    newsletterList: UDBNewsletterExpanded[],
    user: User,
    userTypeByVertical: SubscriberTypeModel[],
): ProfileEmailDeliveryPreferences => {
    const sampleIssueTypeByVerticalIdMap = userTypeByVertical.reduce<Record<number, UDBNewsletterCampaignSegment>>(
        (preparedUserTypeMap, { type, verticalId }) => ({
            ...preparedUserTypeMap,
            [verticalId]: segmentBySubscriberTypeMap[type],
        }),
        {},
    );

    const preferences: ProfileEmailDeliveryPreferences = {
        uuid: user.uuid,
        email: user.email.email,
        phoneNumber: user.phoneNumber?.phoneNumber,
        verticals: [],
    };

    const userVerticals = user.userVerticals?.reduce<Record<UserVertical['verticalId'], UserVertical>>(
        (userVerticalMapping, userVertical) => {
            userVerticalMapping[userVertical.verticalId] = userVertical;
            return userVerticalMapping;
        },
        {},
    );

    const selectedNewsletters = user.newsletters?.reduce<Record<string, UDBNewsletterChannel[]>>(
        (preparedNewsletters, { id, channel }) => {
            if (!preparedNewsletters[id]) {
                preparedNewsletters[id] = [];
            }

            preparedNewsletters[id].push(channel);
            return preparedNewsletters;
        }
        , {},
    ) ?? {};

    const verticalsById: Record<string, ProfileEmailDeliveryVertical> = {};

    newsletterList.forEach((item) => {
        const {
            id,
            name,
            key,
            description,
            sendDay,
            sendTime,
            customSendTime,
            initialSend,
            vertical,
            sampleIssues,
        } = item;

        if (!vertical) {
            return;
        }

        if (!verticalsById[vertical.id]) {
            verticalsById[vertical.id] = {
                id: vertical.id,
                name: vertical.name,
                newsletters: [],
                hasSampleIssues: false,
                hasDateTime: false,
            };
        }

        const sampleIssueType = sampleIssueTypeByVerticalIdMap?.[vertical.id] ?? SubscriberType.basic ;
        const hasSampleIssues = !!sampleIssues?.[sampleIssueType];

        verticalsById[vertical.id].smsAllowed = userVerticals?.[vertical.id]?.smsAllowed ?? false;

        verticalsById[vertical.id].hasSampleIssues = verticalsById[vertical.id].hasSampleIssues || hasSampleIssues;
        verticalsById[vertical.id].hasDateTime = verticalsById[vertical.id].hasDateTime || !!sendDay;

        verticalsById[vertical.id].newsletters.push({
            id,
            name,
            key,
            description,
            sendDay,
            sendTime,
            customSendTime,
            initialSend,
            selectedChannels: selectedNewsletters[id] || [],
            sampleIssue: sampleIssues?.[sampleIssueType],
        });
    });

    preferences.verticals = Object.values(verticalsById);

    preferences.verticals.sort(byNumericId);
    preferences.verticals.forEach((vertical) => {
        vertical.newsletters.sort(byNumericId);
    });

    return preferences;
};

export const getActiveNewsletters = (
    selectedNewsletters: Record<UDBNewsletterChannel, number[]>,
    changedNewsletters: NewsletterDifference[],
): Record<UDBNewsletterChannel, number[]> => {
    const smsNewslettersSet = new Set(selectedNewsletters[UDBNewsletterChannel.SMS]);
    const emailNewslettersSet = new Set(selectedNewsletters[UDBNewsletterChannel.EMAIL]);

    const newslettersSetMapping = {
        [UDBNewsletterChannel.EMAIL]: emailNewslettersSet,
        [UDBNewsletterChannel.SMS]: smsNewslettersSet,
    };

    changedNewsletters.forEach(({ id, chanel, value }) => {
        const newslettersSet = newslettersSetMapping[chanel];

        if (value) {
            newslettersSet.add(id);
        } else {
            newslettersSet.delete(id);
        }
    });

    return {
        [UDBNewsletterChannel.EMAIL]: [...emailNewslettersSet],
        [UDBNewsletterChannel.SMS]: [...smsNewslettersSet],
    };
};
