import React, { FC, ReactNode } from 'react';
import { Box, Stack, StackProps } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import Tooltip from 'src/components/Tooltip';
import { StyledRenewalDetail } from 'src/components/Modals/UpcomingRenewalModal/UpcomingRenewalModal.styles';
import Line from 'src/components/Line';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

type RenewalDataRowProps = {
    title: string;
    currentRenewalData: number | string | null | ReactNode;
    pulledRenewalData?: number | null;
    formatter?: (value: number | unknown) => string;
    skipLine?: boolean;
    children?: ReactNode;
    overrideButton?: ReactNode;
    paddingY?: BoxProps['paddingY'];
    paddingBottom?: BoxProps['paddingBottom'];
    paddingTop?: BoxProps['paddingTop'];
    height?: StackProps['height'];
    pulledDataTooltip?: string | ReactNode;
    currentDataTooltip?: string | ReactNode;
};

const RenewalDataRow: FC<RenewalDataRowProps> = ({
    paddingY = { xs: 0.5, sm: 0 },
    paddingBottom,
    skipLine,
    overrideButton,
    title,
    currentRenewalData,
    pulledRenewalData,
    formatter,
    children,
    height = { xs: '18px', sm: '20px' },
    pulledDataTooltip,
    currentDataTooltip,
    paddingTop,
}) => {
    const getColorIndex = (currentData: number | unknown, pulledData: number | unknown) => {
        if (typeof currentData !== 'number' || typeof pulledData !== 'number') {
            return currentData === pulledData ? 0 : null;
        }

        return currentData - pulledData;
    };

    return (
        <>
            <Box paddingY={paddingY} paddingBottom={paddingBottom} paddingTop={paddingTop}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    height={height}
                >
                    <Box>
                        <StyledRenewalDetail>
                            {title}
                        </StyledRenewalDetail>
                        {overrideButton}
                    </Box>
                    <Box textAlign="right">
                        <StyledRenewalDetail
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            {formatter ? formatter(currentRenewalData) : currentRenewalData}
                            {currentDataTooltip && (
                                <Tooltip
                                    data-max-width="unset"
                                    title={currentDataTooltip}
                                    placement="right"
                                    arrow
                                >
                                    <InfoIcon />
                                </Tooltip>
                            )}
                        </StyledRenewalDetail>
                        <StyledRenewalDetail
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            data-color-index={getColorIndex(currentRenewalData, pulledRenewalData)}
                        >
                            {pulledRenewalData !== undefined && (
                                formatter ? formatter(pulledRenewalData) : pulledRenewalData
                            )}
                            {pulledDataTooltip && (
                                <Tooltip
                                    data-max-width="unset"
                                    title={pulledDataTooltip}
                                    placement="right"
                                    arrow
                                >
                                    <InfoIcon />
                                </Tooltip>
                            )}
                        </StyledRenewalDetail>
                    </Box>
                </Stack>
                {children}
            </Box>
            {!skipLine && <Line />}
        </>
    );
};

export default RenewalDataRow;
