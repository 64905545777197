import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import type { StripeError } from '@stripe/stripe-js';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { Appearance } from '@stripe/stripe-js/dist/stripe-js/elements-group';
import { Link } from 'src/components/Link';
import Line from 'src/components/Line';
import { theme } from 'src/theme';
import { useSnackbarMessage } from 'src/hooks';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { Button } from 'src/components/Buttons';
import PageTitle from 'src/components/PageTitle';
import { formatPrice } from 'src/services/formatters';
import { createSubscriptionToCheckout } from 'src/services/sso-api';
import { makeURL } from 'src/services/url-maker';
import { SnackbarMessageVariants } from 'src/constants';

type CheckoutFormProps = {
    productName: string;
    priceInCents: number;
    priceId: string;
    isNewUser: boolean;
    userUUID: string;
};

export const appearance: Appearance = {
    theme: 'stripe',
    variables: {
        colorText: theme.palette.grey,
        colorDanger: theme.palette.red,
        fontFamily: theme.fonts.medium,
        borderRadius: '4px',
        gridColumnSpacing: '16px',
        gridRowSpacing: '24px',
    },
    rules: {
        '.Input': {
            fontSize: '16px',
            padding: '14px 16px',
            color: theme.palette.black,
            fontFamily: theme.fonts.normal,
            boxShadow: 'none',
        },
        '.Input:hover': {
            backgroundColor: theme.palette.hoveredInputBackground,
        },
        '.Input:focus': {
            border: `solid 1px ${theme.palette.blue}`,
            boxShadow: 'none',
        },
        '.Input--invalid': {
            border: `solid 1px ${theme.palette.red}`,
            boxShadow: 'none',
        },
        '.Input::placeholder': {
            fontFamily: theme.fonts.normal,
            color: theme.palette.lightGrey,
            fontSize: '16px',
        },
        '.Error': {
            color: theme.palette.red,
            fontFamily: theme.fonts.normal,
            fontSize: '12px',
        },
        '.Label': {
            fontSize: '14px',
        },
        '.TermsText': {
            color: theme.palette.grey,
            fontSize: '14px',
        },
    },
};

const CheckoutForm: FC<CheckoutFormProps> = ({
    isNewUser,
    userUUID,
    priceInCents,
    productName,
    priceId,
}) => {
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);

    const { addMessage } = useSnackbarMessage();
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        elements?.update({
            appearance,
        });
    }, [elements]);

    useEffect(() => {
        if (!!stripe && !!elements) {
            setLoading(false);
        }
    }, [!!stripe, !!elements]);

    const handleError = (error?: StripeError | Error) => {
        setLoading(false);
        addMessage(
            error?.message ?? 'Failed to checkout, please try again or contact us',
            SnackbarMessageVariants.ERROR,
        );
    };

    const handleSubmit = async (event: FormEvent) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit().catch((error) => ({ error }));
        if (submitError) {
            handleError(submitError);
            return;
        }

        const checkoutSubscriptionDetails = await createSubscriptionToCheckout({ priceId, userUUID, isNewUser })
            .catch(() => handleError());

        if (!checkoutSubscriptionDetails) {
            return;
        }

        await stripe.confirmPayment({
            elements,
            clientSecret: checkoutSubscriptionDetails.clientSecret,
            confirmParams: {
                return_url: makeURL(`/upgrade/insider/${checkoutSubscriptionDetails.accessCode}`).toString(),
            },
        })
            .then(({ error }) => error && handleError(error))
            .catch(() => handleError());
    };

    const formattedPrice = formatPrice(priceInCents);

    return (
        <>
            {isLoading && <LoaderOverlay />}
            <form onSubmit={handleSubmit} data-testid="checkout_form">
                <Box paddingBottom={2} display={{ xs: 'block', md: 'none' }}>
                    <PageTitle variant="form" testId="checkout-form-title" title="Checkout" />
                </Box>
                <Grid
                    container
                    data-testid="checkout-page"
                    display="flex"
                    direction={{ xs: 'column-reverse', md: 'row' }}
                    justifyContent={{ xs: 'flex-end' }}
                    paddingX={{ xs: 0, md: 2.5 }}
                >
                    <Grid
                        item
                        xs={12}
                        md={5.375}
                        container
                        display="flex"
                        direction="column"
                        justifyContent="space-between"
                    >
                        <Stack spacing={{ xs: 1.5, md: 1 }}>
                            <Box paddingBottom={3} display={{ xs: 'none', md: 'block' }}>
                                <PageTitle variant="form" testId="checkout-form-title" title="Checkout" />
                                <Typography paddingTop={3} variant="h2" fontSize={36} fontFamily={theme.fonts.bold}>
                                    {formattedPrice}
                                </Typography>
                                <Typography fontFamily={theme.fonts.medium} fontSize={18} color="secondary">
                                    Total to pay
                                </Typography>
                            </Box>
                            <Stack direction="row" justifyContent="space-between" paddingBottom={{ xs: 0, md: 2 }}>
                                <Typography fontFamily={theme.fonts.medium} fontSize={14}>
                                    {productName}
                                </Typography>
                                <Typography fontFamily={theme.fonts.medium} fontSize={14}>
                                    {formattedPrice}
                                </Typography>
                            </Stack>
                            <Line />
                            <Stack direction="row" justifyContent="space-between">
                                <Typography fontFamily={theme.fonts.medium} color="secondary" fontSize={14}>
                                    Subtotal
                                </Typography>
                                <Typography fontFamily={theme.fonts.medium} color="secondary" fontSize={14}>
                                    {formattedPrice}
                                </Typography>
                            </Stack>
                            <Line />
                            <Stack direction="row" justifyContent="space-between">
                                <Typography fontFamily={theme.fonts.bold} fontSize={14}>
                                    Total due
                                </Typography>
                                <Typography fontFamily={theme.fonts.bold} fontSize={14}>
                                    {formattedPrice}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box
                            width="fit-content"
                            paddingTop={3}
                            margin={{ xs: 'auto', md: 0 }}
                            position={{ xs: 'absolute', md: 'static' }}
                            bottom={16}
                            left={0}
                            right={0}
                        >
                            <Link target="_blank" href="/paid-subscription-terms-and-conditions">
                                <Typography
                                    fontFamily={theme.fonts.medium}
                                    color={theme.palette.grey}
                                    fontSize={{ xs: 12, md: 14 }}
                                >
                                    Terms and Conditions
                                </Typography>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item md={1.25} xs={12}>
                        <Box display={{ xs: 'block', md: 'none' }} paddingY={3}>
                            <Line />
                        </Box>
                        <Box
                            display={{ xs: 'none', md: 'block' }}
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            margin="auto"
                            height="100%"
                            width="1px"
                        >
                            <Line variant="vertical" />
                        </Box>
                    </Grid>
                    <Grid item md={5.375} xs={12}>
                        <Stack spacing={4.5} height="100%" justifyContent="space-between">
                            <PaymentElement onChange={({ complete }) => setSubmitDisabled(!complete)} />
                            <Box display={{ xs: 'none', md: 'block' }} width="100%">
                                <Button type="submit" disabled={isSubmitDisabled} fullWidth>
                                    Pay {formattedPrice}
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
                <Box
                    display={{ xs: 'block', md: 'none' }}
                    textAlign="center"
                    width="100%"
                    paddingBottom={3.75}
                    paddingTop={3}
                >
                    <Button type="submit" disabled={isSubmitDisabled} fullWidth>
                        Pay {formattedPrice}
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default CheckoutForm;
