import { combineReducers } from 'redux';
import {
    accessCodeSlice,
    configSlice,
    generalModalSlice,
    pageSlice,
    subscriptionSlice,
    userSlice,
    userUpgradeSlice,
} from './slices';

export const rootReducer = combineReducers({
    [configSlice.name]: configSlice.reducer,
    [accessCodeSlice.name]: accessCodeSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [generalModalSlice.name]: generalModalSlice.reducer,
    [pageSlice.name]: pageSlice.reducer,
    [subscriptionSlice.name]: subscriptionSlice.reducer,
    [userUpgradeSlice.name]: userUpgradeSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
