import { MIN_PASSWORD_LENGTH } from 'src/constants';

export const generatePassword = (
    length = MIN_PASSWORD_LENGTH,
): string => {
    if (length < MIN_PASSWORD_LENGTH) {
        console.error(`Should be longer then minimal password length ${MIN_PASSWORD_LENGTH}`);

        return '';
    }

    const charSets: Array<string> = [
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        'abcdefghijklmnopqrstuvwxyz',
        '0123456789',
        '!@#$%^&*()-_=+',
    ];

    const getRandomChar = (value: string) => {
        const randomIndex = Math.floor(Math.random() * value.length);

        return value.charAt(randomIndex);
    };

    const initialChars = charSets.map((select) => getRandomChar(select));

    const remainingCharsCount = length - charSets.length;
    const remainingChars = Array.from({ length: remainingCharsCount }, () => {
        const randomSet = charSets[Math.floor(Math.random() * charSets.length)];
        return getRandomChar(randomSet);
    });

    return [...initialChars, ...remainingChars]
        .sort(() => Math.random() - 0.5)
        .join('');
};
