import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import {
    PageDescription,
    StyledArrowBackIcon,
    StyledEndpointsEnterPrice,
    StyledLogo,
    StyledPaper,
} from './RequestAQuote.styles';
import { CenteredFullScreenLayout } from 'src/layouts/CenteredFullScreenLayout';
import Line from 'src/components/Line';
import PageTitle from 'src/components/PageTitle';
import { PAGE_TITLES } from 'src/constants';
import { RequestAQuoteForm } from 'src/components/Forms/RequestAQuoteForm';
import { Spinner } from 'src/components/Spinner';
import { ReceivedRequestMessage } from 'src/components/ReceivedRequestMessage';

export const RequestAQuotePage = () => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleFormSuccess = () => {
        setIsFormSubmitted(true);
    };

    if (isSubmitting) {
        return <Spinner />;
    }

    if (isFormSubmitted) {
        return <ReceivedRequestMessage />;
    }

    return (
        <CenteredFullScreenLayout>
            <StyledPaper data-testid="request-a-quote-panel">
                <StyledLogo alignItems="center" paddingX={{ xs: 2, sm: 4.5 }} paddingY={3.5} container>
                    <Grid item xs={0} sm={4}>
                        <StyledArrowBackIcon data-testid="arrow-back-icon" onClick={() => navigate('/upgrade')} />
                    </Grid>
                    <Grid item xs={0} sm={8} paddingLeft={{ xs: 2.5, sm: 0 }}>
                        <StyledEndpointsEnterPrice />
                    </Grid>
                </StyledLogo>
                <Line />
                <Stack paddingX={{ xs: 2, sm: 4.5 }} paddingY={{ xs: 3.5, sm: 4 }}>
                    <PageTitle variant="form" paddingBottom={{ xs: 2.5, sm: 3 }} textAlign={'left'} title={PAGE_TITLES.REQUEST_A_QUOTE}>
                        <PageDescription>
                                Our Enterprise subscription gives your entire company unlimited access to
                                Endpoints News journalism. Please fill out the following information, and
                                we&apos;ll be in touch with your custom price and next steps to get your
                                subscription activated.
                        </PageDescription>
                    </PageTitle>
                    <RequestAQuoteForm
                        onSuccess={handleFormSuccess}
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                    />
                </Stack>
            </StyledPaper>
        </CenteredFullScreenLayout>
    );
};
