import styled from 'styled-components';
import { Stack } from '@mui/material';
import { Button } from 'src/components/Buttons';

export const NotFoundStackContainer = styled(Stack)`
  max-width: 390px;
  justify-items: center;
  align-items: center;
  
  & svg {
    max-width: 100%;
    height: auto;
  }
`;

export const NotFoundButton = styled(Button)`
  width: 235px;
`;
