import dayjs, { Dayjs } from 'dayjs';
import { TimeZoneType } from './profile-settings-helpers';

let timeZone = 'UTC';

export const setTimeZone = (userTimeZone: string) => {
    timeZone = userTimeZone;
};

export const convertDateTimeZone = (date: number | string | Date): Dayjs => {
    return dayjs.utc(date).tz(timeZone);
};

export const convertFilterDate = (date: string): Dayjs => {
    return timeZone !== TimeZoneType.UTC ? dayjs.utc(date) : dayjs(date);
};

export const getTimeZone = (): string => {
    return timeZone !== TimeZoneType.UTC ? 'Local' : TimeZoneType.UTC;
};
