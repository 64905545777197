import React, { FC, useState } from 'react';
import { FormikHelpers } from 'formik';
import { EditContactInformationModalContent } from './components';
import { makeContactInformationConfirmMessages } from './components/ContactInformationConfirmMessages';
import { ConfirmationMessages, ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { BaseModal } from 'src/components/Modals/BaseModal';
import {
    countryValidator,
    emailValidator,
    validateSchemaObject,
} from 'src/services/validators';
import { FormWrapper } from 'src/components/Forms/components';
import { useGeneralModal, useIsSubmitDisabled, useLookupNamesHook, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { EMAIL_IS_TAKEN_ERROR, INVALID_EMAIL_VALIDATION_ERROR, SnackbarMessageVariants } from 'src/constants';
import { type FormattedContactInfoType } from 'src/pages/udb/ManageUser/components/ContactInformationSection/ContactInformationSection';
import { ChangeEmailCheckData } from 'src/@types/sso-api';
import { checkEmailAvailability, requestChangeEmailForUser } from 'src/services/sso-api';
import { deleteUserEmail, updateUser } from 'src/services/unified-db-api';
import { getCountriesList } from 'src/services/user-profile-api';

export type EditContactInformationModalProps = {
    contactsInformation: FormattedContactInfoType;
    changeEmailStatus: ChangeEmailCheckData;
    uuid: string;
};

export type EditContactInformationFormValues = {
    email: string;
    country: string | null;
};

const EditContactInformationModal: FC<EditContactInformationModalProps> = ({
    contactsInformation,
    changeEmailStatus,
    uuid,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [updateUserData, setUpdateUserData] = useState<Partial<EditContactInformationFormValues>>({});
    const [phoneNumberRemoved, setPhoneNumberRemoved] = useState<boolean>(false);
    const [removedEmails, setRemovedEmails] = useState<number[]>([]);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);

    const { closeModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();
    const initialCountryName = useLookupNamesHook(getCountriesList, contactsInformation.country);
    const newCountryName = useLookupNamesHook(getCountriesList, updateUserData.country);

    const messages = makeContactInformationConfirmMessages(
        updateUserData,
        contactsInformation,
        phoneNumberRemoved,
        removedEmails,
        { from: initialCountryName, to: newCountryName },
    );
    const { secondaryEmails } = contactsInformation;
    const formValues: EditContactInformationFormValues = {
        email: contactsInformation.email ?? '',
        country: contactsInformation.country ?? '',
    };

    const {
        isSubmitDisabled,
        onFormChangeHandler,
        forceChangeValues,
    } = useIsSubmitDisabled<EditContactInformationFormValues>(formValues);
    const { addMessage } = useSnackbarMessage();

    const handleCloseConfirmModal = () => {
        setIsConfirming(false);
    };

    const handleConfirm = () => {
        const pendingRequests = [];
        if (updateUserData.email) {
            pendingRequests.push(requestChangeEmailForUser(updateUserData.email, uuid));
        }

        if (updateUserData.country || phoneNumberRemoved) {
            pendingRequests.push(updateUser(uuid, {
                customAttributes: {
                    country: updateUserData.country ?? contactsInformation.country,
                },
                phoneNumber: phoneNumberRemoved ? null : contactsInformation.phoneNumber,
            }));
        }

        if (removedEmails.length) {
            removedEmails.forEach((email) => {
                pendingRequests.push(deleteUserEmail(uuid, email));
            });
        }

        setIsSubmitting(true);

        Promise.all(pendingRequests).then(() => {
            addMessage('User updated successfully', SnackbarMessageVariants.SUCCESS);
            closeModal();
            reloadPage();
        }).catch(() => {
            addMessage('Failed to update User', SnackbarMessageVariants.ERROR);
            setIsConfirming(false);
        }).finally(() => {
            setIsSubmitting(false);
        });
    };

    const handleCountryChange = (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => {
        onFormChangeHandler(event);
    };

    const handleSubmit = (
        data: EditContactInformationFormValues,
        helpers: FormikHelpers<EditContactInformationFormValues>,
    ) => {
        const isEmailChanged = data.email !== formValues.email;

        const newUserData: Partial<EditContactInformationFormValues> = {
            country: data.country !== formValues.country ? data.country : undefined,
            email: isEmailChanged ? data.email : undefined,
        };

        if (isEmailChanged && !secondaryEmails?.some(({ email }) => email === data.email)) {
            setIsSubmitting(true);

            checkEmailAvailability(data.email)
                .then(({ available }) => {
                    if (!available) {
                        helpers.setErrors({ email: EMAIL_IS_TAKEN_ERROR });
                        return;
                    }

                    setUpdateUserData(newUserData);
                    setIsConfirming(true);
                })
                .catch(({ responseError }) => {
                    const emailValidationError: Record<string, string> = {
                        '"email" must be a valid email': INVALID_EMAIL_VALIDATION_ERROR,
                    };

                    return emailValidationError[responseError.data.error] ? (
                        helpers.setErrors({ email: emailValidationError[responseError.data.error] })
                    ) : (
                        addMessage('Failed to check email availability', SnackbarMessageVariants.ERROR)
                    );
                })
                .finally(() => {
                    setIsSubmitting(false);
                });

            return;
        }

        setUpdateUserData(newUserData);
        setIsConfirming(true);
    };

    if (isConfirming) {
        return (
            <BaseModal
                open={true}
                handleClose={handleCloseConfirmModal}
                handleGoBack={handleCloseConfirmModal}
                title="Confirm changes"
                step={1}
            >
                <ConfirmationModal
                    modalId="confirm-edit-contact-information-modal"
                    subtitle={<ConfirmationMessages messages={messages} />}
                    confirmButtonText="Confirm"
                    cancelButtonText="Cancel"
                    onConfirm={handleConfirm}
                    onCancel={handleCloseConfirmModal}
                />
            </BaseModal>
        );
    }

    return (
        <FormWrapper<EditContactInformationFormValues>
            onFormChange={onFormChangeHandler}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            initialValues={formValues}
            validationSchema={validateSchemaObject({
                email: emailValidator.optional(),
                country: countryValidator.optional(),
            })}
        >
            <EditContactInformationModalContent
                changeEmailStatus={changeEmailStatus}
                secondaryEmails={secondaryEmails}
                phoneNumber={contactsInformation.phoneNumber?.phoneNumber}
                isSubmitDisabled={isSubmitDisabled || isSubmitting}
                onCountryChange={handleCountryChange}
                phoneNumberRemoved={phoneNumberRemoved}
                setPhoneNumberRemoved={setPhoneNumberRemoved}
                removedEmails={removedEmails}
                setRemovedEmails={setRemovedEmails}
                forceChangeValues={forceChangeValues}
                updatedFormValues={updateUserData}
            />
        </FormWrapper>
    );
};

export default EditContactInformationModal;
