import styled, { css } from 'styled-components';
import { Box, Typography } from '@mui/material';

const sharedStyles = css`
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.medium};
        
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
       font-size: 14px;
    }
`;

export const StyledType = styled(Box)`
    && {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: ${({ theme }) => theme.palette.black};
        text-transform: uppercase;
        font-family: ${({ theme }) => theme.fonts.demiBold};
        
        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
            font-family: ${({ theme }) => theme.fonts.medium};
        }
    } 
`;

export const StyledRole = styled(Typography)`
    && {
        padding: 8px 0;
        color: ${({ theme }) => theme.palette.lightBlack};
            
        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            padding: 4px 0;
        }
            
        ${sharedStyles}
            
    } 
`;

export const StyledText = styled.span`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};

        ${sharedStyles}
    } 
`;

export const StyledDate = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightGrey};

        ${sharedStyles}
    } 
`;
