import axios from 'axios';
import { CountriesListType, UiConfigType } from 'src/@types/user-profile-api';

let countriesList: CountriesListType;

export const loadConfig = async (): Promise<UiConfigType> => (
    axios.get<UiConfigType>('/config')
        .then(({ data }) => data)
);

const fetchCountriesList = async (): Promise<CountriesListType> => (
    axios.get<CountriesListType>('/countries-list')
        .then(({ data }) => data)
);

export const getCountriesList = async (): Promise<CountriesListType> => {
    if (!countriesList) {
        countriesList = await fetchCountriesList();
    }
    return countriesList;
};
