import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CommunicationPreferencesPage } from './CommunicationPreferences';
import { CredentialsPage } from './Credentials';
import { ProfilePage } from './Profile';
import { LogInPage } from './LogIn';
import { RequestResetPasswordPage } from './RequestResetPassword';
import { ResetPasswordPage } from './ResetPassword';
import { RequestLogInLinkPage } from './RequestLogInLink';
import { LogInLinkPage } from './LogInLink';
import { LogOutPage } from './LogOut';
import { ProfileSubscriptionManage } from './ProfileSubscriptionManage';
import UdbRouter from './udb/UdbRouter';
import { NotFoundPage } from './NotFound';
import { ChangeEmailPage } from './ChangeEmail';
import { SubscriptionTACPage } from './SubscriptionTAC';
import { CheckoutResultPage, UpgradeToInsiderPage } from './UpgradeToInsider';
import { ApplicationsPage } from './Applications';
import { SignUp } from './SignUp';
import { SignUpSuccessPage } from './SignUpSuccess';
import { SubscriberPage } from './Subscriber';
import { OneClickSubscribePage } from './OneClickSubscribe';
import { RequestAQuotePage } from './RequestAQuote';
import { CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';
import { userSelector } from 'src/redux/slices';
import { wrapLogInPageWithHandler, wrapPageWithHandler } from 'src/components/PageHandler';
import { PAGE_TITLES } from 'src/constants';

const AppRouter = () => {
    // trigger re-render on user change
    useSelector<RootState, CurrentUserState>(userSelector);

    return (
        <Routes>
            <Route path="/" element={wrapPageWithHandler(<ProfilePage />, PAGE_TITLES.ROOT)} />
            <Route path="/delivery" element={wrapPageWithHandler(<ProfilePage />, PAGE_TITLES.DELIVERY)} />
            <Route path="/delivery/:uuid" element={wrapPageWithHandler(<CommunicationPreferencesPage />, PAGE_TITLES.DELIVERY)} />
            <Route path="/security" element={wrapPageWithHandler(<ProfilePage />, PAGE_TITLES.SECURITY)} />
            <Route path="/subscriptions" element={wrapPageWithHandler(<ProfilePage />, PAGE_TITLES.PROFILE_SUBSCRIPTIONS)} />
            <Route path="/applications" element={wrapPageWithHandler(<ApplicationsPage />, PAGE_TITLES.APPLICATIONS)} />
            <Route path="/credentials" element={wrapPageWithHandler(<CredentialsPage />, PAGE_TITLES.CREDENTIALS)} />
            <Route path="/udb/*" element={wrapPageWithHandler(<UdbRouter />)} />
            <Route path="/log-in" element={wrapPageWithHandler(<LogInPage />, PAGE_TITLES.LOG_IN)} />
            <Route path="/log-out" element={wrapPageWithHandler(<LogOutPage />, PAGE_TITLES.LOG_OUT)} />
            <Route path="/log-in-link" element={wrapPageWithHandler(<RequestLogInLinkPage />, PAGE_TITLES.LOG_IN_LINK)} />
            <Route path="/log-in-link/:accessCode" element={wrapLogInPageWithHandler(<LogInLinkPage />, PAGE_TITLES.LOG_IN)} />
            <Route path="/reset-password" element={wrapPageWithHandler(<RequestResetPasswordPage />, PAGE_TITLES.RESET_PASSWORD)} />
            <Route path="/reset-password/:accessCode" element={wrapPageWithHandler(<ResetPasswordPage />, PAGE_TITLES.RESET_PASSWORD)} />
            <Route path="/change-email/:accessCode" element={wrapPageWithHandler(<ChangeEmailPage />, PAGE_TITLES.CHANGE_EMAIL)} />
            <Route path="/create-account" element={wrapPageWithHandler(<SignUp />, PAGE_TITLES.CREATE_ACCOUNT)} />
            <Route path="/create-account/success" element={wrapPageWithHandler(<SignUpSuccessPage />, PAGE_TITLES.CREATE_ACCOUNT)} />
            <Route path="/one-click-subscribe/:newsletterKey/:uuid" element={wrapPageWithHandler(<OneClickSubscribePage />, PAGE_TITLES.ONE_CLICK_SUBSCRIBE)} />
            <Route path="/upgrade" element={wrapPageWithHandler(<SubscriberPage />, PAGE_TITLES.SUBSCRIBER)} />
            <Route path="/upgrade/insider" element={wrapPageWithHandler(<UpgradeToInsiderPage />, PAGE_TITLES.UPGRADE_TO_INSIDER)} />
            <Route path="/upgrade/insider/:accessCode" element={wrapPageWithHandler(<CheckoutResultPage />, PAGE_TITLES.UPGRADE_TO_INSIDER)} />
            <Route path="/upgrade/request-a-quote" element={wrapPageWithHandler(<RequestAQuotePage />, PAGE_TITLES.REQUEST_A_QUOTE)} />
            <Route path="/paid-subscription-terms-and-conditions" element={wrapPageWithHandler(<SubscriptionTACPage />, PAGE_TITLES.SUBSCRIPTION_TAC)} />
            <Route path="/subscriptions/:uuid" element={wrapPageWithHandler(<ProfileSubscriptionManage />, PAGE_TITLES.MANAGE_SUBSCRIPTIONS)} />
            <Route path="/*" element={wrapPageWithHandler(<NotFoundPage />, PAGE_TITLES.NOT_FOUND)} />
        </Routes>
    );
};

export default AppRouter;
