import styled from 'styled-components';
import { Typography } from '@mui/material';
import { generalTableCellStyles } from 'src/components/TableSection/TableSection.styles';
import { ReactComponent as UserIcon } from 'src/assets/icons/user-icon.svg';

export const FullNameTitle = styled(Typography)`
    && {
       ${generalTableCellStyles};
      
        display: block;  
        color: ${({ theme }) => theme.palette.black};
        white-space: nowrap;
}
`;

export const JobTitle = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightGrey};

        ${generalTableCellStyles}
    }
`;

export const EmailTitle = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.grey};
            
        ${generalTableCellStyles}
    }
`;

export const StyledUserIcon = styled(UserIcon)`
    && {
        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            width: 16px;
        }
    }
`;
