import { SubscriptionModel } from 'src/@types/subscription-service-api';
import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { ManageProfileSubscriptionActions, PaidSubscriptionStatus } from 'src/constants';

export const getManageSubscriptionActionsList = (
    paidSubscription?: SubscriptionModel,
): Partial<Record<ManageProfileSubscriptionActions, ManageActionType>> => {
    const isActive = paidSubscription?.status === PaidSubscriptionStatus.ACTIVE;
    const isCanceled = paidSubscription?.customAttributes?.subscriptionCanceled === '1';

    return {
        ...(isActive && !isCanceled && { [ManageProfileSubscriptionActions.cancelSubscription]: { label: 'Cancel subscription' } }),
        ...(isActive && isCanceled && { [ManageProfileSubscriptionActions.restoreSubscription]: { label: 'Restore subscription' } }),
    };
};
