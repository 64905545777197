import React, { Dispatch, FC, SetStateAction } from 'react';
import { PaymentItem } from './components';
import { gridColumnParams } from './services/schemas';
import { GridColumnProps } from 'src/components/TableSection/TableSection';
import { PaginationData } from 'src/@types/pagination';
import {
    PriceHistoryModel,
    PriceHistoryModelWithRenewal,
    SubscriptionModel,
} from 'src/@types/subscription-service-api';
import { TableSection } from 'src/components/TableSection';

type PaymentSectionProps = {
    subscription: SubscriptionModel;
    priceHistory: PriceHistoryModel[] | PriceHistoryModelWithRenewal[];
    paginationModel: PaginationData;
    setPagination: Dispatch<SetStateAction<PaginationData>>;
    variant: 'udb' | 'profile';
    isLoading: boolean;
};

const PaymentSection: FC<PaymentSectionProps> = ({
    subscription,
    priceHistory,
    paginationModel,
    setPagination,
    variant,
    isLoading,
}) => {
    const itemRenderer = (
        payment: PriceHistoryModelWithRenewal,
        extraProps: { gridColumnParams: GridColumnProps },
    ) => (
        <PaymentItem
            payment={payment}
            rowProps={extraProps.gridColumnParams}
            subscription={subscription}
            variant={variant}
        />
    );

    return (
        <TableSection
            items={priceHistory}
            headerPaddingBottom={1}
            sectionHeader="Payments"
            isLoading={isLoading}
            noContentMessage="There are no payment records to be shown"
            renderItem={itemRenderer}
            gridColumnParams={gridColumnParams}
            paginationModel={paginationModel}
            setPagination={setPagination}
        >
        </TableSection>
    );
};

export default PaymentSection;
