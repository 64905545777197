import { Button } from '@mui/base';
import styled from 'styled-components';

export const ToolBarConditionIcon = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.palette.lightBlack};
  background-color: ${({ theme }) => theme.palette.white};
  min-width: 50px;
  height: 50px;

  svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
    color: ${({ theme }) => theme.palette.black};
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.palette.blue};
  }
`;
