import React, { FC, useEffect, useState } from 'react';
import { numberDividerRegExp } from 'src/services/validators';
import { stringifyNumberWithTwoDecimal } from 'src/services/formatters';
import { TextAdornment } from 'src/components/Inputs/components/InputAdornment/InputAdornment.styles';
import { TextInput } from 'src/components/Inputs/TextInput';

type PercentInputProps = {
    value: number;
    onChange: (value: number) => void;
};

export const toValidPercentage = (value: number | string) => (
    Math.max(0, Math.min(100, Number(value) || 0))
);

const PercentInput: FC<PercentInputProps> = ({
    value,
    onChange,
}) => {
    const [percent, setPercent] = useState<string>(
        stringifyNumberWithTwoDecimal(toValidPercentage(value)),
    );

    useEffect(() => {
        onChange(toValidPercentage(percent));
    }, [percent]);

    const handlePercentInputBlur = () => {
        setPercent(stringifyNumberWithTwoDecimal(toValidPercentage(percent)));
    };

    const handlePercentInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { data: key } = event.nativeEvent as Event & { data: string };

        const hasDot = percent.includes('.');
        const hasComma = percent.includes(',');
        const hasDotOrComma = hasDot || hasComma;
        const isDotOrComma = ['.', ','].includes(key);
        const isNumber = !Number.isNaN(Number(key));

        if ((hasDotOrComma && isDotOrComma) || (!isNumber && !isDotOrComma) && key) {
            return;
        }

        const newValue = event.target.value.replace(numberDividerRegExp, '');

        setPercent(newValue.replace(',', '.'));
    };

    return (
        <TextInput
            value={percent}
            autoSelect
            onChange={handlePercentInputChange}
            onBlur={handlePercentInputBlur}
            InputProps={{
                inputProps: {
                    'data-testid': 'percent-input',
                    autoComplete: 'off',
                },
                endAdornment: (
                    <TextAdornment paddingRight={2}>
                        %
                    </TextAdornment>
                ),
            }}
        />
    );
};

export default PercentInput;
