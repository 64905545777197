import React, { FC } from 'react';
import { Box, Grid, GridProps, Stack } from '@mui/material';
import { StyledDateInfo, StyledOwnerBox, StyledPlan } from './SubscriptionItem.styles';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import { getSubscriptionPaymentStatus } from 'src/services/subscription-service-getters';
import StyledTooltip from 'src/components/Tooltip';
import { roundedBadgePropsMapping, SubscriptionPaymentStatus, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { formatDate } from 'src/services/formatters';
import { AccessibleSubscription } from 'src/@types/subscription-service-api';
import { RoundedBadge } from 'src/components/Badge';
import { ReactComponent as DetailsIcon } from 'src/assets/icons/payment-details-icon.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/grace-period-info.svg';

type SubscriptionItemProps = {
    gridColumnParams: Record<string, GridProps>;
    subscription: AccessibleSubscription;
};

const getBadgeText = ({ isUser, isManager, isOwner }: Pick<AccessibleSubscription, 'isUser' | 'isOwner' | 'isManager'>) => {
    if (isOwner) {
        return 'Owner';
    }

    if (isManager) {
        return 'Manager';
    }

    if (isUser) {
        return 'User';
    }
};

const SubscriptionItem: FC<SubscriptionItemProps> = ({
    subscription,
    gridColumnParams,
}) => {
    const {
        type,
        activationDate,
        expirationDate,
        gracePeriodEndDate,
        status,
        isOwner,
        isUser,
        isManager,
        subscriptionUUID,
    } = subscription;

    const onGracePeriod = getSubscriptionPaymentStatus(subscription) === SubscriptionPaymentStatus.ON_GRACE_PERIOD;
    const badgeText = getBadgeText({ isUser, isManager, isOwner });

    return (
        <Stack position="relative" direction="row" alignItems="center">
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <Grid item {...gridColumnParams.plan}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <StyledPlan>{type}</StyledPlan>
                        <Box display={{ xs: 'block', md: 'none' }}>
                            <RoundedBadge {...roundedBadgePropsMapping[status]} />
                        </Box>
                    </Stack>
                    <Box display={{ xs: 'none', lg: 'flex' }} gap={1}>
                        {badgeText && (
                            <RoundedBadge label={badgeText} />
                        )}
                        {!isUser && (
                            <StyledTooltip arrow title="Not a user">
                                <InfoIcon data-testid="info-icon" />
                            </StyledTooltip>
                        )}
                    </Box>
                    <Box display={{ xs: 'block', md: 'flex', lg: 'none' }}>
                        <StyledOwnerBox>
                            {badgeText}
                            {isUser ? '' : ' | Not a user'}
                        </StyledOwnerBox>
                    </Box>
                </Grid>
                <Grid item {...gridColumnParams.ActivatedOn}>
                    <StyledDateInfo>
                        <Box marginRight={0.5} display={{ xs: 'block', md: 'none' }}>
                            Activated on:
                        </Box>
                        {formatDate(activationDate)}
                    </StyledDateInfo>
                </Grid>
                <Grid item {...gridColumnParams.ExpiresOn}>
                    <StyledDateInfo>
                        <Box marginRight={0.5} display={{ xs: 'block', md: 'none' }}>
                            Expires on:
                        </Box>
                        {formatDate(expirationDate)}
                    </StyledDateInfo>
                </Grid>
                <Grid item {...gridColumnParams.GracePeriod}>
                    <StyledDateInfo>
                        <Box marginRight={0.5} display={{ xs: 'block', md: 'none' }}>
                            Grace period:
                        </Box>
                        {formatDate(gracePeriodEndDate)}
                    </StyledDateInfo>
                </Grid>
                <Grid item {...gridColumnParams.status}>
                    <RoundedBadge {...roundedBadgePropsMapping[status]} />
                    {onGracePeriod && (
                        <StyledTooltip arrow title="On grace period">
                            <InfoIcon data-testid="on-grace-period-info-icon" />
                        </StyledTooltip>
                    )}
                </Grid>
            </Grid>
            <Box position="absolute" right={0}>
                <StyledTooltip arrow title="Manage subscription">
                    <ActionButton
                        data-testid="open-button"
                        data-attribute-to-change="stroke"
                        data-variant={ActionButtonVariant.BLUE}
                        href={`/udb/subscriptions/${subscriptionUUID}`}
                        target="_blank"
                    >
                        <DetailsIcon />
                    </ActionButton>
                </StyledTooltip>
            </Box>
        </Stack>
    );
};

export default SubscriptionItem;
