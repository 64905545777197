import styled from 'styled-components';
import { Grid, Link, Stack, Typography } from '@mui/material';

export const SubscriptionInfo = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
        
        @media(min-width: ${({ theme }) => theme.brakePoints.sm}) {
            margin-right: 8px;
            font-size: 16px;
        }
    }
`;

export const StyledStack = styled(Stack).attrs({ direction: 'row', alignItems: 'center' })`
    &&:last-of-type {
        .MuiAccordionDetails-root {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }
`;

export const SecondaryTitle = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.bold};
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        @media(min-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 24px;
            font-family: ${({ theme }) => theme.fonts.demiBold};
        }
    }
`;

export const StyledGrid = styled(Grid)`
    && {
        border-radius: 4px;
        padding: 24px 36px;
        background-color: ${({ theme }) => theme.palette.white};

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            padding: 16px
        }
    }
`;

export const LinkContainer = styled(Link)`
    && {
        color: ${({ theme }) => theme.palette.snackbar.infoFont};
        font-family: ${({ theme }) => theme.fonts.medium};
        display: inline-flex;
        font-size: 16px;
        text-decoration: none;
        gap: 8px;

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
        }
    }
`;
