import dayjs from 'dayjs';
import {
    CalculatedRenewalItem,
    PriceHistoryModel,
    PriceHistoryModelWithRenewal,
    RenewalItem,
} from 'src/@types/subscription-service-api';
import { formatDate } from 'src/services/formatters';
import { DateFormats } from 'src/constants';

export type UpcomingRenewalModalData = {
    renewalPeriod: string;
    amountOfUsers: number;
    renewalIndex: number | null;
    calculatedOn: string;
    activeUsers: number;
    contentViews: number;
    currentPrice?: number | null;
    suggestedPriceInCents: number | null;
    pricePerUserInCents: number | null;
    finalPriceInCents: number | null;
    finalPricePerUserInCents: number | null;
    contractLink: string | null;
};

export type PulledRenewalData = Pick<UpcomingRenewalModalData, 'amountOfUsers' | 'renewalIndex' | 'calculatedOn' | 'activeUsers' | 'contentViews' | 'suggestedPriceInCents' | 'pricePerUserInCents' | 'currentPrice'>;

export const prepareUpcomingRenewalModalData = (
    renewal: RenewalItem,
    upcomingPriceHistory: PriceHistoryModelWithRenewal,
    currentPriceHistory: PriceHistoryModel | undefined,
): UpcomingRenewalModalData => ({
    renewalPeriod: `${formatDate(upcomingPriceHistory.periodStart, DateFormats.DATE)} - ${formatDate(upcomingPriceHistory.periodEnd, DateFormats.DATE)}`,
    amountOfUsers: upcomingPriceHistory.requestedAmountOfUsers ?? 0,
    renewalIndex: renewal.renewalIndex,
    calculatedOn: formatDate(renewal.calculatedOn),
    activeUsers: renewal.activeUsers,
    contentViews: renewal.contentViews,
    currentPrice: currentPriceHistory?.priceInCents && currentPriceHistory.priceInCents / 100,
    suggestedPriceInCents: renewal.calculatedPriceInCents,
    pricePerUserInCents:
        renewal.calculatedPriceInCents !== null && upcomingPriceHistory.requestedAmountOfUsers ? (
            renewal.calculatedPriceInCents / upcomingPriceHistory.requestedAmountOfUsers
        ) : null,
    finalPriceInCents: upcomingPriceHistory.priceInCents,
    finalPricePerUserInCents:
        upcomingPriceHistory.priceInCents !== null && upcomingPriceHistory.requestedAmountOfUsers ? (
            upcomingPriceHistory.priceInCents / upcomingPriceHistory.requestedAmountOfUsers
        ) : null,
    contractLink: upcomingPriceHistory.contractLink,
});

export const preparePulledData = (calculatedRenewal: CalculatedRenewalItem): PulledRenewalData => ({
    amountOfUsers: calculatedRenewal.totalUsers,
    renewalIndex: calculatedRenewal.renewalIndex,
    calculatedOn: dayjs().toString(),
    activeUsers: calculatedRenewal.activeUsers,
    contentViews: calculatedRenewal.contentViews,
    currentPrice: calculatedRenewal.currentPriceInCents / 100,
    suggestedPriceInCents: calculatedRenewal.calculatedPriceInCents,
    pricePerUserInCents: calculatedRenewal.totalUsers ? (
        calculatedRenewal.calculatedPriceInCents / calculatedRenewal.totalUsers) : null,
});
