import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, SelectChangeEvent, Stack } from '@mui/material';
import ArrowLeftSharpIcon from '@mui/icons-material/ArrowLeftSharp';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PAGINATION_DEFAULT_PAGE } from 'src/constants';
import Line from 'src/components/Line';
import {
    PageSizeSelect,
    PaginationButton,
    PaginationMenuItem,
    PaginationStack,
    PaginationTypography,
    StyledCurrentNumberInput,
    StyledPagination,
} from 'src/components/Pagination/Pagination.styles';

type PaginationProps = {
    pageSizeOptions: number[];
    totalCount?: number;
    pageSize: number;
    setPageSizeState: (pageSize: number) => void;
    pageState: number;
    setPageState: (page: number) => void;
    variant?: 'default' | 'dataGrid' | 'profile-section';
};

export const Pagination = ({
    totalCount,
    pageSizeOptions,
    pageSize,
    pageState,
    setPageState,
    setPageSizeState,
    variant = 'default',
}: PaginationProps) => {
    const [inputPage, setInputPage] = useState<number | string>(pageState);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        setInputPage(pageState);
    }, [pageState]);

    const getLastRowCount = () => {
        if (totalCount) {
            const lastRowOnPage = pageState * pageSize;

            return lastRowOnPage > totalCount ? totalCount : lastRowOnPage;
        }
    };

    const getTotalPages = (count: number, size: number) => {
        return Math.ceil(count / size);
    };

    const getFirsRowCount = () => {
        return pageSize * (pageState - 1) + 1;
    };

    const handleNextButtonClick = () => {
        setPageState(pageState + 1);
    };

    const handlePrevButtonClick = () => {
        setPageState(pageState - 1);
    };

    const handlePageSizeChange = ({ target }: SelectChangeEvent<unknown>) => {
        setPageSizeState(parseInt(target.value as string));
        setPageState(PAGINATION_DEFAULT_PAGE);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputPage(event.target.value);
    };

    const updatePageValue = () => {
        const pageValue = Number(inputPage) || pageState || 0;
        const totalPages = getTotalPages(totalCount || 0, pageSize);
        const correctPageValue = Math.min(totalPages, Math.max(1, pageValue));

        setPageState?.(correctPageValue);
        setInputPage(correctPageValue);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            updatePageValue();
        }
    };

    return (
        <Box
            paddingTop={variant === 'profile-section' ? 2 : 0}
        >
            {variant === 'profile-section' && <Line />}
            <StyledPagination
                data-testid="pagination"
                data-variant={variant}
                direction={{ xs: 'column-reverse', sm: 'row' }}
                justifyContent="space-between"
                paddingTop={variant === 'profile-section' ? 2 : 2.5}
            >
                <Stack
                    spacing={2.5}
                    justifyContent={{ xs: 'space-between', sm: 'unset' }}
                    direction={{ xs: 'row-reverse', sm: 'row' }}
                    alignItems="center"
                >
                    <Stack spacing={1} direction="row" alignItems="center">
                        <PaginationTypography>Items per page</PaginationTypography>
                        <PageSizeSelect
                            data-size={pageSize}
                            open={open}
                            data-variant={variant}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            data-testid={'select-page-size-element'}
                            inputProps={{ 'data-tesid': 'select-page-size-input' }}
                            onChange={handlePageSizeChange}
                            value={pageSize}
                            IconComponent={() => (
                                <ArrowDropDownIcon onClick={() => setOpen(!open)} />
                            )}
                        >
                            {pageSizeOptions.map((option) => (
                                <PaginationMenuItem data-testid={`select-page-size-${option}`} key={option} value={option}>
                                    {option}
                                </PaginationMenuItem>
                            ))}
                        </PageSizeSelect>
                    </Stack>
                    <PaginationTypography>
                        {getFirsRowCount()}-{getLastRowCount()} of {totalCount} items
                    </PaginationTypography>
                </Stack>
                <Stack paddingY={2} display={{ xs: 'block', sm: 'none' }}><Line /></Stack>
                {!!totalCount && (
                    <Stack spacing={1.5} direction="row" alignItems="center" justifyContent={{ xs: 'space-between', sm: 'unset' }}>
                        <PaginationButton disabled={pageState === PAGINATION_DEFAULT_PAGE} data-testid="previous-button" onClick={handlePrevButtonClick}>
                            <ArrowLeftSharpIcon />
                        </PaginationButton>
                        <PaginationStack alignItems="center" gap={1.5} direction="row">
                        Page
                            <StyledCurrentNumberInput
                                placeholder=""
                                type="number"
                                value={inputPage}
                                onBlur={() => updatePageValue()}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                inputProps={{
                                    'data-testid': 'current-input',
                                }}
                            />
                        of {getTotalPages(totalCount, pageSize)}
                        </PaginationStack>
                        <PaginationButton
                            disabled={getLastRowCount() === totalCount}
                            data-testid="next-button"
                            onClick={handleNextButtonClick}
                        >
                            <ArrowRightSharpIcon />
                        </PaginationButton>
                    </Stack>
                )}
            </StyledPagination>
        </Box>
    );
};
