import styled from 'styled-components';
import { Box, IconButton } from '@mui/material';
import { VisibilityOff } from '@mui/icons-material';

export const AutoGenerateWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
`;

export const AutoGenerateText = styled.span`
  padding-left: 4px;
  color: ${({ theme }) => theme.palette.blue};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
`;

export const VisibilityOffStyled = styled(VisibilityOff)`
  &&{
      color: ${({ theme }) => theme.palette.blue} !important;
  }
`;

export const StyledIconButton = styled(IconButton)`
    && {
        margin-right: 8px;

        svg {
            color: #D5D5DB;
        }
    }
`;
