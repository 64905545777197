import { ConditionsParamsType } from 'src/@types/tool-bar';

export const getModelFromQuery = (key: string, searchParams: URLSearchParams): Record<string, ConditionsParamsType> => {
    try {
        const modelString = searchParams.get(key);
        const model = modelString && JSON.parse(decodeURI(modelString));
        if (model && !Array.isArray(model) && typeof model === 'object') {
            return model;
        }
    } catch (e) {
        console.error(e);
    }
    return {};
};
