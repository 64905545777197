import React from 'react';
import { Box, Stack } from '@mui/material';
import { invoiceLinkRenderer } from 'src/components/InvoiceLink';
import Tooltip from 'src/components/Tooltip';
import {
    NoContractText,
    RenewalIndexContainer,
    StyledLinkContainer,
} from 'src/components/PaymentSection/components/PaymentItem/PaymentItem.styles';
import { SubscriptionType } from 'src/constants';
import { formatDate, formatPrice } from 'src/services/formatters';
import type { PriceHistoryModel, PriceHistoryModelWithRenewal, RenewalItem } from 'src/@types/subscription-service-api';
import { ReactComponent as DoneIcon } from 'src/assets/icons/done-with-bacground-icon.svg';
import { ReactComponent as WarnIcon } from 'src/assets/icons/yellow-warn-icon.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

export const getContractLinkElement = (payment: PriceHistoryModel) => {
    if (!payment.contractLink) {
        return (
            <NoContractText variant="inherit">
                {payment.contractSigned &&
                <Tooltip title="The contract has been signed">
                    <DoneIcon />
                </Tooltip>}
                No contract added
            </NoContractText>
        );
    }

    return (
        <StyledLinkContainer data-modal href={payment.contractLink} target="_blank">
            <Tooltip
                title={`The contract ${payment.contractSigned ? 'has' : 'has not'} been signed`}
            >
                {payment.contractSigned ? <DoneIcon /> : <WarnIcon />}
            </Tooltip>
            Open contract
        </StyledLinkContainer>
    );
};

export const getRenewalIndexElement = (
    { renewalIndex, calculatedOn, activeUsers, contentViews }: RenewalItem,
    priceInCents: number | null,
) => {
    if (!renewalIndex) {
        return 'N/A';
    }

    return (
        <RenewalIndexContainer>
            {renewalIndex}
            <Tooltip
                data-max-width="unset"
                title={
                    <>
                        <Stack direction={{ xs: 'column', sm: 'row' }}>
                            Calculated on {formatDate(calculatedOn)}
                            <Box marginLeft={{ xs: 0, sm: 0.5 }}>based on:</Box>
                        </Stack>
                        Active users: {activeUsers}<br />
                        Content views: {contentViews}<br />
                        Price: {formatPrice(priceInCents)}<br />
                    </>
                }
                placement="right"
                arrow
            >
                <InfoIcon />
            </Tooltip>
        </RenewalIndexContainer>
    );
};

type PaymentDetailsData = {
    title: string;
    value: string | JSX.Element | number;
    tooltip?: string;
};

export const getPaymentDetailsData = (
    payment: PriceHistoryModel,
    variant: 'profile' | 'udb',
    type?: string,
): PaymentDetailsData[] => {
    const isUdb = variant === 'udb';
    const {
        priceInCents,
        periodStart,
        periodEnd,
        isPaid,
        domains,
        requestedAmountOfUsers,
        actualAmountOfUsers,
    } = payment;

    const generalList: PaymentDetailsData[] = [
        { title: 'Amount:', value: formatPrice(priceInCents) },
        { title: 'Invoice:', value: payment.stripeInvoiceID ? invoiceLinkRenderer(payment, variant, true) : 'Not available' },
        { title: 'Period start:', value: formatDate(periodStart) },
        { title: 'Period end:', value: formatDate(periodEnd) },
        { title: 'Payment status:', value: isPaid ? 'Paid' : 'Not paid' },
    ];

    const coveredDomainsItem: PaymentDetailsData = {
        title: 'Covered domains:',
        value: domains?.length ?? 0,
        tooltip: domains?.length ? domains.join('\n') : undefined,
    };

    if (type === SubscriptionType.ENTERPRISE) {
        return [
            ...generalList,
            { title: 'Requested amount of users:', value: requestedAmountOfUsers ?? 'N/A' },
            { title: 'Actual amount of users:', value: actualAmountOfUsers ?? 'N/A' },
            ...(isUdb ? [
                {
                    title: 'Price per user:',
                    value: formatPrice(((priceInCents ?? 0) / (actualAmountOfUsers || 1))),
                },
                coveredDomainsItem,
                { title: 'Contract:', value: getContractLinkElement(payment) },
            ] : [
                coveredDomainsItem,
            ]),
        ];
    }

    return generalList;
};

export const getRenewalData = ({ renewal, priceInCents }: PriceHistoryModelWithRenewal) => {
    if (!renewal) {
        return;
    }

    return [
        { title: 'Renewal index:', value: getRenewalIndexElement(renewal, priceInCents) },
        { title: 'Calculated price:', value: formatPrice(renewal.calculatedPriceInCents) },
    ];
};
