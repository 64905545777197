const execWhenCondition = (conditionFn: () => boolean, fnToExec: () => void, msInterval: number) => {
    if (conditionFn()) {
        fnToExec();
        return;
    }

    setTimeout(() => execWhenCondition(conditionFn, fnToExec, msInterval), msInterval);
};

export const sleepTill = async (breakFn: () => boolean, msInterval = 50) => {
    await new Promise((resolve) => {
        execWhenCondition(breakFn, () => resolve(undefined), msInterval);
    });
};
