import React, { FC } from 'react';
import { Grid, type GridProps, Stack, StackProps } from '@mui/material';
import { StyledBox, StyledContentGrid } from './Content.styles';
import { PageVariant } from 'src/layouts/BasicLayout/BasicLayout';

type ContentProps = Pick<GridProps, 'paddingX'> & Pick<StackProps, 'spacing' | 'children'> & {
    pageVariant?: PageVariant;
};

const Content: FC<ContentProps> = ({ paddingX, pageVariant, spacing, children }) => {
    return (
        <StyledBox
            paddingTop={{ xs: 2.5, sm: 3 }}
            paddingBottom={{ xs: 4, sm: 5 }}
            paddingX={paddingX}
            textAlign="center"
            boxSizing="border-box"
        >
            <StyledContentGrid display="inline-flex" container data-page-variant={pageVariant}>
                <Grid item xs={12}>
                    <Stack spacing={spacing}>
                        {children}
                    </Stack>
                </Grid>
            </StyledContentGrid>
        </StyledBox>
    );
};

export default Content;
