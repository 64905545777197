import styled, { css } from 'styled-components';
import { Box, Typography } from '@mui/material';
import {
    StyledHeaderMenuItem,
} from 'src/layouts/BasicLayout/components/Header/components/HeaderMenuItem/HeaderMenuItem.styles';
import { NavButton } from 'src/components/NavButton';
import { theme as mainTheme } from 'src/theme';

export const nestedDropdownWrapperPosition = (props: {
    'data-position'?: 'left' | 'right';
    theme: typeof mainTheme;
}) => {
    switch (props['data-position']) {
        case 'left':
            return css`
                transform: translateX(-90%);
            `;
        case 'right':
        default:
            return css`
                right: auto;
                transform: translateX(90%);
            `;
    }
};

export const Dropdown = styled(Box)`
    position: relative;
`;

export const DropdownButton = styled(Typography)`
    &.MuiTypography-root {
        display: flex;
        align-items: center;
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 16px;
        cursor: pointer;
        user-select: none;

        svg {
            margin-left: 8px;
            transition: transform 0.3s;
        }

        & > svg path {
            fill: ${({ theme }) => theme.palette.grey};
        }

        &:hover > svg path {
            fill: ${({ theme }) => theme.palette.black};
        }
    }
`;

export const DropdownWrapper = styled(Box)`
    position: absolute;
    visibility: hidden;
    z-index: 1001;
    border-radius: 4px;
    box-shadow: ${({ theme }) => `0 0 40px 0 ${theme.palette.shadow}`};
    background: ${({ theme }) => theme.palette.white};
    right: 0;
`;

export const DropdownContent = styled(Box)`
    & ${NavButton},
    & ${StyledHeaderMenuItem}{
        display: block;
        line-height: 20px;
        text-align: left;
        padding: 12px 16px;
        font-family: ${({ theme }) => theme.fonts.medium};
        user-select: none;
    }

    & ${NavButton}:hover,
    & ${StyledHeaderMenuItem}:hover {
        background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
    }
`;

export const DropdownContainer = styled(Dropdown)`
    ${DropdownButton} > svg {
        transform: rotate(90deg);
    }

    &:last-of-type {
        & > div:first-child {
            padding-right: 0;
        }
    }
    
    &:hover {
        ${DropdownButton} > svg {
            transform: rotate(-90deg);

            & path {
                fill: ${({ theme }) => theme.palette.black};
            }
        }
        
        & > ${DropdownWrapper} {
            visibility: visible;
        }
    }
`;

export const DropdownNavButton = styled(NavButton)`
    padding: 0 20px;
    line-height: normal;
    font-family: ${({ theme }) => theme.fonts.demiBold};
    user-select: none;
`;

export const ActiveSubscriptionHeaderWrapper = styled(Box)`
    padding: 16px;
    padding-top: 20px;
    padding-bottom: 8px;
    text-align: left;
    user-select: none;
`;

export const NestedDropdownContainer = styled(DropdownContainer)`
    &&& {
        padding: 0;

        & > div {
            padding: 0;
        }

        & > ${DropdownWrapper} {
            top: -4px;
            ${nestedDropdownWrapperPosition}
        }  
    }
`;

export const NestedDropdownNavButton = styled(DropdownNavButton)`
    &&& {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: ${({ theme }) => theme.fonts.demiBold};

        ${NestedDropdownContainer}:hover & {
            background-color: ${({ theme }) => theme.palette.mainBackgroundColor};

            & > svg path {
                fill: ${({ theme }) => theme.palette.black};
            }
        }

        & > svg path {
            fill: ${({ theme }) => theme.palette.grey};
        }
    }
`;
