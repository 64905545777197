import React, { FC } from 'react';
import { Box } from '@mui/material';
import { MobileMenu, DesktopMenu } from 'src/layouts/BasicLayout/components/Header/components';

const HeaderLinks: FC = () => {
    return (
        <>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <DesktopMenu />
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <MobileMenu />
            </Box>
        </>
    );
};

export default HeaderLinks;
