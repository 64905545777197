import React, { FC } from 'react';
import { RoundedBadgeVariant, StyledChip } from './RoundedBadge.styles';

export type RoundedBadgeProps = {
    variant?: RoundedBadgeVariant;
    label: string;
};

const RoundedBadge: FC<RoundedBadgeProps> = ({
    variant = RoundedBadgeVariant.DEFAULT,
    label,
}) => (
    <StyledChip
        data-variant={variant}
        label={label}
    />
);

export default RoundedBadge;
