import React, { FC, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { useGeneralModal } from 'src/hooks';
import { JobInfo } from 'src/@types/unified-db-api';
import { DEFAULT_MODAL_MAX_WIDTH, DEFAULT_MODAL_PADDING_X } from 'src/components/Modals/BaseModal';
import { LabeledInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import { IndustrySelect, JobFunctionSelect } from 'src/components/Select';

export type EditJobInformationModalContentProps = {
    isSubmitDisabled: boolean;
    onFormChangeHandler: (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => void;
    forceChangeValues: (newValues: Partial<JobInfo>) => void;
    updatedFormValues: Partial<JobInfo>;
};

const EditJobInformationModalContent: FC<EditJobInformationModalContentProps> = ({
    isSubmitDisabled,
    onFormChangeHandler,
    forceChangeValues,
    updatedFormValues,
}) => {
    const { values, initialValues, setValues } = useFormikContext<Partial<JobInfo>>();
    const { closeModal } = useGeneralModal();

    useEffect(() => {
        const newValues = {
            companyName: updatedFormValues.companyName ?? values.companyName ?? initialValues.companyName,
            jobTitle: updatedFormValues.jobTitle ?? values.jobTitle ?? initialValues.jobTitle,
            industryId: updatedFormValues.industryId ?? values.industryId ?? initialValues.industryId,
            jobFunctionId: updatedFormValues.jobFunctionId ?? values.jobFunctionId ?? initialValues.jobFunctionId,
        };

        forceChangeValues(newValues);
        setValues(newValues);
    }, [updatedFormValues]);

    return (
        <Stack maxWidth={DEFAULT_MODAL_MAX_WIDTH} paddingX={DEFAULT_MODAL_PADDING_X}>
            <Grid container spacing={{ xs: 2.5, sm: 4 }}>
                <Grid item xs={12}>
                    <LabeledInput
                        testId="company-input"
                        name="companyName"
                        label="Company"
                        placeholder="Enter your company name"
                        flex={1}
                        isRevertBtnVisible={values.companyName !== initialValues.companyName}
                        hasRevertButton
                        onRevert={() => forceChangeValues({ ...values, companyName: initialValues.companyName })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LabeledInput
                        testId="job-title-input"
                        name="jobTitle"
                        label="Job title"
                        placeholder="Enter your job title"
                        flex={1}
                        isRevertBtnVisible={values.jobTitle !== initialValues.jobTitle}
                        hasRevertButton
                        onRevert={() => forceChangeValues({ ...values, jobTitle: initialValues.jobTitle })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <IndustrySelect
                        flex={1}
                        hasRevertButton
                        onRevert={() => forceChangeValues({ ...values, industryId: initialValues.industryId })}
                        onChangeHandler={onFormChangeHandler}
                        isRevertBtnVisible={values.industryId !== initialValues.industryId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <JobFunctionSelect
                        flex={1}
                        hasRevertButton
                        onRevert={() => {
                            forceChangeValues({ ...values, jobFunctionId: initialValues.jobFunctionId });
                        }}
                        onChangeHandler={onFormChangeHandler}
                        isRevertBtnVisible={values.jobFunctionId !== initialValues.jobFunctionId}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" spacing={{ xs: 2, sm: 2.5 }} marginTop={{ xs: 3, sm: 6 }}>
                <Button
                    minSize="small"
                    data-testid="job-information-cancel-button"
                    onClick={closeModal}
                    fullWidth
                    variant="secondary"
                >
                    Cancel
                </Button>
                <Button
                    minSize="small"
                    fullWidth
                    data-testid="job-information-submit-button"
                    disabled={isSubmitDisabled}
                    type="submit"
                >
                    Save
                </Button>
            </Stack>
        </Stack>
    );
};

export default EditJobInformationModalContent;
