import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { StyledSwitch } from 'src/components/Switch';
import { formatPrice } from 'src/services/formatters';
import { SectionTitle } from 'src/components/PageSection/PageSection.styles';
import {
    getDiscountDurationRadioButtons,
} from 'src/pages/udb/CreateSubscription/services/renewable-price-additional-info-getters';
import { BillingCycleLengthInMonth, DiscountDuration } from 'src/pages/udb/CreateSubscription/services/constants';
import Line from 'src/components/Line';
import {
    StyledFinalPrice,
    StyledSwitchLabel,
} from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep.styles';
import { RadioGroup } from 'src/components/Radio';

export type RenewalPriceAdditionalInfoProps = {
    priceInCents: number;
    hasDiscount: boolean;
    setHasDiscount: (value: boolean) => void;
    discountDuration: DiscountDuration | undefined;
    setDiscountDuration: (value: DiscountDuration) => void;
    billingCycleLength: BillingCycleLengthInMonth;
    numberOfCycles: number;
    discountDurationLength: number;
    setDiscountDurationLength: (value?: number) => void;
};

const RenewablePriceAdditionalInfo: FC<RenewalPriceAdditionalInfoProps> = ({
    priceInCents,
    hasDiscount,
    setHasDiscount,
    discountDuration,
    setDiscountDuration,
    discountDurationLength,
    billingCycleLength,
    numberOfCycles,
    setDiscountDurationLength,
}) => {
    return (
        <Stack spacing={{ xs: 2, sm: 2.5 }} paddingTop={{ xs: 0, sm: 0.5 }}>
            <Line />
            <Stack direction="row" justifyContent="space-between">
                <StyledFinalPrice>
                    {formatPrice(priceInCents)}
                </StyledFinalPrice>
                <Stack direction="row" spacing={1} alignItems="center">
                    <StyledSwitch
                        data-testid="apply-discount-switch"
                        checked={hasDiscount}
                        onClick={() => setHasDiscount(!hasDiscount)}
                    />
                    <StyledSwitchLabel>
                        Apply discount
                    </StyledSwitchLabel>
                </Stack>
            </Stack>
            {hasDiscount && (
                <Stack spacing={{ xs: 2, sm: 2.5 }}>
                    <Line />
                    <SectionTitle paddingTop={{ xs: 0, sm: 0.5 }}>
                        Discount duration
                    </SectionTitle>
                    <RadioGroup
                        selectedValue={discountDuration}
                        items={getDiscountDurationRadioButtons({
                            billingCycleLength,
                            numberOfCycles,
                            discountDuration,
                            discountDurationLength,
                            setDiscountDurationLength,
                        })}
                        onChange={setDiscountDuration}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default RenewablePriceAdditionalInfo;
