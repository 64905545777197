import { Grid, Link, Stack } from '@mui/material';
import styled from 'styled-components';
import { cellPadding } from 'src/components/DataGrid/DataGrid.styles';

export const StyledGridContainer = styled(Grid)`
    height: 100%;
    ${cellPadding}
`;

export const StyledCopyIconContainer = styled(Grid)`
    display: none;
    width: 22px;

    ${StyledGridContainer}:hover & {
        display: unset;
    }
`;

export const StyledContent = styled(Link)`
    max-width: calc(100% - 32px);
    overflow: hidden;
    text-overflow: ellipsis;
    
    &&.MuiLink-root {
        font-size: 16px;
        text-transform: none;
        text-decoration: none;
        box-sizing: border-box;
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};
    }

    ${StyledGridContainer}:hover & {
        text-overflow: initial;
    }
`;

export const StyledSubGrid = styled(Grid)`
    max-width: 100%;
    
    ${StyledGridContainer}:hover & {
        max-width: calc(100% - 30px);
    }
`;

export const StyledContentContainer = styled(Stack)`
    && {
        & svg {
            cursor: pointer;
            
            &:hover {
                filter: brightness(0.9)
            }
        }
    }
`;
