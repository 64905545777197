import React, { FC, forwardRef } from 'react';
import { LinkProps } from '@mui/material';
import { StyledLink } from './Link.styles';

const Link: FC<LinkProps & { outlineOffset?: string }> = forwardRef(function Link({ outlineOffset, ...props }, ref) {
    return (
        <StyledLink
            ref={ref}
            data-outline-offset={outlineOffset}
            underline="none"
            {...props}
        />
    );
});

export default Link;
