export const parseDomain = (url: string): string => {
    try {
        const { hostname } = new URL(url);
        return hostname;
    } catch (error) {
        return url;
    }
};

export const parseTwitterHandle = (url: string): string | null => {
    const match = url.match(/(?:twitter|x)\.com\/([a-zA-Z0-9_]+)/);
    return match ? `@${match[1]}` : null;
};
