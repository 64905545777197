import React, { FC } from 'react';
import { Box, Stack, BoxProps } from '@mui/material';
import { StyledPaper, StyledPaperVariant } from './SingleModalLayout.styles';
import { Header, type HeaderProps } from './components';
import Line from 'src/components/Line';
import { CenteredFullScreenLayout } from 'src/layouts/CenteredFullScreenLayout';

type SingleModalPageProps = {
    children: BoxProps['children'];
    testId?: string;
    variant?: StyledPaperVariant;
} & Pick<HeaderProps, 'goBackAction'>;

const SingleModalLayout: FC<SingleModalPageProps> = ({ children, goBackAction, testId, variant = 'form' }) => (
    <CenteredFullScreenLayout data-testid={testId}>
        <StyledPaper data-variant={variant}>
            <Stack>
                <Header goBackAction={goBackAction} marginY={{ sm: 3.5, xs: 3 }} />
                <Line />
                <Box
                    position="relative"
                    paddingX={{ sm: 4.5, xs: 2 }}
                    paddingTop={{ sm: 4, xs: 3 }}
                    paddingBottom={{ sm: 4.5, xs: 3 }}
                >
                    {children}
                </Box>
            </Stack>
        </StyledPaper>
    </CenteredFullScreenLayout>
);

export default SingleModalLayout;
