import React, { FC, useState } from 'react';
import { FormikHelpers } from 'formik';
import { RequestResetPasswordFormValuesType } from 'src/@types/forms';
import { Button } from 'src/components/Buttons';
import { emailValidator, validateSchemaObject } from 'src/services/validators';
import { sendResetPassword } from 'src/services/sso-api';
import { EMPTY_EMAIL_VALIDATION_ERROR, FAILED_REQUEST_RESET_PASSWORD_MESSAGE } from 'src/constants';
import { SingleModalForm } from 'src/components/Forms/components';
import { LabeledInput } from 'src/components/Inputs';
import PageTitle from 'src/components/PageTitle';

const RequestResetPasswordForm: FC = () => {
    const [isSubmitFailed, setIsSubmitFailed] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSucceeded, setSucceeded] = useState<boolean>(false);

    const initialValues: RequestResetPasswordFormValuesType = {
        email: '',
    };

    const validationSchema = validateSchemaObject({
        email: emailValidator.required(EMPTY_EMAIL_VALIDATION_ERROR),
    });

    const handleSubmit = async (
        { email }: RequestResetPasswordFormValuesType,
        { setErrors }: FormikHelpers<RequestResetPasswordFormValuesType>,
    ): Promise<void> => {
        setIsSubmitFailed(false);
        setIsSubmitting(true);
        await sendResetPassword(email)
            .then(() => {
                setSucceeded(true);
                setIsSubmitting(false);
            })
            .catch(() => {
                setIsSubmitFailed(true);
                setIsSubmitting(false);
                setErrors({ email: ' ' });
            });
    };

    if (isSucceeded) {
        return (
            <PageTitle variant="form" textAlign="center" testId="success-message">
                An email with a link to set a new password is on its way to you.<br />
                Please check your inbox.
            </PageTitle>
        );
    }

    return (
        <SingleModalForm
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            testId="request-reset-password-form"
            fromError={isSubmitFailed && FAILED_REQUEST_RESET_PASSWORD_MESSAGE}
            onFormChange={() => {
                setIsSubmitFailed(false);
            }}
            title="Reset password"
            subtitle={(
                <>
                    We will email you a link to set a new password.
                    Please note that this link is <b>one-time use only</b> and is valid for 24 hours.
                </>
            )}
            fieldsBlock={(
                <LabeledInput
                    disallowSpace
                    disabled={isSubmitting}
                    name="email"
                    label="Email address"
                    placeholder="Enter your email address"
                    hasSubmittingError={isSubmitFailed}
                />
            )}
            buttonsBlock={(
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    testId="submit-reset-password"
                >
                    Reset password
                </Button>
            )}
        />
    );
};

export default RequestResetPasswordForm;
