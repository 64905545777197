import React, { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { StyledContainer, StyledContent, StyledLinkedCell, StyledLinkedContent } from './CopyCell.styles';
import { GRID_DEFAULT_TOOLTIP_PROPS } from 'src/constants';
import { CopyIcon } from 'src/components/CopyIcon';
import { EMPTY_CELL_VALUE } from 'src/services/grid-formatters';
import StyledTooltip from 'src/components/Tooltip';

type CopyCellProps = {
    href?: string;
    content: string;
    icon?: ReactNode;
    iconWidth?: string;
};

const CopyCell: FC<CopyCellProps> = ({
    content,
    href,
    icon,
    iconWidth = '16px',
}) => {
    if (content === EMPTY_CELL_VALUE) {
        return <span>{EMPTY_CELL_VALUE}</span>;
    }

    return (
        <StyledContainer data-has-link={!!href} data-testid="copy-cell" paddingLeft={3} paddingRight={2}>
            {href ? (
                <StyledLinkedCell target="_blank" href={href}>
                    <StyledTooltip
                        placement="bottom-start"
                        disableInteractive
                        title={content}
                        arrow
                    >
                        <StyledLinkedContent>{content}</StyledLinkedContent>
                    </StyledTooltip>
                    <Stack minWidth={iconWidth}>{icon}</Stack>
                </StyledLinkedCell>
            ) : (
                <StyledTooltip
                    title={content}
                    {...GRID_DEFAULT_TOOLTIP_PROPS}
                >
                    <StyledContent>{content}</StyledContent>
                </StyledTooltip>
            )}
            <CopyIcon content={content} hiddenIcon />
        </StyledContainer>
    );
};

export default CopyCell;
