import React, { FC } from 'react';
import { Box, BoxProps } from '@mui/material';
import { ChangeEmailBox, ChangeEmailText, ChangeEmailTitle } from './styles';
import { Link } from 'src/components/Link';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { ReactComponent as BellIcon } from 'src/assets/icons/bell-icon.svg';
import { ChangeEmailCheckData } from 'src/@types/sso-api';

type ChangeEmailNotificationProps = {
    changeEmailStatus?: ChangeEmailCheckData;
    marginTop?: BoxProps['marginTop'];
    isPersonalEmail?: boolean;
    isLoading?: boolean;
    handleResend?: () => Promise<void>;
};

const getTimeToExpirationString = (dateStr: string) => {
    const now = new Date();
    const expirationDate = new Date(dateStr);

    const diff = expirationDate.getTime() - now.getTime();

    const minutes = Math.floor(diff / 1000 / 60);
    const hours = Math.floor(minutes / 60);

    return `${hours} hours and ${minutes - (hours * 60)} minutes`;
};

const ChangeEmailNotification: FC<ChangeEmailNotificationProps> = ({
    marginTop,
    isPersonalEmail = false,
    changeEmailStatus = {
        newEmail: null,
        expiration: null,
        attemptsLeft: null,
    },
    handleResend,
    isLoading,
}) => {
    if (!changeEmailStatus?.newEmail || !changeEmailStatus?.expiration) {
        return <></>;
    }

    const notificationText = isPersonalEmail ? (
        <>
            You have requested a change of email address to <span>{changeEmailStatus.newEmail}</span>.
            Please check your inbox for a confirmation email and click on the link to finish the process.
        </>
    ) : (
        <>
            A request to change email address to <span>{changeEmailStatus.newEmail}</span> has been made.
            The user needs to check their inbox for a confirmation email and click on the link to finish the process.
        </>
    );

    return (
        <ChangeEmailBox
            paddingX={{ xs: 2, md: 4.5 }}
            paddingY={{ xs: 2, md: 2.5 }}
            marginTop={marginTop}
            data-testid="change-email-notification"
            position="relative"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
        >
            {isLoading && <LoaderOverlay />}
            <BellIcon />
            <Box>
                <ChangeEmailTitle>
                    {isPersonalEmail ? 'Confirm your email address' : 'Email address to be confirmed'}
                </ChangeEmailTitle>
                <ChangeEmailText data-testid="change-email-notification-text">
                    {notificationText}
                    &nbsp;The link expires in <span>{getTimeToExpirationString(changeEmailStatus.expiration)}</span>.
                    {!!handleResend && Number(changeEmailStatus.attemptsLeft) > 0 && (
                        <Link data-testid="resend-confirmation-email" onClick={handleResend}>
                            &nbsp;Re-send confirmation email.
                        </Link>
                    )}
                </ChangeEmailText>
            </Box>
        </ChangeEmailBox>
    );
};

export default ChangeEmailNotification;
