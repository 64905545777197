import React, { Fragment } from 'react';
import { Box } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { ActivationDate, additionalWideInputWidth, BillingCycleLengthInMonth } from './constants';
import Tooltip from 'src/components/Tooltip';
import { paymentTypeRenderMapping, SubscriptionPaymentType, SubscriptionType } from 'src/constants';
import type { RadioGroupItem } from 'src/components/Radio/RadioGroup';
import { TextButton } from 'src/components/Buttons';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { ReactComponent as StripeLinkIcon } from 'src/assets/icons/stripe-link-icon.svg';
import { DatePicker } from 'src/components/DatePicker';

export const billingCycleRadioItems = [
    {
        value: BillingCycleLengthInMonth.YEAR,
        label: '1 year',
    },
    {
        value: BillingCycleLengthInMonth.MONTH,
        label: '1 month',
    },
];

export const getBillingRadioItems = (
    selectedSubscriptionType: SubscriptionType,
    handleClick?: () => void,
): Array<RadioGroupItem<SubscriptionPaymentType>> => {
    const typesToRender: Array<RadioGroupItem<SubscriptionPaymentType>> = [
        {
            value: SubscriptionPaymentType.FREE,
            label: paymentTypeRenderMapping[SubscriptionPaymentType.FREE],
        },
        {
            value: SubscriptionPaymentType.INVOICED,
            label: paymentTypeRenderMapping[SubscriptionPaymentType.INVOICED],
        },
    ];

    if (selectedSubscriptionType === SubscriptionType.INSIDER) {
        typesToRender.push(
            {
                value: SubscriptionPaymentType.STRIPE_MANAGED,
                label: paymentTypeRenderMapping[SubscriptionPaymentType.STRIPE_MANAGED],
                disabled: true,
                additionalNode: handleClick && (
                    <Fragment>
                        <Tooltip arrow title="Upgrade">
                            <Box>
                                <TextButton data-testid="stripe-link-icon" onClick={handleClick}>
                                    <StripeLinkIcon />
                                </TextButton>
                            </Box>
                        </Tooltip>
                        <Tooltip arrow title="To create a Stripe-managed subscription, client has to go through the Insider flow">
                            <InfoIcon />
                        </Tooltip>
                    </Fragment>
                ),
                additionalNodeProps: {
                    spacing: 1,
                    alignItems: 'center',
                },
            },
        );
    }

    return typesToRender;
};

export const getMinActivationDate = (date: Dayjs, billCycleLength?: BillingCycleLengthInMonth) => date
    .subtract(1, 'year')
    .add(billCycleLength === BillingCycleLengthInMonth.MONTH ? 4 : 2, 'day')
    .hour(0)
    .minute(0)
    .second(0)
;

export const getActivationDateRadioItems = (
    onChange: (date: string | undefined) => void,
    activationDate?: string | null,
    selectedType?: ActivationDate,
    billCycleLength?: BillingCycleLengthInMonth,
): Array<RadioGroupItem<ActivationDate>> => [
    {
        value: ActivationDate.NOW,
        label: 'Now',
    },
    {
        value: ActivationDate.SPECIFIED,
        label: 'Specific date & time',
        ...(selectedType === ActivationDate.SPECIFIED && {
            additionalNodeProps: {
                direction: 'column',
                spacing: 2,
            },
            additionalNode: (
                <Box maxWidth={additionalWideInputWidth}>
                    <DatePicker
                        minDate={getMinActivationDate(dayjs(), billCycleLength)}
                        initialValue={activationDate}
                        handleDateChange={onChange}
                        dateTimeOnly
                        withSeconds
                    />
                </Box>
            ),
        }),
    },
    {
        value: ActivationDate.MANUALLY,
        label: 'Activate manually later',
    },
];

export const getNumberOfCyclesWhenPaymentTypeChange = (
    subscriptionPaymentType?: SubscriptionPaymentType,
    numberOfCycles?: number,
) => {
    if (subscriptionPaymentType === SubscriptionPaymentType.INVOICED || !numberOfCycles) {
        return 1;
    }

    return numberOfCycles;
};

export const gatActivationDate = (selectedActivationDateType: ActivationDate) => {
    if ([ActivationDate.MANUALLY, ActivationDate.NOW].includes(selectedActivationDateType)) {
        return null;
    }
};
