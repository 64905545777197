import styled from 'styled-components';
import { Stack } from '@mui/material';

export const StyledStack = styled(Stack)`
    &&& {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -47%);

        & .MuiInputBase-root {
            input {
                max-width: unset;
            }
        }
    }
`;
