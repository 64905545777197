import axios, { AxiosError, AxiosInstance, isAxiosError, RawAxiosRequestHeaders } from 'axios';
import APIClientError from './api-client-error';
import TokenProvider from './token-provider';
import type { APIClientConfig, APIClientRequestConfig, APIClientResponseError } from 'src/@types/api-client';
import type { RefreshTokenRequestData, RevokeTokenRequestData, TokensResponseData } from 'src/@types/sso-api';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, SsoApiEventTypes } from 'src/constants';
import type {
    SsoAPIEvent,
    SsoApiEventHandler,
    SsoAPIEventRevokeToken,
    SsoAPIEventUpdateTokens,
} from 'src/@types/sso-api-client';

export default class SsoApiClient {
    private client?: AxiosInstance;

    readonly tokenProvider: TokenProvider;

    private eventHandlers: SsoApiEventHandler[] = [];

    constructor(tokenProvider: TokenProvider) {
        this.tokenProvider = tokenProvider;
    }

    on<H extends SsoApiEventHandler>(eventType: H['eventType'], handler: H['handler']) {
        this.eventHandlers.push({ eventType, handler });
    }

    private dispatchEvent<E extends SsoAPIEvent>(eventType: E['eventType'], payload: E['payload']) {
        this.eventHandlers.forEach((eventHandler) => {
            if (eventType === eventHandler.eventType) {
                eventHandler.handler(payload as never);
            }
        });
    }

    init(config: APIClientConfig): SsoApiClient {
        this.client = axios.create(config);

        return this;
    }

    updateTokens({ accessToken, refreshToken, payload }: TokensResponseData<true>) {
        this.tokenProvider.store({
            [ACCESS_TOKEN_KEY]: accessToken,
            [REFRESH_TOKEN_KEY]: refreshToken,
        });

        this.dispatchEvent<SsoAPIEventUpdateTokens>(SsoApiEventTypes.updateTokens, payload);
    }

    async refreshToken(clear = true) {
        const refreshToken = this.tokenProvider.getToken(REFRESH_TOKEN_KEY);

        if (clear) {
            this.tokenProvider.clear();
        }

        if (!refreshToken) {
            return;
        }

        await this.request<RefreshTokenRequestData<true>, TokensResponseData<true>>('/auth/token/refresh', {
            method: 'POST',
            data: { refreshToken, returnPayload: true, includeSettings: true },
        }).then((data) => this.updateTokens(data));
    }

    async revokeToken() {
        const logOut = async () => {
            this.tokenProvider.clear();
            this.dispatchEvent<SsoAPIEventRevokeToken>(SsoApiEventTypes.revokeToken, undefined);
        };

        const refreshToken = this.tokenProvider.getToken(REFRESH_TOKEN_KEY);
        if (!refreshToken) {
            await logOut();
            return;
        }

        const accessToken = this.tokenProvider.getToken(ACCESS_TOKEN_KEY);

        await Promise.all([
            this.request<RevokeTokenRequestData>('/auth/token/revoke', {
                method: 'POST',
                data: { refreshToken },
                headers: { Authorization: `Bearer ${accessToken}` },
            }),
            logOut(),
        ]);
    }

    getClient(): AxiosInstance {
        if (!this.client) {
            throw new APIClientError('SSO API Client instance is not initialized yet!');
        }

        return this.client;
    }

    async request<RequestDataType = object, ResponseDataType = object | string>(
        url: string,
        config?: APIClientRequestConfig<RequestDataType>,
    ): Promise<ResponseDataType> {
        const headers: RawAxiosRequestHeaders = { ...config?.headers };
        const accessToken = this.tokenProvider.getToken(ACCESS_TOKEN_KEY);
        if (accessToken && !headers.Authorization) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        return this.getClient()
            .request({ ...config, headers, url })
            .then(({ data }) => data)
            .catch((error) => {
                throw new APIClientError('SSO API Client request error', this.unwrapAxiosErrorResponse(error));
            });
    }

    private unwrapAxiosErrorResponse(error: Error | AxiosError): APIClientResponseError {
        if (!isAxiosError(error) || !error.response) {
            return { message: error.message };
        }

        const { response } = error;
        const { data, status, config } = response;
        const { baseURL, url, method, params, data: configData } = config;

        return {
            data,
            status,
            config: { baseURL, url, method, params, data: configData },
        };
    }
}
