import React, { FC } from 'react';
import { LinkedCell } from 'src/components/DataGrid/components/LinkedCell';
import { ReactComponent as LinkIcon } from 'src/assets/icons/stripe-link-icon.svg';
import { SubscriberType } from 'src/constants';

type TypeLinkProps = {
    type: SubscriberType;
    id: string;
};

export const SubscriptionLinkedCell: FC<TypeLinkProps> = ({ type, id }) => {
    return (
        <LinkedCell
            href={`/udb/subscriptions/${id}`}
            icon={<LinkIcon />}
            showOnlyTooltipText
            text={type}
            tooltipText={'Open subscription page'}
        />
    );
};
