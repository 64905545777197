import React, { FC } from 'react';
import { TypographyProps } from '@mui/material';
import { StyledTimer } from './CountDown.styles';
import { useCountDown } from 'src/hooks/useCountDown';

type CountDownProps = { endDate: string } & TypographyProps;

const CountDown: FC<CountDownProps> = ({ endDate, ...props }) => {
    const { hours, minutes, seconds } = useCountDown(endDate);

    return (
        <StyledTimer {...props}>{hours}h {minutes}m {seconds}s</StyledTimer>
    );
};

export default CountDown;
