import React, { FC, Fragment } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useReloadPage } from 'src/hooks';
import { DATE_FORMAT } from 'src/constants';
import { NonBasicUserCreatedFlow } from 'src/pages/UpgradeToInsider/components/UserCreatedContent';
import Line from 'src/components/Line';
import type { RootState } from 'src/redux/root-reducer';
import type { ConfigState, CurrentUserState, UserUpgradeState } from 'src/@types/redux';
import { configSelector, userSelector, userUpgradeSelector } from 'src/redux/slices';
import { Button } from 'src/components/Buttons';
import PageTitle from 'src/components/PageTitle';

type PaymentSuccessContentProps = {
    renewalDate: string;
};

const PaymentSuccessContent: FC<PaymentSuccessContentProps> = ({ renewalDate }) => {
    const { mainSiteUrl } = useSelector<RootState, ConfigState>(configSelector);
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);
    const { isNewUser, userUUID } = useSelector<RootState, UserUpgradeState>(userUpgradeSelector);

    const { reloadPage } = useReloadPage();
    const navigate = useNavigate();

    const handleLogIn = () => {
        navigate('/log-in');
    };

    const nextPaymentDate = dayjs(renewalDate).format(DATE_FORMAT);

    const getNotLoggedInUserButtonSet = (handleShowNewsletters: () => void) => isNewUser ? (
        <Stack spacing={{ xs: 3, sm: 4 }} data-testid="new-user-upgraded">
            <Button onClick={handleShowNewsletters}>
                Choose your newsletters
            </Button>
            <Line>or</Line>
            <Button variant="secondary" onClick={handleLogIn}>
                Log in to start reading
            </Button>
        </Stack>
    ) : (
        <Button onClick={handleLogIn} data-testid="user-upgraded" >
            Log in to start reading
        </Button>
    );

    const getButtonSet = (handleShowNewsletters: () => void) => {
        return currentUser ? (
            <Stack spacing={{ xs: 2, sm: 2.5 }} data-testid="logged-in-user-upgraded">
                <Button href={mainSiteUrl}>
                    Read the latest news
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => {
                        reloadPage();
                        navigate('/');
                    }}
                >
                    Go to profile
                </Button>
            </Stack>
        ) : getNotLoggedInUserButtonSet(handleShowNewsletters);
    };

    return (
        <NonBasicUserCreatedFlow createdAccountUUID={userUUID}>
            {(handleShowNewsletters) => (
                <Fragment>
                    <Stack spacing={3}>
                        <PageTitle
                            variant="form"
                            title="You’re now an Insider. Welcome."
                            subtitle={
                                `We’ve successfully processed your payment. Your subscription will renew automatically on ${nextPaymentDate}.`
                            }
                        />
                        {getButtonSet(handleShowNewsletters)}
                    </Stack>
                </Fragment>
            )}
        </NonBasicUserCreatedFlow>
    );
};

export default PaymentSuccessContent;
