import styled, { css } from 'styled-components';
import { Button, MenuItem, Select, Stack, Typography } from '@mui/material';
import { TextInput } from 'src/components/Inputs/TextInput';

const baseStyles = css`
  && {
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.palette.lightBlack};
  }
`;

const paginationVariantSelector = ({ 'data-variant': variant }: { 'data-variant': string }) => {
    switch (variant) {
        case 'dataGrid':
            return css`
                background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
            `;
        case 'default':
        default:
            return null;
    }
};

const pageSizeVariantSelector = ({ 'data-variant': variant }: { 'data-variant': string }) => {
    switch (variant) {
        case 'profile-section':
            return css`
                border: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
            `;
        case 'default':
        default:
            return null;
    }
};

export const StyledPagination = styled(Stack)`
  width: 100%;
    
    ${paginationVariantSelector}
`;

export const PaginationButton = styled(Button)`
  && {
    height: 36px;
    padding: 0;
    min-width: 36px;
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.black};

    &:disabled {
      opacity: 0.4;
      color: ${({ theme }) => theme.palette.black};
    }  
  }
`;

export const PaginationTypography = styled(Typography)`
  && {
      
    @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: 14px;  
    }

    ${baseStyles}   
  }
`;

export const PaginationStack = styled(Stack)`
  font-size: 16px;
    
  ${baseStyles}
`;

export const StyledCurrentNumberInput = styled(TextInput)`
  && {
    width: unset;

    .MuiInputBase-root {
      input {
        max-width: 39px;
        text-align: center;
        height: unset;
        padding: 6px 0;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
      }
        
      &:hover {
        background-color: unset;
      }
    }
  }
`;

const getWidthForSize = (size: number) => {
    switch (true) {
        case size < 100:
            return '62px';
        case size < 1000:
            return '72px';
        default:
            return '79px';
    }
};

export const PageSizeSelect = styled(Select)<{ 'data-size': number, 'data-variant': string }>`
  && {
    width: ${({ 'data-size': size }) => getWidthForSize(Number(size))};
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.black};
    height: 36px;
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.normal};

    ${pageSizeVariantSelector}
      
    && .MuiSelect-select {
       padding-right: 0;
       padding-left: 12px;
     }

    .MuiSvgIcon-root {
      cursor: pointer;  
      padding-right: 4px;
      top: auto;  
      position: relative;
      color: ${({ theme }) => theme.palette.black};
      transform: ${({ open }) => `rotate(${open ? '180deg' : '0deg'}) translateX(${open ? '15%' : '0%'})`};  
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }    
  }
`;

export const PaginationMenuItem = styled(MenuItem)`
  && {
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.palette.black}; 
  }
`;
