import React, { createContext } from 'react';
import { ToolbarItemContext } from 'src/@types/tool-bar';

export const ToolBarItemContext = createContext<ToolbarItemContext>({} as ToolbarItemContext);

type ToolBarContextProps = {
    context: ToolbarItemContext;
    children: React.ReactNode;
};

export const ToolBarContextProvider = ({ context, children }: ToolBarContextProps) => (
    <ToolBarItemContext.Provider value={context}>
        {children}
    </ToolBarItemContext.Provider>
);
