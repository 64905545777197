import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { SubscriberTypeByVertical } from 'src/@types/subscription-service-api';
import { useSnackbarMessage } from 'src/hooks';
import { getUsersTypeByVertical } from 'src/services/subscription-service-api';
import { BasicLayout, PageVariant } from 'src/layouts';
import {
    DATA_GRID_NO_ROWS_MESSAGE,
    FAILED_TO_LOAD_DATA_GRID_MESSAGE,
    GENERAL_VERTICAL_ID,
    GRID_DEFAULT_PAGE_SIZE,
    GRID_DEFAULT_PAGE_SIZE_OPTIONS,
    Order,
    PAGE_TITLES,
    PAGINATION_DEFAULT_PAGE,
    SnackbarMessageVariants,
} from 'src/constants';
import { DataGrid } from 'src/components/DataGrid';
import { usersDataGridColumns } from 'src/pages/udb/Users/schemas/user-table-schema';
import { searchUsers } from 'src/services/unified-db-api';
import { User } from 'src/@types/unified-db-api';
import { ToolBarItem } from 'src/components/ToolBar/components/ToolBarItem';
import { ToolBar } from 'src/components/ToolBar';
import { ReactComponent as SortingIcon } from 'src/assets/icons/sorting-icon.svg';
import { ReactComponent as FiltersIcon } from 'src/assets/icons/filters-icon.svg';
import { filteringSchema } from 'src/pages/udb/Users/schemas/users-filtering-schema';
import { ToolBarModelType } from 'src/@types/tool-bar';
import { getValidModel } from 'src/components/ToolBar/services/tool-bar-validators';
import { getModelFromQuery } from 'src/services/query-helpers';
import { getSortingApiParams, getSortingSchema } from 'src/services/sorting-helpers';
import { getFiltersUdbApiParams } from 'src/services/filtering-helpers';
import PageTitle from 'src/components/PageTitle';

const UsersPage: FC = () => {
    const [noDataMessage, setNoDataMessage] = useState<string>(DATA_GRID_NO_ROWS_MESSAGE);
    const [rows, setRows] = useState<User[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const page = Math.max(parseInt(`${searchParams.get('page')}`) || PAGINATION_DEFAULT_PAGE, 0);
    const pageSize = parseInt(searchParams.get('pageSize') ?? '0');
    const sortingSchema = getSortingSchema(usersDataGridColumns);
    const validFiltersModel = getValidModel(getModelFromQuery('filters', searchParams), filteringSchema);
    const validSortingModel = getValidModel(getModelFromQuery('sorting', searchParams), sortingSchema);

    const [filtersModel, setFiltersModel] = useState<ToolBarModelType>(validFiltersModel);
    const [sortingModel, setSortingModel] = useState<ToolBarModelType>(validSortingModel);

    const [pageState, setPageState] = useState<number>(page);
    const [pageSizeState, setPageSizeState] =
        useState<number>(GRID_DEFAULT_PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize : GRID_DEFAULT_PAGE_SIZE);

    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        setFiltersModel(validFiltersModel);
    }, [JSON.stringify(validFiltersModel)]);

    useEffect(() => {
        setSortingModel(validSortingModel);
    }, [JSON.stringify(validSortingModel)]);

    useEffect(() => {
        setLoading(true);
        setSearchParams((previousState) => ({
            ...previousState,
            ...(Object.values(sortingModel).length && { sorting: encodeURI(JSON.stringify(sortingModel)) }),
            ...(Object.values(filtersModel).length && { filters: encodeURI(JSON.stringify(filtersModel)) }),
            ...(pageState !== PAGINATION_DEFAULT_PAGE && { page: pageState }),
            ...(pageSizeState !== GRID_DEFAULT_PAGE_SIZE && { pageSize: pageSizeState }),
        }));
        searchUsers({
            pageSize: pageSizeState,
            page: pageState,
            filterBy: getFiltersUdbApiParams(filtersModel),
            orderBy: { createdAt: Order.DESC },
            ...getSortingApiParams(sortingModel),
        }, ['jobInfo', 'email', 'secondaryEmails', 'phoneNumber'])
            .then(async ({ data, totalCount: fetchedTotalCount }) => {
                setTotalCount(fetchedTotalCount);
                setNoDataMessage(DATA_GRID_NO_ROWS_MESSAGE);

                if (!data.length) {
                    setRows([]);
                    return;
                }

                const userUUIDs = data.map(({ uuid }) => uuid);
                const usersType = await getUsersTypeByVertical(userUUIDs)
                    .then((fetchedTypes) => fetchedTypes
                        .reduce<Record<string, Partial<SubscriberTypeByVertical>>>(
                        (typeMapping, { userUUID, types }) => ({
                            ...typeMapping,
                            [userUUID]: { ...(types.find(({ verticalId }) => verticalId === GENERAL_VERTICAL_ID)) },
                        }),
                        {},
                    ))
                    .catch(() => {
                        addMessage('Failed to fetch user types', SnackbarMessageVariants.WARNING);
                    });

                setRows(data.map((user) => ({
                    ...user,
                    ...usersType?.[user.uuid],
                })));
            })
            .catch(() => {
                setRows([]);
                setTotalCount(0);
                setNoDataMessage(FAILED_TO_LOAD_DATA_GRID_MESSAGE);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [JSON.stringify(filtersModel), JSON.stringify(sortingModel), pageSizeState, pageState]);

    return (
        <BasicLayout pageVariant={PageVariant.DATA_GRID} testId="users-page">
            <Stack
                data-testid="users-toolbar-panel"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={{ xs: 0, md: 0.5 }}
            >
                <PageTitle title={PAGE_TITLES.USERS} />
                <ToolBar>
                    <ToolBarItem
                        toolBarTitle="Filter conditions"
                        schema={filteringSchema}
                        model={filtersModel}
                        setModel={(model) => {
                            setFiltersModel(model);
                            setPageState(PAGINATION_DEFAULT_PAGE);
                        }}
                        submitLabel="Apply filter"
                        clearLabel="Clear filter"
                        selectPlaceholder="Filter by"
                        toolBarButton={{
                            icon: <FiltersIcon />,
                            label: 'Filters',
                        }}
                    />
                    <ToolBarItem
                        toolBarTitle="Select sorting type"
                        schema={sortingSchema}
                        model={sortingModel}
                        setModel={(model) => {
                            setSortingModel(model);
                            setPageState(PAGINATION_DEFAULT_PAGE);
                        }}
                        submitLabel="Sort"
                        clearLabel="Clear"
                        selectPlaceholder="Sort by"
                        toolBarButton={{
                            icon: <SortingIcon />,
                            label: 'Sorting',
                        }}
                    />
                </ToolBar>
            </Stack>
            <DataGrid
                noRowsOverlayMessage={noDataMessage}
                getRowId={({ uuid }) => uuid}
                rows={rows}
                columns={usersDataGridColumns.map((column) => ({ ...column, sortable: false }))}
                rowCount={totalCount}
                pageSize={pageSizeState}
                pageState={pageState}
                setPageState={setPageState}
                setPageSizeState={setPageSizeState}
                loading={isLoading}
                checkboxSelection
            />
        </BasicLayout>
    );
};

export default UsersPage;
