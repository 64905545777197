import React, { Dispatch, FC, SetStateAction } from 'react';
import UserItem from './UserItem';
import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { TableSection } from 'src/components/TableSection';
import { PaginationData } from 'src/@types/pagination';
import { GridColumnProps } from 'src/components/TableSection/TableSection';

type UserSectionProps = {
    isLoading: boolean;
    hasUserLink?: boolean;
    users: SubscriptionUserModel[];
    paginationModel: PaginationData;
    setPagination: Dispatch<SetStateAction<PaginationData>>;
};

const gridColumnParams: GridColumnProps = {
    name: {
        column_title: 'Name',
        md: 1.5,
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        paddingRight: 3,
    },
    email: {
        column_title: 'Email address',
        md: 1.5,
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        paddingRight: 3,
    },
    companyName: {
        column_title: 'Company',
        md: 1,
        xs: 0,
        display: { xs: 'none', md: 'flex' },
        paddingRight: 3,
    },
    jobTitle: {
        column_title: 'Position',
        md: 1,
        xs: 0,
        display: { xs: 'none', md: 'flex' },
        paddingRight: 3,
    },
    jobInfo: {
        md: 0,
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        display: { xs: 'flex', md: 'none' },
    },
};

const UserSection: FC<UserSectionProps> = ({
    paginationModel,
    setPagination,
    users,
    hasUserLink,
    isLoading,
}) => {
    const itemRenderer = (
        user: SubscriptionUserModel,
        extraProps: { gridColumnParams: GridColumnProps },
    ) => (
        <UserItem
            rowProps={extraProps.gridColumnParams}
            hasUserLink={hasUserLink}
            user={user}
        />
    );

    return (
        <TableSection
            items={users}
            isLoading={isLoading}
            sectionHeader="Enterprise users"
            noContentMessage="There are no users to be shown"
            paginationModel={paginationModel}
            setPagination={setPagination}
            renderItem={itemRenderer}
            gridColumnParams={gridColumnParams}
            headerPaddingBottom={{ xs: 0, md: 2.25 }}
            maxContentHeight={{ xs: '700px', sm: '720px', md: '450px' }}
        />
    );
};

export default UserSection;
