import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { FormikHelpers } from 'formik';
import { FORM_COLUMN_SPACING, FORM_ROW_SPACING, FORM_SEPARATOR_MARGIN_TOP } from 'src/constants';
import { Button } from 'src/components/Buttons';
import { useIsSubmitDisabled } from 'src/hooks/useIsSubmitDisabled';
import {
    socialLinkedInValidator,
    socialXValidator,
    validateSchemaObject,
    websiteValidator,
} from 'src/services/validators';
import { ProfileInfoSocial } from 'src/@types/profile-info';
import { FormWrapper } from 'src/components/Forms/components';
import { LinkedInInput, SocialXInput, WebSiteInput } from 'src/components/Inputs';

type SocialNetworksFormProps = {
    social?: ProfileInfoSocial;
    onSubmit: (
        data: Partial<ProfileInfoSocial>,
        form: FormikHelpers<Partial<ProfileInfoSocial>>
    ) => Promise<void>;
};

export const SocialNetworksForm = ({
    social = {},
    onSubmit,
}: SocialNetworksFormProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { isSubmitDisabled, onFormChangeHandler, setIsSubmitDisabled } =
        useIsSubmitDisabled<ProfileInfoSocial>(social);

    return (
        <FormWrapper<Partial<ProfileInfoSocial>>
            isSubmitting={isSubmitting}
            onFormChange={onFormChangeHandler}
            validationSchema={validateSchemaObject({
                socialX: socialXValidator.optional(),
                socialLinkedIn: socialLinkedInValidator.optional(),
                website: websiteValidator.optional(),
            })}
            initialValues={{
                socialX: social?.socialX ?? '',
                socialLinkedIn: social?.socialLinkedIn ?? '',
                website: social?.website ?? '',
            }}
            onSubmit={(
                values: Partial<ProfileInfoSocial>,
                formikHelpers: FormikHelpers<Partial<ProfileInfoSocial>>,
            ) => {
                setIsSubmitDisabled(true);
                setIsSubmitting(true);
                onSubmit(values, formikHelpers)
                    .catch(() => setIsSubmitDisabled(false))
                    .finally(() => setIsSubmitting(false));
            }}
        >
            <Grid container columnSpacing={FORM_COLUMN_SPACING} rowSpacing={FORM_ROW_SPACING}>
                <Grid item md={4} sm={6} xs={12}>
                    <LinkedInInput />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <WebSiteInput />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <SocialXInput />
                </Grid>
                <Grid item xs={12} marginTop={FORM_SEPARATOR_MARGIN_TOP} /> {/* separator */}
                <Grid item xs={12} sm={1.95}>
                    <Button
                        data-testid="social-networks-form-submit-button"
                        fullWidth
                        disabled={isSubmitDisabled}
                        type="submit"
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </FormWrapper>
    );
};
