import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledStack = styled(Stack)`
    padding: 16px 24px 16px 16px;
    border: 1px solid ${({ theme }) => theme.palette.borderGrey};
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: 4px;
`;

export const Title = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 16px;
    }
`;

export const JobDescription = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
    }
`;

export const Email = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.normal};
        word-break: break-word;
        font-size: 14px;
    }
`;
