import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { FooterButton, FooterWrapper, StyledAccessTimeIcon, TimeText } from './Footer.styles';
import { TimeZoneType } from 'src/services/profile-settings-helpers';
import { useGeneralModal } from 'src/hooks';
import { GeneralModalKey } from 'src/constants';
import { getTimeZone } from 'src/services/date-time-zone-converter';

const Footer: FC = () => {
    const timeZone = getTimeZone();
    const { openModal } = useGeneralModal();

    const handleChangeTimezone = () => {
        openModal({
            key: GeneralModalKey.settingsModal,
        });
    };

    return (
        <FooterWrapper
            data-testid="footer-container"
            direction="row"
            alignItems="center"
            paddingX={{ xs: 2, sm: 3.5 }}
        >
            <StyledAccessTimeIcon />
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
            >
                <TimeText marginRight={{ xs: 0, sm: 0.5 }}>
                    All times are shown in {timeZone === TimeZoneType.UTC
                        ? timeZone :
                        timeZone.toLowerCase()} timezone.
                </TimeText>
                <FooterButton
                    data-testid="footer-button"
                    onClick={handleChangeTimezone}
                >
                    Change timezone
                </FooterButton>
            </Stack>
        </FooterWrapper>
    );
};

export default Footer;
