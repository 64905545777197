import styled from 'styled-components';
import { TextButton } from 'src/components/Buttons';

export const StyledTextButton = styled(TextButton)`
    &&& {
        &.MuiButton-root {
            font-family: ${({ theme }) => theme.fonts.medium};
            display: inline-flex;
            gap: 8px;
        }
    }
`;
