import React, { ReactNode } from 'react';
import {
    IconWrapper,
    StyledStartInputAdornment,
} from 'src/components/Inputs/components/InputAdornment/InputAdornment.styles';

export const StartInputAdornment = ({ children }: { children: ReactNode }) => {
    return (
        <StyledStartInputAdornment position="start">
            <IconWrapper>
                {children}
            </IconWrapper>
        </StyledStartInputAdornment>
    );
};
