import React, { FC } from 'react';
import { BadgeVariant, StyledChip } from './Badge.styles';

export type BadgeProps = {
    variant?: BadgeVariant;
    label: string;
};

const Badge: FC<BadgeProps> = ({
    variant = BadgeVariant.DEFAULT,
    label,
}) => (
    <StyledChip
        variant="outlined"
        data-variant={variant}
        label={label}
    />
);

export default Badge;
