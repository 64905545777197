import { createListenerMiddleware } from '@reduxjs/toolkit';
import { setConfig } from 'src/redux/slices';
import * as intercomService from 'src/services/intercom';
import { initSsoApi } from 'src/services/sso-api';

export const setConfigListener = createListenerMiddleware();
setConfigListener.startListening({
    actionCreator: setConfig,
    effect: async ({ payload }, listenerApi) => {
        intercomService.initIntercom(payload);
        await initSsoApi(payload.ssoApiUrl, listenerApi.dispatch);
    },
});
