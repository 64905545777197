import React, { FC } from 'react';
import { Grid, type GridProps } from '@mui/material';
import { StyledArrowBackIcon, StyledHeaderContainer } from './Header.styles';
import { TextButton } from 'src/components/Buttons';
import { ReactComponent as LogoIcon } from 'src/assets/icons/endpoints-news-logo.svg';

export type HeaderProps = { goBackAction?: () => void } & Pick<GridProps, 'marginY'>;

const Header: FC<HeaderProps> = ({ goBackAction, marginY }) => {
    return (
        <StyledHeaderContainer container paddingX={{ sm: 4.5, xs: 2 }} marginY={marginY}>
            <Grid item sm={3} xs={2} display="flex" alignItems="center">
                {!!goBackAction && (
                    <TextButton onClick={goBackAction}>
                        <StyledArrowBackIcon data-testid="arrow-back" />
                    </TextButton>
                )}
            </Grid>
            <Grid
                item
                sm={6}
                xs={8}
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <LogoIcon />
            </Grid>
        </StyledHeaderContainer>
    );
};

export default Header;
