import React, { FC, useState } from 'react';
import { EditUserNameModalContent } from './index';
import {
    fullNameValidator,
    validateSchemaObject,
} from 'src/services/validators';
import { SingleModalForm } from 'src/components/Forms/components';
import { useIsSubmitDisabled } from 'src/hooks';

export type EditUserNameModalProps = {
    name: string;
    uuid: string;
};

const EditUserNameModal: FC<EditUserNameModalProps> = ({
    name,
    uuid,
}) => {
    const [isConfirming, setIsConfirming] = useState<boolean>(false);

    const initialValues = {
        name: name,
    };

    const { isSubmitDisabled, onFormChangeHandler } =
        useIsSubmitDisabled<Partial<EditUserNameModalProps>>(initialValues);

    return (
        <SingleModalForm
            testId="edit-user-form"
            onFormChange={onFormChangeHandler}
            onSubmit={() => setIsConfirming(true)}
            initialValues={initialValues}
            validationSchema={validateSchemaObject({ name: fullNameValidator })}
        >
            <EditUserNameModalContent
                isSubmitDisabled={isSubmitDisabled}
                setIsConfirming={setIsConfirming}
                isConfirming={isConfirming}
                name={name}
                uuid={uuid}
            />
        </SingleModalForm>
    );
};

export default EditUserNameModal;
