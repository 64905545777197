import React, { FC, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import type { NewsLetterSetSchema } from 'src/@types/newsletters-set';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { PageSection } from 'src/components/PageSection';
import { ChangeEmailForm, ChangePhoneNumberForm } from 'src/components/Forms';
import { fetchProfileNewsletterDelivery, updateProfileNewsletterDelivery } from 'src/services/sso-api';
import { FORM_COLUMN_SPACING, FORM_ROW_SPACING, SnackbarMessageVariants, UDBNewsletterChannel } from 'src/constants';
import { Spinner } from 'src/components/Spinner';
import { useSnackbarMessage } from 'src/hooks';
import { ProfileEmailDeliveryPreferences } from 'src/@types/sso-api';
import { NewslettersDelivery } from 'src/components/NewslettersDelivery';

const newsLetterSetSchema: NewsLetterSetSchema = {
    pageColumnParams: {
        newsletters: {
            display_title: 'block',
        },
    },
};
const ProfileDelivery: FC = () => {
    const [preferences, setPreferences] = useState<ProfileEmailDeliveryPreferences>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { pageReloadCount } = useReloadPage();
    const { addMessage } = useSnackbarMessage();

    const onCheckboxChange = (isSelected: boolean, name: string) => {
        const [id, channel] = name.split('_');

        setIsSubmitting(true);
        updateProfileNewsletterDelivery(id, { channel: channel as UDBNewsletterChannel, isSelected })
            .then(() => {
                addMessage('Changes were saved successfully', SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                addMessage('An error has occurred', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    useEffect(() => {
        fetchProfileNewsletterDelivery()
            .then(setPreferences)
            .catch(() => {
                addMessage(
                    'Failed to fetch profile newsletter delivery preferences. Please, reload the page.',
                    SnackbarMessageVariants.ERROR,
                );
            });
    }, [pageReloadCount]);

    if (!preferences) {
        return <Spinner testId="profile-delivery-container" />;
    }

    return (
        <Box data-testid="profile-delivery-container">
            <PageSection header="News delivery">
                <Grid
                    columnSpacing={FORM_COLUMN_SPACING}
                    rowSpacing={FORM_ROW_SPACING}
                    container
                >
                    <ChangeEmailForm email={preferences.email} />
                    <ChangePhoneNumberForm initialPhoneNumber={preferences.phoneNumber || ''} />
                </Grid>
            </PageSection>
            <PageSection
                sectionPaddingX={0}
                sectionPaddingBottom={0}
                sectionPaddingTop={0}
                header="Newsletters"
            >
                <NewslettersDelivery
                    onCheckboxChange={onCheckboxChange}
                    preferences={preferences}
                    newsLetterSetSchema={newsLetterSetSchema}
                    isSubmitting={isSubmitting}
                />
            </PageSection>
        </Box>
    );
};

export default ProfileDelivery;
