import styled from 'styled-components';
import { Box, Grid, Stack } from '@mui/material';
import StyledTitle from 'src/components/Title';

export const AdditionalEmailsWrapper = styled(Stack)`
    &&& {
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.palette.dirtyWhite};
        padding: 10px 16px;
    }
`;

export const PhoneNumberInputWrapper = styled(Grid)<{ 'data-phone-number-removed': boolean }>`
    &&& {
        .MuiInputBase-root {
            background-color: ${({ theme }) => theme.palette.disabledInput};
            
            fieldset {
                border-color: ${({ theme }) => theme.palette.disabledInput};
            }

            input {
                text-decoration: ${(props) => props['data-phone-number-removed'] ? 'line-through' : 'none'};
            }

            .iti__selected-dial-code {
                color: ${({ theme }) => theme.palette.grey};
            }

            .iti__arrow {
                border-top-color: ${({ theme }) => theme.palette.grey};
            }
        }
    }
`;

export const EmailWarning = styled(Box)`
    &&& {
        font-family: ${({ theme }) => theme.fonts.normal};
        font-weight: 500;
        padding: 15px 12px;
        font-size: 15px;
        background-color: ${({ theme }) => theme.palette.snackbar.warningBackground};
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            margin: 0;
        }
    }
`;

export const AdditionalEmailTitle = styled(StyledTitle)`
    &&& {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 16px; 
        width: 100%;
    }
`;
