import React, { FC, useEffect } from 'react';
import { SingleModalLayout } from 'src/layouts';
import { ssoApiClient } from 'src/services/sso-api';
import RedirectionMessage from 'src/components/RedirectionMessage';

const LogOutPage: FC = () => {
    useEffect(() => {
        ssoApiClient.revokeToken().catch(() => null);
    }, []);

    return (
        <SingleModalLayout variant="message" testId="log-out-success-page">
            <RedirectionMessage
                title="You’ve been logged out."
                link="/log-in"
                linkNameTo="your previous page"
                testId="log-out-message"
                variant="form"
            />
        </SingleModalLayout>
    );
};

export default LogOutPage;
