import React, { FC, Fragment, SyntheticEvent } from 'react';
import { Box, BoxProps, GridProps, Stack, Typography } from '@mui/material';
import { StyledHeaderName } from 'src/components/TableSection/HeaderSection/HeaderSection.styles';
import type { GridColumnParams, NewsLetterSetSchema, VerticalSchemaName } from 'src/@types/newsletters-set';
import HeaderSection from 'src/components/TableSection/HeaderSection/HeaderSection';
import { GeneralModalKey, newslettersSetGridColumns, UDBNewsletterChannel } from 'src/constants';
import { useGeneralModal } from 'src/hooks';
import { TextButton } from 'src/components/Buttons';
import { NewsletterItem } from 'src/components/NewslettersSet/components/NewsletterItem';
import { ProfileEmailDeliveryVertical, VerticalModel } from 'src/@types/sso-api';
import Line from 'src/components/Line';
import {
    NewslettersSetHeader,
    StyledSelectAllContainer,
    TitleContainer,
} from 'src/components/NewslettersSet/NewslettersSet.styles';
import { ReactComponent as LogoIcon } from 'src/assets/icons/endpoints-news-logo.svg';
import { ChangeAllLabeledCheckbox } from 'src/components/Checkbox';
import { ReactComponent as BiopharmaIcon } from 'src/assets/icons/biopharma-icon.svg';
import { ReactComponent as HealthcareIcon } from 'src/assets/icons/healthcare-icon.svg';
import { ReactComponent as CommunicationIcon } from 'src/assets/icons/general-communication-icon.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit-icon.svg';

type NewslettersSetProps = {
    newsLetterSetSchema?: NewsLetterSetSchema;
    onCheckboxChange?: (checked: boolean, name: string) => void;
    onFormChangeHandler?: (event: SyntheticEvent, values?: Record<string, boolean>) => void;
    vertical: ProfileEmailDeliveryVertical | VerticalModel;
    userNewsletters?: Record<UDBNewsletterChannel, number[]>;
    subscribeAll?: boolean;
    isSMSDisabled?: boolean;
    hasPhoneNumber?: boolean;
    isEmailDisabled?: boolean;
    isEditable?: boolean;
    userUUID?: string;
    productNameOnly?: boolean;
    hasScrollOverlay?: boolean;
    headerSectionDisplay?: BoxProps['display'];
} & Pick<BoxProps, 'paddingX' | 'paddingTop' | 'paddingBottom'>;

const getDefaultPageColumnParamsGrid = (hasDateTime: boolean, hasSampleIssues: boolean): GridColumnParams => ({
    newsletters: {
        column_title: 'Newsletter',
        md: 2,
        xs: 3.5,
    },
    date: {
        md: 1.5,
        column_title: 'Date & time',
        display: hasDateTime ? 'flex' : 'none',
        xs: 3.5,
    },
    sampleIssue: {
        column_title: 'Sample issue',
        display: hasSampleIssues ? 'flex' : 'none',
        md: 1,
        xs: 3.5,
    },
    email: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    sms: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
});

const defaultNewsLetterSetSchema: NewsLetterSetSchema = {
    biopharma: {
        titleNode: <BiopharmaIcon />,
        subtitle: 'Choose the Biopharma newsletters you want to receive.',
    },
    healthcare: {
        titleNode: <HealthcareIcon />,
        subtitle: 'Choose the Healthcare newsletters you want to receive.',
    },
    general_communications: {
        titleNode: <CommunicationIcon />,
        subtitle: 'Choose the communications you want to receive.',
        gridColumnParams: {
            sms: {
                display: 'none',
            },
        },
    },
};

const buildGridColumnParam = (
    {
        defaultNewslettersSetColumnParams = {},
        pageColumnParams = {},
        newslettersSetColumnParams = {},
        hasDateTime,
        hasSampleIssues,
    }: {
        hasSampleIssues: boolean;
        hasDateTime: boolean;
        defaultNewslettersSetColumnParams?: Partial<GridColumnParams>;
        pageColumnParams?: Partial<GridColumnParams>;
        newslettersSetColumnParams?: Partial<GridColumnParams>;
    },
): GridColumnParams => {
    return newslettersSetGridColumns.reduce<Partial<GridColumnParams>>((preparedState, key) => ({
        ...preparedState,
        [key]: {
            ...getDefaultPageColumnParamsGrid(hasDateTime, hasSampleIssues)[key],
            ...defaultNewslettersSetColumnParams[key],
            ...pageColumnParams[key],
            ...newslettersSetColumnParams[key],
        },
    }), {}) as GridColumnParams;
};

const NewslettersSet: FC<NewslettersSetProps> = ({
    paddingX = { xs: 2, md: 4.5 },
    paddingTop = { xs: 2, md: 3.5 },
    paddingBottom = { xs: 1, md: 2.5 },
    vertical,
    subscribeAll,
    onFormChangeHandler,
    onCheckboxChange,
    newsLetterSetSchema,
    isSMSDisabled,
    hasPhoneNumber,
    isEmailDisabled,
    isEditable,
    userUUID,
    productNameOnly,
    userNewsletters,
    hasScrollOverlay,
    headerSectionDisplay,
}) => {
    const { openModal } = useGeneralModal();

    const { newsletters, name, hasSampleIssues, hasDateTime, smsAllowed } = vertical;
    const shouldDisableSMSCheckbox = !smsAllowed || !hasPhoneNumber || isSMSDisabled;

    let SMSTooltipText: string | undefined = hasPhoneNumber || !smsAllowed
        ? 'Paid subscription is required to enable SMS delivery'
        : 'You need to provide phone number first';
    if (!shouldDisableSMSCheckbox || isSMSDisabled) {
        SMSTooltipText = undefined;
    }

    const paramName: VerticalSchemaName = name.toLowerCase().replace(' ', '_') as VerticalSchemaName;
    const verticalSchema = {
        titleNode: <LogoIcon />,
        ...defaultNewsLetterSetSchema[paramName],
        ...newsLetterSetSchema?.[paramName],
        gridColumnParams: buildGridColumnParam(
            {
                hasSampleIssues,
                hasDateTime,
                defaultNewslettersSetColumnParams: defaultNewsLetterSetSchema[paramName]?.gridColumnParams,
                pageColumnParams: newsLetterSetSchema?.pageColumnParams,
                newslettersSetColumnParams: newsLetterSetSchema?.[paramName]?.gridColumnParams,
            },
        ),
    };

    const shouldShowEditButton = verticalSchema?.editIcon;

    const handleEditClick = () => {
        openModal({
            key: GeneralModalKey.editNewsletterDelivery,
            title: 'Edit subscriptions',
            extraProps: {
                hasPhoneNumber,
                vertical,
                userUUID,
                userNewsletters,
            },
        });
    };

    const getItemsPaddingBottom = (index: number, length: number): GridProps['paddingBottom'] => {
        if (index === length - 1 && hasScrollOverlay) {
            return 0;
        }
    };

    const getItemsPaddingTop = (index: number): GridProps['paddingTop'] => {
        if (index === 0 && hasScrollOverlay) {
            return productNameOnly ? { sm: 1.25, xs: '1px' } : 0.25;
        }

        if (productNameOnly) {
            return { sm: 2.75, xs: 2 };
        }

        return index === 0 ? { xs: 2, md: 1.5 } : 2;
    };

    return (
        <Box
            paddingBottom={paddingBottom}
            paddingX={paddingX}
        >
            <TitleContainer
                position={hasScrollOverlay ? 'sticky' : 'initial'}
                paddingTop={paddingTop}
                zIndex={100}
                top={0}
            >
                <Stack paddingBottom={{ xs: 2, md: 3.5 }} justifyContent="space-between" alignItems="center" direction="row">
                    <NewslettersSetHeader justifyContent="center" alignItems="start">
                        {verticalSchema.titleNode}
                        {verticalSchema.subtitle && (
                            <Typography marginTop={0.75}>
                                {verticalSchema.subtitle}
                            </Typography>
                        )}
                    </NewslettersSetHeader>
                    {shouldShowEditButton && isEditable ? (
                        <TextButton data-testid="edit-newsletters-btn" onClick={handleEditClick}>
                            <EditIcon />
                        </TextButton>
                    ) : null }
                    {subscribeAll && (
                        <Stack paddingX={1.25} direction="row" spacing={{ xs: 3.75, md: 5.25 }}>
                            <StyledSelectAllContainer
                                direction="row-reverse"
                                alignItems="flex-end"
                                justifyContent="flex-start"
                                spacing={3.5}
                                display={verticalSchema.gridColumnParams.email?.display}
                            >
                                <ChangeAllLabeledCheckbox
                                    name="subscribeAll_email"
                                    itemsKey="email"
                                    label="Select all"
                                    testId="subscribe-all"
                                    onFormChangeHandler={onFormChangeHandler}
                                />
                            </StyledSelectAllContainer>
                            <StyledSelectAllContainer
                                direction="row-reverse"
                                alignItems="flex-end"
                                justifyContent="flex-start"
                                display={verticalSchema.gridColumnParams.sms?.display}
                            >
                                <ChangeAllLabeledCheckbox
                                    name="subscribeAll_sms"
                                    itemsKey="sms"
                                    label=""
                                    testId="subscribe-all_sms"
                                    disabled={shouldDisableSMSCheckbox}
                                    onFormChangeHandler={onFormChangeHandler}
                                    tooltipPlacement="bottom-start"
                                    tooltipTitle={SMSTooltipText}
                                />
                            </StyledSelectAllContainer>
                        </Stack>
                    )}
                </Stack>
                <Line />
                <Box display={headerSectionDisplay}>
                    <HeaderSection
                        paddingTop={{ xs: 2, md: 3 }}
                        paddingBottom={hasScrollOverlay ? { xs: 2, md: 1.5 } : 0}
                        gridColumnParams={verticalSchema.gridColumnParams}
                        additionalTitleNode={(
                            <Stack
                                direction="row"
                                spacing={{ xs: 1, md: 3.25 }}
                                position="absolute"
                                right={0}
                            >
                                <Stack
                                    width="45px"
                                    display={
                                        verticalSchema.gridColumnParams.email?.display_title
                                       || verticalSchema.gridColumnParams.email?.display
                                    }
                                >
                                    <StyledHeaderName>Email</StyledHeaderName>
                                </Stack>
                                <Stack
                                    width="45px"
                                    display={
                                        verticalSchema.gridColumnParams.sms?.display_title
                                           || verticalSchema.gridColumnParams.sms?.display
                                    }
                                >
                                    <StyledHeaderName textAlign="center">Sms</StyledHeaderName>
                                </Stack>
                            </Stack>
                        )}
                    />
                </Box>
            </TitleContainer>
            {newsletters.map((newsletter, index) => (
                <Fragment key={newsletter.key}>
                    {index !== 0 && <Line />}
                    <NewsletterItem
                        columnParams={verticalSchema.gridColumnParams}
                        subscribeAll={subscribeAll}
                        productNameOnly={productNameOnly}
                        newsletter={newsletter}
                        onCheckboxChange={onCheckboxChange}
                        changeHandler={onFormChangeHandler}
                        isSMSDisabled={shouldDisableSMSCheckbox}
                        isEmailDisabled={isEmailDisabled}
                        SMSTooltipText={SMSTooltipText}
                        paddingTop={getItemsPaddingTop(index)}
                        paddingBottom={getItemsPaddingBottom(index, newsletters.length)}
                    />
                </Fragment>
            ))}
        </Box>
    );
};

export default NewslettersSet;
