import dayjs, { Dayjs } from 'dayjs';
import { NewsletterModel } from 'src/@types/sso-api';
import { DATE_FORMAT, weekDaysMapping } from 'src/constants';

export const commonMessage = 'You will receive your first issue';

export const isInitialSendBeyondNextWeek = (initialSend: string): boolean => {
    const nextWeekEnd = dayjs().locale('en-gb').add(1, 'week').endOf('week');
    const initialSendDate = dayjs(initialSend);

    return initialSendDate.isAfter(nextWeekEnd);
};

export const getSentTimeText = (sendTime: string): string => {
    if (!sendTime) {
        return '';
    }

    return `, around ${sendTime} ET`;
};

const convert12To24Hours = (hours: number, timeIndicator: string): number => {
    if (hours === 12) {
        return timeIndicator.toLowerCase() === 'am' ? 0 : 12;
    }

    return hours + (timeIndicator.toLowerCase() === 'pm' ? 12 : 0);
};

export const willDeliverToday = ({
    sendTime,
    allPossibleSendDays,
    today,
}: { sendTime?: string, allPossibleSendDays: string[], today: Dayjs }): boolean => {
    const [time = '', timeIndicator = ''] = sendTime
        ?.split(/(?=am|pm)/i)
        .filter(Boolean) ?? [];
    const [hours, minutes = ''] = time.split(':');

    const hasMinutes = Boolean(minutes);
    const hoursIn24Format = convert12To24Hours(Number(hours), timeIndicator);

    const isDeliveryToday = allPossibleSendDays.some((day) => ['Daily', weekDaysMapping[today.day()]].includes(day));

    if (!isDeliveryToday) {
        return false;
    }

    if (hoursIn24Format > today.hour()) {
        return true;
    }

    if (hasMinutes && hoursIn24Format === today.hour()) {
        return Number(minutes) > today.minute();
    }

    return false;
};

export const getDeliveryMessage = ({
    today,
    sendTime,
    allPossibleSendDays,
}: {
    today: Dayjs;
    sendTime: string;
    allPossibleSendDays: string[];
}): string => {
    let nextSendDay: string | undefined = undefined;

    const weekDaysLeft = Object.entries(weekDaysMapping).reduce<string[]>((daysName, [index, name]) => {
        if (today.day() < Number(index)) {
            if (!nextSendDay && allPossibleSendDays.includes(name)) {
                nextSendDay = name;
            }
            daysName.push(name);
        }

        return daysName;
    }, []);

    const isDeliveryThisWeek = allPossibleSendDays
        .some((day) => ['Daily', ...weekDaysLeft].includes(day));

    const thisWeekSendDay = `this ${nextSendDay ?? weekDaysMapping[today.day() + 1]}`;
    const nextWeekSendDay = `next ${allPossibleSendDays[0]}`;

    return `${commonMessage} ${isDeliveryThisWeek ? thisWeekSendDay : nextWeekSendDay}${getSentTimeText(sendTime)}`;
};

export const getFirstIssueDayContent = ({ sendDay, sendTime, initialSend }: NewsletterModel): string => {
    const allPossibleSendDays = sendDay?.split(', ') ?? [];
    const today = dayjs();

    if (isInitialSendBeyondNextWeek(initialSend)) {
        return `${commonMessage} on ${dayjs(initialSend).format(DATE_FORMAT)}${getSentTimeText(sendTime)}`;
    }

    if (sendDay === 'Sometimes' || !sendDay) {
        return `${commonMessage} some time in the near future`;
    }

    if (willDeliverToday({ sendTime, allPossibleSendDays, today })) {
        return `${commonMessage} today${getSentTimeText(sendTime)}`;
    }

    return getDeliveryMessage({ sendTime, today, allPossibleSendDays });
};
