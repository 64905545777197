import dayjs from 'dayjs';
import { MenuOption } from 'src/components/Menu/Menu';
import {
    PriceHistoryModel,
    PriceHistoryModelWithRenewal,
    SubscriptionModel,
} from 'src/@types/subscription-service-api';
import { RoundedBadgeProps } from 'src/components/Badge/RoundedBadge/RoundedBadge';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import { GeneralModalKey, PaidSubscriptionStatus, PriceHistoryStatus } from 'src/constants';

export const getPaymentStatusBadgeProps = (
    { isPaid, periodStart }: PriceHistoryModel,
    { status, paymentType }: SubscriptionModel,
): RoundedBadgeProps => {
    if (isPaid) {
        return {
            label: PriceHistoryStatus.PAID,
            variant: RoundedBadgeVariant.GREEN,
        };
    }

    if (paymentType === 'free') {
        return {
            label: PriceHistoryStatus.FREE,
        };
    }

    if ([PaidSubscriptionStatus.SERVICE_ENDED, PaidSubscriptionStatus.TERMINATED].includes(status)) {
        return {
            label: PriceHistoryStatus.VOID,
            variant: RoundedBadgeVariant.GREY,
        };
    }

    if (dayjs(periodStart).isAfter(dayjs())) {
        return {
            label: PriceHistoryStatus.UPCOMING,
            variant: RoundedBadgeVariant.BLUE,
        };
    }

    return {
        label: PriceHistoryStatus.PAST_DUE,
        variant: RoundedBadgeVariant.RED,
    };
};

export const getUdbPaymentActions = (
    { renewal }: PriceHistoryModelWithRenewal,
    paymentStatus: string,
    canManage: boolean,
): MenuOption[] => {
    const actions: MenuOption[] = [
        {
            label: 'View details',
            value: GeneralModalKey.paymentDetailsModal,
        },
    ];

    if (canManage) {
        actions.push({
            label: 'Contract details',
            value: GeneralModalKey.contractDetailsModal,
        });
    }

    if (renewal && paymentStatus === PriceHistoryStatus.UPCOMING) {
        actions.push({
            label: `${renewal.disableRenewalNotifications ? 'Enable' : 'Disable'} renewal notifications`,
            value: GeneralModalKey.editPaymentRenewalNotification,
        });
    }

    return actions;
};
