import styled from 'styled-components';
import { Checkbox as MuiCheckbox } from '@mui/material';

export const StyledMUICheckbox = styled(MuiCheckbox)`
    &&& {
        padding: 0;

        &.Mui-disabled {
            cursor: no-drop;
            pointer-events: auto;

            &:hover {
                background: none;
            }
        }
    }
`;
