import styled, { css } from 'styled-components';
import { Box, Stack } from '@mui/material';

export type OverlayColor = 'darker' | 'lighter';

const backgroundOverlayColor = (props: { 'color-variant'?: OverlayColor }) => {
    switch (props['color-variant']) {
        case 'darker':
            return css`
                background-color: rgba(0, 0, 0, 0.6);
            `;
        case 'lighter':
            return css`
                background-color: rgba(0, 0, 0, 0.4);
            `;
    }
};

export const ToolBar = styled(Stack).attrs({ direction: 'row', justifyContent: 'end' })`
    position: relative;
    min-height: 40px;
    background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
    
    @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
        position: inherit;
    }
`;

export const Overlay = styled(Box)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.01);
    z-index: 500;
    
    @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
        ${backgroundOverlayColor};
    }
`;
