import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';

export const getColumnsVisibilityModel = (columns: readonly GridColDef[]): GridColumnVisibilityModel => {
    return columns.reduce<GridColumnVisibilityModel>((
        preparedModel,
        { field },
    ) => ({
        ...preparedModel,
        [field]: true,
    }), {});
};
