import React from 'react';
import { useFormikContext } from 'formik';
import { StyledSwitchStack } from './Switch.styles';
import { StyledSwitch } from 'src/components/Switch';
import { FormikValuesType } from 'src/@types/forms';

const SwitchAdornment = () => {
    const { values, setFieldValue } = useFormikContext<FormikValuesType>();

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue('contractSigned', event.target.checked);
    };

    return (
        <StyledSwitchStack direction="row" paddingX={2}>
            <StyledSwitch
                size="small"
                name="contractSigned"
                checked={!!values.contractSigned}
                onChange={handleSwitchChange}
                inputProps={{ 'aria-label': 'contract signed switch' }}
            />
        </StyledSwitchStack>
    );
};

export default SwitchAdornment;
