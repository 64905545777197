import { Box } from '@mui/material';
import styled from 'styled-components';

export const ChangeEmailBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.white};
  display: flex;
  border-radius: 4px;
  font-size: 15px;

  & svg {
    min-width: 40px;
    margin-right: 24px;
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        margin-right: 12px;
    }
  }
`;

export const ChangeEmailTitle = styled.h4`
  color: ${({ theme }) => theme.palette.black};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.bold};
  margin: 0;

  @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: 14px;
  }  
`;

export const ChangeEmailText = styled.p`
  color: ${({ theme }) => theme.palette.lightBlack};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.normal};
  margin: 0;

  span {
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.medium};
  }

  @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
     font-size: 14px;
  }
`;
