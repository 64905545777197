import styled from 'styled-components';
import { Grid } from '@mui/material';

export const StyledRequestError = styled(Grid)`
&&& {
    background-color: ${({ theme }) => theme.palette.lightRed};
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.black};
    border-radius: 5px;
    font-size: 15px;
    
    & .MuiButtonBase-root {
        color: ${({ theme }) => theme.palette.black};
        border-bottom: 1px solid ${({ theme }) => theme.palette.black};
    }
}
`;
