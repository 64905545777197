import React, { FC, SyntheticEvent, useState, ReactNode } from 'react';
import { Box, SelectProps as MuiSelectProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import {
    StyledSelect,
    StyledMenuItemSpinner,
    StyledMenuList,
    StyledSelectHeader,
    StyledSelectRoot,
    StyledSelectInput,
    StyledSelectPopover,
} from './LazyLoadSelect.styles';
import { Spinner } from 'src/components/Spinner';

type LazyLoadSelectProps = MuiSelectProps & {
    handleInputChange?: (value: string) => void;
    testId?: string;
    title?: string;
    loadNextPage?: () => void;
    isLoadingNextPage?: boolean;
    options: (visible?: boolean) => ReactNode[];
    footer?: ReactNode;
};

const LazyLoadSelect: FC<LazyLoadSelectProps> = ({
    testId,
    handleInputChange,
    title,
    loadNextPage,
    isLoadingNextPage,
    options,
    footer,
    ...props
}) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (event: SyntheticEvent) => {
        setOpen(true);
        props.onOpen?.(event);
    };

    const handleClose = (event: SyntheticEvent) => {
        setOpen(false);
        props.onClose?.(event);
    };

    const handleListScroll = (e: SyntheticEvent) => {
        const target = e.target as HTMLDivElement;
        const bottom = target.scrollHeight - target.scrollTop <= target.clientHeight + 5;
        if (bottom) {
            loadNextPage?.();
        }
    };

    return (
        <StyledSelect
            {...props}
            open={open}
            slots={{
                root: StyledSelectRoot,
                input: StyledSelectInput,
            }}
            onOpen={handleOpen}
            onClose={handleClose}
            labelId={`select-${testId}-label`}
            data-testid={`select-${testId}-element`}
            inputProps={{
                'data-testid': `select-${testId}-input`,
            }}
            MenuProps={{
                disablePortal: true,
                MenuListProps: {
                    component: 'div',
                },
                slots: {
                    root: StyledSelectPopover,
                },
            }}
        >
            <StyledSelectHeader
                container
                paddingX={3}
                paddingBottom={2.25}
                paddingTop={1.5}
                display={{ xs: 'flex', sm: 'none' }}
            >
                <Grid item xs={12} textAlign="center">
                    {title}
                </Grid>
            </StyledSelectHeader>
            <StyledMenuList
                onScroll={handleListScroll}
                data-testid={`select-${testId}-scrollable-list`}
            >
                {options(true)}
                {isLoadingNextPage && (
                    <StyledMenuItemSpinner>
                        <Box minHeight={44}>
                            <Spinner />
                        </Box>
                    </StyledMenuItemSpinner>
                )}
            </StyledMenuList>
            {options()}
            {footer && (
                <Box
                    paddingTop={1.75}
                    paddingBottom={1}
                    paddingX={2}
                >
                    {footer}
                </Box>
            )}
        </StyledSelect>
    );
};

export default LazyLoadSelect;
