import React, { FC } from 'react';
import { RadioProps } from '@mui/material';
import { StyledRadio } from './Radio.styles';
import { ReactComponent as CheckedRadioIcon } from 'src/assets/icons/radio-icon-checked.svg';
import { ReactComponent as RadioIcon } from 'src/assets/icons/radio-icon.svg';

const Radio: FC<RadioProps> = (props) => {
    return (
        <StyledRadio
            {...props}
            icon={<RadioIcon />}
            checkedIcon={<CheckedRadioIcon />}
        />
    );
};

export default Radio;
