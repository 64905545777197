import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { FormikHelpers } from 'formik';
import { SignedText } from './ContractDetailsModal.styles';
import { SwitchAdornment } from 'src/components/Inputs/Adornments/Switch';
import { INVALID_SALESFORCE_CONTRACT_LINK } from 'src/constants';
import { contractLinkValidator, validateSchemaObject } from 'src/services/validators';
import { LabeledInput } from 'src/components/Inputs';
import { useGeneralModal, useIsSubmitDisabled, useReloadPage } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import { FormWrapper } from 'src/components/Forms/components';
import { updatePriceHistory } from 'src/services/subscription-service-api';
import { PriceHistoryModel, PriceHistoryModelWithRenewal } from 'src/@types/subscription-service-api';

export type ContractDetailsModalProps = {
    payment: PriceHistoryModelWithRenewal;
    uuid: string;
};

const ContractDetailsModal: FC<ContractDetailsModalProps> = ({ payment, uuid }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const initialValues: Partial<PriceHistoryModel> = {
        contractLink: payment.contractLink || '',
        contractSigned: payment.contractSigned,
    };

    const { onFormChangeHandler, isSubmitDisabled, setIsSubmitDisabled } = useIsSubmitDisabled(initialValues);
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();

    const isAdd = !payment.contractLink && !payment.contractSigned;

    const handleSaveContractDetails = async (
        values: Partial<PriceHistoryModel>,
        helpers: FormikHelpers<Partial<PriceHistoryModel>>,
    ) => {
        setIsSubmitting(true);
        setIsSubmitDisabled(true);

        const dataToUpdate: Partial<PriceHistoryModel> = {
            contractSigned: values.contractSigned,
            contractLink: values.contractLink,
        };

        if (payment.contractLink && !values.contractLink) {
            setIsSubmitting(false);

            helpers.setErrors({
                contractLink: INVALID_SALESFORCE_CONTRACT_LINK,
            });
            return;
        }

        return updatePriceHistory(uuid, payment.id, dataToUpdate)
            .then(() => {
                closeModal();
                reloadPage();
            })
            .catch(() => setIsSubmitDisabled(false))
            .finally(() => setIsSubmitting(false));
    };

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            testId="contract-link-form"
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            onSubmit={handleSaveContractDetails}
            validationSchema={validateSchemaObject({ contractLink: contractLinkValidator })}
        >
            <Stack position="relative">
                <SignedText>Signed</SignedText>
                <LabeledInput
                    disallowSpace
                    flexGrow={1}
                    placeholder="Add contract link"
                    name="contractLink"
                    label="Contract link"
                    testId="contract-link-input"
                    endAdornment={<SwitchAdornment />}
                />
                <Stack direction="row" marginTop={3} spacing={{ xs: 2, sm: 2.5 }}>
                    <Button onClick={closeModal} minSize="small" variant="secondary" fullWidth>Cancel</Button>
                    <Button disabled={isSubmitting || isSubmitDisabled} minSize="small" fullWidth type="submit">
                        {isAdd ? 'Add' : 'Update'}
                    </Button>
                </Stack>
            </Stack>
        </FormWrapper>
    );
};

export default ContractDetailsModal;
