import { RadioGroup, Stack } from '@mui/material';
import React, { FC } from 'react';
import { IconContainer, StyledIconCaption, StyledStack } from './SubscriptionTypeRadioGroup.styles';
import { Radio } from 'src/components/Radio';
import { SubscriptionType } from 'src/constants';
import { ReactComponent as EndpointsInsider } from 'src/assets/icons/endpoints-insider-logo.svg';
import { ReactComponent as EndpointsEnterprise } from 'src/assets/icons/endpoints-enterprise-logo.svg';

type SubscriptionTypeRadioGroupProps = {
    selectedSubscriptionType: SubscriptionType | undefined;
    onChange: (value: SubscriptionType) => void;
};

const groupItems = [
    { value: SubscriptionType.INSIDER, icon: <EndpointsInsider /> },
    { value: SubscriptionType.ENTERPRISE, icon: <EndpointsEnterprise /> },
];

const SubscriptionTypeRadioGroup: FC<SubscriptionTypeRadioGroupProps> = ({
    selectedSubscriptionType,
    onChange,
}) => {
    return (
        <RadioGroup
            value={selectedSubscriptionType ?? ''}
        >
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, sm: 3 }} justifyContent="center">
                {groupItems.map(({ value, icon }) => (
                    <StyledStack
                        data-testid={`${value}-type-radio`}
                        onClick={() => onChange(value)}
                        data-selected={value === selectedSubscriptionType}
                        key={value}
                        direction="row"
                        justifyContent="space-between"
                        paddingX={{ xs: 2, sm: 3 }}
                        paddingY={{ xs: 2.5, sm: 3 }}
                        spacing={{ xs: 5 }}
                        boxSizing="border-box"
                        width="100%"
                    >
                        <IconContainer spacing={{ xs: 1, sm: 1.5 }}>
                            {icon}
                            <StyledIconCaption>
                                Individual license that unlocks premium features
                            </StyledIconCaption>
                        </IconContainer>
                        <Stack justifyContent="center">
                            <Radio value={value} />
                        </Stack>
                    </StyledStack>
                ))}
            </Stack>
        </RadioGroup>
    );
};

export default SubscriptionTypeRadioGroup;
