export const buildStipeInvoiceHref = (stripeInvoiceID: string) => {
    return `https://dashboard.stripe.com/invoices/${stripeInvoiceID}`;
};

export const buildStripeSubscriptionHref = (stripeSubscriptionId: string) => {
    return `https://dashboard.stripe.com/subscriptions/${stripeSubscriptionId}`;
};

export const buildStripeCustomerHref = (stripeCustomerId: string) => {
    return `https://dashboard.stripe.com/customers/${stripeCustomerId}`;
};
