import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SingleModalLayout } from 'src/layouts';
import { Spinner } from 'src/components/Spinner';
import { changeEmail } from 'src/services/sso-api';
import { accessCodeSelector, userSelector } from 'src/redux/slices';
import RedirectionMessage from 'src/components/RedirectionMessage';
import { APIClientResponseHTTPError } from 'src/@types/api-client';
import { LinkExpiredContent } from 'src/components/LinkExpiredContent';
import type { AccessCodeState, CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

export const ChangeEmailPage = () => {
    const { accessCode, isValid } = useSelector<RootState, AccessCodeState>(accessCodeSelector);

    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [errorStatus, setErrorStatus] = useState<number>(0);

    const errorTitle = errorStatus === 500 ? 'Failed to update email' : 'This link has expired';
    const errorSubTitle = errorStatus === 500 ? 'Please try again or contact us to get assistance' : '';

    const handleChangeEmailRequest = async () => {
        await changeEmail({ accessCode: accessCode as string })
            .catch((e) => {
                setErrorStatus((e.responseError as APIClientResponseHTTPError).status);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    useEffect(() => {
        if (isValid === undefined) {
            return;
        }

        if (isValid) {
            handleChangeEmailRequest();
            return;
        }

        setErrorStatus(400);
        setIsSubmitting(false);
    }, [isValid]);

    if (isSubmitting) {
        return <Spinner />;
    }

    return (
        <SingleModalLayout testId="change-email-page">
            {errorStatus === 0 ? (
                <RedirectionMessage
                    title="Your email has been changed."
                    linkNameTo={currentUser ? 'the Delivery preferences page' : 'the Login page'}
                    link={currentUser ? '/delivery' : '/log-in'}
                    testId="redirect-after-change-email"
                />
            ) : (
                <LinkExpiredContent
                    mainButtonLink={currentUser ? '/delivery' : '/log-in'}
                    mainButtonText={currentUser ? 'Change preferences' : 'Log in to change preferences'}
                    testId="change-email-error-content"
                    titleText={errorTitle}
                    subTitleText={errorSubTitle}
                />
            )}
        </SingleModalLayout>
    );
};
