import React, { FC, useEffect, useState } from 'react';
import { BoxProps } from '@mui/material';
import { LabeledSelect } from 'src/components/Select/LabeledSelect';
import { OptionsType } from 'src/components/Select/Select';
import { SnackbarMessageVariants } from 'src/constants';
import { getCountriesList } from 'src/services/user-profile-api';
import { useSnackbarMessage } from 'src/hooks';

type CountrySelectProps = {
    onChangeHandler?: (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => void;
    onRevert?: () => void;
    hasRevertButton?: boolean;
    isRevertBtnVisible?: boolean;
} & BoxProps;

export const CountrySelect: FC<CountrySelectProps> = ({
    onChangeHandler,
    hasRevertButton,
    onRevert,
    isRevertBtnVisible,
    ...boxProps
}) => {
    const [countriesList, setCountriesList] = useState<OptionsType[]>([]);

    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getCountriesList()
            .then((data) => (
                Object.entries(data)
                    .map(([value, label]) => ({ value, label }))
                    .sort((a, b) => a.label.localeCompare(b.label))
            ))
            .catch(() => {
                addMessage('Failed to fetch countries list', SnackbarMessageVariants.ERROR);

                return [];
            })
            .then((data) => setCountriesList([{ value: '', label: 'None' }, ...data]));
    }, []);

    return (
        <LabeledSelect
            testId="country-input"
            name="country"
            label="Country"
            placeholder="Select country"
            isRevertBtnVisible={isRevertBtnVisible}
            onFormChange={onChangeHandler}
            options={countriesList}
            hasRevertButton={hasRevertButton}
            onRevert={onRevert}
            {...boxProps}
        />
    );
};
