import dayjs from 'dayjs';
import { AccessibleSubscription, SubscriptionModel } from 'src/@types/subscription-service-api';
import { ProfileAccessibleSubscription, SubscriberTypeModel } from 'src/@types/sso-api';
import {
    GENERAL_VERTICAL_ID,
    PaidSubscriptionStatus,
    paymentTypeMapping,
    SubscriberType,
    SubscriptionPaymentStatus,
    SubscriptionPaymentType,
} from 'src/constants';

export const getSubscriberType = (subscriberTypes: Array<SubscriberTypeModel>): SubscriberType => {
    return subscriberTypes
        .find(({ verticalId }) => verticalId === GENERAL_VERTICAL_ID)
        ?.type || SubscriberType.basic;
};

export const isOnGracePeriod = (
    { expirationDate, gracePeriodEndDate }: Pick<SubscriptionModel, 'expirationDate' | 'gracePeriodEndDate'>,
): boolean => {
    const currentUTC = dayjs.utc();
    return currentUTC.isAfter(expirationDate) && currentUTC.isBefore(gracePeriodEndDate);
};

export const getSubscriptionPaymentStatus = (
    subscription: SubscriptionModel | AccessibleSubscription | ProfileAccessibleSubscription,
): SubscriptionPaymentStatus | null => {
    const isActive = subscription.status === PaidSubscriptionStatus.ACTIVE;
    const isPaused = subscription.status === PaidSubscriptionStatus.PAUSED;

    if (!isActive && !isPaused) {
        return null;
    }

    if (isOnGracePeriod(subscription)) {
        return SubscriptionPaymentStatus.ON_GRACE_PERIOD;
    }

    if ('customAttributes' in subscription && subscription.customAttributes?.subscriptionCanceled === '1') {
        return SubscriptionPaymentStatus.WILL_NOT_RENEW;
    }

    return null;
};

export const getPersonLabelForSubscription = (subscription: ProfileAccessibleSubscription): string => {
    const { isOwner, isManager, isUser } = subscription;
    const labels = isUser ? [] : ['Not a user'];

    if (isOwner) {
        labels.unshift('Owner');
    } else if (isManager) {
        labels.unshift('Manager');
    }

    return labels.join(' | ');
};

export const getPaymentTypeLabel = (paymentType: SubscriptionPaymentType) => {
    return paymentTypeMapping[paymentType];
};
