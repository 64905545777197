import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { GeneralModalState, OpenModalActionPayload } from 'src/@types/redux';
import { useGeneralModal } from 'src/hooks/useGeneralModal';
import { generalModalSelector } from 'src/redux/slices';
import type { RootState } from 'src/redux/root-reducer';

type ConfirmationModalProps = {
    onConfirm: () => Promise<void>;
    onCancel?: () => void;
};

export const useConfirmationModal = ({
    onConfirm,
    onCancel,
}: ConfirmationModalProps) => {
    const { openModal, closeModal, updateModal } = useGeneralModal();
    const {
        extraProps: {
            confirmedModalId,
            cancelledModalId,
        },
    } = useSelector<RootState, GeneralModalState>(generalModalSelector);
    const [modalId] = useState<string>(Math.random().toString());

    const openModalWithId = ({ extraProps = {}, ...restPayload }: OpenModalActionPayload) => (
        openModal({ ...restPayload, extraProps: { ...extraProps, modalId } })
    );

    useEffect(() => {
        if (confirmedModalId === modalId) {
            onConfirm().finally(closeModal);
        }
    }, [confirmedModalId]);

    useEffect(() => {
        if (cancelledModalId === modalId) {
            onCancel?.();
            closeModal();
        }
    }, [cancelledModalId]);

    return {
        openModal: openModalWithId,
        closeModal,
        updateModal,
        modalId,
    };
};
