import React, { FC } from 'react';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { SnackbarMessageVariants } from 'src/constants';
import { useSnackbarMessage } from 'src/hooks';
import { changePassword } from 'src/services/sso-api';
import { SingleModalLayout } from 'src/layouts';
import { ResetPasswordForm } from 'src/components/Forms/ResetPasswordForm';

const ForceChangePasswordPage: FC = () => {
    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();

    const handleSubmit = async (newPassword: string, repeatNewPassword: string) => {
        await changePassword({ newPassword, repeatNewPassword })
            .then(() => {
                reloadPage();
                addMessage('Password changed successfully', SnackbarMessageVariants.SUCCESS);
            });
    };

    return (
        <SingleModalLayout testId="force-change-password-form">
            <ResetPasswordForm onSubmit={handleSubmit} forceChangePassword submitLabel="Change password" />
        </SingleModalLayout>
    );
};

export default ForceChangePasswordPage;
