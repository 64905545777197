import React, { FC, useState } from 'react';
import { Box, Stack, StackProps } from '@mui/material';
import { ButtonWrapper } from './SearchManagerForm.styles';
import { searchUsers } from 'src/services/unified-db-api';
import { User } from 'src/@types/unified-db-api';
import { SnackbarMessageVariants } from 'src/constants';
import { LabeledInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import { emailValidator, validateSchemaObject } from 'src/services/validators';
import { FormWrapper } from 'src/components/Forms/components';
import { useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import { FormikValuesType } from 'src/@types/forms';

type SearchManagerFormProps = {
    setRequestResult: (data: User) => void;
    setIsLoading: (isLoading: boolean) => void;
    paddingX?: StackProps['paddingX'];
    paddingBottom?: StackProps['paddingBottom'];
    hideLabel?: boolean;
    initialEmail?: string;
};

const SearchManagerForm: FC<SearchManagerFormProps> = ({
    setRequestResult,
    setIsLoading,
    paddingX,
    paddingBottom,
    hideLabel,
    initialEmail = '',
}) => {
    const [showButton, setShowButton] = useState<boolean>(false);

    const initialValue = {
        searchEmail: initialEmail,
    };

    const { addMessage } = useSnackbarMessage();
    const {
        isSubmitDisabled,
        onFormChangeHandler,
        setIsSubmitDisabled,
    } = useIsSubmitDisabled(initialValue);

    const handleSubmit = (values: FormikValuesType) => {
        setIsLoading(true);
        setIsSubmitDisabled(true);

        searchUsers({
            filterBy: {
                filters: [
                    {
                        name: 'emails.primary',
                        value: '1',
                        comparison: '=',
                    },
                    {
                        name: 'emails.email',
                        value: values.searchEmail,
                        comparison: '=',
                    },
                ],
            },
        }, [
            'email',
            'jobInfo',
        ])
            .then((response) => {
                setRequestResult(response.data[0]);
            })
            .catch(() => {
                addMessage('Failed to search email', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitDisabled(false);
                setIsLoading?.(false);
            });
    };

    return (
        <Box data-testid="search-manager-wrapper">
            <FormWrapper
                data-testid="search-manager-form"
                onFormChange={onFormChangeHandler}
                validationSchema={validateSchemaObject({ searchEmail: emailValidator })}
                initialValues={initialValue}
                onSubmit={handleSubmit}
            >
                <Stack
                    spacing={1}
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="flex-start"
                    paddingX={paddingX}
                    paddingBottom={paddingBottom}
                >
                    <LabeledInput
                        testId="search-manager-input"
                        disallowSpace
                        name="searchEmail"
                        label={hideLabel ? '' : 'Email address'}
                        placeholder="Enter email"
                        inputProps={{ required: true }}
                        flex={1}
                        onFocus={() => setShowButton(true)}
                        onBlur={() => setShowButton(!isSubmitDisabled)}
                    />
                    {showButton && (
                        <ButtonWrapper paddingTop={{ xs: 3, sm: hideLabel ? 0 : 2.75 }}>
                            <Button
                                data-testid="search-manager-submit-button"
                                disabled={isSubmitDisabled}
                                type="submit"
                                variant="primary"
                                minSize="small"
                                fullWidth
                            >
                                Find
                            </Button>
                        </ButtonWrapper>
                    )}
                </Stack>
            </FormWrapper>
        </Box>
    );
};

export default SearchManagerForm;
