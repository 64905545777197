import React, { FC, useState } from 'react';
import { FormikHelpers } from 'formik';
import { Button } from 'src/components/Buttons';
import { emailValidator, validateSchemaObject } from 'src/services/validators';
import { sendLogInCode } from 'src/services/sso-api';
import { EMPTY_EMAIL_VALIDATION_ERROR, FAILED_REQUEST_LOG_IN_LINK_MESSAGE } from 'src/constants';
import { RequestLogInLinkFormValuesType } from 'src/@types/forms';
import { SingleModalForm } from 'src/components/Forms/components';
import { LabeledInput } from 'src/components/Inputs';
import PageTitle from 'src/components/PageTitle';

const RequestLogInLinkForm: FC = () => {
    const [isSubmitFailed, setIsSubmitFailed] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSucceeded, setSucceeded] = useState<boolean>(false);

    const initialValues: RequestLogInLinkFormValuesType = {
        email: '',
    };

    const validationSchema = validateSchemaObject({
        email: emailValidator.required(EMPTY_EMAIL_VALIDATION_ERROR),
    });

    const handleSubmit = async (
        { email }: RequestLogInLinkFormValuesType,
        { setErrors }: FormikHelpers<RequestLogInLinkFormValuesType>,
    ) => {
        setIsSubmitFailed(false);
        setIsSubmitting(true);
        await sendLogInCode(email)
            .then(() => {
                setSucceeded(true);
                setIsSubmitting(false);
            })
            .catch(() => {
                setIsSubmitFailed(true);
                setIsSubmitting(false);
                setErrors({ email: ' ' });
            });
    };

    if (isSucceeded) {
        return (
            <PageTitle variant="form" textAlign="center" testId="success-message">
                An email with your magic link is on its way to you.<br />
                Please check your inbox
            </PageTitle>
        );
    }

    return (
        <SingleModalForm
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            testId="request-log-in-link-form"
            onFormChange={() => {
                setIsSubmitFailed(false);
            }}
            fromError={isSubmitFailed && FAILED_REQUEST_LOG_IN_LINK_MESSAGE}
            title="Request magic link"
            subtitle={(
                <>
                    If you’re already an Endpoints subscriber, enter your email below for a magic link
                    that lets you log in quickly without using a password.
                    Please note the magic link is <b>one-time use only</b> and expires after 24 hours.
                </>
            )}
            fieldsBlock={(
                <LabeledInput
                    disallowSpace
                    disabled={isSubmitting}
                    name="email"
                    label="Email address"
                    placeholder="Enter your email address"
                    hasSubmittingError={isSubmitFailed}
                />
            )}
            buttonsBlock={(
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    testId="submit-log-in-link"
                >
                    Send magic link
                </Button>
            )}
        />
    );
};

export default RequestLogInLinkForm;
