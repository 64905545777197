import { Features } from 'src/@types/user-profile-api';
import SignUpSuccessPage from 'src/pages/SignUpSuccess/SignUpSuccess';
import { EnterpriseCalculatorPage } from 'src/pages/udb/EnterpriseCalculator';
import { ManageUserPage } from 'src/pages/udb/ManageUser';
import { SubscriptionTACPage } from 'src/pages/SubscriptionTAC';
import { SubscriberPage } from 'src/pages/Subscriber';
import { CheckoutResultPage, UpgradeToInsiderPage } from 'src/pages/UpgradeToInsider';
import { CommunicationPreferencesPage } from 'src/pages/CommunicationPreferences';
import { CredentialsPage } from 'src/pages/Credentials';
import type { CurrentUserState } from 'src/@types/redux';
import { UserRoles } from 'src/constants';
import { LogInPage } from 'src/pages/LogIn';
import { RequestLogInLinkPage } from 'src/pages/RequestLogInLink';
import { RequestResetPasswordPage } from 'src/pages/RequestResetPassword';
import { ResetPasswordPage } from 'src/pages/ResetPassword';
import { LogInLinkPage } from 'src/pages/LogInLink';
import { LogOutPage } from 'src/pages/LogOut';
import { NotFoundPage } from 'src/pages/NotFound';
import UdbRouter from 'src/pages/udb/UdbRouter';
import { SpinnerPage } from 'src/pages/Spinner';
import { ApplicationsPage } from 'src/pages/Applications';
import { SubscriptionsPage } from 'src/pages/udb/Subscriptions';
import { ChangeEmailPage } from 'src/pages/ChangeEmail';
import { ServerErrorPage } from 'src/pages/ServerError';
import { ProfilePage } from 'src/pages/Profile';
import { SignUp } from 'src/pages/SignUp';
import { UsersPage } from 'src/pages/udb/Users';
import { OneClickSubscribePage } from 'src/pages/OneClickSubscribe';
import { UdbSubscriptionManage } from 'src/pages/udb/UdbSubscriptionManage';
import { RequestAQuotePage } from 'src/pages/RequestAQuote';
import { CreateSubscriptionPage } from 'src/pages/udb/CreateSubscription';

export const canUserAccessPage = (
    { currentUser, signUpData }: CurrentUserState,
    page: string,
    features?: Features,
): boolean => {
    switch (page) {
        case LogInPage.name:
        case LogInLinkPage.name:
        case RequestLogInLinkPage.name:
        case RequestResetPasswordPage.name:
        case ResetPasswordPage.name:
        case CommunicationPreferencesPage.name:
        case SignUp.name:
            return !currentUser;

        case SignUpSuccessPage.name:
            return !!signUpData;

        case ApplicationsPage.name:
            return !!currentUser && [UserRoles.ADMIN, UserRoles.APP_MANAGER].includes(currentUser.role);

        case EnterpriseCalculatorPage.name:
        case CreateSubscriptionPage.name:
            return !!currentUser && [UserRoles.ADMIN, UserRoles.USER_MANAGER].includes(currentUser.role);

        case UdbRouter.name:
        case CredentialsPage.name:
        case SubscriptionsPage.name:
        case UsersPage.name:
        case UdbSubscriptionManage.name:
        case ManageUserPage.name:
            return !!currentUser && [UserRoles.ADMIN, UserRoles.USER_MANAGER, UserRoles.ANALYST]
                .includes(currentUser.role);

        case SubscriberPage.name:
        case RequestAQuotePage.name:
        case CheckoutResultPage.name:
        case UpgradeToInsiderPage.name:
            return !!features?.accountUpgrade;

        case NotFoundPage.name:
        case LogOutPage.name:
        case SpinnerPage.name:
        case ChangeEmailPage.name:
        case ServerErrorPage.name:
        case OneClickSubscribePage.name:
        case SubscriptionTACPage.name:
            return true;

        default:
            return !!currentUser;
    }
};

export const getAccessDeniedRedirectionLink = (pageName: string): string => {
    switch (pageName) {
        case ProfilePage.name:
            return '/log-in';
        case CommunicationPreferencesPage.name:
            return '/delivery';
        default:
            return '/';
    }
};
