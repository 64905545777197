import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearTFAAccessCode, userSelector } from 'src/redux/slices';
import { LogInForm, TwoFactorAuthLogInForm } from 'src/components/Forms';
import { SingleModalLayout } from 'src/layouts';
import { CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

const LogInPage: FC = () => {
    const dispatch = useDispatch();
    const { tfaAccessCode } = useSelector<RootState, CurrentUserState>(userSelector);

    const goBackAction = () => {
        dispatch(clearTFAAccessCode());
    };

    if (tfaAccessCode) {
        return (
            <SingleModalLayout goBackAction={goBackAction} testId="2fa-log-in-page">
                <TwoFactorAuthLogInForm accessCode={tfaAccessCode} />
            </SingleModalLayout>
        );
    }

    return (
        <SingleModalLayout testId="log-in-page">
            <LogInForm />
        </SingleModalLayout>
    );
};

export default LogInPage;
