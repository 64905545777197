import { useEffect, useState } from 'react';
import { getManagers } from 'src/services/subscription-service-api';
import { searchUsers } from 'src/services/unified-db-api';
import { User } from 'src/@types/unified-db-api';
import { useSnackbarMessage } from 'src/hooks';
import { SnackbarMessageVariants } from 'src/constants';

const PAGE_SIZE = 20;

export const useSubscriptionManagersData = (subscriptionUUID: string): {
    managers: User[] | null;
    owner: User | null;
    isLoading: boolean;
    loadNextPage: () => void;
    refresh: () => Promise<void>;
} => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [managers, setManagers] = useState<User[] | null>(null);
    const [owner, setOwner] = useState<User | null>(null);
    const [lastSuccessfulPage, setLastSuccessfulPage] = useState<number>(0);
    const [shouldLoad, setShouldLoad] = useState<boolean>(true);
    const [isCompletelyLoaded, setIsCompletelyLoaded] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();

    const loadManagers = async (refresh: boolean) => {
        if (!shouldLoad && !refresh) {
            return;
        }

        setIsLoading(true);

        return getManagers(subscriptionUUID, { pageSize: PAGE_SIZE, page: lastSuccessfulPage + 1 })
            .then(async (response) => {
                const managersUUID = response.data.map((manager) => manager.userUUID);
                const ownerUUID = response.data.find((manager) => manager.isOwner)?.userUUID;
                const totalCount = response.totalCount;

                await searchUsers({
                    filterBy: {
                        filters: [
                            {
                                name: 'uuid',
                                value: managersUUID,
                                comparison: 'anyOf',
                            },
                        ],
                    },
                    pageSize: PAGE_SIZE,
                    page: 1,
                }, [
                    'email',
                ])
                    .then(({ data }) => {
                        const newManagers = [...managers ?? [], ...data].sort(
                            (item) => item.uuid === ownerUUID ? -1 : 0,
                        );

                        if (newManagers.length >= totalCount) {
                            setIsCompletelyLoaded(true);
                        }

                        setManagers(newManagers);

                        if (!owner || refresh) {
                            setOwner(newManagers.find((manager) => manager.uuid === ownerUUID) ?? null);
                        }
                    })
                    .catch(() => {
                        addMessage('Failed to get relevant managers data', SnackbarMessageVariants.ERROR);
                    });
            })
            .catch(() => {
                addMessage('Failed to get managers', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsLoading(false);
                setShouldLoad(false);
                setLastSuccessfulPage((prev) => prev + 1);
            });
    };

    const loadNextPage = () => {
        if (isCompletelyLoaded) {
            return;
        }

        setShouldLoad(true);
    };

    const refresh = () => {
        setLastSuccessfulPage(0);
        return loadManagers(true);
    };

    useEffect(() => {
        loadManagers(false);
    }, [subscriptionUUID, lastSuccessfulPage, shouldLoad]);

    return {
        isLoading,
        managers,
        owner,
        loadNextPage,
        refresh,
    };
};
