import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledPanelHeader = styled(Typography)`
  && {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.lightBlack};
    font-family: ${({ theme }) => theme.fonts.bold};
  }
`;

export const StyledPanelItem = styled(Typography)`
  && {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.medium};
  }
`;
