import { UDBNewsletterChannel } from 'src/constants';
import { TransformedValues, VerticalsFormValues } from 'src/components/NewslettersDelivery/NewslettersDelivery';

const sendDayMapping: Record<string, string> = {
    Sometimes: 'Two to three times a week',
    Daily: 'Business days',
};

export const dateTimeFormatter = (sendDay: string | undefined, sendTime: string | undefined): string | undefined => {
    if (!sendDay) {
        return;
    }

    if (!sendTime) {
        return `${sendDayMapping[sendDay] ?? sendDay}`;
    }

    return `${sendDayMapping[sendDay] ?? sendDay} at ${sendTime.replace(/am|pm/, (value) => ` ${value.toUpperCase()} ET`)}`;
};

export const getFormattedValues = ({ unsubscribeAll, ...restValues }: VerticalsFormValues) => {
    return Object.keys((restValues)).reduce<TransformedValues>((preparedValues, key) => {
        const [id, channel] = key.split('_');
        const currentValue = { [id]: preparedValues[id] ?? [] };

        if (Number(restValues[key])) {
            currentValue[id].push(channel as UDBNewsletterChannel);
        }

        return {
            ...preparedValues,
            ...currentValue,
        };
    }, {});
};
