import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { SnackbarMessageVariants } from 'src/constants';
import { getCheckoutConfig } from 'src/services/sso-api';
import { FinalPriceRowTitle, FinalPriceRowValue } from 'src/pages/udb/CreateSubscription/components/FinalPriceRow';
import { PageSection } from 'src/components/PageSection';
import { PercentInput } from 'src/components/Inputs/PercentInput';
import { RadioGroup } from 'src/components/Radio';
import {
    BillingCycleLengthInMonth,
    DiscountDuration,
    DiscountType,
    PricingModel,
} from 'src/pages/udb/CreateSubscription/services/constants';
import {
    discountTypeRadioButtons,
    getDiscountAmount,
    getPricingModelRadioButtons,
} from 'src/pages/udb/CreateSubscription/services/pricing-terms-step-getters';
import PriceInput from 'src/components/Inputs/PriceInput';
import {
    FinalPriceContainer,
    StyledCheckboxLabel,
} from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep.styles';
import { Checkbox } from 'src/components/Checkbox';
import { useSnackbarMessage } from 'src/hooks';

export type InsiderPricingTermsData = {
    selectedPricingModel?: PricingModel;
    discountDuration?: DiscountDuration;
    selectedDiscountType?: DiscountType;
    discountAmount?: number;
    discountDurationLength?: number;
    hasDiscount?: boolean;
    priceInCents?: number;
};

type InsiderPricingTermsProps = {
    billingCycleLength: BillingCycleLengthInMonth;
    setInsiderData: Dispatch<SetStateAction<InsiderPricingTermsData | undefined>>;
    initialData?: InsiderPricingTermsData;
    numberOfCycles: number;
};

const InsiderPricingTerms: FC<InsiderPricingTermsProps> = ({
    billingCycleLength,
    numberOfCycles,
    setInsiderData,
    initialData = {},
}) => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [cachedHasDiscount, setCachedHasDiscount] = useState<boolean>(false);
    const [cachedDiscountDuration, setCachedDiscountDuration] = useState<DiscountDuration>();
    const [cachedDiscountType, setCachedDiscountType] = useState<DiscountType>();
    const [cachedDiscountAmount, setCachedDiscountAmount] = useState<number>();
    const [cachedPriceInCents, setCachedPriceInCents] = useState<number>();
    const [productPriceInCents, setProductPriceInCents] = useState<number>(0);

    const { addMessage } = useSnackbarMessage();

    const {
        selectedPricingModel,
        discountDuration,
        selectedDiscountType,
        discountDurationLength = numberOfCycles,
        discountAmount = 0,
        hasDiscount = false,
        priceInCents = productPriceInCents,
    } = initialData;

    useEffect(() => {
        getCheckoutConfig()
            .then(({ product }) => {
                if (product?.priceInCents) {
                    setProductPriceInCents(product.priceInCents);
                }
            })
            .catch(() => {
                addMessage('Failed to get checkout config', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        const hasDiscountModel = selectedPricingModel === PricingModel.RENEWABLE_PRICE;

        setCachedHasDiscount(hasDiscount);
        setCachedDiscountDuration(discountDuration);
        setCachedDiscountType(selectedDiscountType);
        setCachedDiscountAmount(discountAmount);

        setInsiderData((prevState) => ({
            ...prevState,
            priceInCents,
            hasDiscount: hasDiscountModel ? cachedHasDiscount : false,
            discountDuration: hasDiscountModel ? cachedDiscountDuration : undefined,
            selectedDiscountType: hasDiscountModel ? cachedDiscountType : undefined,
            discountAmount: hasDiscountModel ? cachedDiscountAmount : 0,
        }));
    }, [selectedPricingModel]);

    const setData = (
        key: keyof InsiderPricingTermsData,
    ) => (
        value: InsiderPricingTermsData[keyof InsiderPricingTermsData],
    ) => {
        setInsiderData({ ...initialData, [key]: value });
    };

    const handleDiscountTypeChange = (newType: DiscountType) => {
        setInsiderData(
            (previousState) => ({
                ...previousState,
                selectedDiscountType: newType,
                discountAmount: getDiscountAmount(discountAmount, newType, previousState?.selectedDiscountType),
            }),
        );
    };

    return (
        <Fragment>
            <PageSection isLoading={isLoading} variant="headless" header="Pricing model" testId="insider-pricing-model">
                <RadioGroup<PricingModel>
                    selectedValue={selectedPricingModel}
                    items={getPricingModelRadioButtons({
                        priceInCents,
                        numberOfCycles,
                        hasDiscount,
                        discountDuration,
                        billingCycleLength,
                        selectedPricingModel,
                        discountDurationLength,
                        setPrice: setData('priceInCents'),
                        setHasDiscount: setData('hasDiscount'),
                        setDiscountDuration: setData('discountDuration'),
                        setDiscountDurationLength: setData('discountDurationLength'),
                    })}
                    onChange={(newModel) => {
                        setCachedPriceInCents(priceInCents);
                        setInsiderData(
                            (previousState) => ({
                                ...previousState,
                                priceInCents: newModel === PricingModel.RENEWABLE_PRICE
                                    ? productPriceInCents
                                    : cachedPriceInCents,
                                selectedPricingModel: newModel,
                            }),
                        );
                    }}
                />
            </PageSection>
            {hasDiscount && (
                <PageSection variant="headless" header="Discount type">
                    <RadioGroup
                        selectedValue={selectedDiscountType}
                        items={discountTypeRadioButtons}
                        onChange={handleDiscountTypeChange}
                        containerProps={{
                            direction: 'row',
                            spacing: { xs: 0, sm: 6 },
                            flexWrap: 'wrap',
                            justifyContent: { xs: 'space-between', sm: 'flex-start' },
                            maxWidth: { xs: '330px', sm: '100%' },
                        }}
                    />
                    {selectedDiscountType && (
                        <Box maxWidth="110px" paddingTop={2}>
                            {selectedDiscountType === DiscountType.PERCENTAGE ? (
                                <PercentInput
                                    value={discountAmount}
                                    onChange={setData('discountAmount')}
                                />
                            ) : (
                                <PriceInput
                                    maxPrice={priceInCents}
                                    initialPrice={discountAmount}
                                    handleChange={setData('discountAmount')}
                                    currency="$"
                                />
                            )}
                        </Box>
                    )}
                </PageSection>
            )}
            {selectedPricingModel && (
                <PageSection variant="headless" header="Resulting price to be paid">
                    <Stack spacing={2}>
                        <FinalPriceContainer direction="row" alignItems="center" justifyContent="space-between" padding={{ xs: 2.5, sm: 3 }}>
                            <FinalPriceRowTitle
                                hasDiscount={hasDiscount}
                                selectedDiscountType={selectedDiscountType}
                                discountAmount={discountAmount}
                            />
                            <FinalPriceRowValue
                                priceInCents={priceInCents}
                                hasDiscount={hasDiscount}
                                discountAmount={discountAmount}
                                selectedDiscountType={selectedDiscountType}
                            />
                        </FinalPriceContainer>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={{ xs: 1.5, sm: 2 }}
                        >
                            <Checkbox />
                            <StyledCheckboxLabel>
                                Create invoice
                            </StyledCheckboxLabel>
                        </Stack>
                    </Stack>
                </PageSection>
            )}
        </Fragment>
    );
};

export default InsiderPricingTerms;
