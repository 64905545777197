import { useEffect, useState } from 'react';

type Entity = { id: number, name: string };
type FetchFunction<T> = () => Promise<T>;

export const useLookupNamesHook = <T extends { [key: string]: string } | Entity[]>(
    fetchFunction: FetchFunction<T>,
    id: string | number | null | undefined,
    notFoundMessage: string = 'N/A',
): string => {
    const [entityName, setEntityName] = useState<string>(notFoundMessage);

    useEffect(() => {
        let isMounted = true;

        if (!id) {
            setEntityName(notFoundMessage);
            return;
        }

        fetchFunction().then((data) => {
            const name = !Array.isArray(data)
                ? data[id] || notFoundMessage
                : (data as Entity[]).find((item) => item.id.toString() === id.toString())?.name || notFoundMessage;

            if (isMounted) {
                setEntityName(name);
            }
        })
            .catch(() => {
                if (isMounted) {
                    setEntityName('Error fetching');
                }
            });

        return () => {
            isMounted = false;
        };
    }, [id]);

    return entityName;
};
