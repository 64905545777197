import styled, { css } from 'styled-components';
import { Chip } from '@mui/material';

export enum BadgeVariant {
    BLUE = 'blue',
    GOLD = 'gold',
    DEFAULT = 'default',
}

const variantMapping = {
    [BadgeVariant.GOLD]: css`
        color: ${({ theme }) => theme.palette.gold};
    `,
    [BadgeVariant.BLUE]: css`
        color: ${({ theme }) => theme.palette.blue};
    `,
    [BadgeVariant.DEFAULT]: css`
        color: ${({ theme }) => theme.palette.lightBlack};
    `,
};

const variantSelector = (props: { 'data-variant': BadgeVariant }) => variantMapping[props['data-variant']];

export const StyledChip = styled(Chip)`
    && {
        height: 26px;
        font-size: 14px;
        padding: 8px 10px;
        border-radius: 2px;
        cursor: default;
        line-height: normal;
        text-transform: capitalize;
        font-family: ${({ theme }) => theme.fonts.demiBold};
        border: 1px solid ${({ theme }) => theme.palette.mainBorderColor};
        
        & .MuiChip-label {
            padding: 0;
        }
        
        ${variantSelector}
    }
`;
