import React, { type FC, type ReactElement } from 'react';
import { Stack, type StackProps } from '@mui/material';
import { type PageTitleVariant, StyledArrowBackIcon, StyledPageSubtitle, StyledPageTitle } from './styles';

export type PageTitleProps = Omit<StackProps, 'title'> & {
    title?: string | ReactElement;
    subtitle?: string | ReactElement;
    testId?: string;
    variant?: PageTitleVariant;
    handleGoBack?: () => void;
};

const PageTitle: FC<PageTitleProps> = ({ handleGoBack, title, subtitle, children, variant = 'page', testId, ...stackProps }) => {
    const spacing = stackProps?.textAlign !== 'center' ? { xs: 0.5, sm: 1 } : { xs: 1, sm: 1.5 };

    return (
        <Stack data-testid={testId} data-variant={variant} spacing={spacing} {...stackProps}>
            {(!!title) && (
                <StyledPageTitle data-variant={variant} data-testid="page-title">
                    {handleGoBack && (
                        <StyledArrowBackIcon onClick={handleGoBack} />
                    )}
                    {title}
                </StyledPageTitle>
            )}
            {(!!subtitle || children) && (
                <StyledPageSubtitle data-has-title={!!title} data-variant={variant} data-testid="page-subtitle">
                    {subtitle || children}
                </StyledPageSubtitle>
            )}
        </Stack>
    );
};

export default PageTitle;
