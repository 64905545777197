import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { NewsLetterSetSchema } from 'src/@types/newsletters-set';
import { NewslettersDelivery } from 'src/components/NewslettersDelivery';
import PageTitle from 'src/components/PageTitle';
import { BasicLayout, CenteredFullScreenLayout } from 'src/layouts';
import { fetchEmailDelivery, updateEmailDelivery } from 'src/services/sso-api';
import { NewsletterPreferences, ProfileEmailDeliveryPreferences } from 'src/@types/sso-api';
import { Spinner } from 'src/components/Spinner';
import * as intercomService from 'src/services/intercom';
import { StyledEmail, StyledTextButton } from 'src/pages/CommunicationPreferences/CommunicationPreferences.styles';
import { APIClientResponseHTTPError } from 'src/@types/api-client';
import { ServerErrorPage } from 'src/pages/ServerError';
import { NotFoundPage } from 'src/pages/NotFound';
import { configSelector } from 'src/redux/slices';
import { type TransformedValues } from 'src/components/NewslettersDelivery/NewslettersDelivery';
import { useSnackbarMessage } from 'src/hooks';
import { SnackbarMessageVariants, UDBNewsletterChannel } from 'src/constants';
import { ConfigState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

export const newsLetterSetSchema: NewsLetterSetSchema = {
    pageColumnParams: {
        email: {
            display_title: 'none',
        },
        sms: {
            display: 'none',
        },
    },
};

const CommunicationPreferencesPage: FC = () => {
    const { uuid = '' } = useParams<{ uuid: string }>();
    const { addMessage } = useSnackbarMessage();
    const { mainSiteUrl } = useSelector<RootState, ConfigState>(configSelector);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<APIClientResponseHTTPError>();
    const [preferences, setPreferences] = useState<ProfileEmailDeliveryPreferences>();

    const handleContactUsClick = () => {
        intercomService.showIntercom();
    };

    const handleSubmit = async (values: TransformedValues) => {
        const newsletters = Object.keys(values)
            .filter((id) => !isNaN(Number(id)))
            .map((id): NewsletterPreferences => ({
                id: Number(id),
                isSelected: values[id].includes(UDBNewsletterChannel.EMAIL),
            }));

        return updateEmailDelivery(uuid, { newsletters })
            .then(() => (
                fetchEmailDelivery(uuid).then(setPreferences)
            ))
            .then(() => {
                addMessage('Changes were saved successfully', SnackbarMessageVariants.SUCCESS);
            })
            .catch((updateError) => {
                addMessage('An error has occurred', SnackbarMessageVariants.ERROR);
                throw new Error(updateError);
            });
    };

    useEffect(() => {
        fetchEmailDelivery(uuid)
            .then(setPreferences)
            .catch(({ responseError }) => setError(responseError))
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return (
            <CenteredFullScreenLayout>
                <Spinner />
            </CenteredFullScreenLayout>
        );
    }

    if ([404, 403].includes(error?.status as number)) {
        return <NotFoundPage />;
    }

    if (error) {
        return <ServerErrorPage />;
    }

    return (
        <BasicLayout
            logoLinkTo={mainSiteUrl}
            testId="delivery-by-uuid-page"
        >
            <PageTitle
                title={
                    <>
                        Manage communication preferences for
                        <StyledEmail variant="caption">
                            {` ${preferences?.email}`}
                        </StyledEmail>
                    </>
                }
                spacing={0.5}
            >
                 If this is not your email address, ignore this page. If you have questions, please&nbsp;
                <StyledTextButton data-testid="contact-us" onClick={handleContactUsClick}>contact us</StyledTextButton>
            </PageTitle>
            <NewslettersDelivery
                paddingTop={0.5}
                marginTop={{ xs: 2, sm: 4 }}
                preferences={preferences}
                onSubmit={handleSubmit}
                newsLetterSetSchema={newsLetterSetSchema}
                headerSectionDisplay={{ xs: 'none', md: 'block' }}
            />
        </BasicLayout>
    );
};

export default CommunicationPreferencesPage;
