import styled from 'styled-components';
import { Form } from 'formik';

export const StyledFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledNavButton = styled.button`
  padding: 0;
  font-size: 12px;
  border: none;
  background: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.palette.grey};
  
  &:disabled{
    cursor: default;
    opacity: 0.5;
  }
`;
