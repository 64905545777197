import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { ActivationDate } from 'src/pages/udb/CreateSubscription/services/constants';
import { SuccessMessage } from 'src/pages/udb/CreateSubscription/components/SuccessMessage';
import { calculateDates, createSubscription } from 'src/services/subscription-service-api';
import {
    getPricingTermsData,
    getTypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/services/confirmation-step-getters';
import { DomainInfoBox } from 'src/components/DomainInfoBox';
import { UserInfoBox } from 'src/components/UserInfoBox';
import { SubscriptionPaymentType, SubscriptionType, UDB_DATE_TIME_FORMAT } from 'src/constants';
import { InfoBox } from 'src/components/InfoBox';
import { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import type {
    TypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep/TypeAndBillingStep';
import { PageSection } from 'src/components/PageSection';
import { StepContainer } from 'src/pages/udb/CreateSubscription/components/StepContainer';
import { User } from 'src/@types/unified-db-api';

type ConfirmationStepProps = {
    handleGoBack: () => void;
    typeAndBillingData: Required<TypeAndBillingData>;
    pricingTermsData: PricingTermsData;
    ownerData: User;
    reloadPage: () => void;
};

const ConfirmationStep: FC<ConfirmationStepProps> = ({
    handleGoBack,
    typeAndBillingData,
    pricingTermsData,
    ownerData,
    reloadPage,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCreated, setIsCreated] = useState<boolean>(false);
    const [expirationDate, setExpirationDate] = useState<string>();

    const isFreeInsider = typeAndBillingData.selectedPaymentType === SubscriptionPaymentType.FREE
        && typeAndBillingData.selectedSubscriptionType === SubscriptionType.INSIDER;

    useEffect(() => {
        if (
            typeAndBillingData.selectedActivationDateType === ActivationDate.SPECIFIED
            && typeAndBillingData.activationDate
        ) {
            setIsLoading(true);
            calculateDates({
                cycleCount: typeAndBillingData.numberOfCycles,
                cycleLengthInMonths: typeAndBillingData.selectedBillingCycleLength,
                activationDate: dayjs(typeAndBillingData.activationDate).format(UDB_DATE_TIME_FORMAT),
            })
                .then(({ expirationDate: calculatedExpirationDate }) => {
                    setExpirationDate(calculatedExpirationDate);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, []);

    if (isCreated) {
        return (
            <SuccessMessage
                createdSubscriptionUUID={''}
                ownerEmail={ownerData.email.email}
                paymentType={typeAndBillingData.selectedPaymentType}
                subscriptionType={typeAndBillingData.selectedSubscriptionType}
                reloadPage={reloadPage}
            />
        );
    }

    return (
        <StepContainer
            isLoading={isLoading}
            handleGoBack={handleGoBack}
            handleNextButtonClick={() => {
                setIsLoading(true);
                createSubscription()
                    .then(() => {
                        setIsCreated(true);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }}
            showNextButton
            showGoBackButton
            title="Confirmation"
            nextButtonTitle="Create"
            testId="confirmation-step"
        >
            <PageSection variant="headless" header="Subscription type and billing terms">
                <InfoBox items={getTypeAndBillingData(typeAndBillingData, expirationDate)} />
            </PageSection>
            {!isFreeInsider && (
                <PageSection variant="headless" header="Pricing terms">
                    <InfoBox
                        items={getPricingTermsData(pricingTermsData, typeAndBillingData)}
                    />
                </PageSection>
            )}
            {typeAndBillingData.selectedSubscriptionType === SubscriptionType.ENTERPRISE && (
                <PageSection variant="headless" header="Domains">
                    <Stack spacing={1.5}>
                        {pricingTermsData.enterprise!.domains.map((domain) => (
                            <DomainInfoBox key={domain.domain} domain={domain} />
                        ))}
                    </Stack>
                </PageSection>
            )}
            <PageSection variant="headless" header="Owner account">
                <UserInfoBox data={ownerData} isManager={false} />
            </PageSection>
        </StepContainer>
    );
};

export default ConfirmationStep;
