import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SingleModalLayout } from 'src/layouts';
import type { RootState } from 'src/redux/root-reducer';
import { type AccessCodeState } from 'src/@types/redux';
import { accessCodeSelector, setUserUpgrade } from 'src/redux/slices';
import { SpinnerPage } from 'src/pages/Spinner';
import { LinkExpiredContent } from 'src/components/LinkExpiredContent';
import { verifyCheckoutResult } from 'src/services/sso-api';

const CheckoutResultPage: FC = () => {
    const { accessCode, isValid } = useSelector<RootState, AccessCodeState>(accessCodeSelector);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const paymentIntentId = searchParams.get('payment_intent');

    useEffect(() => {
        if (isValid && paymentIntentId) {
            verifyCheckoutResult(accessCode, paymentIntentId)
                .then(({ isNewUser, userUUID, expirationDate }) => {
                    dispatch(setUserUpgrade({
                        expirationDate,
                        isNewUser,
                        userUUID,
                        isCheckoutSucceeded: true,
                    }));
                    navigate('/upgrade/insider');
                })
                .catch(() => setErrorMessage('Payment verification failed'));
            return;
        }

        if (isValid === false || !paymentIntentId) {
            setErrorMessage(!paymentIntentId ? 'This link has incomplete data' : 'This link has expired');
        }
    }, [accessCode, isValid, paymentIntentId]);

    if (errorMessage) {
        return (
            <SingleModalLayout testId="upgrade-account-invalid-message">
                <LinkExpiredContent
                    mainButtonLink="/upgrade/insider"
                    mainButtonText="Try to upgrade your account again"
                    titleText={errorMessage}
                    subTitleText="We couldn't verify your payment. Please try again or contact us."
                />
            </SingleModalLayout>
        );
    }

    return <SpinnerPage />;
};

export default CheckoutResultPage;
