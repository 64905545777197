import styled from 'styled-components';
import { Typography } from '@mui/material';

export const SearchTitle = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 16px;
        
        @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
        }
    }
`;

export const OwnerLablel = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 16px;

        @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
        }
    }
`;
