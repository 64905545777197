import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { StyledFooterText, CustomStyledPageSection, StyledLink } from './SubscriptionTAC.styles';
import endpointsTerms from './constants';
import { TermItem } from './index';
import Line from 'src/components/Line';
import StyledTitle from 'src/components/Title';
import { BasicLayout } from 'src/layouts';

const SubscriptionTACPage: FC = () => {
    return (
        <BasicLayout testId="subscription-terms-page">
            <CustomStyledPageSection
                sectionPaddingTop={{ xs: 1, md: 1.5 }}
                sectionPaddingBottom={{ xs: 3, md: 4 }}
                header="ENDPOINTS NEWS PAID SUBSCRIPTION TERMS AND CONDITIONS"
            >
                <Stack paddingTop={{ xs: 0, md: 0 }} marginBottom={{ xs: -1, md: -2 }}>
                    <Line />
                </Stack>
                {endpointsTerms.map((section, index) => (
                    section.title && (
                        <Stack key={index}>
                            <Stack flexDirection="row" gap={0.5} paddingTop={{ xs: 4, md: 6 }}>
                                <StyledTitle>
                                    {index + 1}.
                                </StyledTitle>
                                <StyledTitle>
                                    {section.title}
                                </StyledTitle>
                            </Stack>
                            <TermItem content={section.content} />
                        </Stack>
                    )
                ))}
                <Stack paddingY={{ xs: 3, md: 4 }}>
                    <Line />
                </Stack>
                <Stack alignItems="center">
                    <StyledFooterText data-testid="footer-section">
                      If you have any questions please contact us at:
                        <StyledLink underline="none" href="mailto:subscriptions@endpointsnews.com"> subscriptions@endpointsnews.com</StyledLink>
                    </StyledFooterText>
                </Stack>
            </CustomStyledPageSection>
        </BasicLayout>
    );
};

export default SubscriptionTACPage;
