import { SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { User } from 'src/@types/unified-db-api';

export const prepareSubscriptionUserData = (
    users: User[],
    ownerData: { userUUID: string, isOwner: boolean, isManager?: boolean }[],
): SubscriptionUserModel[] => {
    const usersMap = users.reduce<Record<string, User>>((acc, current) => {
        acc[current.uuid] = current;
        return acc;
    }, {});

    const formattedManagers = ownerData.map(({ userUUID, isManager, isOwner }): SubscriptionUserModel => ({
        userUUID,
        isOwner,
        isManager,
        fullName: usersMap[userUUID]?.fullName ?? '',
        jobTitle: usersMap[userUUID]?.jobInfo?.jobTitle ?? '',
        email: usersMap[userUUID]?.email?.email ?? '',
        companyName: usersMap[userUUID]?.jobInfo?.companyName ?? '',
    }));

    return formattedManagers.sort((a, b) => Number(b.isOwner) - Number(a.isOwner));
};
