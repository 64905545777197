import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { FormControl, SelectProps as MuiSelectProps, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    StyledArrowBackIcon,
    StyledInputPlaceholder,
    StyledListItemText,
    StyledMenuItem,
    StyledSelect,
    StyledSelectHeader,
} from 'src/components/Select/Select.styles';
import { Checkbox } from 'src/components/Checkbox';

export type OptionsType = {
    value: string;
    label: string;
    disabled?: boolean;
};

type SelectProps = MuiSelectProps & {
    handleInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    testId?: string;
    options: OptionsType[];
    hasHeader?: boolean;
    title?: string;
};

export const Select = ({
    testId,
    options,
    handleInputChange,
    hasHeader,
    title,
    ...props
}: SelectProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (event: SyntheticEvent) => {
        setOpen(true);
        props.onOpen?.(event);
    };

    const handleClose = (event: SyntheticEvent) => {
        setOpen(false);
        props.onClose?.(event);
    };

    const toggleSelectOpen = () => {
        setOpen(!open);
    };

    return (
        <FormControl fullWidth>
            {!props.value && <StyledInputPlaceholder id={`select-${testId}-placeholder`}>{props.placeholder}</StyledInputPlaceholder>}
            <StyledSelect
                {...props}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                labelId={`select-${testId}-label`}
                data-testid={`select-${testId}-element`}
                inputProps={{
                    'data-testid': `select-${testId}-input`,
                    onChange: handleInputChange,
                }}
                IconComponent={() => (
                    <ArrowDropDownIcon onClick={toggleSelectOpen} />
                )}
                MenuProps={{
                    disablePortal: true,
                }}
            >
                {hasHeader && (
                    <StyledSelectHeader
                        container
                        paddingX={3}
                        paddingBottom={2.25}
                        paddingTop={1.5}
                        display={{ xs: 'flex', sm: 'none' }}
                    >
                        <Grid item sm={3} xs={2}>
                            <StyledArrowBackIcon
                                data-testid={`${testId}-arrow-back`}
                                onClick={(event: SyntheticEvent) => {
                                    props.onClose?.(event);
                                    setOpen(false);
                                }}
                            />
                        </Grid>
                        <Grid item sm={6} xs={8} textAlign="center">
                            {title}
                        </Grid>
                    </StyledSelectHeader>
                )}
                {options.map(({
                    label,
                    value,
                    disabled,
                }) => (
                    <StyledMenuItem
                        disabled={disabled}
                        data-testid={`select-${testId}-${value}`}
                        key={value}
                        value={value}
                    >
                        {props.multiple && Array.isArray(props.value) ? (
                            <Stack direction="row" spacing={1.5}>
                                <Checkbox checked={props.value.indexOf(value) > -1} />
                                <StyledListItemText primary={label} />
                            </Stack>
                        ) : label}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
        </FormControl>
    );
};
