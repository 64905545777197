import React, { createRef, FC, RefObject, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Splitted2FACodeInput } from 'src/components/Inputs';
import { tfaLogIn } from 'src/services/sso-api';
import { SnackbarMessageVariants, TFA_CODE_LENGTH } from 'src/constants';
import { SingleModalForm } from 'src/components/Forms/components';
import { Button } from 'src/components/Buttons';
import PageTitle from 'src/components/PageTitle';
import { useSnackbarMessage } from 'src/hooks';
import { clearTFAAccessCode } from 'src/redux/slices';

export type TwoFactorAuthLogInFormValues = {
    code: string;
};

type TwoFactorAuthLogInFormProps = {
    accessCode: string;
};

const TwoFactorAuthLogInForm: FC<TwoFactorAuthLogInFormProps> = ({ accessCode }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [code, setCode] = useState<string>('');

    const dispatch = useDispatch();
    const { addMessage } = useSnackbarMessage();

    const submitButtonRef: RefObject<HTMLButtonElement> = createRef<HTMLButtonElement>();
    const initialValues: TwoFactorAuthLogInFormValues = {
        code,
    };

    useEffect(() => {
        if (code.length === TFA_CODE_LENGTH) {
            submitButtonRef.current?.focus();
        }
    }, [code]);

    const handle2FACodeChange = (newCode: string) => {
        setCode(newCode);
    };

    const handle2FALogIn = async () => {
        setIsSubmitting(true);
        tfaLogIn({ code, accessCode })
            .catch((error) => {
                setCode('');
                const logInAccessCodeExpired = error.responseError.status === 401;
                addMessage(
                    `Login attempt has ${logInAccessCodeExpired ? 'expired' : 'failed'}. Please try again`,
                    SnackbarMessageVariants.ERROR,
                );

                if (logInAccessCodeExpired) {
                    dispatch(clearTFAAccessCode());
                }

            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <SingleModalForm
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            onSubmit={handle2FALogIn}
            testId="2fa-log-in-form"
        >
            <PageTitle
                variant="form"
                testId="form-title"
                title={`Enter your ${TFA_CODE_LENGTH}-digit code`}
                subtitle="The code can be retrieved from your authenticator app"
            />
            <Splitted2FACodeInput
                currentValue={code}
                onChange={handle2FACodeChange}
            />
            <Button
                type="submit"
                testId="submit-button"
                ref={submitButtonRef}
                disabled={code.length !== TFA_CODE_LENGTH}
            >
                Proceed
            </Button>
        </SingleModalForm>
    );
};

export default TwoFactorAuthLogInForm;
