import React, { FC } from 'react';
import { type BoxProps } from '@mui/material';
import { CopyTextField, StyledInputAdornment } from './CopyInput.styles';
import { CopyIcon } from 'src/components/CopyIcon';
import { StyledFieldContainer } from 'src/components/Inputs/StyledInput.styles';
import { Label } from 'src/components/Label';

type CopyInputProps = {
    name?: string;
    testId?: string;
    label?: string;
    value: string;
    tooltipType?: 'link' | 'text';
    disabled?: boolean;
} & BoxProps;

const CopyInput: FC<CopyInputProps> = ({
    testId = 'copy-input',
    name = 'copyInput',
    label,
    value = '',
    disabled = true,
    tooltipType = 'text',
    ...restBoxProps
}) => {
    const preparedTestId = testId ?? `${name}-input`;
    const preparedLabel = label?.replace(/^./, (m) => m.toUpperCase())
        ?? name.replace(/[-_]/g, ' ').replace(/\b\w/g, (m) => m.toUpperCase());

    const inputProps = {
        endAdornment: (
            <StyledInputAdornment position="end">
                <CopyIcon content={value} type={tooltipType} />
            </StyledInputAdornment>
        ),
    };

    return (
        <StyledFieldContainer data-testid={testId} {...restBoxProps}>
            <Label htmlFor={name}>
                {preparedLabel}
            </Label>
            <CopyTextField
                className="form-input"
                type="text"
                name={name}
                value={value}
                disabled={disabled}
                InputProps={{
                    ...inputProps,
                    inputProps: {
                        'data-testid': `${preparedTestId}-element`,
                    },
                }}
            />
        </StyledFieldContainer>
    );
};

export default CopyInput;
