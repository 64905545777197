import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { AccessCodeState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';
import { accessCodeSelector } from 'src/redux/slices';
import { SingleModalLayout } from 'src/layouts';
import { LinkExpiredContent } from 'src/components/LinkExpiredContent';
import { logInViaCode } from 'src/services/sso-api';
import { SpinnerPage } from 'src/pages/Spinner';

const LogInLinkPage: FC = () => {
    const { accessCode, isValid } = useSelector<RootState, AccessCodeState>(accessCodeSelector);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        if (isValid) {
            logInViaCode(accessCode).catch(() => setIsError(true));
            return;
        }

        setIsError(isValid === false);
    }, [isValid, accessCode]);

    if (!isError) {
        return <SpinnerPage />;
    }

    return (
        <SingleModalLayout testId="log-in-link-invalid-message">
            <LinkExpiredContent
                mainButtonLink="/log-in-link"
                mainButtonText="Get a new login link"
            />
        </SingleModalLayout>
    );
};

export default LogInLinkPage;
