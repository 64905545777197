import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { theme as mainTheme } from 'src/theme';

export type NavButtonVariant = 'default' | 'menuItem' | 'mobileMenuHeader' | 'desktopMenuHeader';

const variantStyleSelector = (props: { 'data-variant'?: NavButtonVariant, theme: typeof mainTheme }) => {
    switch (props['data-variant']) {
        case 'menuItem':
            return 'width: 100%; padding: 8px 24px;';
        case 'mobileMenuHeader':
            return css`
                background-color: ${props.theme.palette.mainBackgroundColor};
                padding: 0 16px;
                outline-offset: -2px;
                font-size: 14px;
                line-height: 28px;
            `;
        case 'desktopMenuHeader':
            return css`
                background-color: ${props.theme.palette.white};
                padding: 0 16px;
                outline-offset: -2px;
                font-size: 14px;
            `;
        case 'default':
        default:
            return css`
                background-color: ${props.theme.palette.white};
                padding: 0 16px;
                outline-offset: -2px;
            `;
    }
};

export const NavButton = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.black};
  font-family: ${({ theme }) => theme.fonts.demiBold};
  font-size: 16px;
  line-height: 32px;
  align-self: center;

  ${variantStyleSelector}
`;
