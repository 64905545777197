import styled from 'styled-components';
import { Typography } from '@mui/material';

export const UsageDataTableTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: ${({ theme }) => theme.palette.lightBlack};
    font-family: ${({ theme }) => theme.fonts.demiBold};

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        font-size: 14px;
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.black};
        font-weight: 500;
    }  
  }
`;
