import { DataGridProps as MuiDataGridProps } from '@mui/x-data-grid/models/props/DataGridProps';

export const copyDataWithColumnNames = (
    columns: MuiDataGridProps['columns'],
) => (
    copiedData: string,
) => {
    const headerNames = columns.map(({ headerName }) => headerName).join('\t');
    return navigator.clipboard.writeText(`${headerNames}\n${copiedData}`);
};
