import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/root-reducer';
import type { GeneralModalState, OpenModalActionPayload, UpdateModalActionPayload } from 'src/@types/redux';

const initialState: GeneralModalState = Object.freeze({
    key: null,
    title: null,
    extraProps: {},
});

export { initialState as generalModalInitialState };

export const generalModalSlice = createSlice<GeneralModalState, {
    openModal: CaseReducer<GeneralModalState, PayloadAction<OpenModalActionPayload>>;
    updateModal: CaseReducer<GeneralModalState, PayloadAction<UpdateModalActionPayload>>;
    closeModal: CaseReducer<GeneralModalState>;
}>({
    name: 'generalModal',
    initialState,
    reducers: {
        openModal: (state, { payload: { key, title = null, closeIsBlocked, extraProps = {} } }) => ({
            ...state,
            key,
            title,
            closeIsBlocked,
            extraProps,
        }),
        updateModal: (state, { payload: { extraProps = {}, ...restPayload } }) => ({
            ...state,
            ...restPayload,
            extraProps: {
                ...state.extraProps,
                ...extraProps,
            },
        }),
        closeModal: () => initialState,
    },
});

export const {
    openModal,
    updateModal,
    closeModal,
} = generalModalSlice.actions;

export const generalModalSelector = (state: RootState): GeneralModalState => state[generalModalSlice.name];
