import React, { FC } from 'react';
import { Box } from '@mui/material';
import { InfoBox } from './SubscriptionActionModal.styles';
import { isOnGracePeriod } from 'src/services/subscription-service-getters';
import getSubscriptionAction from 'src/services/subscription-actions';
import { SubscriptionAction, subscriptionActionMapping } from 'src/constants';
import { useGeneralModal, useReloadPage } from 'src/hooks';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { ReactComponent as WarningIcon } from 'src/assets/icons/blue-warn-icon.svg';

export type SubscriptionActionModalProps = {
    subscriptionUUID: string;
    selectedAction: SubscriptionAction;
    isUdb: boolean;
    expirationDate: string;
    gracePeriodEndDate: string;
};

const SubscriptionActionModal: FC<SubscriptionActionModalProps> = ({
    subscriptionUUID,
    selectedAction,
    isUdb,
    expirationDate,
    gracePeriodEndDate,
}) => {
    const { closeModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();

    const subscriptionAction = getSubscriptionAction(isUdb, selectedAction);
    const isGracePeriod = isOnGracePeriod({ expirationDate, gracePeriodEndDate });

    const handleConfirm = async () => {
        if (subscriptionAction) {
            return subscriptionAction(subscriptionUUID).then(() => {
                reloadPage();
                closeModal();
            });
        }
    };

    return (
        <BaseModal
            open
            handleClose={closeModal}
            title={subscriptionActionMapping[selectedAction]}
        >
            <ConfirmationModal
                modalId="subscription-action-modal"
                mobileDirection="row"
                onConfirm={handleConfirm}
                onCancel={closeModal}
                subtitle={(
                    <Box>
                        <>
                          Are you sure you want to {selectedAction} this subscription?
                        </>
                        {selectedAction === SubscriptionAction.CANCEL && isGracePeriod && (
                            <InfoBox gap={1} marginTop={3}>
                                <WarningIcon />
                                Subscription will end immediately after cancellation
                            </InfoBox>
                        )}
                    </Box>
                )}
            />
        </BaseModal>
    );
};

export default SubscriptionActionModal;
