import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import type { GridColumnParams } from 'src/@types/newsletters-set';
import { useSnackbarMessage } from 'src/hooks';
import { getNewsletterList } from 'src/services/sso-api';
import { SignUpNewsLettersForm } from 'src/components/Forms';
import { NewsletterList, VerticalModel } from 'src/@types/sso-api';
import { SnackbarMessageVariants } from 'src/constants';

type SignUpNewsLettersFlowProps = {
    activeScreen: string;
    currentScreenId: string;
    setActiveScreen: (screenId: string) => void;
    onCompleted: (selectedNewsletter: Record<string, boolean>) => void;
    setDeliveryNewslettersLength: (length: number) => void;
    submitButton?: string;
    pageColumnParams: Partial<GridColumnParams>;
};

const SignUpNewslettersFlow: FC<SignUpNewsLettersFlowProps> = ({
    setActiveScreen,
    currentScreenId,
    activeScreen,
    onCompleted,
    setDeliveryNewslettersLength,
    submitButton,
    pageColumnParams,
}) => {
    const [deliveryPreferences, setDeliveryPreferences] = useState<VerticalModel[]>([]);
    const [selectedNewsletter, setSelectedNewsletter] = useState<Record<string, boolean>>({});
    const activeNewsletterIndex = Number(activeScreen.split('_')[1]);
    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getNewsletterList()
            .then((({ verticals }: NewsletterList) => {
                const deliveryVerticals = verticals.filter(({ name }) => name !== 'General communications');
                setDeliveryPreferences(deliveryVerticals);
                setDeliveryNewslettersLength(deliveryVerticals.length);
            }))
            .catch(() => {
                addMessage('Failed to fetch delivery preferences', SnackbarMessageVariants.ERROR);
            });
    }, []);

    const handleNewslettersSubmit = () => {
        if (activeNewsletterIndex === deliveryPreferences?.length - 1) {
            onCompleted(selectedNewsletter);
        } else {
            setActiveScreen(`${currentScreenId}_${activeNewsletterIndex + 1}`);
        }
    };

    const onNewslettersFormChange = ({ target }: SyntheticEvent, values?: Record<string, boolean>) => {
        const { name, checked } = target as HTMLInputElement;

        setSelectedNewsletter((prevState) => ({
            ...prevState,
            ...values,
            [name === 'subscribeAll_email' ? `${name}_${activeNewsletterIndex}` : name]: checked,
        }));
    };

    if (!activeScreen.includes(currentScreenId)) {
        return null;
    }

    return (
        deliveryPreferences.map((preference, index) => (
            <SignUpNewsLettersForm
                key={preference.id}
                index={index}
                newsLetterSetSchema={{ pageColumnParams }}
                handleSubmit={handleNewslettersSubmit}
                onFormChange={onNewslettersFormChange}
                initialValues={selectedNewsletter}
                deliveryPreferences={preference}
                submitButton={index === deliveryPreferences.length - 1 ? submitButton : undefined}
            />
        ))[activeNewsletterIndex]
    );
};

export default SignUpNewslettersFlow;
