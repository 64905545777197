import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { ActionButtonVariant, InputActionButton } from 'src/components/Buttons/ActionButton';
import {
    AdditionalEmailTitle,
} from 'src/components/Modals/EditContactInformationModal/EditContactInformationModal.styles';
import { StyledCloseIcon } from 'src/components/Modals/BaseModal/BaseModal.styles';
import { ReactComponent as RevertIcon } from 'src/assets/icons/revert.svg';
import StyledTooltip from 'src/components/Tooltip';

export type StyledAdditionalEmailRowProps = {
    email: string;
    id: number;
    index: number;
    isRemoved: boolean;
    onRevert: (id: number) => void;
    onRemove: (id: number) => void;
};

const AdditionalEmailRow: FC<StyledAdditionalEmailRowProps> = ({
    id,
    email,
    index,
    isRemoved,
    onRevert,
    onRemove,
}) => {
    const ref = useRef<HTMLParagraphElement>(null);
    const [isOverflown, setIsOverflown] = useState<boolean>(false);

    const makeIsOverflown = () => {
        if (!ref.current) {
            return;
        }

        setIsOverflown(ref.current.scrollWidth > ref.current.clientWidth);
    };

    useLayoutEffect(() => {
        window.addEventListener('resize', makeIsOverflown);
        makeIsOverflown();
        return () => window.removeEventListener('resize', makeIsOverflown);
    }, []);

    return (
        <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            data-testid={`email-${index}`}
        >
            <StyledTooltip title={isOverflown ? email : ''} arrow>
                <AdditionalEmailTitle
                    data-font="normal"
                    data-is-removed={isRemoved}
                    data-testid={`email-value-${index}`}
                    ref={ref}
                >
                    {email}
                </AdditionalEmailTitle>
            </StyledTooltip>
            {isRemoved ? (
                <StyledTooltip title="Revert" arrow>
                    <InputActionButton
                        data-attribute-to-change="fill"
                        data-variant={ActionButtonVariant.GREY}
                        data-testid={`revert-email-button-${index}`}
                        onMouseDown={() => onRevert(id)}
                    >
                        <RevertIcon />
                    </InputActionButton>
                </StyledTooltip>
            ) : (
                <StyledTooltip title="Remove email address" arrow>
                    <InputActionButton
                        onMouseDown={() => onRemove(id)}
                        data-variant={ActionButtonVariant.GREY}
                        data-attribute-to-change="fill"
                        data-testid={`remove-email-button-${index}`}
                    >
                        <StyledCloseIcon />
                    </InputActionButton>
                </StyledTooltip>
            )}
        </Stack>
    );
};

export default AdditionalEmailRow;
