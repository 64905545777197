import { ConditionsParamsType, SchemaType, ToolBarModelType } from 'src/@types/tool-bar';
import { emptyValueConditions } from 'src/constants';

export const getValidModel = (model: ToolBarModelType, schema: SchemaType) => {
    return Object.entries(model).reduce<ToolBarModelType>((validQueries, [param, conditionObject]) => {
        if (param === 'quickAction' && schema.quickAction?.options.some(({ value }) => value === conditionObject.value)) {
            return { [param]: conditionObject };
        }

        Object.entries(conditionObject).forEach(([condition, conditionValue]) => {
            const hasValidCondition = schema.fields[param]?.conditionOptions?.some(({ value }) => value === condition);
            const validValue = hasValidCondition && schema.fields[param].validateConditionValue(conditionValue);
            const isEmptyCondition = emptyValueConditions.includes(condition);

            if ((hasValidCondition && validValue) || isEmptyCondition) {
                validQueries[param] = {
                    ...validQueries[param],
                    [condition]: isEmptyCondition ? '' : validValue,
                } as ConditionsParamsType;
            }
        });

        return validQueries;
    }, {});
};

export const stringValidator = (value: unknown) => typeof value === 'string' && value;

export const dateValidator = (value: unknown) => typeof value === 'string' && !isNaN(Date.parse(value)) && value;

export const multiSelectValidator = (allowedValues: string[], isAllowedString: boolean = true) => (values: unknown) => {
    if (Array.isArray(values)) {
        const validValues = values.filter((value) => (
            typeof value === 'string' && allowedValues.includes(value)
        ));

        if (validValues?.length) {
            return validValues as string[];
        }
    } else if (isAllowedString && allowedValues.includes(values as string)) {
        return values as string;
    }

    return false;
};
