import type { APIClientRequestConfig } from 'src/@types/api-client';
import {
    Industry,
    JobFunction,
    UDBNewsletterExpanded,
    UpdateUserModel,
    User,
    UserExpand,
    UsersSearchParams,
    UsersSearchProps,
    UsersSearchResult,
} from 'src/@types/unified-db-api';
import { ssoApiRequest } from 'src/services/sso-api';

export const unifiedDBApiRequest = async <
    RequestDataType extends object | void,
    ResponseType extends object | string | void,
>(
    url: string,
    config?: APIClientRequestConfig<RequestDataType>,
): Promise<ResponseType> => {
    return ssoApiRequest<RequestDataType, ResponseType>(`/udb/${url.replace(/^\//, '')}`, config);
};

let industriesList: Industry[] = [];
let jobFunctionsList: JobFunction[] = [];

export const getUser = (uuid: string) => {
    return unifiedDBApiRequest<UsersSearchProps, User>(
        `/user/${uuid}`,
        {
            method: 'GET',
            params: {
                expand: [
                    'email',
                    'jobInfo',
                    'phoneNumber',
                    'newsletters',
                    'customAttributes',
                    'secondaryEmails',
                    'userVerticals',
                ],
            },
        },
    );
};

export const searchUsers = (data: UsersSearchParams, expand: UserExpand[]) => {
    return unifiedDBApiRequest<UsersSearchProps, UsersSearchResult>(
        '/user/search',
        {
            method: 'POST',
            data: {
                ...data,
                expand,
            },
        },
    );
};

export const updateUser = (uuid: string, data: UpdateUserModel) => {
    return unifiedDBApiRequest<UpdateUserModel, User>(`/user/${uuid}`, {
        method: 'PATCH',
        data,
    });
};

export const fetchIndustriesList = async (): Promise<Industry[]> => (
    unifiedDBApiRequest<void, Industry[]>('/industry/list')
);

export const fetchJobFunctionsList = async (): Promise<JobFunction[]> => (
    unifiedDBApiRequest<void, JobFunction[]>('/job-function/list')
);

export const fetchAvailableNewsletterList = async (): Promise<UDBNewsletterExpanded[]> => (
    unifiedDBApiRequest<void, UDBNewsletterExpanded[]>('/newsletter/list', {
        params: { expand: ['vertical', 'sampleIssues'] },
    })
);

export const getIndustriesList = async () => {
    if (!industriesList.length) {
        industriesList = await fetchIndustriesList();
    }

    return industriesList;
};

export const getJobFunctionsList = async (): Promise<JobFunction[]> => {
    if (!jobFunctionsList.length) {
        jobFunctionsList = await fetchJobFunctionsList();
    }

    return jobFunctionsList;
};

export const deleteUserEmail = async (userUUID: string, emailId: number): Promise<void> => (
    unifiedDBApiRequest<void, void>(`/user/${userUUID}/email/${emailId}`, { method: 'DELETE' })
);
