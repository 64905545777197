import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SignUpNewslettersFlow } from 'src/components/SignUpNewslettersFlow';
import { SingleModalLayout } from 'src/layouts';
import { SignUpAdditionalDataForm, SignUpRequiredDataForm } from 'src/components/Forms';
import { checkEmailAvailability, signUp } from 'src/services/sso-api';
import {
    EMAIL_IS_TAKEN_ERROR,
    INVALID_EMAIL_VALIDATION_ERROR,
    PASSWORDS_DO_NOT_MATCH_MESSAGE,
    SnackbarMessageVariants,
    UDBNewsletterChannel,
} from 'src/constants';
import { NewsletterPreferences, SignUpData } from 'src/@types/sso-api';
import { useSnackbarMessage } from 'src/hooks';
import { OptionalDataFormValuesType, RequiredDataFormValuesType } from 'src/@types/create-account';
import { setSignUpData } from 'src/redux/slices';

enum ScreenId {
    REQUIRED_DATA = 'required-data',
    NEWSLETTERS = 'newsletters',
    ADDITIONAL_DATA = 'additional-data',
}

const newslettersColumnParams = {
    newsletters: {
        md: 2.25,
        display_title: 'block',
    },
    sms: {
        display: 'none',
    },
};

const SignUpPage: FC = () => {
    const [requiredData, setRequiredData] = useState<RequiredDataFormValuesType>();
    const [additionalData, setAdditionalData] = useState<OptionalDataFormValuesType>({});
    const [selectedNewsletter, setSelectedNewsletter] = useState<Record<string, boolean>>({});
    const [activeScreen, setActiveScreen] = useState<string>(ScreenId.REQUIRED_DATA);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [deliveryNewslettersLength, setDeliveryNewslettersLength] = useState<number>();
    const activeNewsletterIndex = Number(activeScreen.split('_')[1]);
    const { addMessage } = useSnackbarMessage();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveScreen(ScreenId.REQUIRED_DATA);
    }, []);

    const handleSubmit = () => {
        setIsSubmitting(true);

        const { socialX, socialLinkedIn, website, ...restAdditionalData } = additionalData;

        const social = {
            ...(socialLinkedIn && { socialLinkedIn }),
            ...(socialX && { socialX }),
            ...(website && { website }),
        };

        const newsletters = Object.keys(selectedNewsletter)
            .filter((key) => key.split('_')[1] === UDBNewsletterChannel.EMAIL)
            .map<NewsletterPreferences>(
            (key) => ({
                id: Number(key.split('_')[0]),
                isSelected: selectedNewsletter[key],
            }),
        ).filter(({ id }) => !Number.isNaN(id));

        const preparedData = {
            ...requiredData,
            ...restAdditionalData,
            ...(newsletters.length && { newsletters }),
            ...(Object.keys(social).length && { social }),
        };

        signUp(preparedData as SignUpData)
            .then(({ subscriberTypes }) => {
                dispatch(setSignUpData({ subscriberTypes }));
                navigate('/create-account/success');
            })
            .catch(() => {
                addMessage('Failed to create account', SnackbarMessageVariants.ERROR);
                setIsSubmitting(false);
            });
    };

    const handleRequiredDataSubmit = (
        values: RequiredDataFormValuesType,
        form: FormikHelpers<RequiredDataFormValuesType>,
    ) => {
        setIsSubmitting(true);
        const formValues = values;

        if (formValues.password !== formValues.repeatPassword) {
            form.setErrors({
                repeatPassword: PASSWORDS_DO_NOT_MATCH_MESSAGE,
            });

            setIsSubmitting(false);
            return;
        }

        checkEmailAvailability(formValues.email)
            .then(({ available }) => {
                if (!available) {
                    form.setErrors({ email: EMAIL_IS_TAKEN_ERROR });
                    return;
                }

                setRequiredData(formValues);
                setActiveScreen(deliveryNewslettersLength ? `${ScreenId.NEWSLETTERS}_0` : ScreenId.ADDITIONAL_DATA);
            })
            .catch(({ responseError }) => {
                const emailValidationError: Record<string, string> = {
                    '"email" must be a valid email': INVALID_EMAIL_VALIDATION_ERROR,
                };

                return emailValidationError[responseError.data.error] ? (
                    form.setErrors({ email: emailValidationError[responseError.data.error] })
                ) : (
                    addMessage('Failed to check email availability', SnackbarMessageVariants.ERROR)
                );
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleNewslettersComplete = (newsletters: Record<string, boolean>) => {
        setActiveScreen(ScreenId.ADDITIONAL_DATA);
        setSelectedNewsletter(newsletters);
    };

    const onAdditionalDataFormChange = (event: ChangeEvent<HTMLFormElement | HTMLInputElement>) => {
        setAdditionalData((prevState) => ({
            ...prevState,
            [event.target.name]:
                ['industryId', 'jobFunctionId'].includes(event.target.name) ? Number(event.target.value) : event.target.value,
        }));
    };

    const goBackActionHandler = () => {
        if (activeScreen === ScreenId.ADDITIONAL_DATA) {
            setActiveScreen(deliveryNewslettersLength
                ? `${ScreenId.NEWSLETTERS}_${deliveryNewslettersLength - 1}`
                : ScreenId.REQUIRED_DATA);
        } else if (activeNewsletterIndex === 0) {
            setActiveScreen(ScreenId.REQUIRED_DATA);
        } else {
            setActiveScreen(`${ScreenId.NEWSLETTERS}_${activeNewsletterIndex - 1}`);
        }
    };

    return (
        <SingleModalLayout
            goBackAction={activeScreen !== ScreenId.REQUIRED_DATA ? goBackActionHandler : undefined}
            testId="create-account-page"
            variant={activeScreen.includes(ScreenId.NEWSLETTERS) ? 'newsLettersForm' : 'form'}
        >
            <>
                {activeScreen === ScreenId.REQUIRED_DATA && (
                    <SignUpRequiredDataForm
                        isSubmitting={isSubmitting}
                        initialValues={requiredData}
                        handleSubmit={handleRequiredDataSubmit}
                    />
                )}
                <SignUpNewslettersFlow
                    setDeliveryNewslettersLength={setDeliveryNewslettersLength}
                    pageColumnParams={newslettersColumnParams}
                    currentScreenId={ScreenId.NEWSLETTERS}
                    setActiveScreen={setActiveScreen}
                    onCompleted={handleNewslettersComplete}
                    activeScreen={activeScreen}
                />
                {activeScreen === ScreenId.ADDITIONAL_DATA && (
                    <SignUpAdditionalDataForm
                        onFormChange={onAdditionalDataFormChange}
                        initialValues={additionalData}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                    />
                )}
            </>
        </SingleModalLayout>
    );
};

export default SignUpPage;
