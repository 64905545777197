import { CheckboxProps as MuiCheckboxProps } from '@mui/material';
import React, { ForwardedRef, forwardRef, Fragment } from 'react';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import { TooltipProps } from '@mui/material/Tooltip';
import { StyledMUICheckbox } from './Checkbox.styles';
import StyledTooltip from 'src/components/Tooltip';
import { ReactComponent as CheckboxIcon } from 'src/assets/icons/check-box-icon.svg';
import { ReactComponent as CheckedIcon } from 'src/assets/icons/check-box-checked-icon.svg';
import { ReactComponent as DisabledCheckboxIcon } from 'src/assets/icons/disabled-check-box-icon.svg';
import { ReactComponent as DisabledCheckedCheckboxIcon } from 'src/assets/icons/disabled-checked-check-box-icon.svg';
import { ReactComponent as IndeterminateCheckboxIcon } from 'src/assets/icons/indeterminate-chackobx-icon.svg';

type CheckboxProps = {
    ['data-testid']?: string;
    tooltipPlacement?: TooltipProps['placement'];
    tooltipTitle?: TooltipProps['title'];
} & MuiCheckboxProps;

const Checkbox = forwardRef(
    function Checkbox(
        { tooltipPlacement, tooltipTitle, ...props }: CheckboxProps,
        ref: ForwardedRef<HTMLInputElement>,
    ) {
        const CheckboxWrapper = tooltipTitle ? StyledTooltip : Fragment;
        const wrapperProps = tooltipTitle ? {
            placement: tooltipPlacement,
            disableInteractive: true,
            title: tooltipTitle,
            arrow: true,
        } : null;

        return (
            <CheckboxWrapper
                {...wrapperProps}
            >
                <StyledMUICheckbox
                    inputProps={{
                        'data-testid': `${props['data-testid'] ?? 'checkbox'}-input`,
                        ref,
                    } as SwitchBaseProps['inputProps']}
                    icon={props.disabled ? <DisabledCheckboxIcon /> : <CheckboxIcon />}
                    checkedIcon={props.disabled ? <DisabledCheckedCheckboxIcon /> : <CheckedIcon />}
                    indeterminateIcon={<IndeterminateCheckboxIcon />}
                    {...props}
                />
            </CheckboxWrapper>
        );
    },
);

export default Checkbox;
