import styled from 'styled-components';
import { Grid } from '@mui/material';

export const StyledActiveValidatorsItem = styled(Grid)`
  p {
    margin: 0 0 0 4px;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.lightBlack};
    font-family: ${({ theme }) => theme.fonts.regular};
  }
`;
