import { createListenerMiddleware } from '@reduxjs/toolkit';
import { refreshToken } from 'src/services/sso-api';
import { ApplicationsPage } from 'src/pages/Applications';
import { LogInLinkPage } from 'src/pages/LogInLink';
import { ResetPasswordPage } from 'src/pages/ResetPassword';
import { ChangeEmailPage } from 'src/pages/ChangeEmail';
import { CredentialsPage } from 'src/pages/Credentials';
import { SubscriptionsPage } from 'src/pages/udb/Subscriptions';
import { UsersPage } from 'src/pages/udb/Users';
import { CheckoutResultPage } from 'src/pages/UpgradeToInsider';
import UdbRouter from 'src/pages/udb/UdbRouter';
import { pageLoaded } from 'src/redux/actions/page';
import { setAccessCode, incrementPageReloadCount } from 'src/redux/slices';
import { updateIntercom } from 'src/services/intercom';

export const pageLoadedListener = createListenerMiddleware();
pageLoadedListener.startListening({
    actionCreator: pageLoaded,
    effect: async ({ payload: { page, params } }, listenerApi) => {
        switch (page) {
            case LogInLinkPage.name:
            case ResetPasswordPage.name:
            case ChangeEmailPage.name:
            case CheckoutResultPage.name:
                if (params.accessCode) {
                    listenerApi.dispatch(setAccessCode(params.accessCode));
                }
                break;
        }

        switch (page) {
            case UdbRouter.name:
                break;
            case ApplicationsPage.name:
            case UsersPage.name:
            case CredentialsPage.name:
            case SubscriptionsPage.name:
                updateIntercom({ hide_default_launcher: true });
                break;
            default:
                updateIntercom({ hide_default_launcher: false });
        }
    },
});

export const pageReloadedListener = createListenerMiddleware();
pageReloadedListener.startListening({
    actionCreator: incrementPageReloadCount,
    effect: async () => {
        await refreshToken(false);
    },
});
