import React, { FC, ReactNode } from 'react';
import { Box, FormControl, SelectProps as MuiSelectProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    AddButton,
    Description,
    OptionWrapper,
    StyledInputPlaceholder,
    Title,
} from './OwnerSelect.styles';
import LazyLoadSelectMenuItem from 'src/components/Select/LazyLoadSelect/components/LazyLoadSelectMenuItem';
import LazyLoadSelect from 'src/components/Select/LazyLoadSelect/LazyLoadSelect';
import { RoundedBadge } from 'src/components/Badge';
import { User } from 'src/@types/unified-db-api';

export type OptionsType = {
    value: string;
    label: string;
    disabled?: boolean;
};

type OwnerSelectProps = MuiSelectProps & {
    handleInputChange?: (value: string) => void;
    testId?: string;
    managers: Omit<User, 'jobInfo'>[] | null;
    title?: string;
    owner: Omit<User, 'jobInfo'> | null;
    addManagerHandler: () => void;
    loadNextPage?: () => void;
    isLoadingNextPage?: boolean;
};

const OwnerSelect: FC<OwnerSelectProps> = ({
    testId,
    managers,
    handleInputChange,
    title,
    owner,
    addManagerHandler,
    loadNextPage,
    isLoadingNextPage,
    ...props
}) => {
    const options = (visible = false): ReactNode[] => {
        if (!managers) {
            return [];
        }

        return managers.map((manager) => (
            <LazyLoadSelectMenuItem
                disabled={manager.uuid === owner?.uuid}
                testId={visible ? `select-${testId}-${manager.uuid}` : `select-${testId}-${manager.uuid}-hidden`}
                key={manager.uuid}
                value={manager.uuid}
                onClick={() => handleInputChange?.(manager.uuid)}
                visible={visible}
            >
                <OptionWrapper
                    direction="row"
                    flexGrow={1}
                    justifyContent="space-between"
                    alignItems="center"
                    paddingY={1.5}
                >
                    <Box>
                        <Title>{manager.fullName}</Title>
                        <Description>{manager.email.email}</Description>
                    </Box>
                    {manager.uuid === owner?.uuid && <RoundedBadge label="Owner" /> }
                </OptionWrapper>
            </LazyLoadSelectMenuItem>
        ));
    };

    return (
        <FormControl fullWidth>
            {!props.value && <StyledInputPlaceholder id={`select-${testId}-placeholder`}>{props.placeholder}</StyledInputPlaceholder>}
            <LazyLoadSelect
                {...props}
                footer={(
                    <AddButton
                        data-testid="add-button"
                        onClick={addManagerHandler}
                    >
                        <AddIcon />
                        Add manager
                    </AddButton>
                )}
                testId={testId}
                options={options}
                loadNextPage={loadNextPage}
                isLoadingNextPage={isLoadingNextPage}
                title={title}
                handleInputChange={handleInputChange}
            />
        </FormControl>
    );
};

export default OwnerSelect;
