import type { Key } from 'react';

export const getKeyProp = (item: Record<string, unknown>): Key | undefined => {
    switch (true) {
        case ('id' in item):
            return `${item.id}`;
        case ('uuid' in item):
            return `${item.uuid}`;
        case ('token' in item):
            return `${item.token}`;
        case ('domain' in item):
            return `${item.domain}`;
        default:
            return undefined;
    }
};
