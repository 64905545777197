import React, { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { showIntercom } from 'src/services/intercom';
import type { SubscriberSummary } from 'src/@types/sso-api';
import { emailValidator, validateSchemaObject } from 'src/services/validators';
import { checkSubscriberByEmail } from 'src/services/sso-api';
import { useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import { LabeledInput } from 'src/components/Inputs';
import PageTitle from 'src/components/PageTitle';
import { RequestError, SingleModalForm } from 'src/components/Forms/components';
import { Button, TextButton } from 'src/components/Buttons';
import { EMPTY_EMAIL_VALIDATION_ERROR, SnackbarMessageVariants, SubscriberStatus } from 'src/constants';

type UpgradeAnotherAccountFormValues = {
    email: string;
};

type UpgradeAnotherAccountFormProps = {
    onSubmitted: (email: string, subscriber: SubscriberSummary) => void;
};

const initialValues = {
    email: '',
};

const CheckAccountToUpgradeForm: FC<UpgradeAnotherAccountFormProps> = ({
    onSubmitted,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isInactive, setIsInactive] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();

    const { isSubmitDisabled, onFormChangeHandler } =
        useIsSubmitDisabled<UpgradeAnotherAccountFormValues>(initialValues);

    const validationSchema = validateSchemaObject({
        email: emailValidator.required(EMPTY_EMAIL_VALIDATION_ERROR),
    });

    const handleSubmit = ({ email }: UpgradeAnotherAccountFormValues) => {
        setIsSubmitting(true);
        checkSubscriberByEmail(email)
            .then((subscriber) => {
                setIsInactive(subscriber.status === SubscriberStatus.INACTIVE);
                onSubmitted(email, subscriber);
            })
            .catch(() => {
                addMessage('Technical error is occurred', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <SingleModalForm
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            onFormChange={(event) => {
                onFormChangeHandler(event);
                setIsInactive(false);
            }}
            validationSchema={validationSchema}
            testId="get-account-to-upgrade-form"
        >
            <PageTitle
                variant="form"
                testId="form-title"
                title="Let’s get started"
                subtitle="Enter the email address you'd like to use."
            />
            <Stack spacing={3} paddingBottom={{ xs: 0.5, sm: 0 }}>
                <LabeledInput
                    disallowSpace
                    name="email"
                    label="Email address"
                    placeholder="Enter your email address"
                />
                {isInactive ? (
                    <RequestError
                        data-testid="inactive-account-error"
                    >
                        An inactive account has been found with the provided email. Please
                        &nbsp;<TextButton onClick={showIntercom}>contact us</TextButton>&nbsp;
                        to resolve this issue
                    </RequestError>
                ) : (
                    <Box display={{ xs: 'none', sm: 'initial' }} />
                )}
            </Stack>
            <Button
                type="submit"
                testId="submit-button"
                disabled={isSubmitDisabled}
            >
                Next
            </Button>
        </SingleModalForm>
    );
};

export default CheckAccountToUpgradeForm;
