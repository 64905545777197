import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { GradientSvg, StyledSpinner } from './Spinner.styles';
import { theme } from 'src/theme';

export const Spinner: FC<{ testId?: string }> = ({ testId = 'spinner' }) => (
    <StyledSpinner
        data-testid={testId}
        justifyContent="center"
        alignItems="center"
    >
        <GradientSvg>
            <defs>
                <linearGradient id="linearColors">
                    <stop offset="5%" stopColor={`${theme.palette.lightBlue}`}></stop>
                    <stop offset="95%" stopColor={`${theme.palette.darkBlue}`}></stop>
                </linearGradient>
            </defs>
        </GradientSvg>
        <CircularProgress />
    </StyledSpinner>
);
