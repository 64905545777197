import React, { FC, useState } from 'react';
import { Box, Stack, StackProps } from '@mui/material';
import { FormikHelpers } from 'formik';
import { DUPLICATE_DOMAIN_ERROR_MESSAGE, SnackbarMessageVariants } from 'src/constants';
import type { DomainInfoModel, DomainInfoSubscription, DomainModel } from 'src/@types/subscription-service-api';
import { normalizeDomain } from 'src/services/domain-processor';
import { getDomainInfo } from 'src/services/subscription-service-api';
import { domainValidator, validateSchemaObject } from 'src/services/validators';
import { LabeledInput } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import { FormWrapper } from 'src/components/Forms/components';
import { useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';

type AddDomainFormValues = {
    domain: string;
};

type AddDomainFormProps = {
    domains: DomainInfoModel[];
    setDomains: (domains: DomainInfoModel[]) => void;
    paddingX?: StackProps['paddingX'];
    verticalIds: number[];
    subscriptionDomains: DomainModel[];
    subscriptionUUID: string;
};

const initialValues: AddDomainFormValues = {
    domain: '',
};

const getSubscriptionByVerticals = (
    subscriptions: Array<DomainInfoSubscription>,
    verticalIds: number[],
): DomainInfoSubscription | undefined => (
    subscriptions.find((item) => item.verticalIds.some((id) => verticalIds.includes(id)))
);

const getDomainErrorMessage = (isCurrentSubscription: boolean) => {
    return `This domain is already attached to ${isCurrentSubscription ? 'this' : 'another'} subscription`;
};

const AddDomainModalForm: FC<AddDomainFormProps> = ({
    domains,
    verticalIds,
    paddingX,
    subscriptionDomains,
    subscriptionUUID,
    setDomains,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();

    const {
        isSubmitDisabled,
        onFormChangeHandler,
        setIsSubmitDisabled,
    } = useIsSubmitDisabled(initialValues);

    const handleSubmit = (values: AddDomainFormValues, helpers: FormikHelpers<AddDomainFormValues>) => {
        const normalizedDomain = normalizeDomain(values.domain);
        const domainAlreadyAdded = domains.some(({ domain }) => normalizeDomain(domain) === normalizedDomain);
        const domainInSubscription = subscriptionDomains
            .some(({ domain }) => normalizeDomain(domain) === normalizedDomain);

        if (domainAlreadyAdded) {
            helpers.setErrors({
                domain: DUPLICATE_DOMAIN_ERROR_MESSAGE,
            });
            return;
        }

        if (domainInSubscription) {
            helpers.setErrors({ domain: getDomainErrorMessage(true) });
            return;
        }

        setIsSubmitDisabled(true);
        setIsSubmitting(true);

        getDomainInfo({ domain: normalizedDomain })
            .then((domainInfo) => {
                const collision = getSubscriptionByVerticals(domainInfo.subscriptions, verticalIds);

                if (collision) {
                    helpers.setErrors({
                        domain: getDomainErrorMessage(collision.uuid === subscriptionUUID),
                    });
                    return;
                }

                setDomains([...domains, domainInfo]);
                helpers.resetForm();
            })
            .catch(() => {
                setIsSubmitDisabled(false);
                addMessage('Failed to get domain info', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            isSubmitting={isSubmitting}
            validationSchema={validateSchemaObject({
                domain: domainValidator.required(),
            })}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            testId="add-domain-form"
        >
            <Stack
                paddingX={paddingX}
                spacing={{ xs: 3, sm: 1 }}
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="flex-start"
            >
                <LabeledInput
                    testId="domain-input"
                    disallowSpace
                    name="domain"
                    label="Add domain"
                    placeholder="Enter domain name"
                    flex={1}
                    errorPosition="relative"
                />
                <Box paddingTop={{ xs: 0, sm: 2.75 }}>
                    <Button
                        data-testid="add-button"
                        disabled={isSubmitDisabled}
                        fullWidth
                        type="submit"
                        variant="primary"
                        minSize="small"
                    >
                            Add
                    </Button>
                </Box>
            </Stack>
        </FormWrapper>
    );
};

export default AddDomainModalForm;
