import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';
import { cellStyles, ROWS_HEIGHT } from 'src/components/DataGrid/DataGrid.styles';

export const StyledLinkedCell = styled(Stack)`
    display: flex;
    align-items: center;
    width: inherit;
    position: relative;
    height: ${ROWS_HEIGHT}px;
    text-decoration: none;
    box-sizing: border-box;
    
    &:hover {
        background: none;
    }

    svg {
        display: flex;
        
        &:hover {
            filter: brightness(0.9)
        }
    }
`;

export const StyledLink = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.palette.black};
    text-transform: none;
    font-size: 16px;
    box-sizing: border-box;
      
    ${cellStyles}
  }
`;
