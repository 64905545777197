import React, { FC } from 'react';
import { EditSocialMediaModalFormValues } from 'src/components/Modals/EditSocialMediaModal/EditSocialMediaModal';
import { StartInputAdornment } from 'src/components/Inputs/components';
import { LabeledInput } from 'src/components/Inputs';
import { ReactComponent as LinkedInIcon } from 'src/assets/icons/linkedIn-icon.svg';

export type LinkedInInputProps = {
    forceChangeValues?: (newValues: { socialLinkedIn: string | undefined }) => void;
    values?: EditSocialMediaModalFormValues;
    initialValues?: EditSocialMediaModalFormValues;
    hasRevertButton?: boolean;
    isRevertBtnVisible?: boolean;
};

export const LinkedInInput: FC<LinkedInInputProps> = ({
    hasRevertButton,
    forceChangeValues,
    values,
    initialValues,
    isRevertBtnVisible,
}) => (
    <LabeledInput
        disallowSpace
        testId="socialLinkedIn-input"
        name="socialLinkedIn"
        label="LinkedIn"
        placeholder="Link to your LinkedIn account"
        inputProps={{
            startAdornment: (
                <StartInputAdornment>
                    <LinkedInIcon />
                </StartInputAdornment>
            ),
        }}
        flex={1}
        isRevertBtnVisible={isRevertBtnVisible}
        hasRevertButton={hasRevertButton}
        onRevert={() => {
            if (forceChangeValues) {
                forceChangeValues({
                    ...values,
                    socialLinkedIn: initialValues?.socialLinkedIn,
                });
            }
        }}
    />
);
