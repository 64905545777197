import { loadConfig } from './services/user-profile-api';
import { ssoApiInitialized } from './services/sso-api';
import { setupStore } from './redux/store';
import { setConfig } from './redux/slices';
import { sleepTill } from './services/sleep';

export default async function boot() {
    const config = await loadConfig();
    const store = setupStore();
    store.dispatch(setConfig(config));

    await sleepTill(() => ssoApiInitialized);

    return store;
}
