export enum DatePickerTypes {
    DATE = 'DATE',
    DATE_AND_TIME = 'DATE_AND_TIME',
}

export const datePickerDateFormat = 'DD MMM YYYY';
export const datePickerDateTimeFormat = 'DD MMM YYYY HH:mm';
export const datePickerDateTimeWithSecondsFormat = 'DD MMM YYYY HH:mm:ss';

export const getPreDefinedType = (dateOnly?: boolean, dateTimeOnly?: boolean) => {
    if (dateOnly) {
        return DatePickerTypes.DATE;
    }

    if (dateTimeOnly) {
        return DatePickerTypes.DATE_AND_TIME;
    }

    return undefined;
};

export const getDatePickerType = (initialValue?: string | null) => {
    if (!initialValue || initialValue.length === 11) {
        return DatePickerTypes.DATE;
    }

    return DatePickerTypes.DATE_AND_TIME;
};
