import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

export const ServerErrorStackContainer = styled(Stack)`
  max-width: 420px;
  
  svg {
    max-width: 100%;
    height: auto;
  }
`;

export const BoxContainer = styled(Box)`
  width: 240px;
`;
