import React from 'react';
import type { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { ManageEntityLink } from 'src/pages/udb/Subscriptions/components';
import type { GridColDef } from 'src/@types/data-grid';
import { defaultHeaderRenderer } from 'src/components/DataGrid/components/defaultHeaderRenderer';
import { userRoleRenderMapping } from 'src/constants';
import CopyCellRenderer from 'src/components/DataGrid/components/CopyCell/Renderer';
import { booleanValueFormatter, dateValueFormatter, defaultValueFormatter } from 'src/services/grid-formatters';
import { defaultCellRenderer } from 'src/components/DataGrid/components/defaultCellRenderer';
import { UserLinkedCell } from 'src/components/DataGrid/components/UserLinkedCell';

const COLUMN_HEADERS = {
    id: 'ID',
    uuid: 'UUID',
    email: 'Email address',
    status: 'Status',
    role: 'Role',
    hasWPPassword: 'Has WP password',
    tfaEnabled: '2FA enabled',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    manage: 'Manage',
};

export const roleGetter = ({ value }: { value: keyof typeof userRoleRenderMapping }) => (
    userRoleRenderMapping[value]
);

export const userEmailLinkRender = ({ formattedValue, row }: GridRenderCellParams) => (
    <UserLinkedCell name={formattedValue} id={row.uuid} />
);

export const ManageLinkRenderer = ({ row }: GridRenderCellParams) => (
    <ManageEntityLink id={row.uuid} type="credentials" />
);

export const credentialsDataGridColumns: readonly GridColDef[] = [
    {
        field: 'id',
        headerName: COLUMN_HEADERS.id,
        minWidth: 100,
        flex: 1,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.id),
    },
    {
        field: 'uuid',
        headerName: COLUMN_HEADERS.uuid,
        minWidth: 375,
        flex: 3,
        valueFormatter: defaultValueFormatter,
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.uuid),
    },
    {
        field: 'email',
        headerName: COLUMN_HEADERS.email,
        minWidth: 325,
        flex: 4,
        valueFormatter: defaultValueFormatter,
        renderCell: userEmailLinkRender,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.email),
    },
    {
        field: 'status',
        headerName: COLUMN_HEADERS.status,
        minWidth: 125,
        flex: 1,
        valueFormatter: defaultValueFormatter,
        renderCell: defaultCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.status),
    },
    {
        field: 'role',
        headerName: COLUMN_HEADERS.role,
        minWidth: 125,
        flex: 1,
        valueFormatter: defaultValueFormatter,
        renderCell: defaultCellRenderer,
        valueGetter: roleGetter,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.role),
    },
    {
        field: 'hasWPPassword',
        headerName: COLUMN_HEADERS.hasWPPassword,
        minWidth: 200,
        flex: 1,
        valueFormatter: booleanValueFormatter,
        type: 'boolean',
        renderCell: defaultCellRenderer,
        align: 'left',
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.hasWPPassword),
    },
    {
        field: 'tfaEnabled',
        headerName: COLUMN_HEADERS.tfaEnabled,
        minWidth: 150,
        flex: 1,
        valueFormatter: booleanValueFormatter,
        type: 'boolean',
        renderCell: defaultCellRenderer,
        align: 'left',
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.tfaEnabled),
    },
    {
        field: 'createdAt',
        headerName: COLUMN_HEADERS.createdAt,
        minWidth: 225,
        flex: 2,
        valueFormatter: dateValueFormatter,
        type: 'date',
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.createdAt),
    },
    {
        field: 'updatedAt',
        headerName: COLUMN_HEADERS.updatedAt,
        minWidth: 225,
        flex: 2,
        valueFormatter: dateValueFormatter,
        type: 'date',
        renderCell: CopyCellRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.updatedAt),
    },
    {
        field: 'manage',
        headerName: COLUMN_HEADERS.manage,
        width: 106,
        valueFormatter: defaultValueFormatter,
        renderCell: ManageLinkRenderer,
        renderHeader: defaultHeaderRenderer(COLUMN_HEADERS.manage),
        sticky: true,
    },
];
