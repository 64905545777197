import styled from 'styled-components';
import { Typography } from '@mui/material';

export const DateInputLabel = styled(Typography)`
    &.MuiTypography-root {
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.grey};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 4px;
    }
    
    &.focused {
        color: ${({ theme }) => theme.palette.blue}
    }
`;
