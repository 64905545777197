import React, { useContext, useEffect, useState } from 'react';
import { SelectChangeEvent, Stack } from '@mui/material';
import { ToolBarItemsTitle } from 'src/components/ToolBar/components/ToolBarItem/ToolBarItemsTitle';
import { Select } from 'src/components/Select';
import { ToolbarItemContext } from 'src/@types/tool-bar';
import { ToolBarItemContext } from 'src/components/ToolBar/components/ToolBarContext';

export const QuickActions = () => {
    const {
        model,
        schema,
        conditionsModel,
        selectPlaceholder,
        setConditionsModel,
    } = useContext<ToolbarItemContext>(ToolBarItemContext);
    const [cachedConditionsModel = []] = conditionsModel.filter(([param]) => param === 'quickAction');
    const [,conditionObject = {}] = cachedConditionsModel;
    const { value: cachedValue } = conditionObject;
    const [value, setValue] = useState<string>(cachedValue as string ?? model?.quickAction?.value ?? '');

    useEffect(() => {
        if (value) {
            setConditionsModel([['quickAction', { value }]]);
        }
    }, [value]);

    return (
        <>
            <ToolBarItemsTitle />
            <Stack direction="row" alignItems="center" paddingX={3} gap={1} marginBottom={{ xs: 3, sm: value ? 3 : 0 }}>
                <Select
                    hasHeader
                    title="Filter by"
                    testId="quick-actions"
                    name="quick-actions"
                    placeholder={selectPlaceholder}
                    onChange={(event: SelectChangeEvent<unknown>) => setValue(event.target.value as string)}
                    options={schema.quickAction?.options ?? []}
                    value={value ?? ''}
                />
            </Stack>
        </>
    );
};
