import React, { FC, useEffect, useState } from 'react';
import { Box, type BoxProps, InputAdornment } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { StringSchema } from 'yup';
import { PASSWORDS_DO_NOT_MATCH_MESSAGE } from 'src/constants';
import LabeledInput from 'src/components/Inputs/LabeledInput';
import { generatePassword } from 'src/services/generate-password';
import {
    AutoGenerateText,
    AutoGenerateWrapper,
    StyledIconButton,
    VisibilityOffStyled,
} from 'src/components/Inputs/PasswordInput/PasswordInput.styles';
import { ReactComponent as AutoGenerateIcon } from 'src/assets/icons/auto-generate-icon.svg';
import { FormikValuesType } from 'src/@types/forms';

type PasswordInputPropsType = {
    name?: string;
    placeholder?: string;
    testId?: string;
    label?: string;
    labelIcon?: JSX.Element;
    required?: boolean;
    disabled?: boolean;
    hasActiveValidator?: boolean;
    showAutoGenerate?: boolean;
    handlerAutoGenerate?: (newPasswordValues: Record<string, string>) => void;
    hasSubmittingError?: boolean;
    className?: string;
    setShowUpdateButton?: (isShow: boolean) => void;
    setInputValue?: (value: string) => void;
    validator?: StringSchema;
} & BoxProps;

const PasswordInput: FC<PasswordInputPropsType> = ({
    testId = 'password-input',
    placeholder = 'Password',
    name = 'password',
    label = 'Password',
    required,
    disabled,
    hasActiveValidator,
    showAutoGenerate,
    handlerAutoGenerate,
    hasSubmittingError,
    setShowUpdateButton,
    setInputValue,
    validator,
    ...restBoxProps
}) => {
    const [shouldShowPassword, setShouldShowPassword] = useState<boolean>(false);

    const { setFieldValue, setFieldError, values, errors, submitCount } = useFormikContext<FormikValuesType>();

    const repeatName = `repeat${name?.charAt(0).toUpperCase() + name?.slice(1)}`;

    useEffect(() => {
        setInputValue?.(values[name]);
    }, [values[name]]);

    useEffect(() => {
        if (submitCount > 0) {
            setShouldShowPassword(false);
        }
    }, [submitCount]);

    const togglePasswordVisibility = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        setShouldShowPassword((show) => !show);
    };

    const handlerOnFocus = () => {
        setShowUpdateButton?.(true);
    };

    const handlerOnBlur = () => {
        setShowUpdateButton?.(false);
    };

    const handleChange = (): void => {
        if (errors[repeatName] === PASSWORDS_DO_NOT_MATCH_MESSAGE) {
            setFieldError(repeatName, undefined);
        }
    };

    const inputProps = {
        endAdornment: (
            <InputAdornment position="end">
                <StyledIconButton
                    data-testid="toggle-visibility-button"
                    aria-label="toggle-password-visibility"
                    onMouseDown={togglePasswordVisibility}
                >
                    {shouldShowPassword ? <VisibilityOffStyled /> : <Visibility />}
                </StyledIconButton>
            </InputAdornment>
        ),
    };

    const handleAutoGenerateClick = () => {
        const newGeneratedPassword = generatePassword();

        setFieldValue(name, newGeneratedPassword);
        setFieldValue(repeatName, newGeneratedPassword);
        setFieldError(name, undefined);
        setFieldError(repeatName, undefined);

        setShouldShowPassword(true);
        handlerAutoGenerate?.({ [name]: newGeneratedPassword, [repeatName]: newGeneratedPassword });
    };

    return (
        <Box position="relative">
            {showAutoGenerate && (
                <AutoGenerateWrapper
                    data-testid="auto-generate-button"
                    display="flex"
                    alignItems="center"
                    marginBottom={0.5}
                    onClick={handleAutoGenerateClick}
                >
                    <AutoGenerateIcon />
                    <AutoGenerateText>Auto-generate</AutoGenerateText>
                </AutoGenerateWrapper>
            )}
            <LabeledInput
                disallowSpace
                name={name}
                testId={testId}
                label={label}
                required={required}
                inputProps={inputProps}
                placeholder={placeholder}
                type={shouldShowPassword ? 'text' : 'password'}
                disabled={disabled}
                hasPasswordActiveValidator={hasActiveValidator}
                hasSubmittingError={hasSubmittingError}
                handleFocus={handlerOnFocus}
                handleBlur={handlerOnBlur}
                onChange={handleChange}
                validator={validator}
                {...restBoxProps}
            />
        </Box>
    );
};

export default PasswordInput;
