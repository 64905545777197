import { InputAdornment } from '@mui/material';
import styled from 'styled-components';

export const CurrencyAdornment = styled(InputAdornment)`
    &&&& {
        margin: 0 -6px 0 0;
        padding-left: 16px;
        
        & .MuiTypography-root {
            color: ${({ theme }) => theme.palette.lightGrey};
            font-family: ${({ theme }) => theme.fonts.medium};
            text-align: right;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
`;
