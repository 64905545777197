import styled, { css } from 'styled-components';
import Switch from '@mui/material/Switch';

const mobileStyles = css`
    width: 36px;
    height: 20px;

    &:active .MuiSwitch-thumb {
            width: 14px;
    }

    .MuiSwitch-switchBase {
        padding: 3px;

        &.Mui-checked {
                transform: translateX(16px);
        }
    }

    .MuiSwitch-thumb {
        width: 14px;
        height: 14px;
    }
`;

const defaultStyles = css`
    width: 44px;
    height: 24px;

    &:active .MuiSwitch-thumb {
        width: 20px;
    }

    .MuiSwitch-switchBase {
        padding: 3px;

        &.Mui-checked {
           transform: translateX(20px);
        }
    }

    .MuiSwitch-thumb {
        width: 18px;
        height: 18px;
    }

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        ${mobileStyles}
    }
`;

export const StyledSwitch = styled(Switch)`
    && {
        display: flex;
        padding: 0;
        ${({ size }) => (size === 'small' ? mobileStyles : defaultStyles)}
        
        .MuiSwitch-thumb {
            box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
            border-radius: 12px;
        }

        .MuiSwitch-track {
            border-radius: 12px;
            opacity: 1;
            background-color: #C1C1C1;
            box-sizing: border-box;
        }

        .MuiSwitch-switchBase {
            color: #fff;

            &.Mui-checked + .MuiSwitch-track {
                opacity: 1;
                background-color: ${({ theme }) => theme.palette.snackbar.infoFont};
            }
        }
    }
`;
