import React, { ForwardedRef, forwardRef, useContext } from 'react';
import { SelectChangeEvent, SelectProps, TextFieldProps } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { Select } from 'src/components/Select';
import { ToolBarConditionTypes, UdbConditions } from 'src/constants';
import { OptionsType } from 'src/components/Select/Select';
import { DatePicker } from 'src/components/DatePicker';
import { DatePickerProps } from 'src/components/DatePicker/DatePicker';
import { TextInput } from 'src/components/Inputs/TextInput';
import { ConditionModelType, ConditionsParamsType, ToolbarItemContext } from 'src/@types/tool-bar';
import { ToolBarItemContext } from 'src/components/ToolBar/components/ToolBarContext';

type ValueInputProps = {
    elementProps?: DatePickerProps | SelectProps | TextFieldProps;
    currentConditionValue: keyof ConditionsParamsType;
    currentParamValue: string;
    title: string;
    inputType?: keyof typeof ToolBarConditionTypes;
    initialInputValue?: string | string[];
    onChange: (value: string | string[] | undefined) => void;
    options?: OptionsType[];
    onOpen?: () => void;
    onClose?: () => void;
};

export const getMaxDate = (
    conditionsModel: ConditionModelType[],
    paramValue: string,
    currentCondition: string,
): Dayjs | undefined => {
    if (![UdbConditions.MORE_THAN, UdbConditions.EQUAL_OR_MORE, 'from'].includes(currentCondition)) {
        return;
    }

    const filteredConditions = conditionsModel
        .filter(([param]) => param === paramValue)
        .filter(([, condition]) => !Object.keys(condition).includes(currentCondition));

    return filteredConditions.reduce<Dayjs | undefined>((maxDay, [, condition]) => {
        if (condition[UdbConditions.EQUAL_OR_LESS]) {
            return dayjs(condition[UdbConditions.EQUAL_OR_LESS] as string);
        }
        if (condition[UdbConditions.LESS_THAN]) {
            return dayjs(condition[UdbConditions.LESS_THAN] as string);
        }
        if (condition.to) {
            return dayjs(condition.to as string);
        }
        return maxDay;
    }, undefined);
};

export const getMinDate = (
    conditionsModel: ConditionModelType[],
    paramValue: string,
    currentCondition: string,
): Dayjs | undefined => {
    if (![UdbConditions.LESS_THAN, UdbConditions.EQUAL_OR_LESS, 'to'].includes(currentCondition)) {
        return;
    }

    const filteredConditions = conditionsModel
        .filter(([param]) => param === paramValue)
        .filter(([, condition]) => !Object.keys(condition).includes(currentCondition));

    return filteredConditions.reduce<Dayjs | undefined>((minDay, [, condition]) => {
        if (condition[UdbConditions.EQUAL_OR_MORE]) {
            return dayjs(condition[UdbConditions.EQUAL_OR_MORE] as string);
        }
        if (condition[UdbConditions.MORE_THAN]) {
            return dayjs(condition[UdbConditions.MORE_THAN] as string);
        }
        if (condition.from) {
            return dayjs(condition.from as string);
        }
        return minDay;
    }, undefined);
};

export const ValueInput = forwardRef(function ValueInput({
    title,
    elementProps,
    inputType,
    initialInputValue,
    onChange,
    options = [],
    currentConditionValue,
    currentParamValue,
    onOpen,
    onClose,
}: ValueInputProps, ref?: ForwardedRef<HTMLInputElement>) {
    const { conditionsModel } =
        useContext<ToolbarItemContext>(ToolBarItemContext);

    const isMultiSelectHasSingleAllowedValue = inputType === ToolBarConditionTypes.MULTI_SELECT && (
        [UdbConditions.EQUAL, UdbConditions.NOT_EQUAL, UdbConditions.IS_EMPTY, UdbConditions.IS_NOT_EMPTY]
            .includes(currentConditionValue as UdbConditions));

    const disabled = currentConditionValue === UdbConditions.IS_EMPTY ||
        currentConditionValue === UdbConditions.IS_NOT_EMPTY;
    const isDatePickerType = inputType === ToolBarConditionTypes.DATE || (
        inputType === ToolBarConditionTypes.DATE_TIME) || (inputType === ToolBarConditionTypes.DATE_OR_DATE_TIME);

    const inputProps = {
        ...elementProps,
        inputProps: {
            'data-testid': 'value-input',
            id: 'value-input',
        },
    };

    if (isMultiSelectHasSingleAllowedValue || inputType === ToolBarConditionTypes.SELECT) {
        return (
            <Select
                hasHeader
                title={title}
                disabled={disabled}
                testId="value"
                name="value"
                {...inputProps as SelectProps}
                onChange={(event: SelectChangeEvent<unknown>) => {
                    onChange(event.target.value as string);
                }}
                onOpen={onOpen}
                onClose={onClose}
                options={options}
                value={initialInputValue ?? ''}
                inputRef={ref}
            />
        );
    }

    if (inputType === ToolBarConditionTypes.MULTI_SELECT) {
        return (
            <Select
                hasHeader
                title={title}
                disabled={disabled}
                multiple
                {...inputProps as SelectProps}
                testId="value"
                name="value"
                renderValue={(selected) => Array.isArray(selected) && (
                    selected
                        .map((value) => options.find((option) => option.value === value)?.label)
                        .filter(Boolean)
                        .join(', ')
                )}
                onChange={(event: SelectChangeEvent<unknown>) => {
                    const value = event.target.value as string[];

                    onChange(value.length ? value : undefined);
                }}
                onOpen={onOpen}
                onClose={onClose}
                options={options}
                value={typeof initialInputValue === 'string' ? [initialInputValue] : initialInputValue ?? []}
                inputRef={ref}
            />
        );
    }

    if (isDatePickerType) {
        return (
            <DatePicker
                {...inputProps as DatePickerProps}
                disabled={disabled}
                maxDate={getMaxDate(conditionsModel, currentParamValue, currentConditionValue as string)}
                minDate={getMinDate(conditionsModel, currentParamValue, currentConditionValue as string)}
                dateOnly={inputType === ToolBarConditionTypes.DATE}
                dateTimeOnly={inputType === ToolBarConditionTypes.DATE_TIME}
                initialValue={initialInputValue as string ?? ''}
                handleDateChange={(date: string | undefined) => {
                    onChange(date as string);
                }}
                inputRef={ref}
            />
        );
    }

    return (
        <TextInput
            name={'input'}
            testId="input"
            value={initialInputValue ?? ''}
            disabled={disabled}
            type={inputType === ToolBarConditionTypes.NUMBER_FIELD ? 'number' : 'text'}
            placeholder={inputType === ToolBarConditionTypes.NUMBER_FIELD ? 'Enter number...' : 'Enter text...'}
            {...inputProps as TextFieldProps}
            onChange={(event) => {
                onChange(event.target.value);
            }}
            inputRef={ref}
        />
    );
});
