import React from 'react';
import { StyledBoldText } from 'src/components/Modals/ConfirmationModal/ConfirmationMessage.styles';
import { ConfirmationMessageData } from 'src/components/Modals/ConfirmationModal/ConfirmationMessages';
import { EditContactInformationFormValues } from 'src/components/Modals/EditContactInformationModal/EditContactInformationModal';
import { type FormattedContactInfoType } from 'src/pages/udb/ManageUser/components/ContactInformationSection/ContactInformationSection';

export const makeContactInformationConfirmMessages = (
    data: Partial<EditContactInformationFormValues>,
    contactsInformation: FormattedContactInfoType,
    phoneNumberRemoved: boolean,
    removedEmails: number[],
    countryNames: { from: string | null, to: string | null },
): ConfirmationMessageData[] => {
    const messages: ConfirmationMessageData[] = [];

    if (contactsInformation.phoneNumber?.phoneNumber && phoneNumberRemoved) {
        const text = ['Phone number', 'removed'];
        messages.push({
            content:
                <>
                    {text[0]}
                    <StyledBoldText> {contactsInformation.phoneNumber?.phoneNumber} </StyledBoldText>
                    {text[1]}
                </>,
            key: 'phone',
            testId: 'message-phone-number-removed',
        });
    }

    if (data.email && data.email !== contactsInformation.email) {
        const text = ['A confirmation link will be sent to', 'to finalize the change from'];
        messages.push({
            content:
                <>
                    {text[0]}
                    <StyledBoldText> {data.email} </StyledBoldText>
                    {text[1]}
                    <StyledBoldText> {contactsInformation.email} </StyledBoldText>
                </>,
            key: 'email',
            testId: 'message-email-changed',
        });
    }

    removedEmails.forEach((id, index) => {
        const email = contactsInformation.secondaryEmails?.find((e) => e.id === id);
        messages.push({
            content:
                <>
                    Additional email
                    <StyledBoldText> {email?.email} </StyledBoldText>
                    removed
                </>,
            key: `additional-email-${index}`,
            testId: `message-email-removed-${index}`,
        });
    });

    const isInitialCountryEmpty = !contactsInformation.country || contactsInformation.country === '';
    const isNewCountryEmpty = !data.country || data.country === '';

    if (data.country !== undefined && data.country !== contactsInformation.country) {
        if (!isInitialCountryEmpty && isNewCountryEmpty) {
            const text = ['Country selection of', 'removed'];
            messages.push({
                content:
                    <>
                        {text[0]}
                        <StyledBoldText> &quot;{countryNames.from}&quot; </StyledBoldText>
                        {text[1]}
                    </>,
                key: 'country',
                testId: 'message-country-unset',
            });
        }

        if (!isInitialCountryEmpty && !isNewCountryEmpty) {
            const text = ['Country changed from', 'to'];
            messages.push({
                content:
                    <>
                        {text[0]}
                        <StyledBoldText> {countryNames.from} </StyledBoldText>
                        {text[1]}
                        <StyledBoldText> {countryNames.to} </StyledBoldText>
                    </>,
                key: 'country',
                testId: 'message-country-changed',
            });
        }

        if (isInitialCountryEmpty && !isNewCountryEmpty) {
            messages.push({
                content: <>Country set to <StyledBoldText>{countryNames.to}</StyledBoldText></>,
                key: 'country',
                testId: 'message-country-set',
            });
        }
    }

    return messages;
};
