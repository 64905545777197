import styled from 'styled-components';
import { Box, Paper, Typography } from '@mui/material';
import { Button } from 'src/components/Buttons';

export const StyledPaper = styled(Paper)`
  && {
    display: flex;  
    flex-direction: column;
    max-width: 504px;
    border-radius: unset;
    box-shadow: 0 15px 40px 0 ${({ theme }) => theme.palette.shadow};
  }
`;

export const StyledLogo = styled(Box)`
    display: flex;
    padding: 28px 36px;
    justify-content: center;
    min-height: 34px;
    align-items: center;
    
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        min-height: 19px;
    }
`;

export const StyledPrice = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.bold};
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        font-size: 28px;
    }
  }
`;

export const StyledDescription = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.lightBlack};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        padding-left: 33px;
        padding-right: 33px;
    }
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    background: linear-gradient(90deg, #BB9451 0%, #A58F60 50%); 
      
    &:focus {
          outline: 2px solid #A58F60;
    }
  }
`;

export const StyledTitleDescription = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        max-width: 404px;

        & span {
            font-family: ${({ theme }) => theme.fonts.demiBold};
        }
    }
`;

export const StyledCaption = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        text-align: center;
        font-style: normal;
        line-height: normal;
        font-weight: 450;
        font-size: 16px;
    }
`;
