import { getGeolocationByIP } from './sso-api';
import { IpGeolocationData } from 'src/@types/ip-geolocation';

const cache: Record<string, Promise<IpGeolocationData>> = {};

export const getCachedGeolocationByIP = async (ip: string): Promise<IpGeolocationData> => {
    if (!cache[ip]) {
        cache[ip] = getGeolocationByIP(ip).catch(() => ({ ip }));
    }

    return cache[ip];
};
