export const findParentByClassName = (
    element: HTMLElement,
    className: string,
    maxDepth = 10,
): HTMLElement | undefined => {
    if (!element.parentElement || maxDepth < 1) {
        return undefined;
    }

    if (element.parentElement.classList.contains(className)) {
        return element.parentElement;
    }

    return findParentByClassName(element.parentElement, className, maxDepth - 1);
};
