import { configureStore, type PreloadedState } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { rootReducer, type RootState } from './root-reducer';
import {
    accessCodeListener,
    pageLoadedListener,
    clearCurrentUserListener,
    pageReloadedListener,
    setConfigListener,
    setCurrentUserListener,
} from './listeners';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    const store = configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
            setConfigListener.middleware,
            setCurrentUserListener.middleware,
            clearCurrentUserListener.middleware,
            pageLoadedListener.middleware,
            accessCodeListener.middleware,
            pageReloadedListener.middleware,
        ),
    });

    setupListeners(store.dispatch);

    return store;
};
